import {Button, Flex, Table} from "antd";
import React from "react";
import {Link} from "react-router-dom";
import t from "../../../../configs/i18n";
import useManageCompanies from "./callbacks/useManageCompanies";
import {PlusOutlined} from "@ant-design/icons";


export default function ManageComapany() {
    const manageCompanies = useManageCompanies();

    return (
        <Flex align={"center"} className="w-full h-full" vertical>
            <Flex className={"w-2/3 mt-8"} justify={"flex-end"}>
                <Link to="create">
                    <Button type={"primary"}><PlusOutlined/> {t.home.company.create}</Button>
                </Link>
            </Flex>
            <Table className="w-2/3 mt-8" columns={manageCompanies.columns} dataSource={manageCompanies.context.companies}/>
        </Flex>
    )
}