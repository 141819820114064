export enum Role {
    ADMIN = "ADMIN",
    GAMEMASTER = "GAMEMASTER",
    ORGANISER = "ORGANISER",
    SUPERGAMEMASTER = "SUPERGAMEMASTER",
    SUPERORGANISER = "SUPERORGANISER",
    NONE = "",
};


export class AccessDictionnary {
    value: {[sessionId: string]: Role[]};
    admin: boolean;

    public constructor() {
        this.value = {};
    }

    public addRole(sessionId: string, role: Role) {
        if (!this.value[sessionId]) {
            this.value[sessionId] = [];
        }
        if (role === Role.ADMIN) {
            this.admin = true;
        }
        this.value[sessionId].push(role);
    }

    public hasRights(sessionId: string, role: Role) {
        if (this.admin) return true;
        if (this.value[sessionId]?.includes(role)) return true;
        if (this.value[sessionId]?.includes(Role.SUPERGAMEMASTER) && role === Role.GAMEMASTER) return true;
        if (this.value[sessionId]?.includes(Role.SUPERORGANISER) && role === Role.ORGANISER) return true;
        if (role === Role.NONE) return true;
        return false;
    }

    public isGamemaster() {
        return this.admin || Object.values(this.value).some((roles) => roles.includes(Role.GAMEMASTER) || roles.includes(Role.SUPERGAMEMASTER));
    }
}