import {supabase} from "../../../configs/supabase";
import Session from "../Session/session";
import React from "react";
import {ErrorCode} from "../../services/const/ErrorCode";
import {RealtimeChannel} from "@supabase/supabase-js";
import Notification from "../../services/notification/Notification";
import t from "../../../configs/i18n";
import { Player } from "../Player/Player";

export class Team {
    id: string;
    name: string;
    gamemaster: string;
    step: number;
    subStep: number | undefined; // Use to know which players in a substep (ex: step video quizz)
    stepStartDate: Date | undefined;
    currentTimerStop: Date | undefined;
    totalTimerStop: number | undefined; // in milliseconds
    bonus: number; // in seconds, if negative it's a malus
    endTime: number;
    playerToAnswer: string;
    photo: string;
    points: number;
    otherPlayers: string[];
    visioLink: string;

    constructor(obj: any) {
        this.id = obj.id;
        this.name = obj.name;
        this.gamemaster = obj.gamemaster;
        this.step = obj.step;
        this.subStep = obj.sub_step;
        this.stepStartDate = obj.step_start_date ? new Date(obj.step_start_date) : undefined;
        this.currentTimerStop = obj.current_timer_stop ? new Date(obj.current_timer_stop) : undefined;
        this.totalTimerStop = obj.total_timer_stop;
        this.bonus = obj.bonus;
        this.endTime = obj.end_time ? new Date(obj.end_time).getTime() : 0;
        this.playerToAnswer = obj.player_to_answer;
        this.photo = obj.photo;
        this.points = obj.points;
        this.otherPlayers = obj.other_players ? obj.other_players instanceof Array ? obj.other_players : JSON.parse(obj.other_players) : [];
        this.visioLink = obj.visio_link;
    }

    public getNeedGamemaster(players: Player[] | undefined) : boolean {
        if (!players) {
            return false;
        }
        
        return players.find(player => player.need_gamemaster && player.team === this.id) !== undefined;
    }

    public static newTeam(obj: any) : Team | undefined {
        if (!obj) {
            return undefined;
        }
        return new Team(obj);
    }

    public static newTeams(obj: any[]) : Team[] {
        return obj.map((team: any) => new Team(team));
    }

    public getTotalTime(session?: Session) : number | undefined {
        if (!(session && this.endTime && session.startGameDate)) {
            return undefined;
        }
        return this.endTime - session.startGameDate.getTime() - (this.totalTimerStop || 0) - (this.bonus || 0) * 1000;
    }


    public static async byId(id: string | undefined, columns : string) : Promise<Team | undefined> {
        if (!id) {
            Notification.error(t.error.team, ErrorCode.EMPTY_VALUE);
            return undefined;
        }

        const query = await supabase
            .from("team")
            .select(columns)
            .eq("id", id);

        if (query.error) {
            Notification.error(t.error.team, query.error);
            return undefined;
        }

        return Team.newTeam(query.data?.at(0));
    }

    public static listenById(id: string | undefined, callback: (value: Team | undefined) => void) : RealtimeChannel | undefined {
        if (!id) {
            Notification.error(t.error.team, ErrorCode.EMPTY_VALUE);
            return undefined;
        }

        return supabase
            .channel(`team-${id}`)
            .on("postgres_changes",
                {
                    event: 'UPDATE',
                    schema: 'public',
                    table: 'team',
                    filter: `id=eq.${id}`
                }, (payload) => {
                    callback(Team.newTeam(payload.new));
                }).subscribe();
    }

    public static async bySession(session: Session | undefined, columns: string) : Promise<Team[]> {
        if (!session || !session.id) {
            Notification.error(t.error.teams, ErrorCode.EMPTY_VALUE);
            return [];
        }

        const teamQuery = await supabase
            .from("team")
            .select(columns)
            .eq("session", session.id)
            .order("created_at", {ascending: true})

        if (teamQuery.error) {
            Notification.error(t.error.teams, teamQuery.error);
            return [];
        }

        return Team.newTeams(teamQuery.data);
    }

    public static listenInSession(session: Session | undefined, setTeams: (value: React.SetStateAction<Team[]>) => void) {
        if (!session || !session.id) {
            Notification.error(t.error.teams, ErrorCode.EMPTY_VALUE);
            return
        }

        return supabase
            .channel(`team-session-${session.id}`)
            .on("postgres_changes",
            {
                schema: 'public',
                table: "team",
                filter: `session=eq.${session.id}`,
                event: '*',
            }, (payload) => {
                if (payload.eventType === "INSERT") {
                    const newTeam = Team.newTeam(payload.new)
                    if (!newTeam) {
                        return
                    }
                    setTeams(currentTeams => [...currentTeams, newTeam]);
                }
                else if (payload.eventType === "UPDATE") {
                    const updatedTeam = Team.newTeam(payload.new)
                    if (!updatedTeam) {
                        return
                    }
                    setTeams(currentTeams => currentTeams.map(team => team.id === updatedTeam.id ? updatedTeam : team))
                }
                else if (payload.eventType === "DELETE") {
                    const deletedTeam = Team.newTeam(payload.old)
                    if (!deletedTeam) {
                        return
                    }
                    setTeams(currentTeams => currentTeams.filter(team => team.id !== deletedTeam.id))
                }
            }).subscribe();
    }
}
