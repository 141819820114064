import {Button, Flex, Modal} from "antd";
import React, {useEffect} from "react";
import {PauseCircleOutlined} from "@ant-design/icons";
import {supabase} from "../../../configs/supabase";
import {Team} from "../../models/Team/Team";
import Notification from "../../services/notification/Notification";
import t from "../../../configs/i18n";

interface TimerPausedPopupProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    team: Team | undefined;
    callback?: () => void;
}


export default function TimerPausedPopup({open, setOpen, team, callback} : TimerPausedPopupProps) {
    const stopTimer = async () => {
        const response = await supabase
            .rpc("stop_timer", {
                team_id: team?.id
            })

        if (response.error) {
            Notification.error(t.error.chrono, response.error);
        }
    }

    useEffect(() => {
        if (!open) {
            return;
        }

        if (!team?.currentTimerStop) {
            stopTimer();

        }
    }, [open]);

    const validatePopup = () => {
        setOpen(false);
        if (callback) {
            callback();
        }
    }

    return (
        <Modal closeIcon={false} className={"max-w-[500px]"} footer={false} open={open}>
            <Flex className={" text-center mt-8"} align={"center"} justify={"center"} vertical>
                <PauseCircleOutlined className={"text-6xl text-gray-500"} />
                <h3>{t.sessionGame.timerPausedPopup.title}</h3>
                <p>{t.sessionGame.timerPausedPopup.message}</p>
                <Button className={"mt-6"} size={"large"} type={"primary"} onClick={validatePopup}>{t.sessionGame.timerPausedPopup.button}</Button>
            </Flex>
        </Modal>
    )
}