import { useState } from "react";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import ImageRatioBackground from "../../../shared/components/image/ImageRatioBackground";
import InvisibleButton from "../../../shared/components/button/InvisibleButton";
import PasswordModalStep10 from "./components/PasswordModal.step10";

export default function ItStep10() {
    const context = useGameContext();
    const [open, setOpen] = useState<boolean>(false);


    return (
        <ImageRatioBackground bgImage={context.step?.description.background} >
            <InvisibleButton onClick={() => setOpen(true)} top={9} left={40} h={43} w={39.5} posInPercent/>
            <PasswordModalStep10 open={open} setOpen={setOpen} />
        </ImageRatioBackground>
    )
}