import {BaseHintProps} from "../../../types/BaseHintProps";
import {Flex} from "antd";
import ImageR from "../../../../shared/components/image/ImageR";
import {useGameContext} from "../../../../pages/session_game/context/context.game";

export default function Hint2EcoStep7({number}: BaseHintProps) {
    const context = useGameContext();
    const image = `${context.step?.description.hint2}${number}.png`;

    return (
        <Flex align={"center"} justify={"center"} vertical>
            <ImageR src={image} width={"520px"} height={"500px"} className={"object-scale-down mt-5"} />
        </Flex>
    )
}