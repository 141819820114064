import {Button, Flex} from "antd";
import {useEffect, useMemo, useRef, useState} from "react";
import {supabase} from "../../../configs/supabase";
import StepProps from "../../types/StepProps";
import WaitingForPlayers from "../../../shared/components/popup/WaitingForPlayers";
import TimerPausedPopup from "../../../shared/components/popup/TimerPausedPopup";
import Notification from "../../../shared/services/notification/Notification";
import t from "../../../configs/i18n";
import PopupConfirmation from "./PopupConfirmation";
import { useGameContext } from "../../../pages/session_game/context/context.game";
import { Role } from "../../../shared/models/UserData/Role";
import scenarios from "../../scenarios";

interface SubStepVideoProps {
    video: string;
    stepContext: StepProps;
}

export default function SubStepVideo({video, stepContext} : SubStepVideoProps) {
    const [open, setOpen] = useState<boolean>(false);
    const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
    const [timerPausedPopup, setTimerPausedPopup] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const context = useGameContext();
    const isSolo = useMemo(() => scenarios[context.session?.scenario ?? ''].solo, [context.session?.scenario]);
    const isTablet = useMemo(() => scenarios[context.session?.scenario ?? ''].tablet, [context.session?.scenario]);
    useEffect(() => {
        setTimerPausedPopup(!context.user?.hasRights(Role.GAMEMASTER, context.session?.id));
    }, []);


    const validateSubStep = async () => {
        setConfirmationOpen(false);
        if (!context.session?.scenario || isSolo || isTablet) {
            const response = await supabase
                .from("team")
                .update({
                    sub_step: 0
                })
                .eq("id", stepContext.team?.id)

            if (response.error) {
                Notification.error(t.error.validateStep, response.error);
                return;
            }

            const responseRestartTimer = await supabase
                .rpc("restart_timer", {
                    team_id: stepContext.team?.id
                })
            
            if (responseRestartTimer.error) {
                Notification.error(t.error.validateStep, responseRestartTimer.error);
                return;
            }

            return;
        } else if (!stepContext.currentUser?.hasRights(Role.GAMEMASTER, context.session?.id)) {
            const response = await supabase
                .from("player")
                .update({
                    step_validated: true
                })
                .eq("id", stepContext.player?.playerId)

            if (response.error) {
                Notification.error(t.error.validateStep, response.error);
                return;
            }
        } else {
            setOpen(true);
        }


        if (videoRef.current) {
            videoRef.current.pause();
        }
    }

    const startVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }
    
    return (
        <Flex align="center" className={!isSolo ? "pl-6 pt-2 pr-6" : ""} vertical>
            <TimerPausedPopup callback={startVideo} team={stepContext?.team} open={timerPausedPopup} setOpen={setTimerPausedPopup} />
            <PopupConfirmation open={confirmationOpen} setOpen={setConfirmationOpen} onOk={validateSubStep}/>
            <WaitingForPlayers setOpen={setOpen} currentUser={stepContext.currentUser} team={stepContext.team} playersTeam={stepContext.playersTeam} open={open}/>
            {
                isSolo ?
                    <>
                        <video ref={videoRef} controls={true} src={video} style={{maxHeight: "80vh", maxWidth: "98%", objectFit: "contain"}} />
                        <Button onClick={() => setConfirmationOpen(true)} size={"large"} className={"mt-[2vh] w-[15%]"} type={"primary"}>{t.sessionGame.goToQuizz}</Button>
                    </>
                :
                    <>
                        <video ref={videoRef} controls={true} src={video} className={"w-full"}/>
                        <Button onClick={() => setConfirmationOpen(true)} size={"large"} className={"mt-2"} type={"primary"}>{t.sessionGame.goToQuizz}</Button>
                    </>
            }
        </Flex>
    )
}