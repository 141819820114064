import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import {Button, Flex, Form} from "antd";
import t from "../../../../configs/i18n";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import Notification from "../../../../shared/services/notification/Notification";
import InputCategory from "./InputCategory";
import React, { useRef, useState } from "react";
import MouseFloatingText from "../../../../shared/components/mouse/MouseFloatingText";

interface Props {
    onValid?: () => void;
    popup?: boolean;
    setOpenDetails?: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export default function ItStep10PasswordContent(props: Props) {
    const context = useGameContext();

    const [form] = Form.useForm();
    const answers = context.step?.description.answers;

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [onHover, setOnHover] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    
    const updateButton = (value: string, id: string) => {
        // Check if all fields have values
        const allFieldsFilled = Object.values(form.getFieldsValue() as string[]).every(
            (value) => value !== undefined && value !== ""
        );
        setIsButtonDisabled(!allFieldsFilled); // Disable if not all fields are filled
    };

    const validate = () => {
        const values = Object.values(form.getFieldsValue());

        for (const answer of answers) {
            if (!values.includes(answer)) {
                Notification.error(t.sessionGame.wrongAnswers);
                return;
            }
        }

        props.onValid && props.onValid();
    }


    return (
        <>
            <div ref={componentRef}></div>
            {(props.popup === undefined || props.popup) && <CanClosePopup/>}
            <Flex className={"h-full overflow-y-auto mt-8 border-solid border-green-dark rounded-xl p-8"} vertical>
                <InputCategory title={context.step?.description.category1} min={1} group={1} form={form} onChange={updateButton} setOpenDetails={(open: boolean) => props.setOpenDetails && props.setOpenDetails(old => old.map((value, index) => index === 0 ? open : value))} />
                <InputCategory title={context.step?.description.category2} min={5} group={2} form={form} onChange={updateButton} setOpenDetails={(open: boolean) => props.setOpenDetails && props.setOpenDetails(old => old.map((value, index) => index === 1 ? open : value))} />
                <InputCategory title={context.step?.description.category3} min={9} group={3} form={form} onChange={updateButton} setOpenDetails={(open: boolean) => props.setOpenDetails && props.setOpenDetails(old => old.map((value, index) => index === 2 ? open : value))} />
                <InputCategory title={context.step?.description.category4} min={13} group={4} form={form} onChange={updateButton} setOpenDetails={(open: boolean) => props.setOpenDetails && props.setOpenDetails(old => old.map((value, index) => index === 3 ? open : value))} />
            </Flex>
            <Flex justify={"center"} className={"mt-10"}>
                <div onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
                    <Button onClick={validate} type={"primary"} disabled={isButtonDisabled}>{t.common.validate}</Button>
                </div>
            </Flex>
            <MouseFloatingText text={t.common.allFieldsFilled} start={componentRef.current?.getBoundingClientRect()} condition={onHover && isButtonDisabled} />
        </>
    )
}