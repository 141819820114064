import React, {useMemo} from "react";
import {Flex} from "antd";

interface TimeVisualizerProps {
    timestampDifference?: number | undefined;
    timestamp?: number | undefined;
    bgColor?: string;
    textColor?: string;
    showSeconds?: boolean;
}

export default function TimeVisualizer({timestampDifference, timestamp, bgColor = "bg-white", textColor = "text-green-dark", showSeconds = true}: TimeVisualizerProps) {
    const hoursString = useMemo(() => {
        if (timestampDifference) {
            return Math.max(Math.floor((timestampDifference / (1000 * 60 * 60)) % 24), 0).toString().padStart(2, '0');
        }
        if (timestamp) {
            const date = new Date(timestamp);
            return date.getHours().toString().padStart(2, '0');
        }
        return "00";

    }, [timestamp, timestampDifference]);

    const minutesString = useMemo(() => {
        if (timestampDifference) {
            return Math.max(Math.floor((timestampDifference / (1000 * 60)) % 60), 0).toString().padStart(2, '0');
        }
        if (timestamp) {
            const date = new Date(timestamp);
            return date.getMinutes().toString().padStart(2, '0');
        }
        return "00";

    }, [timestamp, timestampDifference]);

    const secondsString = useMemo(() => {
        if (timestampDifference) {
            return Math.max(Math.floor((timestampDifference / 1000) % 60), 0).toString().padStart(2, '0');
        }
        if (timestamp) {
            const date = new Date(timestamp);
            return date.getSeconds().toString().padStart(2, '0');
        }
        return "00";


    }, [timestamp, timestampDifference]);

    return (
        <Flex align={"center"} justify={"center"} className={`font-bold ${textColor}`}>
            <Flex align={"center"} justify={"center"} className={`${bgColor} w-10 h-10 rounded-lg shadow`}>
                <p >{hoursString}</p>
            </Flex>
            <p className={"ml-1"}>:</p>
            <Flex align={"center"} justify={"center"} className={`ml-1 ${bgColor} w-10 h-10 rounded-lg shadow`}>
                <p >{minutesString}</p>
            </Flex>
            {showSeconds && <>
                <p className={"ml-1"}>:</p>
                <Flex align={"center"} justify={"center"} className={`ml-1 ${bgColor} w-10 h-10 rounded-lg shadow`}>
                    <p >{secondsString}</p>
                </Flex>
            </>}
        </Flex>
    )


}