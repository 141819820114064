import {Flex, Modal} from "antd";
import {Player} from "../../../../shared/models/Player/Player";
import PlayerHint from "./PlayerHintButton";
import {Team} from "../../../../shared/models/Team/Team";
import {useEffect, useState} from "react";
import t from "../../../../configs/i18n";

interface MissingPlayersHintPopupProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    playersTeam: Player[];
    team: Team | undefined;
}

export default function MissingPlayersHintPopup({open, setOpen, playersTeam, team}: MissingPlayersHintPopupProps) {
    const [missingPlayers, setMissingPlayers] = useState<number[]>([]);

    useEffect(() => {
        const missingPlayers = [1, 2, 3, 4].filter(number => !playersTeam.find(player => player.number === number));
        setMissingPlayers(missingPlayers);
    }, [playersTeam]);

    return (
       <Modal open={open} onCancel={() => setOpen(false)} footer={false}>
           <Flex gap={"middle"} className={"mt-6 mb-12"} align={"center"} justify={"center"} vertical>
               <h2 className={"text-green-dark"}>{t.sessionGame.hintPopup.title}</h2>
               {missingPlayers.length === 0 && <p>{t.sessionGame.hintPopup.noPlayer}</p>}
               <Flex gap={"middle"} justify={"center"}>
                   {missingPlayers.includes(1) && <PlayerHint team={team} number={1}/>}
                   {missingPlayers.includes(2) && <PlayerHint team={team} number={2}/>}
               </Flex>
               <Flex gap={"middle"} justify={"center"}>
                   {missingPlayers.includes(3) && <PlayerHint team={team}  number={3}/>}
                   {missingPlayers.includes(4) && <PlayerHint team={team}  number={4}/>}
               </Flex>
           </Flex>
       </Modal>
    )
}