import {useHomeContext} from "../../../../context/context.home";
import useHome from "../../../../callbacks/useHome";
import {useSessionInfoContext} from "../context/context.info.session";

export default function useSessionInfo() {
    const context = useSessionInfoContext();

    return {
        context
    }
}