import StepProps from "../../types/StepProps";
import {Flex} from "antd";
import ButtonsStep6 from "./components/buttons.step6";
import ImageR from "../../../shared/components/image/ImageR";
import {useGameContext} from "../../../pages/session_game/context/context.game";

export default function ItStep6() {
    const context = useGameContext();
    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }

    const backgroundUrl = `${context.step?.description.background}${stepContext.player?.number}.webp`;

    return (
        <Flex align={"flex-start"} className={"w-[1000px] h-[640px] relative"}>
            <ImageR src={backgroundUrl} width={"1000px"} height={"600px"} className={"object-scale-down"}/>
            {stepContext.player?.number === 1 && <ButtonsStep6 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={1} mainHintPos={{x: 770, y: 10, h: 220, w: 200}} />}
            {stepContext.player?.number === 2 && <ButtonsStep6 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={2} mainHintPos={{x: 25, y: 355, h: 55, w: 180}} />}
            {stepContext.player?.number === 3 && <ButtonsStep6 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={3} mainHintPos={{x: 770, y: 10, h: 220, w: 205}} />}
            {stepContext.player?.number === 4 && <ButtonsStep6 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={4} mainHintPos={{x: 25, y: 355, h: 55, w: 180}} passwordPos={{x: 595, y: 485, h: 90, w: 75 }}/>}
        </Flex>
    )
}