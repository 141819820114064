import InvisibleButton from "../../../../shared/components/button/InvisibleButton";
import {useState} from "react";
import StepProps from "../../../types/StepProps";
import HintModalStep1 from "./HintModal.step1";
import PasswordModalStep1 from "./PasswordModal.step1";

interface ButtonsStep4Props {
    showHint: boolean;
    playerNumber: number;
    mainHintPos: {x: number, y: number, h: number, w: number};
    stepContext: StepProps;
    buttonId: string;
}

export default function ButtonsStep1({playerNumber, showHint, mainHintPos, stepContext, buttonId}: ButtonsStep4Props) {
    const [hintModal, setHintModal] = useState<boolean>(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);



    return (
        <>
            <HintModalStep1 open={hintModal} setOpen={setHintModal} number={playerNumber}/>
            <PasswordModalStep1 stepContext={stepContext} open={openPasswordModal} setOpen={setOpenPasswordModal} />


            {/* Main Hint Button */}
            {/*<InvisibleButton onHover={() => setHintModal(true)} top={mainHintPos.y} left={mainHintPos.x} h={mainHintPos.h} w={mainHintPos.w} showHint={showHint}/>*/}
            {/* Password Modal */}
            <InvisibleButton onClick={() => setOpenPasswordModal(true)} top={210} left={860} h={50} w={50} showHint={showHint} id={buttonId}/>
        </>
    )
}