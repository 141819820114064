import {getCurrentLanguage} from "../../../configs/i18n";

export default class DateFormatter {
    private static formater = new Intl.DateTimeFormat(getCurrentLanguage(), {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
    public static format(date: Date): string {
        return this.formater.format(date);
    }
}
