import React from "react";

interface InvisibleButtonProps {
    top: number
    left: number
    h: number
    w: number
    dev?: boolean
    onClick?: () => void
    onHover?: () => void
    stopHover?: () => void
    showHint?: boolean,
    id?: string,
    posInPercent?: boolean
}

export default function InvisibleButton({top, left, h, w, dev = false, onClick, onHover, stopHover, showHint = false, id, posInPercent}: InvisibleButtonProps) {
    const unit: string = posInPercent ? "%" : "px"
    return <button onClick={onClick} onMouseOver={onHover} onMouseOut={stopHover} id={id} style={{
        top: `${top}${unit}`,
        left: `${left}${unit}`,
        height: `${h}${unit}`,
        width: `${w}${unit}`,
        position: "absolute",
        border: showHint ? "4px solid lightgreen" : "none",
        backgroundColor: dev ? "red" : "transparent",
        cursor: "pointer",
        zIndex: 40,
        opacity: dev ? 0.5 : 1
    }}/>
}