import {Button, Flex} from "antd";
import Question from "./type/Question";
import {useEffect, useMemo, useState} from "react";
import QuestionGroup from "./QuestionGroup";
import {supabase} from "../../../configs/supabase";
import {InputValue} from "../../../shared/models/Team/InputValue";
import AppSpinner from "../../../shared/components/spinner/AppSpinner";
import QuestionDescription from "./QuestionDescription";
import {Player} from "../../../shared/models/Player/Player";
import Notification from "../../../shared/services/notification/Notification";
import SyncService from "../../../shared/services/sync/SyncService";
import t from "../../../configs/i18n";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import { Role } from "../../../shared/models/UserData/Role";
import scenarios from "../../scenarios";

interface QuizzProps {
    question: Question
    callbackValidate: () => void;
    playerAnswering?: Player | undefined;
}

export default function Quizz({question, callbackValidate, playerAnswering}: QuizzProps) {
    const [userAnswers, setUserAnswers] = useState<string[]>([])
    const [showDescription, setShowDescription] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const stepContext = useGameContext();
    const scenario = useMemo(() => scenarios[stepContext?.session?.scenario || ""], [stepContext?.session?.scenario]);
    const points : number = useMemo(() => stepContext.step?.description.questionPoints || 0, [stepContext.step?.description.questionPoints]);

    const id = useMemo(() => `question-${stepContext.team?.id}-${stepContext.team?.step}-${stepContext.team?.subStep}`, [stepContext.team?.id, stepContext.team?.step, stepContext.team?.subStep])

    const isValid = () => {
        return question.validAnswers.every(answer => userAnswers.includes(question.answers[answer]));
    }

    const validate = async () => {
        const response = await supabase
            .from("input")
            .update({
                validated: true
            })
            .eq("id", id)
        
            const valid: boolean = isValid()

        if (stepContext.player?.getId() === playerAnswering?.getId() && !stepContext.user?.hasRights(Role.GAMEMASTER, stepContext.session?.id)) {
            const time = question.bonus;

            const responseBonus = await supabase
                .from("team")
                .update({
                    bonus: (stepContext.team?.bonus || 0) + (valid ? time : -time),
                    points: (stepContext.team?.points || 0) + (valid ? points : 0),
                    player_to_answer: stepContext.playersTeam[Math.floor(Math.random() * stepContext.playersTeam.length)].playerId,
                })
                .eq("id", stepContext.team?.id)

            if (responseBonus.error) {
                Notification.error(t.error.validateStep, responseBonus.error);
                return;
            }
        }

        if (response.error) {
            Notification.error(t.error.validateStep, response.error);
            return;
        }

        setShowDescription(true)
    }

    const fetchData = async () => {
        setLoading(true)
        const reponse = await supabase
            .from("input")
            .select("*")
            .eq("id", id)

        if (reponse.error) {
            Notification.error(t.error.common, reponse.error)
            return;
        }

        const inputValue = InputValue.newInputValue(reponse.data?.at(0))
        setUserAnswers(JSON.parse(inputValue?.value || "[]"))
        setShowDescription(inputValue?.validated || false)
        setLoading(false);

        supabase
            .channel(`${id}`)
            .on("postgres_changes",
                {
                    schema: 'public',
                    table: 'input',
                    filter: `id=eq.${id}`,
                    event: '*',
                }, (payload) => {
                    if (payload.eventType === "DELETE") {
                        if (payload.old.id === id) {
                            setUserAnswers([])
                            setShowDescription(false)
                        }
                        return;
                    }
                    const inputValue = InputValue.newInputValue(payload.new)
                    setUserAnswers(JSON.parse(inputValue?.value || "[]"))
                    setShowDescription(inputValue?.validated || false)
                }).subscribe();
    }

    useEffect(() => {
        fetchData();
        SyncService.register("fetchDataQuestion", fetchData);
    }, [id]);

    const restartTimer = async () => {
        if (!stepContext.team?.currentTimerStop && stepContext.team?.playerToAnswer === stepContext.player?.playerId) {
            const response = await supabase
                .rpc("restart_timer", {
                    team_id: stepContext.team?.id
                })

            if (response.error) {
                Notification.error(t.error.chrono, response.error);
            }
        }
    }

    useEffect(() => {
        restartTimer();
    }, [stepContext.player?.playerId, stepContext.team?.currentTimerStop, stepContext.team?.playerToAnswer]);


    if (loading) {
        return <AppSpinner/>
    }


    return (
        <Flex align={"center"} className={"text-center"} vertical>
            {!showDescription && <>
                <h2 className={"text-green-dark"}>Question {(stepContext.team?.subStep || 0) + 1}</h2>
                <QuestionGroup disabled={playerAnswering?.getId() !== stepContext.player?.getId()}  question={question} id={id} answers={userAnswers}/>
                {!scenario.solo && !scenario.tablet && <p className={"text-red-500"}>{t.common.only} {playerAnswering?.getName()} {t.sessionGame.canAnswer}</p>}
                <Button disabled={playerAnswering?.getId() !== stepContext.player?.getId() || userAnswers.length === 0} className={"mt-10"} onClick={validate} size={"large"} type={"primary"}>{t.common.validate}</Button>
            </>}
            {showDescription && <>
                <QuestionDescription question={question} validated={isValid()}/>
                <Button className={"mt-10"} onClick={() => callbackValidate()} size={"large"} type={"primary"}>{t.common.continue}</Button>
            </>}
        </Flex>
    )
}