import InvisibleButton from "../../../shared/components/button/InvisibleButton";
import {useEffect, useState} from "react";
import PasswordModal from "./components/PasswordModal";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import ImageRatioBackground from "../../../shared/components/image/ImageRatioBackground";
import HintModal from "./components/HintModal";


export default function ItStep2() {
    const context = useGameContext();
    const [open, setOpen] = useState(false);
    const [hintModals, setHintModals] = useState<boolean[]>([false, false, false, false, false, false, false]);
    const modalInfos = [
        {pos: {top: 9, left: 70.5, h: 18.5, w: 10.8}, dummy: false},
        {pos: {top: 41.3, left: 21, h: 14, w: 8.2}, dummy: false},
        {pos: {top: 10.1, left: 4.3, h: 14, w: 8.2}, dummy: false},
        {pos: {top: 17, left: 87.7, h: 18.5, w: 10.8}, dummy: false},
        {pos: {top: 38.6, left: 79.1, h: 18.5, w: 10.8}, dummy: true},
        {pos: {top: 20.5, left: 18.1, h: 14, w: 8.2}, dummy: true},
        {pos: {top: 30.8, left: 7.25, h: 14, w: 8.2}, dummy: true},
    ]

    useEffect(() => {
        if (context.solution?.solutionOn) {
            setOpen(true);
        }
    }, [context.solution?.solutionOn]);

    return (
        <ImageRatioBackground bgImage={context.step?.description.background}>
            {modalInfos.map(({pos, dummy}, index) => (
                <>
                    <HintModal open={hintModals[index]} setOpen={state => setHintModals(prevState => prevState.map((_, i) => i === index ? state : _))} playerNumber={index + 1} />
                    <InvisibleButton onClick={() => setHintModals(prevState => prevState.map((_, i) => i === index ? true : _))} showHint={!dummy && context.showHint} top={pos.top} left={pos.left} h={pos.h} w={pos.w} posInPercent/>
                </>
            ))}
            <PasswordModal open={open} setOpen={setOpen}/>
            <InvisibleButton onClick={() => setOpen(true)} showHint={context.showHint} top={50.5} left={47} h={10} w={5.5} posInPercent/>
        </ImageRatioBackground>
    )
}