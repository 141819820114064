import {Flex, List} from "antd";
import TotalTeamTimer from "../../../../../../shared/components/time/TotalTeamTimer";
import {FieldTimeOutlined} from "@ant-design/icons";
import React from "react";
import {Team} from "../../../../../../shared/models/Team/Team";
import {useSessionResultContext} from "../context/context.result.session";
import t from "../../../../../../configs/i18n";


interface Props {
    index: number;
    team: Team;

}

export default function SessionResultTeamRow(props: Props) {
    const context = useSessionResultContext();

    return (
        <List.Item className={"w-full"}>
            <Flex className={"w-full"} justify={"space-between"}>
                <Flex justify={"center"} vertical>
                    <Flex gap={"10px"} align={"center"}>
                        <p>{props.index + 1}</p>
                        <h3>{props.team?.name}</h3>
                        {props.team?.endTime && <h3>- {t.common.finished}</h3>}
                    </Flex>
                    <Flex gap={"10px"}>
                        <p>
                            {context.players
                                .filter(player => player.team as unknown as string === props.team.id)
                                .map(player => player.getName())
                                .join(' - ')
                            }
                        </p>
                    </Flex>
                </Flex>
                <Flex justify={"center"} align={"center"} vertical>
                    <TotalTeamTimer team={props.team} session={context.session}/>
                    {props.team?.bonus !== undefined && <Flex>
                        <FieldTimeOutlined />
                        {props.team?.bonus! > 0 && (<p className={"ml-2 text-[10px] text-green-light font-bold"}>- {Math.abs(props.team.bonus)} s</p>)}
                        {props.team?.bonus! < 0 && (<p className={"ml-2 text-[10px] text-red-500 font-bold"}>+ {Math.abs(props.team.bonus)} s</p>)}
                    </Flex>}
                </Flex>
            </Flex>
        </List.Item>
    )
}