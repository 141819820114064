import StepProps from "../../../types/StepProps";
import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Flex, FormInstance, Modal} from "antd";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import t from "../../../../configs/i18n";
import { UserOutlined } from "@ant-design/icons";
import ImageR from "../../../../shared/components/image/ImageR";

interface InputCategoryProps {
    group: number;
    form: FormInstance;
    title: string;
    min: number;
    onChange?: (value: string, id: string) => void;
    setOpenDetails: (open: boolean) => void;
}

export default function InputCategory({title, min, group, form, onChange, setOpenDetails} : InputCategoryProps) {
    const context = useGameContext();
    const [userAnswers, setUserAnswers] = useState<Map<string, string>>(new Map());
    const [valid, setValid] = useState<boolean | undefined>(undefined);
    const answers : string[] = context.step?.description.answers;
    const descriptions : string[] = context.step?.description.descriptions;
    const [loadingArray, setLoadingArray] = useState<boolean[]>([true, true, true, true]);
    const loading = useMemo(() => loadingArray.some(value => value), [loadingArray]);
    const load = (index: number) => setLoadingArray(oldArray => oldArray.map((value, i) => i === index ? false : value))

    const validAnswers = useMemo(() => {
        const start = (group - 1) * 4;
        return answers.slice(start, start + 4);
    }, [answers, group])

    const onChangeInput = (value: string, id: string) => {
        setUserAnswers(oldMap => new Map(oldMap.set(id, value)))
        onChange && onChange(value, id);
    }


    useEffect(() => {
        const userAnswersSet = new Set(Array.from(userAnswers.values()))
        const allAnswersValid = validAnswers.every(answer => userAnswersSet.has(answer))

        if (!loading) { // Conditions pour éviter que le modal s'ouvre au premier chargement
            if (valid !== undefined && valid === false && allAnswersValid) {
                setOpenDetails(true)
            }
            setValid(allAnswersValid)
        }
    }, [userAnswers, validAnswers, form.getFieldsValue()])

    const bgColor = useMemo(() => valid ? "bg-green-400 focus:bg-green-400 hover:bg-green-400 disabled:bg-green-400 disabled:text-gray-700" : "", [valid])

    return (
        <>
            <h2 className={"text-green-dark w-full text-center"}>{title}</h2>
            <Flex align={"center"}>
                <SyncronizedInput fetchCallback={() => load(0)} onChange={onChangeInput} className={`w-[50px] m-2 ${bgColor}`} form={form} id={`${min}-${context.team?.id}-${context.team?.step}`} number={group} player={context.player} playersTeam={context.playersTeam} isDisabled={valid}/>
                {answers.includes(userAnswers.get(`${min}-${context.team?.id}-${context.team?.step}`) || "") && <p className="w-[80%]">{descriptions[answers.findIndex(value => value === userAnswers.get(`${min}-${context.team?.id}-${context.team?.step}`))]}</p>}
                {answers.includes(userAnswers.get(`${min}-${context.team?.id}-${context.team?.step}`) || "") && <ActionType index={answers.findIndex(value => value === userAnswers.get(`${min}-${context.team?.id}-${context.team?.step}`))}/>}
            </Flex>
            <Flex align={"center"}>
                <SyncronizedInput fetchCallback={() => load(1)} onChange={onChangeInput} className={`w-[50px] m-2 ${bgColor}`} form={form} id={`${min + 1}-${context.team?.id}-${context.team?.step}`} number={group} player={context.player} playersTeam={context.playersTeam} isDisabled={valid}/>
                {answers.includes(userAnswers.get(`${min + 1}-${context.team?.id}-${context.team?.step}`) || "") && <p className="w-[80%]">{descriptions[answers.findIndex(value => value === userAnswers.get(`${min + 1}-${context.team?.id}-${context.team?.step}`))]}</p>}
                {answers.includes(userAnswers.get(`${min + 1}-${context.team?.id}-${context.team?.step}`) || "") && <ActionType index={answers.findIndex(value => value === userAnswers.get(`${min + 1}-${context.team?.id}-${context.team?.step}`))}/>}
            </Flex>
            <Flex align={"center"}>
                <SyncronizedInput fetchCallback={() => load(2)} onChange={onChangeInput} className={`w-[50px] m-2 ${bgColor}`} form={form} id={`${min + 2}-${context.team?.id}-${context.team?.step}`} number={group} player={context.player} playersTeam={context.playersTeam} isDisabled={valid}/>
                {answers.includes(userAnswers.get(`${min + 2}-${context.team?.id}-${context.team?.step}`) || "") && <p className="w-[80%]">{descriptions[answers.findIndex(value => value === userAnswers.get(`${min + 2}-${context.team?.id}-${context.team?.step}`))]}</p>}
                {answers.includes(userAnswers.get(`${min + 2}-${context.team?.id}-${context.team?.step}`) || "") && <ActionType index={answers.findIndex(value => value === userAnswers.get(`${min + 2}-${context.team?.id}-${context.team?.step}`))}/>}
            </Flex>
            <Flex align={"center"}>
                <SyncronizedInput fetchCallback={() => load(3)} onChange={onChangeInput} className={`w-[50px] m-2 ${bgColor}`} form={form} id={`${min + 3}-${context.team?.id}-${context.team?.step}`} number={group} player={context.player} playersTeam={context.playersTeam} isDisabled={valid}/>
                {answers.includes(userAnswers.get(`${min + 3}-${context.team?.id}-${context.team?.step}`) || "") && <p className="w-[80%]">{descriptions[answers.findIndex(value => value === userAnswers.get(`${min + 3}-${context.team?.id}-${context.team?.step}`))]}</p>}
                {answers.includes(userAnswers.get(`${min + 3}-${context.team?.id}-${context.team?.step}`) || "") && <ActionType index={answers.findIndex(value => value === userAnswers.get(`${min + 3}-${context.team?.id}-${context.team?.step}`))}/>}
            </Flex>
     </>
    )
}


function ActionType({ index }: { index: number }) {
    const context = useGameContext();
    const condition = useMemo(() => index < 0 || index >= (context.step?.description?.actionTypes?.length || 0), [index])

    const content = useMemo(() => {
        if (condition) return <></>;
        switch(context.step?.description.actionTypes[index]) {
            case "user": return <>
                <UserOutlined className="text-xl mr-2 bg-green-light p-2 rounded-full text-white"/>
                <p className="m-0">{t.sessionGame.actionType.user}</p>
            </>
            case "company": return <>
                <ImageR src={context.step?.description.companyLogo} className="w-full h-full object-contain"/>
            </>
        }
    }, [index])

    if (condition) return <></>;

    return <Flex align="center" justify="center" className="w-[20%] ml-4 px-3">
        {content}
    </Flex>
}
