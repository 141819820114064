import ItStep12 from "../../sustainable-it/step12/ItStep12";
import {supabase} from "../../../configs/supabase";
import {useEffect} from "react";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import StepTabletQuizz from "../../shared/step_tablet_quizz/StepTabletQuizz";
import {Flex} from "antd";

export default function ItTabletStep11() {
    const context = useGameContext();
    useEffect(() => {
        supabase.rpc("end_game", {team_id: context.team?.id})
    }, []);

    return <StepTabletQuizz preComponent={
        <Flex className={"scale-[1.10]"} justify={"center"}>
            <ItStep12/>
        </Flex>
    }/>
}