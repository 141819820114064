import StepProps from "../../types/StepProps";
import {Flex} from "antd";
import ButtonsStep1 from "./components/buttonsStep1";
import ImageR from "../../../shared/components/image/ImageR";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import "./style/glass.css"

export default function EcoStep1() {
    const context = useGameContext();
    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }
    const backgroundUrl = `${context.step?.description.background}${stepContext.player?.number}.png`;
    const invisibleButtonId = "invisible-button-eco-step1";
    
    //@ts-ignore
    function magnify(imgID, zoom) {
        //@ts-ignore
        var img, glass, w, h, bw, invisibleButton;
        img = document.getElementById(imgID);
        
        /* Create magnifier glass: */
        glass = document.createElement("DIV");
        glass.setAttribute("class", "img-magnifier-glass");
        
        /* Getting button */
        invisibleButton = document.getElementById(invisibleButtonId);

        /* Insert magnifier glass: */
        //@ts-ignore
        img.parentElement.insertBefore(glass, img);

        /* Set background properties for the magnifier glass: */
        //@ts-ignore
        glass.style.backgroundImage = "url('" + img.src + "')";
        glass.style.backgroundRepeat = "no-repeat";
        //@ts-ignore
        glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
        bw = 3;
        w = glass.offsetWidth / 2;
        h = glass.offsetHeight / 2;

        /* Execute a function when someone moves the magnifier glass over the image: */
        glass.addEventListener("mousemove", moveMagnifier);
        //@ts-ignore
        img.addEventListener("mousemove", moveMagnifier);
        invisibleButton?.addEventListener("mousemove", moveMagnifier);

        /*and also for touch screens:*/
        glass.addEventListener("touchmove", moveMagnifier);
        //@ts-ignore
        img.addEventListener("touchmove", moveMagnifier);
        //@ts-ignore
        function moveMagnifier(e) {
            var pos, x, y;
            /* Prevent any other actions that may occur when moving over the image */
            e.preventDefault();
            /* Get the cursor's x and y positions: */
            pos = getCursorPos(e);
            x = pos.x;
            y = pos.y;
            /* Prevent the magnifier glass from being positioned outside the image: */
            //@ts-ignore
            if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
            //@ts-ignore
            if (x < w / zoom) {x = w / zoom;}
            //@ts-ignore
            if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
            //@ts-ignore
            if (y < h / zoom) {y = h / zoom;}
            /* Set the position of the magnifier glass: */
            //@ts-ignore
            glass.style.left = (x - w) + "px";
            //@ts-ignore
            glass.style.top = (y - h) + "px";
            /* Display what the magnifier glass "sees": */
            //@ts-ignore
            glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
        }

        //@ts-ignore
        function getCursorPos(e) {
            var a, x = 0, y = 0;
            e = e || window.event;
            /* Get the x and y positions of the image: */
            //@ts-ignore
            a = img.getBoundingClientRect();
            /* Calculate the cursor's x and y coordinates, relative to the image: */
            x = e.pageX - a.left;
            y = e.pageY - a.top;
            /* Consider any page scrolling: */
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            return {x : x, y : y};
        }
    }


    return (
        <Flex align={"flex-start"} className={"w-[1000px] h-[640px] relative"}>
            <div className={"img-magnifier-container"}>
                <ImageR onFinishedLoading={() => magnify("myimage", 3)}  id={"myimage"} src={backgroundUrl} width={"1000px"} height={"600px"} className={"object-scale-down"}/>
            </div>
            {stepContext.player?.number === 1 && <ButtonsStep1 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={1} mainHintPos={{x: 790, y: 14, h: 110, w: 90}} buttonId={invisibleButtonId}/>}
            {stepContext.player?.number === 2 && <ButtonsStep1 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={2} mainHintPos={{x: 80, y: 100, h: 100, w: 70}} buttonId={invisibleButtonId}/>}
            {stepContext.player?.number === 3 && <ButtonsStep1 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={3} mainHintPos={{x: 890, y: 14, h: 110, w: 90}} buttonId={invisibleButtonId}/>}
            {stepContext.player?.number === 4 && <ButtonsStep1 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={4} mainHintPos={{x: 10, y: 100, h: 100, w: 70}} buttonId={invisibleButtonId}/>}
        </Flex>
    )
}