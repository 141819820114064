import React, {useMemo} from "react";
import Session from "../../../../../../shared/models/Session/session";
import {Button, TableProps} from "antd";
import {ArrowRightOutlined, FundFilled} from "@ant-design/icons";
import {Link} from "react-router-dom";
import DeleteButtonListSessionHome from "../components/deleteButton.list.session.home";
import {useHomeContext} from "../../../../context/context.home";
import {SessionState} from "../../../../../../shared/models/Session/sessionState";
import t from "../../../../../../configs/i18n";
import DateFormatter from "../../../../../../shared/services/formatter/dateFormatter";
import ImageR from "../../../../../../shared/components/image/ImageR";
import { Role } from "../../../../../../shared/models/UserData/Role";


export default function useListSession(type: "past" | "future" | "current" | "all") {
    const context = useHomeContext();
    const sessions = useMemo(() => {
        if (type === "all") {
            return context.sessions
        } else if (type === "past") {
            return context.sessions.filter(session => session.state === SessionState.ENDED)
        } else if (type === "future") {
            return context.sessions.filter(session => session.state === SessionState.CLOSED)
        } else {
            return context.sessions.filter(session => session.state !== SessionState.ENDED && session.state !== SessionState.CLOSED)
        }
    }, [context.sessions, type])

    const goToUrl = useMemo(() => {
        if (context.user?.mail === "tablet@thewokies.fr") {
            return "/session_tablet_login"
        }

        if (type === "future") {
            return "/home/session/info"
        }

        return "/session_gm"
    }, [type]);

    const columns : TableProps<Session>['columns'] = useMemo(() => {
        const defaultColumns : TableProps<Session>['columns'] = [
                {
                    title: t.common.company,
                    dataIndex: ["company", "logo"],
                    key: 'logo',
                    render: (value : string) => <ImageR width={"50px"} className="object-scale-down" src={value}/>,
                },
                {
                    title: t.common.name,
                    dataIndex: 'name',
                    key: 'name',
                    render: (name : string) => <p>{name}</p>
                },
                {
                    title: t.common.date,
                    dataIndex: 'startDate',
                    key: 'startDate',
                    render: (date : string) => <p>{DateFormatter.format(new Date(date))}</p>,
                }
            ];

        (type === "past" || type === "all" )  && defaultColumns.push({
            title: t.home.session.result,
            dataIndex: '',
            key: 'result',
            render: (session: Session) =>
                (session.state === SessionState.ENDED && <Link to={`/home/session/result/${session.id}`}>
                    <Button type={"primary"} >
                        <FundFilled /> {t.home.session.showResult}
                    </Button>
                </Link>)
        });

        defaultColumns.push({
            title: '',
            dataIndex: '',
            key: 'goto',
            render: (session: Session) => {
                if (!context.user || !context.user.hasRights(Role.GAMEMASTER, session.id)) return <Button type={"primary"} disabled={true}><ArrowRightOutlined /></Button>
                return <Link target={type === "future" || context.user?.mail === "tablet@thewokies.fr" ? "" : "_blank"} to={`${goToUrl}/${session.id}`}>
                    <Button type={"primary"}>
                        <ArrowRightOutlined />
                    </Button>
                </Link>
            },
        });


        context.user?.hasRights(Role.ADMIN) && defaultColumns.push({
            title: '',
            dataIndex: '',
            key: 'delete',
            render: (session: Session) => <DeleteButtonListSessionHome session={session}/>,
        });


        return defaultColumns;
    }, [context.user?.access, type])

    return {
        columns,
        context,
        sessions
    }
}