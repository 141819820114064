import {Button, Flex, Form, Modal} from "antd";
import StepProps from "../../../types/StepProps";
import ImageR from "../../../../shared/components/image/ImageR";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import {useState} from "react";
import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";
import Answer from "../../../../shared/services/answer/Answer";
import Notification from "../../../../shared/services/notification/Notification";
import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import t from "../../../../configs/i18n";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import ItStep6PasswordContent from "./it.step6.pasword.content";

interface PasswordModalStep4Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    stepContext: StepProps;
}


export default function PasswordModalStep6({open, setOpen, stepContext}: PasswordModalStep4Props) {
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);

    return (
        <>
            <PopupGoodAnswer currentUser={stepContext.currentUser} open={openSuccess} setOpen={setOpenSuccess} team={stepContext.team} player={stepContext.player} playersTeam={stepContext.playersTeam} />
            <Modal width={800} open={open} footer={null} onCancel={() => setOpen(false)}>
                <ItStep6PasswordContent onValid={() => {
                    setOpenSuccess(true);
                    setOpen(false);
                }}/>
            </Modal>
        </>
    )
}