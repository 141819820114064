import {Flex} from "antd";
import ConfettiExplosion from "react-confetti-explosion";
import RankingBar from "../../../../../session_end/components/RankingBar";
import {useSessionResultContext} from "../context/context.result.session";

interface SessionRankingProps {
    effect?: boolean;
    showTop3?: boolean;
    showTop2?: boolean;
    showTop1?: boolean;
}

export default function SessionResultTop3({showTop1 = true, showTop2 = true, showTop3 = true, effect = true}: SessionRankingProps) {
    const context = useSessionResultContext();

    return (
        <Flex className={"w-full h-full"} align={"flex-end"} justify={"space-around"}>
            {showTop2 && context.teamRank.at(1) &&
                <RankingBar number={2} team={context.teamRank.at(1)} session={context.session}/>
            }

            {showTop1 && context.teamRank.at(0) && <>
                {effect && <ConfettiExplosion className={"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"} width={1000} zIndex={9999999} />}
                <RankingBar number={1} team={context.teamRank.at(0)} session={context.session}/>
            </>}

            {showTop3 && context.teamRank.at(2) &&
                <RankingBar number={3} team={context.teamRank.at(2)} session={context.session}/>
            }
        </Flex>
    )
}