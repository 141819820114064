import {ReactElement, ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {Player} from "../../../shared/models/Player/Player";
import {UserData} from "../../../shared/models/UserData/UserData";
import {Team} from "../../../shared/models/Team/Team";
import {Company} from "../../../shared/models/Company/company";
import Session from "../../../shared/models/Session/session";
import {useNavigate} from "react-router-dom";
import RealtimeLogger from "../../../shared/services/logs/RealtimeLogger";
import SyncService from "../../../shared/services/sync/SyncService";
import t, { forceLanguage } from "../../../configs/i18n";
import OnlineService from "../../../shared/services/sync/OnlineService";
import {GameContext} from "./context.game";
import scenarios from "../../../scenarios/scenarios";
import {BaseHintProps} from "../../../scenarios/types/BaseHintProps";
import { Role } from "../../../shared/models/UserData/Role";
import { supabase } from "../../../configs/supabase";

interface Props {
    playerId: string | undefined;
    children: ReactNode;
}
export default function GameProvider(props: Props) {
    const [loading, setLoading] = useState<boolean>(true);
    const [playersTeam, setPlayersTeam] = useState<Player[]>([]);
    const [currentPlayer, setCurrentPlayer] = useState<Player>();
    const [currentUser, setCurrentUser] = useState<UserData | undefined>();
    const [team, setTeam] = useState<Team>();
    const [company, setCompany] = useState<Company>();
    const [session, setSession] = useState<Session>();
    const [step, setStep] = useState<{component: ReactNode, description: any, baseHints: ((props : BaseHintProps) => ReactElement)[]}>();
    const [solutionOn, setSolutionOn] = useState<boolean>(false)
    const navigate = useNavigate();
    const [showHint, setShowHint] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const targetTime = useMemo(() => {
        if (!team?.stepStartDate || !step?.description?.timeBeforeGreen) return undefined;
        return (team.stepStartDate.getTime() + step.description.timeBeforeGreen * 1000);
    }, [team?.stepStartDate, step?.description]);

    const triggerSolutionOn = async () => {
        const response = await supabase
            .from("team")
            .update({
                sub_step: (team?.subStep ?? 0) + 1
            })
            .eq("id", team?.id)
        
        if (response.error) {
            console.error(response.error);
            return;
        }

        setSolutionOn(true);
    }

    useEffect(() => {
        setShowHint(false)
        const updateHint = () => {
            const currentTime = (new Date()).getTime();
            if (targetTime && currentTime >= targetTime) {
                setShowHint(true);
            }
        };
        // Check every second if the target time is reached
        const intervalId = setInterval(updateHint, 1000);

        // Cleanup on component unmount
        return () => clearInterval(intervalId);
    }, [targetTime]);

    const fetchData = async () => {
        const player = await Player.byId(props.playerId, "*, userdata!userdata(*), session(*, company(*)), team!team(*)", false)
        if (!player) {
            return;
        }

        const currentUser = await UserData.getCurrentUser()
        if (currentUser?.uid !== player.getId() && !currentUser?.hasRights(Role.GAMEMASTER, player?.getSession()?.id)) {
            navigate(`/session_login/${player?.getSession()?.id}`)
            return;
        }
        
        const teamPlayers = await Player.getByTeam(player.getTeamId())
        if (currentUser?.hasRights(Role.GAMEMASTER, player?.getSession()?.id)) {
            document.title = (player?.team as Team)?.name || t.common.team;
        }

        setCurrentUser(currentUser)
        setCurrentPlayer(player);
        setTeam(player?.team as Team)
        setSession(player?.getSession())
        setCompany(player?.getSession()?.company)
        setPlayersTeam(teamPlayers)

        player.getSession()?.listen(setSession, () => SyncService.desync(), () => SyncService.sync())
        Player.listenTeam(player, setTeam, setPlayersTeam, setCurrentPlayer)

        if (currentUser?.uid === player.getId()) {
            OnlineService.start(player, session);
        }


        setLoading(false)
    }

    useEffect(() => {
        if (session?.isCommonRoomState()) {
            navigate(`/session_common_room/${currentPlayer?.playerId}`)
        }

        if (Session.isFinalState(session?.state)) {
            navigate(`/session_end/${session?.id}`)
        }
    }, [currentPlayer?.playerId, navigate, session?.state]);

    useEffect(() => {
        if (!session || !team || !scenarios[session.scenario] || !team.step) {
            setStep(undefined)
            return;
        }
        const scenario = session?.scenario || "";
        const step = (team?.step || 1) - 1;
        setSolutionOn(false)
        setStep(scenarios[scenario].steps[step])
        forceLanguage(scenarios[scenario].lang)

        if ((team?.subStep ?? 0) > (scenarios[scenario].steps[step].description.hints?.length ?? 0)) {
            setSolutionOn(true)
        }
    }, [team, session?.scenario]);


    useEffect(() => {
        RealtimeLogger.start()
        fetchData()
        SyncService.register("fetchData", fetchData)
    }, []);

    return (
        <GameContext.Provider value={{
            playersTeam,
            player: currentPlayer,
            user : currentUser,
            team, company,
            session,
            showHint,
            step,
            loading,
            solution: {triggerSolution: triggerSolutionOn, solutionOn},
            containerRef,
        }}>
            {props.children}
        </GameContext.Provider>
    )
}