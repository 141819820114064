import React, { useEffect } from 'react';

const ImpactCO2 = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://impactco2.fr/iframe.js';
        script.setAttribute('name', 'impact-co2');
        script.setAttribute('data-type', 'usagenumerique');
        script.setAttribute('data-search', '?theme=default');
        document.getElementById("iframe-root")?.appendChild(script);

        return () => {
            document.getElementById("iframe-root")?.removeChild(script);
        };
    }, []);

    return (
        <div id={"iframe-root"}>
        </div>
    )
};

export default ImpactCO2;
