import {Button, Flex, notification} from "antd";
import {useState} from "react";
import SurveyQuestion from "./SurveyQuestion";
import {SurveyType} from "../../../shared/models/Survey/SurveyType";
import {supabase} from "../../../configs/supabase";
import {UserData} from "../../../shared/models/UserData/UserData";
import Session from "../../../shared/models/Session/session";
import Notification from "../../../shared/services/notification/Notification";
import t from "../../../configs/i18n";

interface SurveyProps {
    currentUser: UserData | undefined
    session: Session | undefined
}

export default function EndGameSurvey({currentUser, session}: SurveyProps) {
    const [survey, setSurvey] = useState<{[question: string]: {type: SurveyType, answer: string | number}}>({})
    const [validated, setValidated] = useState<boolean>(false)
    const mandatoryAnswers = [
        t.sessionEnd.survey.globalRating,
        t.sessionEnd.survey.tryAgain,
        t.sessionEnd.survey.game,
        t.sessionEnd.survey.recommendation,
        t.sessionEnd.survey.ratingGame
    ]

    const validateSurvey = async () => {
        if (mandatoryAnswers.some(answer => !survey[answer])) {
            notification.warning({message: t.sessionEnd.survey.emptyFields});
            return
        }

        const response =
            await supabase
                .from('survey')
                .insert({
                    userdata: currentUser?.uid,
                    session: session?.id,
                    survey: survey
                });

        if (response.error) {
            Notification.error(t.sessionEnd.survey.error, response.error);
            return
        }

        notification.success({message: t.sessionEnd.survey.success});
        setValidated(true);
    }

    return (
        <Flex align={"center"} justify={"center"} className={"w-full box-border"} vertical>
            {!validated && <>
                <h2 className={"text-green-dark"}>{t.sessionEnd?.survey?.survey}</h2>
                <SurveyQuestion question={t.sessionEnd.survey.globalRating} type={SurveyType.RATING_GLOBAL} survey={survey} setSurvey={setSurvey} />
                <SurveyQuestion question={t.sessionEnd.survey.tryAgain} answers={[t.common.yes, t.common.no]} type={SurveyType.OPTION} survey={survey} setSurvey={setSurvey} />
                <SurveyQuestion question={t.sessionEnd?.survey?.game} type={SurveyType.OPTION} answers={[t.common.yes, t.common.no]} survey={survey} setSurvey={setSurvey} />
                <SurveyQuestion question={t.sessionEnd?.survey?.recommendation} answers={[t.common.yes, t.common.no]} type={SurveyType.RECOMMENDATION} survey={survey} setSurvey={setSurvey} />
                <SurveyQuestion question={t.sessionEnd?.survey?.ratingGame} type={SurveyType.RATING_GAME} survey={survey} setSurvey={setSurvey} />
                <SurveyQuestion question={t.sessionEnd?.survey?.input} optional={true} type={SurveyType.INPUT} survey={survey} setSurvey={setSurvey} />
                <Flex className={"pb-4 pt-4"}>
                    <Button size={"large"} onClick={validateSurvey} type="primary">{t.common.validate}</Button>
                </Flex>
            </>}
            {validated && <h2 className={"text-green-dark"}>{t.sessionEnd.survey?.thanks}</h2>}
        </Flex>
    )
}