import {Link, useParams} from "react-router-dom";
import RankingResultSession from "./components/ranking.result.session";
import {Button, Flex, Menu} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import InfoResultSession from "./components/info.result.session";
import React from "react";
import SessionsStats from "./components/stat.result.session";
import useSessionResult from "./callbacks/useSessionResult";
import {SessionResultProvider} from "./context/provider.result.session";
import AppSpinner from "../../../../../shared/components/spinner/AppSpinner";
import t from "../../../../../configs/i18n";

export default function SessionResult() {
    const {id} = useParams();

    return (
        <SessionResultProvider sessionId={id}>
            <SessionResultUnwrapped/>
        </SessionResultProvider>
    )
}

function SessionResultUnwrapped() {
    const sessionResult = useSessionResult();

    if (sessionResult.context.loading) {
        return <AppSpinner/>;
    }

    return (
        <Flex gap={"20px"} className={"w-full h-full box-border pl-8 pr-8"} vertical>
            <Link to={"/home"}><Button className={"mt-4 mb-4"} type={"text"}><LeftOutlined /> {t.common.goBack}</Button></Link>
            <InfoResultSession session={sessionResult.context.session}/>
            <Menu className={"h-12 flex justify-center"} mode="horizontal" selectedKeys={sessionResult.currentTab} onClick={sessionResult.changeTab} items={sessionResult.items}/>
            {sessionResult.currentTab.includes("rank") && <RankingResultSession/>}
            {sessionResult.currentTab.includes("stat") && <SessionsStats/>}
        </Flex>
    )
}