import React, {useEffect, useState} from "react";
import {Flex} from "antd";
import SkeletonBox from "../spinner/SkeletonBox";

interface ImageRProps {
    src: string;
    width?: string;
    height?: string;
    className?: string;
    id?: string;
    onFinishedLoading?: () => void;
}


export default function ImageR({src, width, height, className, id, onFinishedLoading}: ImageRProps) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!loading && onFinishedLoading) {
            onFinishedLoading();
        }
    }, [loading, onFinishedLoading]);

    return (
        <>
            {loading &&
                <Flex className={className}>
                    <SkeletonBox width={width ? width : height} height={height} />
                </Flex>
                }
            <img id={id} src={src} onLoad={() => setLoading(false)} width={loading ? "0px" : width} height={width ? undefined : (loading ? "0px" : height)} className={className} alt=""/>
        </>
    )


}