import LocaleText from "./localeText";

export default class EN extends LocaleText {
    login = {
        title: "Welcome to your user space!",
        cardTitle: "Access The Wokies",
        success: "A login email has been sent to your email address.",
        imaplayer: "I am a player",
    }

    home = {
        dashboard: "Dashboard",
        session: {
            create: "Create a session",
            result: "Session results",
            showResult: "Discover the results",
            createSuccess: "Session successfully created",
            seePhoto: "Discover the photos",
            exportSurvey: "Export results",
            exportPlayerStatus: "Export attendance sheet",
            deleteAlert: "Do you want to delete this session?",
            passed: "Past sessions",
            future: "Upcoming sessions",
            inProgress: "Current sessions",
            stats: "Statistics",
            podium: "Podium",
            noPhoto: "No photos",
        },
        company: {
            create: "Create a company",
            name: "Company name",
            logo: "Company logo",
        }
    }

    common = {
        answer: "Answer",
        congratulations: "Congratulations",
        connect: "Log in",
        mail: "Email",
        password: "Password",
        company: "Company",
        companies: "Companies",
        name: "Name",
        firstName: "First name",
        lastName: "Last name",
        date: "Date",
        update: "Update",
        yourTeam: "Your team",
        noTeam: "No team",
        team: "Team",
        step: "Step",
        player: "Player",
        goBack: "Go back",
        validate: "Validate",
        cancel: "Cancel",
        warning: "Warning!",
        finished: "Finished",
        continue: "Continue",
        only: "Only",
        secondes: "seconds",
        yourAnswer: "Your answer",
        optional: "Optional",
        yes: "Yes",
        no: "No",
        wrongAnswer: "Wrong answer",
        goodAnswer: "Good answer",
        noAnswer: "No answer",
        allFieldsFilled: "All fields must be filled",
        noted: "Noted!",
    }

    error = {
        common: "An error has occurred, please try again later.",
        wrongMail: "No user was found with this email address.",
        wrongPassword: "The email address or password is incorrect.",
        wait60: "Please wait 60 seconds before submitting a new request.",
        user: "Error retrieving the user.",
        disconnect: "Error disconnecting the user.",
        session: "An error occurred while retrieving the session.",
        company: "Error retrieving companies.",
        score: "Error retrieving scores.",
        players: "Error retrieving players.",
        player: "Error retrieving the player.",
        team: "Error retrieving the team.",
        teams: "Error retrieving teams.",
        notConnected: "This session has expired. Please log in again.",
        linkExpired: "This link has already been used. Please request a new login link.",
        gamemaster: "Error calling the gamemaster.",
        chrono: "Error retrieving the timer.",
        subscription: "Error during subscription.",
        survey: "Error retrieving the survey.",
        input: "Error retrieving the input.",
        dataMap: "Error converting data.",
        validateStep: "Error validating the step.",
        noSlash: "The name cannot contain slashes.",
        onlyLettersAndNumbers: "The name cannot contain special characters",
        emptySolution: "An error occured while retrieving the solution."
    };

    test = {
        api: "Failed to load API resources.",
        websocket: "Unable to connect to WebSocket.",
        jass: "Access to 8x8.vc failed.",
        jitsi: "Access to web-cdn.jitsi.net failed.",
        supabase: "Access to database failed.",
        success: "The tests have been successfully completed!",
        error: "An error was detected.",
    }

    sessionLogin = {
        alreadyStarted: "The session has already started. You must have logged in at least once to access it again.",
        cannotJoin: "The session has already started, and it is no longer possible to join.",
        enterTeam: "Enter your team name",
        enterMail: "Enter your professional email",
        joinSession: "Join the session",
        closedState: "The session has not yet started!",
        comeBack: "Come back on",
        toConnect: "to connect",
        problemDetected: "A problem has been detected",
        accessLimited: "Your access is currently limited to certain features, which will prevent you from participating in the game",
        helpUrl: "Access the technical channel for assistance",
        organisatorHasSolved: "I confirm that the organizer has resolved my issue",
    }

    sessionJoin = {
        imPresent: "I am available throughout the entire session (approximately 1h30)",
        teamInfo: "Team information",
        playerInfo: "Player information",
        team: "Team",
    }

    sessionGame = {
        noStep: "No step was found.",
        noTeam: "You are currently not assigned to a team",
        suggestCalling: "Please contact a game master",
        canClosePopup: "You can close this popup, your input has been saved!",
        wrongAnswer: "Incorrect answer",
        wrongAnswers: "One or more answers are incorrect",
        crosswordIncorrect: "The grid is incorrect",
        goToQuizz: "Go to the quiz",
        confirmationGoToQuizzL1: "Are you sure you want to go to the quiz?",
        confirmationGoToQuizzL2: "You won't be able to review the video once the quiz has started.",
        youCompleted: "you have successfully completed all the steps in",
        waitingForTeam: "Waiting for the last teams...",
        debrief: "The global debrief will take place at",
        thisQuestionNotExists: "This question does not exist.",
        canAnswer: "can respond, cooperation is required!",
        winBonus: "Answer correctly to win 30 seconds",
        goodAnswerQuiz: "Congratulations, correct answer! You win a bonus of",
        wrongAnswerQuiz: "Wrong answer, your team has a penalty of",
        youAnswered: "You answered",
        visio: {
            accessVisio: "Access the video conference",
            noVisioLink: "No visio link found",
            mainVisio: "Click here to access the main video conference",
            teamVisio: "Click here to access your team video conference",
        },
        gamemasterPopup: {
            title: "Waiting for the gamemaster",
            calledBy: "A problem has been reported by",
            messageChrono: "The timer is paused pending the resolution of your problem.",
        },
        sosPopup: {
            title: "What is your issue?",
            message: "Help us understand your problem",
            buttonHint: "Additional hints",
            buttonGamemaster: "Call a gamemaster",
            buttonHelp: "Technical channel"
        },
        hintPopup: {
            title: "Additional hints",
            noPlayer: "No player is missing.",
        },
        game: {
            inputOtherPlayer: "Entered by another player",
            toRemember: "What to remember!",
        },
        cameraPopup: {
            title: "The video conference is about to start!",
            message: "Remember to activate your microphone and camera on the video conference, by allowing it in your browser",
            message2: "Be aware, by default you are not connected and therefore cannot follow the discussions.",
            button: "Got it !",
        },
        goodAnswerPopup: {
            title: "You have found the right answer!",
            button: "Continue to the next step",
        },
        timerPausedPopup: {
            title: "Time is paused!",
            message: "During videos and explanations, time is paused. Take your time to remember everything!",
            button: "Understood!",
        },
        waitingForPlayers: {
            error: "An error occurred during the validation of the step.",
            title: "Waiting for other players",
        },
        hints: {
            hints: "Hints",
            yourhints: "Your hints",
            penalty: "Each unlocked hint results in a penalty",
            hint: "Hint",
            click: "Click to read",
            unlock: "Unlock this hint",
            cantUnlock: "You must unlock prior hints first",
            order: "Hints must be unlocked in order",
            answer: "Unlock the answer",
            answerCondition: "You can only access the answer once you have unlocked all other hints",
            confirmationQuestion: "Are you sure you want to unlock a hint?",
            confirmationSolution: "Are you sure you want to unlock the solution?",
            confirmationInfo: "This will result in a 30 seconds panalty",
            confirmationInfo1: "This will result in a ",
            confirmationInfo2: " seconds panalty",
            solutionComplete: "Solution successfuly completed!"
        },
        actionType: {
            user: "Individual action",
        }
    }

    sessionEnd = {
        seeRanking: "See ranking",
        sessionEnded: "The session is over!",
        survey: {
            success: "The survey was successfully sent.",
            error: "An error occurred while sending the survey.",
            emptyFields: "Please fill in all mandatory fields.",
            survey: "Satisfaction survey",
            thanks: "Thank you for answering the survey",
            globalRating: "Did I have a good time with my colleagues?",
            tryAgain: "Would you like to experience an Online Escape Game again with your colleagues?",
            game: "Did you enjoy the scenario?",
            recommendation: "Would you recommend this activity to a colleague or professional contact?",
            ratingGame: "Did you learn something?",
            input: "In your opinion, what could we improve? What are your suggestions?",
            email: "Email",
        }
    }

    spinner = {
        message: "Is the loading taking too long?",
        tryAgain: "Try again",
    }

    syncPopup = {
        title: "Loss of synchronization",
        message: "Resynchronizing with the session...",
    }
    
    fileNames = {
        survey: "TheWokies_survey_results_",
        attendance: "TheWokies_attendance_sheet_",
        template: "TheWokies_ParticipantsFiles_",
    }

    bdd = {
        teamName: "Team",
    }
}
