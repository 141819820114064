import {Button, Checkbox, Flex, Form, Input, notification} from "antd";
import SessionGmHeader from "./components/SessionGmHeader";
import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import Session from "../../shared/models/Session/session";
import AppSpinner from "../../shared/components/spinner/AppSpinner";
import {UserData} from "../../shared/models/UserData/UserData";
import JitsiVisio from "../../shared/components/jitsi/JitsiVisio";
import SessionInfoCard from "./components/SessionInfoCard";
import SessionStateCard from "./components/SessionStateCard";
import {supabase} from "../../configs/supabase";
import NoTeamTable from "./components/NoTeamTable";
import {Team} from "../../shared/models/Team/Team";
import {SessionState} from "../../shared/models/Session/sessionState";
import TeamTableGame from "./components/TeamTable/TeamTableGame";
import TeamTable from "./components/TeamTable/TeamTable";
import {Player} from "../../shared/models/Player/Player";
import SyncService from "../../shared/services/sync/SyncService";
import SyncPopup from "../../shared/components/popup/SyncPopup";
import Video from "../session_common_room/components/Video";
import WaitingSurvey from "./components/WaitingSurvey";
import Top3ResultSession from "../home/subPages/session/result/components/top3.result.session";
import Notification from "../../shared/services/notification/Notification";
import OnlineService from "../../shared/services/sync/OnlineService";
import {SessionResultProvider} from "../home/subPages/session/result/context/provider.result.session";
import scenarios from "../../scenarios/scenarios";
import t, {forceLanguage} from "../../configs/i18n";
import SessionQuizz from "./components/SessionQuizz";
import ImageR from "../../shared/components/image/ImageR";
import { Role } from "../../shared/models/UserData/Role";

export default function SessionGm() {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [session, setSession] = useState<Session>();
    const [sessionRealTime, setSessionRealTime] = useState<Session>();
    const [teams, setTeams] = useState<Team[]>([]);
    const [players, setPlayers] = useState<Player[]>([]);
    const [loading, setLoading] = useState<boolean>(true)
    const [currentUser, setCurrentUser] = useState<UserData>();
    const [onlinePlayers, setOnlinePlayers] = useState<Set<string>>(new Set<string>())
    const [onlyOwnTeams, setOnlyOwnTeams] = useState<boolean>(false)

    const teamsFilter = useMemo(() => {
        return teams.filter(t => {
            return (!onlyOwnTeams && currentUser && currentUser.isAdmin()) || t.gamemaster === currentUser?.uid
        })
    }, [currentUser, currentUser?.access, currentUser?.mail, teams, onlyOwnTeams])

    const nbPlayersOnline = useMemo(() => onlinePlayers.size, [onlinePlayers])
    const nbPlayersTotal = useMemo(() => players.length, [players])
    const nbCallGamemasters = useMemo(() => players.filter(t => t.need_gamemaster).length, [players])
    const minStepTeam = useMemo(() => Math.min.apply(Math, teamsFilter.map(t => t.step)), [teamsFilter])
    const noRights = useMemo(() => !currentUser?.hasRights(Role.GAMEMASTER, sessionRealTime?.id), [sessionRealTime?.id, currentUser, currentUser?.access])
    const videoUrl = useMemo(() => sessionRealTime?.getRuleVideo(), [sessionRealTime?.subState])

    useEffect(() => {
        let titleInterval : NodeJS.Timer;

        if (nbCallGamemasters > 0) {
            let blink = false;
            titleInterval = setInterval(() => {
                document.title = blink ? `${nbCallGamemasters} - Appel GM` : `🚨 ${nbCallGamemasters} - Appel GM`;
                blink = !blink;
            }, 500); // Change the interval as needed
        } else {
            document.title = "Dashboard GM";
        }

        return () => {
            if (titleInterval) {
                clearInterval(titleInterval);
            }
        };
    }, [nbCallGamemasters]);

    const fetchData = async () => {
        const session = await Session.byId(id, "*, company(*)")
        setSession(session)
        setSessionRealTime(session)
        session?.listen(setSessionRealTime, () => SyncService.desync(), () => SyncService.sync())

        const teams = await Team.bySession(session, "*")
        setTeams(teams)
        Team.listenInSession(session, setTeams)

        const players = await Player.bySession(session)
        setPlayers(players);
        Player.listenBySession(session, setPlayers);

        const currentUser = await UserData.getCurrentUser();
        setCurrentUser(currentUser);

        OnlineService.listen(setOnlinePlayers);

        const scenario = scenarios[session?.scenario || ""];
        forceLanguage(scenario.lang)
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
        SyncService.register("fetchData", fetchData)
    }, [id]);

    const createTeam = async () => {
        const {name} = form.getFieldsValue();
        const response = await supabase
            .from("team")
            .insert({
                name: name,
                session: id,
            })

        if (response.error) {
            Notification.error("Erreur lors de la création de l'équipe.", response.error);
            return;
        }

        notification.success({message: `L'équipe ${name} a bien été créée`});
        form.resetFields();
    }

    const scenario = useMemo(() => scenarios[session?.scenario as string], [session?.scenario])

    const accessVisio = () => {
        window.open(sessionRealTime?.helpUrl, '_blank');
    }

    if (loading) {
        return (<AppSpinner tryAgain={true}/>);
    }

    if (noRights) {
        return (<Flex className={"w-full h-full"} vertical>
            <h1 className={"text-center text-red-500"}>Vous n'êtes pas autorisé à accéder à cette page</h1>
        </Flex>)
    }

    return (
        <Flex className={"w-full h-full"}>
            <SyncPopup/>
            <Flex className={"w-full h-full"} vertical>
                <SessionGmHeader SessionRealtime={sessionRealTime} Session={session} playersTotal={nbPlayersTotal} playersOnline={nbPlayersOnline}/>
                <Flex className={"h-full max-h-screen overflow-y-auto"} vertical>
                    <Flex className={"w-full"}>
                        <SessionInfoCard user={currentUser} session={sessionRealTime}/>
                        <SessionStateCard user={currentUser} session={sessionRealTime} teams={teams} />
                        {sessionRealTime?.photo && <ImageR src={sessionRealTime?.photo} height="320px" className="ml-5 mt-4 max-w-[400px]"/>}
                    </Flex>

                    {sessionRealTime?.state === SessionState.VIDEO && !scenario.tablet && session &&
                        <Flex className={"p-12"}>
                            <Video url={videoUrl ?? ""} key={videoUrl}/>
                        </Flex>
                    }
                    {sessionRealTime?.state === SessionState.SURVEY && <WaitingSurvey session={sessionRealTime}/>}
                    {sessionRealTime?.state === SessionState.QUIZZ && <SessionQuizz teams={teams} session={sessionRealTime}/>}

                    {(sessionRealTime?.state === SessionState.RANK_3 ||
                            sessionRealTime?.state === SessionState.RANK_2 ||
                            sessionRealTime?.state === SessionState.RANK_1)
                        && <Flex className={"w-full min-h-[800px]"}>
                            <SessionResultProvider sessionId={id}>
                                <Top3ResultSession effect={false} showTop3={true} showTop2={sessionRealTime?.state === SessionState.RANK_2 || sessionRealTime?.state === SessionState.RANK_1 || !scenario.tablet} showTop1={sessionRealTime?.state === SessionState.RANK_1 || !scenario.tablet} />
                            </SessionResultProvider>
                        </Flex>}

                    <Flex className={"w-full content-center"} justify="center">
                        {currentUser?.isAdmin() && <Checkbox className={"mr-2 pt-1"} value={onlyOwnTeams} onChange={(e) => setOnlyOwnTeams(e.target.checked)}/>}
                        <h1 className={"text-center text-green-dark"}>Mes équipes</h1>
                    </Flex>
                    {currentUser?.isAdmin() && <Form form={form} onFinish={createTeam} className="ml-5 mt-6 w-1/3" layout={"inline"}>

                        <Form.Item name="name" rules={[{required: true, message: ""}]}>
                            <Input placeholder="Nom de l'équipe"/>
                        </Form.Item>

                        <Form.Item>
                            <Button className="w-full" type="primary" htmlType="submit">
                                Créer l'équipe
                            </Button>
                        </Form.Item>

                    </Form>}
                    {Session.inGame(sessionRealTime?.state) &&
                        <TeamTableGame minStepTeam={minStepTeam} onlinePlayers={onlinePlayers} players={players} teams={teamsFilter}
                                       session={sessionRealTime} user={currentUser}/>}
                    {!Session.inGame(sessionRealTime?.state) &&
                        <TeamTable onlinePlayers={onlinePlayers} players={players} teams={teamsFilter}
                                   session={sessionRealTime} user={currentUser}/>}
                    <NoTeamTable onlinePlayers={onlinePlayers} players={players} teams={teams}/>
                </Flex>
            </Flex>
            {!scenario.tablet && !sessionRealTime?.noJitsi && <JitsiVisio jwt={currentUser?.jitsijwt} admin={currentUser?.isAdmin()} className={"w-[335px]"} name={currentUser?.name} mail={currentUser?.mail} session={session}/>}
            {sessionRealTime?.noJitsi && 
                <Flex className={"w-[335px] items-center justify-center bg-beige"}>
                    <Button 
                        className="w-[80%] whitespace-normal h-auto wrap-text" 
                        type="primary" 
                        onClick={accessVisio}
                    >
                        {t.sessionGame.visio.mainVisio}
                    </Button>
                </Flex>
            }
        </Flex>
    )
}
