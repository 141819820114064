import {Modal} from "antd";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import {useEffect, useState} from "react";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import ItStep6PasswordContent from "./it.step6.pasword.content";

interface PasswordModalStep4Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}


export default function PasswordModalStep6({open, setOpen}: PasswordModalStep4Props) {
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);
    const stepContext = useGameContext();

    useEffect(() => {
        if (stepContext.solution?.solutionOn) {
            setOpen(true);
        }
    }, [stepContext.solution?.solutionOn]);

    return (
        <>
            <PopupGoodAnswer currentUser={stepContext.user} open={openSuccess} setOpen={setOpenSuccess} team={stepContext.team} player={stepContext.player} playersTeam={stepContext.playersTeam} />
            <Modal width={800} open={open} footer={null} onCancel={() => !stepContext.solution?.solutionOn && setOpen(false)} closable={!stepContext.solution?.solutionOn}>
                <ItStep6PasswordContent onValid={() => {
                    setOpenSuccess(true);
                    setOpen(false);
                }}/>
            </Modal>
        </>
    )
}