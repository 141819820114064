import EmptyModal from "./EmptyModal";
import InvisibleButton from "../../../../shared/components/button/InvisibleButton";
import {useState} from "react";
import HintModal from "./HintModal";

interface P3ButtonsProps {
    showHint: boolean;
}

export default function P4Buttons({showHint}: P3ButtonsProps) {
    const [emptyModal, setEmptyModal] = useState<boolean>(false);
    const [hintModal, setHintModal] = useState<boolean>(false);


    return (
        <>
            <EmptyModal open={emptyModal} setOpen={setEmptyModal}/>
            <HintModal playerNumber={4} open={hintModal} setOpen={setHintModal} />

            <InvisibleButton showHint={showHint} onClick={() => setHintModal(true)} top={55} left={72} h={191} w={191} />
            <InvisibleButton showHint={showHint} onClick={() => setEmptyModal(true)} top={127} left={762} h={143} w={143} />
        </>
    )
}