import { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface BaseSizeContextType {
  baseSize: number;
}

const BaseSizeContext = createContext<BaseSizeContextType>({ baseSize: 3 });

export const useBaseSize = () => useContext(BaseSizeContext);

interface BaseSizeProviderProps {
  children: ReactNode;
  containerRef: React.RefObject<HTMLElement>;
}

export function BaseSizeProvider({ children, containerRef }: BaseSizeProviderProps) {
  const [baseSize, setBaseSize] = useState<number>(3);

  useEffect(() => {
    const refresh = () => {
      const { offsetHeight } = containerRef.current ?? { offsetHeight: 100 };
      setBaseSize(offsetHeight * 0.017);
    };
    
    refresh();
    window.addEventListener('resize', refresh);
    return () => window.removeEventListener('resize', refresh);
  }, [containerRef]);

  return (
    <BaseSizeContext.Provider value={{ baseSize }}>
      {children}
    </BaseSizeContext.Provider>
  );
}