import {BaseHintProps} from "../../../types/BaseHintProps";
import {Flex} from "antd";
import {useGameContext} from "../../../../pages/session_game/context/context.game";

export default function ItHintStep8({number}: BaseHintProps) {
    const context = useGameContext();
    const hints = context.step?.description.hints;

    let playerHints : JSX.Element[];
    if (number === 1) {
        playerHints = [hints[0], hints[1]];
    } else if (number === 2) {
        playerHints = [hints[2], hints[3]];
    } else if (number === 3) {
        playerHints = [hints[4], hints[5]];
    } else {
        playerHints = [hints[6], hints[7]];
    }

    return (
        <Flex align={"center"} justify={"center"} vertical>
            <h3 className={"mt-4 text-green-dark"}>Vos définitions</h3>
            {playerHints.map((hint, index) => (
                <p><span className={"font-bold text-green-dark"}>{index + 1 + (number - 1) * 2}.</span> {hint}</p>
            ))}
        </Flex>
    )
}