import {useEffect, useState} from "react";
import {useHomeContext} from "../../../../context/context.home";
import UserDataService from "../../../../../../shared/models/UserData/UserDataService";
import {supabase} from "../../../../../../configs/supabase";
import Notification from "../../../../../../shared/services/notification/Notification";
import {Form, notification} from "antd";
import {useNavigate} from "react-router-dom";
import t from "../../../../../../configs/i18n";
import scenarios from "../../../../../../scenarios/scenarios";
import { Role } from "../../../../../../shared/models/UserData/Role";

export default function useSessionCreate() {
    const [gamemasters, setGamemasters] = useState<{label: string, value: string}[]>([]);
    const [companies, setCompanies] = useState<{label: string, value: string}[]>([]);
    const [availableScenarios, setAvailableScenarios] = useState<{label: string, value: string}[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const context = useHomeContext();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const fetchData = async () => {
        setCompanies(context.companies.map(company => ({label: company.name, value: company.id})));
        setGamemasters(await UserDataService.getGamemastersOptions());
        setAvailableScenarios(Object.keys(scenarios).map(key => ({label: `${scenarios[key].title} - ${scenarios[key].lang}`, value: key})));
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [context.companies]);

    const validateForm = async () => {
        try {
            const values = form.getFieldsValue();

            if (values.name.includes("/")) {
                Notification.error(t.error.noSlash);
                return;
            }
            const response = await supabase.from("session").insert({
                name: values.name,
                company: values.company,
                nb_players: values.nbPlayers,
                main_gamemaster: values.mainGamemaster,
                start_date: values.dates[0],
                end_date: values.dates[1],
                scenario: values.scenario,
            }).select("id").single();

            if (response.error) {
                throw response.error;
            }

            values.gamemasters.forEach(async (gamemaster: string) => {
                const reponseRoles = await supabase.from("role").insert({
                    session: response.data?.id,
                    type: Role.GAMEMASTER,
                    userdata: gamemaster,
                });

                if (reponseRoles.error) {
                    throw reponseRoles.error;
                }
            });

            notification.success({message: t.home.session.createSuccess,});
            await context.fetch();
            navigate(-1);
        } catch (e) {
            Notification.error(t.error.session, e);
        } finally {
        }
    }

    return {
        gamemasters,
        companies,
        loading,
        form,
        validateForm,
        availableScenarios,
    }
}