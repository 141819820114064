import {Button, Flex, Modal} from "antd";
import React from "react";
import PopupProps from "./types/PopupProps";
import t from "../../../configs/i18n";


export default class Popup {
    public static show(options : Partial<PopupProps> ) {
        const onOkWrapper = async () => {
            if (options.onOk) {
                (await options.onOk()) && modal.destroy();
            }
        }


        const footerContent = options.footer === undefined || options.footer ? [
                <Flex gap={"middle"} className={"mt-10"} justify={"flex-end"}>
                    <Button onClick={() => modal.destroy()} key="back">
                        {t.common.cancel}
                    </Button>
                    <Button onClick={onOkWrapper} key="submit" type="primary">
                        {t.common.validate}
                    </Button>
                </Flex>
            ] : null;

        const Component = options.content!;

        const modal = Modal.error({
            title: options.title,
            content: <Component/>,
            footer: footerContent,
            closable: true,
            icon: null,
            maskClosable: true
        })
    }

    public static warning(message: string, onOk: () => void) {
        const onOkWrapper = async () => {
            onOk();
            modal.destroy();
        }


        const footerContent = (
            <Flex gap={"middle"} className={"mt-10"} justify={"flex-end"}>
                <Button onClick={() => modal.destroy()} key="back">
                    {t.common.cancel}
                </Button>
                <Button onClick={onOkWrapper} key="submit" type="primary" danger>
                    {t.common.validate}
                </Button>
            </Flex>
        )


        const modal = Modal.warning({
            title: t.common.warning,
            content: <p>{message}</p>,
            footer: footerContent,
            closable: true,
            maskClosable: true
        })
    }
}