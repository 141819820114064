import { Flex } from "antd";
import { useEffect, useState } from "react";

interface MouseFloatingTextProps {
  start?: DOMRect;
  text: string;
  condition?: boolean | (() => boolean);
}

export default function MouseFloatingText({start, text, condition}: MouseFloatingTextProps) {
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  
    useEffect(() => {
      const handleMouseMove = (event: MouseEvent) => {
        setMousePos({ x: event.clientX, y: event.clientY });
      };
      window.addEventListener('mousemove', handleMouseMove);
      return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);

    if (typeof condition === 'function') {
        if (!condition()) return null;
    } else if (condition === false) {
        return null;
    }
    return (
      <Flex
        className="absolute pointer-events-none"
        style={{
          left: mousePos.x - (start?.x || 0) + 50,
          top: mousePos.y - (start?.y || 0) - 50,
        }}
      >
        <p className="p-2 rounded-xl text-red-700 font-medium bg-gray-100">{text}</p>
      </Flex>
    );
  };
  