import EcoStep1 from "./step1/eco.step1";
import HintEcoStep1 from "./step1/hint/eco.step.1";
import EcoStep3 from "./step3/eco.step3";
import HintEcoStep3 from "./step3/hint/eco.step.3";
import EcoStep5 from "./step5/eco.step5";
import HintEcoStep5 from "./step5/hint/eco.step.5";
import EcoStep7 from "./step7/eco.step7";
import HintEcoStep7 from "./step7/hint/eco.step.7";
import Hint2EcoStep7 from "./step7/hint/eco.hint2.step.7";
import EcoStep9 from "./step9/ecoStep9";
import EcoHintStep9 from "./step9/hint/EcoHintStep9";
import StepVideoQuizz from "../shared/step_video_quizz/StepVideoQuizz";
import ItStep11 from "../sustainable-it/step11/it.step11";
import ItStep12 from "../sustainable-it/step12/ItStep12";

export const ecoMobilityEnScenario = {
    title: 'Ecomobility',
    description: 'Discover best practices for a more responsible digital world.',
    longDescription: 'Learn more about responsible digital practices by solving puzzles as a team. Will you be the fastest and strongest team!?',
    lang: "en",
    rules: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/rules.mp4",
    tablet: false,
    steps: [
        {
            component: <EcoStep1/>,
            baseHints: [HintEcoStep1],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step1/background_p",
                password: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step1/password.png",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step1/hint_p",
                answers: [
                    "2019",
                    "transport",
                    "24",
                    "ghg"
                ],
                text1: "In",
                text2: "the",
                text3: "sector accounted for",
                text4: "% of global",
                text5: "emissions from energy combustion."
            },
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step2/video.mp4",
                questions: [
                    {
                        name: "In France, what proportion of Greenhouse Gas Emissions (GHG) is attributed to transportation?",
                        answers: [
                            "A - 4%",
                            "B - 24%",
                            "C - 31%",
                            "D - 94%"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "In France, transportation is the main source of pollution, accounting for 31% of greenhouse gas emissions, or nearly a third.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What is the main source of CO2 emissions in the transportation sector?",
                        answers: [
                            "A - Rail transport",
                            "B - Air transport",
                            "C - Maritime transport",
                            "D - Road transport"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "In France, road transport accounts for almost all of it, with 94% of emissions in the transportation sector.",
                        multiple: false,
                        bonus: 30
                    },
                ]
            }
        },
        {
            component: <EcoStep3/>,
            baseHints: [HintEcoStep3],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step3/background_p",
                password: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step3/password.png",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step3/hint_p",
                answersCategory1: [
                    "12",
                    "7",
                    "20",
                ],
                answersCategory2: [
                    "5",
                    "19",
                    "14",
                ],
                answersCategory3: [
                    "2",
                    "9",
                    "15",
                ],
                answersCategory4: [
                    "16",
                    "3",
                    "10",
                ],
                descriptions: {
                    5: "Go to the cinema or bowling",
                    2: "Buying vegetables at the market",
                    16: "Dental appointment",
                    12: "Dropping the kids off at school",
                    9: "Buying a birthday present",
                    3: "Drop off a parcel at the post office",
                    7: "Going to a coworking centre",
                    19: "Visit a museum",
                    10: "Getting a haircut",
                    20: "Going to my professional training",
                    14: "Do some yoga in the park",
                    15: "Go on a shopping spree",
                }
            },
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step4/video.mp4",
                questions: [
                    {
                        name: "In France, what proportion of commuting trips are made by car?",
                        answers: [
                            "A - 1/2",
                            "B - 3/4 ",
                            "C - 2/3",
                            "D - 1/4"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "In France, work is the main reason for travel, and 3/4 of commuting trips are made by car, most often alone.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "In France, what proportion of employed people make their commutes of less than 5 km by car?",
                        answers: [
                            "A - 80%",
                            "B - 60%",
                            "C - 40%",
                            "D - 20%"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "60% of working people make their journeys of less than 5 km by car",
                        multiple: false,
                        bonus: 30
                    },
                ]
            }
        },
        {
            component: <EcoStep5/>,
            baseHints: [HintEcoStep5],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step5/background_p",
                password: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step5/password.webp",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step5/hint_p",
                title: "What we can do to face up to all these challenges?",
                cardP3: [
                    {
                        front: "Impact on well-beign",
                        back: "N",
                    },
                    {
                        front: "Resource depletion",
                        back: "E",
                    },
                    {
                        front: "Air pollution",
                        back: "T",
                    },
                    {
                        front: "Economic impact",
                        back: "I",
                    },
                ],
                cardP4: [
                    {
                        front: "Global warming",
                        back: "A",
                    },
                    {
                        front: "Destruction of biodiversity",
                        back: "K",
                    },
                    {
                        front: "Destruction of coral reefs",
                        back: "O",
                    },
                    {
                        front: "Noise pollution",
                        back: "C",
                    }
                ],
                phrase1: [
                    {
                        letter: "T",
                        number: 2,
                        placeholder: "1"
                    },
                    {
                        letter: "A",
                        number: 3,
                        placeholder: "3"
                    },
                    {
                        letter: "K",
                        number: 2,
                        placeholder: "8"
                    },
                    {
                        letter: "E",
                        number: 4,
                        placeholder: "5"
                    }
                ],
                phrase2: [
                    {
                        letter: "A",
                        number: 3,
                        placeholder: "3"
                    },
                    {
                        letter: "C",
                        number: 3,
                        placeholder: "2",
                    },
                    {
                        letter: "T",
                        number: 2,
                        placeholder: "1"
                    },
                    {
                        letter: "I",
                        number: 1,
                        placeholder: "7",
                    },
                    {
                        letter: "O",
                        number: 1,
                        placeholder: "6"
                    },
                    {
                        letter: "N",
                        number: 4,
                        placeholder: "4"
                    }
                ],
            },
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step6/video.mp4",
                questions: [
                    {
                        name: "What are the impacts of traditional transportation mentioned earlier?",
                        answers: [
                            "A - Economic impacts",
                            "B - Impacts on health / quality of life",
                            "C - Ecological impacts",
                            "D - All of the above"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "Rethinking our modes of transportation is urgent, with benefits for the economy, environmental protection, health, and our quality of life.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "When we talk about the social cost of noise in France, what does it refer to?",
                        answers: [
                            "A - The social cost of noise refers to the quantity of decibels generated by traffic in major cities.",
                            "B - The social cost of noise is the monetary value associated with all the impacts generated by noise pollution borne by society.",
                            "C - The social cost of noise is the budget that the French state allocates annually to research on soundproofing materials.",
                            "D - The social cost of noise represents the funding of concerts and festivals to promote musical culture in urban areas."
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Indeed, noise nuisances have a cost due to the illnesses they cause, loss of productivity, and degradation of quality of life.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What is the average annual budget that a French household dedicates to the car?",
                        answers: [
                            "A - Less than €1000",
                            "B - Less than €3000",
                            "C - More than €5000",
                            "D - More than €7000"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "The car remains dominant in transportation spending, with over €5000/year on average spent by French households.",
                        multiple: false,
                        bonus: 30
                    },
                ]
            }
        },
        {
            component: <EcoStep7/>,
            baseHints: [HintEcoStep7, Hint2EcoStep7],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step7/background_p",
                password: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step7/password.png",
                hint1: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step7/hint1_p",
                hint2: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step7/hint2_p",
                descriptions: [
                    "- 560kg of CO2 / 3 months",
                    "Better health",
                    "Meeting new people",
                    "Saving money"
                ],
                answers: [
                    "Bike + Public Transport",
                    "Walking",
                    "Electric SUV + Car sharing",
                    "Electric scooter"
                ],
                options : [
                    {
                        label: "Bike + Bus",
                        value: "Bike + Bus"
                    },
                    {
                        label: "Bike + Public Transport",
                        value: "Bike + Public Transport"
                    },

                    {
                        label: "Car sharing",
                        value: "Car sharing"
                    },
                    {
                        label: "Car + Train",
                        value: "Car + Train"
                    },
                    {
                        label: "Electric bike",
                        value: "Electric bike"
                    },
                    {
                        label: "Electric scooter",
                        value: "Electric scooter"
                    },
                    {
                        label: "Electric SUV + Car sharing",
                        value: "Electric SUV + Car sharing"
                    },
                    {
                        label: "Internal combustion car",
                        value: "Internal combustion car"
                    },
                    {
                        label: "Motorbike",
                        value: "Motorbike"
                    },
                    {
                        label: "Plane",
                        value: "Plane"
                    },
                    {
                        label: "Walking",
                        value: "Walking"
                    },
                    {
                        label: "Walking + Bus",
                        value: "Walking + Bus"
                    },
                ]
            },
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step8/video.mp4",
                questions: [
                    {
                        name: "Over a distance of 10 km, how many times does the bus emit less CO2 than the car?",
                        answers: [
                            "A - Approximately 76 times less",
                            "B - Approximately 40 times less",
                            "C - Approximately 7 times less",
                            "D - Approximately 2 times less"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "Public transportation is less polluting than cars, but also more economical. If the average annual cost of a 10 km commute is €1000 by car, it is only €300 by bus!",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "In the absence of available public transportation, what alternatives represent sustainable mobility solutions for long trips?",
                        answers: [
                            "A - Electric car",
                            "B - Motorcycle",
                            "C - Carpooling",
                            "D - Traditional car"
                        ],
                        validAnswers: [
                            0,
                            2
                        ],
                        description: "Electric car: Over the entire lifespan, electric cars are less polluting than traditional cars. Carpooling: Carpooling allows for dividing CO2 emissions by the number of passengers.",
                        multiple: true,
                        bonus: 30
                    },
                ]
            }
        },
        {
            component: <EcoStep9/>,
            baseHints: [EcoHintStep9],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step9/background_p",
                hintAll: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step9/hint_all.png",
                hint1: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step9/hint1_p",
                hint2: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step9/hint2_p",
                hint3: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step9/hint3_p",
                hint4: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/en/step9/hint4_p",
                answers:  [
                    "12",
                    "60",
                    "89",
                    "19",
                    "38",
                    "2",
                    "46",
                    "49",
                    "94",
                    "28",
                    "35",
                    "66",
                    "33",
                    "67",
                    "54",
                    "27",
                ],
                descriptions: [
                    "Invest in R&D to enhance battery technology, improving energy density and extending battery life, while reducing the need for critical materials.",
                    "Focus on lightweight materials and innovative design techniques to reduce vehicle weight, enhancing efficiency and improving battery energy density",
                    "Implement aerodynamic enhancements such as streamlined body shapes and active aerodynamics to reduce drag, improving energy efficiency and increasing range.",
                    "Encourage the adoption of regenerative braking systems to capture and store energy during deceleration, maximizing energy efficiency in battery-powered vehicles.",
                    "Reduce reliance on fossil fuels and decrease emissions by transitioning from internal combustion engines to electric or hybrid vehicles.",
                    "Reducing environmental impact by regularly maintain vehicles to ensure optimal fuel efficiency and longevity.",
                    "Minimize the number of vehicles on the road and promote resource sharing by participating in car-sharing programs.",
                    "Optimize the fuel and time consumption by coordinating errands to minimize individual trips, combining multiple tasks into single outings.",
                    "Diversify supplier networks and establish redundant supply sources to mitigate risks and ensure continuity in the face of disruptions.",
                    "Implement robust inventory management systems to optimize stock levels, minimize waste, and enhance resilience against supply chain disruptions.",
                    "Embrace sustainable manufacturing practices such as resource efficiency, renewable energy adoption, and waste reduction to minimize environmental impact and enhance long-term viability.",
                    "Foster collaboration and information sharing among supply chain partners to enhance visibility, agility, and responsiveness, enabling proactive risk management and resilience-building efforts.",
                    "Ride-sharing Apps: Services like Uber and Lyft allow users to book rides from nearby drivers using smartphone apps, providing convenient and flexible transportation options.",
                    "Bike-sharing Programs: promoting the use of Bikes / E-bikes for short distances travels.",
                    "Electric Scooter and Car Rentals: Dockless electric scooter-sharing services provide on-demand scooters and cars for short-distance travel, accessible through smartphone apps.",
                    "Carpooling: Sharing rides with others going in the same direction reduces traffic, cuts costs, and promotes sustainability in daily commutes.",
                ],
                category1: "Create new energy low-carbon vehicles",
                category2: "Optimize and reduce the environmental impact of our vehicle usage",
                category3: "Move towards a sustainable and resilient supply chain and manufacturing operations",
                category4: "Adopt new and more sustainable mobility modes"
            }
        },
        {
            component: <ItStep11/>,
            baseHints: [],
            description: {
                endMessage1: "You can be proud of yourselves. You have developed a coherent action plan to move towards a more sustainable mobility!",
                endMessage2: <>To discover your score, you must first complete the final quiz! Be careful, penalties/bonuses can be earned in this quiz. <strong>Do it quickly!</strong></>,
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step3/video.webm",
                questions: [
                    {
                        name: "What percentage of global CO2 emissions is due to transportation?",
                        answers: [
                            "A - 3%",
                            "B - 24%",
                            "C - 51%",
                            "D - 64%"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "The transportation sector is the fastest-growing source of emissions and currently accounts for 24% of global energy-related greenhouse gas emissions.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "In France, what proportion of greenhouse gas emissions is specifically attributed to road traffic?",
                        answers: [
                            "A - 50%",
                            "B - 65%",
                            "C - 94%",
                            "D - 80%"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "Road transport accounts for almost all emissions in the transportation sector, at 94%, with more than half of it attributed to private vehicles.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What is the social cost of noise in France?",
                        answers: [
                            "A - €50 billion",
                            "B - €95 billion",
                            "C - €147.1 billion ",
                            "D - €200 billion"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "Socio-economic analyses show that noise pollution issues have a major impact, particularly on health (fatigue, stress leading to illnesses...).",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Out of the total social cost of noise in France, what proportion is attributed to transportation?",
                        answers: [
                            "A - 1/2",
                            "B - 2/3",
                            "C - 3/4",
                            "D - 4/5"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Two-thirds of the social cost of noise (66.5%) are attributed to transportation, with road noise constituting more than half (54.8%).",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What is the average annual budget spent by a French individual on a car?",
                        answers: [
                            "A - Around €500",
                            "B - Around €1500",
                            "C - Around €3000",
                            "D - Around €5000 "
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "With an average annual expenditure of over €5000, the car represents a significant portion of the French budget, including maintenance, insurance, parking, and rising fuel costs.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What is the approximate number of premature deaths in France due to air pollution?",
                        answers: [
                            "A - 8000 deaths",
                            "B - 48,000 deaths ",
                            "C - 158,000 deaths",
                            "D - 198,000 deaths"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "This figure varies depending on the studies. If we consider the often-cited 48,000 deaths, this represents 8% of mortality in France, a significant figure.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What advantages do public transportation offer compared to cars?",
                        answers: [
                            "A - Reduction of CO2 emissions and atmospheric pollution. ",
                            "B - Reduction of traffic congestion and time spent in traffic. ",
                            "C - Savings on travel expenses, such as fuel, maintenance, and parking. ",
                            "D - Opportunity to read, relax, or work during the commute, improving the quality of travel time. "
                        ],
                        validAnswers: [
                            0,
                            1,
                            2,
                            3
                        ],
                        description: "Opting for public transportation reduces our environmental impact, saves money, and allows for better use of our time, thus improving our quality of life.",
                        multiple: true,
                        bonus: 30
                    },
                    {
                        name: "What are the annual savings made possible by carpooling with a colleague on a 30 km commute to work?",
                        answers: [
                            "A - €200",
                            "B - €800",
                            "C - €1,000",
                            "D - €2,000"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "In addition to these substantial savings, carpooling promotes the sharing of CO2 emissions, thus contributing to a significant reduction in our environmental impact and strengthening solidarity among colleagues.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "The Mobility Orientation Law (LOM) and the sustainable mobility package in France aim to:",
                        answers: [
                            "A - Allow everyone to travel for free",
                            "B - Promote environmentally friendly mobility practices ",
                            "C - Contribute to longer and faster travel",
                            "D - Encourage the purchase of motorized two-wheelers"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "The Mobility Orientation Law (LOM) was published in the Official Journal on December 26, 2019. This law fundamentally transforms mobility policy, with a simple objective: everyday transportation that is both easier, less expensive, and cleaner.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Among the following eco-actions, which one does not contribute to soft mobility?",
                        answers: [
                            "A - Use an electric bicycle for short trips",
                            "B - Opt for carpooling",
                            "C - Make short trips (< 5km) in a personal car",
                            "D - Take the train for long distances"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "In France, 75% of car trips are less than 5 km. Choosing to walk or cycle for these short distances reduces our ecological footprint and helps make our cities greener and more pleasant.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "The increases in Greenhouse Gas Emissions (GHGs) in the transportation sector in France since 1800 are primarily due to:",
                        answers: [
                            "A - More trips made daily per person",
                            "B - Increased daily travel time per inhabitant",
                            "C - Lengthening of the distance traveled daily per inhabitant ",
                            "D - All three factors"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "While the number of daily trips (2 to 3 on average) and time spent in transportation (about 1 hour per day) have remained relatively stable since 1800, it is the extension of the distance traveled that marks the real evolution. We have shifted from mobility dominated by walking to mobility dominated by much faster modes of transportation, notably cars. However, we have not sought to reduce our travel time but rather to increase our radius of action, leveraging speed to travel further.",
                        multiple: false,
                        bonus: 30
                    },
                ]
            }
        },
        {
            component: <ItStep12/>,
            baseHints: [],
            description: {
                endMessage1: "You can be proud of yourselves. You have developed a coherent action plan to move towards a more responsible digital world!"
            }
        }
    ]
}