import {UserData} from "./UserData";
import {supabase} from "../../../configs/supabase";
import Notification from "../../services/notification/Notification";
import t from "../../../configs/i18n";
import {ErrorCode} from "../../services/const/ErrorCode";
import { Role } from "./Role";
import Normalize from "../../services/formatter/normalize";

export default class UserDataService {
    public static async getGamemastersOptions() : Promise<{label: string, value: string}[]> {
        const response = await supabase
            .from("role")
            .select("userdata(*)")
            .in("type", [Role.GAMEMASTER, Role.ADMIN])
            .is("session", null);

        if (response.error) {
            Notification.error(t.error.user, response.error);
            return [];
        }
        
        return (await UserData.newUserDatas(response.data.map(
            (role : any) => role.userdata
        ))).map(gamemaster => ({label: gamemaster.name, value: gamemaster.uid})).sort(
            (a, b) => a.label?.localeCompare(b.label)
        );
    }
}