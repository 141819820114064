import ItStep4PasswordContent from "../../sustainable-it/step4/components/it.step4.password.content";
import {Flex} from "antd";
import PopupGoodAnswer from "../../../shared/components/popup/PopupGoodAnswer";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import {useState} from "react";

export default function ItTabletStep4() {
    const context = useGameContext();
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Flex className="h-full w-full" vertical>
            <PopupGoodAnswer open={open} setOpen={setOpen} team={context.team} player={context.player} playersTeam={context.playersTeam} currentUser={context.user}/>
            <Flex className={"w-full h-full bg-[#FEF9F3] overflow-hidden"} align={"center"} vertical>
                <ItStep4PasswordContent popup={false} onValid={() => setOpen(true)}/>
            </Flex>
        </Flex>
    );
}