import {Flex} from "antd";
import t from "../../../configs/i18n";


export default function SessionFinished() {
    return (
        <Flex justify={"center"} vertical>
            <h2 className={"text-green-dark"}>{t.sessionEnd.sessionEnded}</h2>
        </Flex>
    )
}