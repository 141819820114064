import {useHomeContext} from "../../../context/context.home";
import useSessionInfo from "./callbacks/useSessionInfo";
import InfoResultSession from "../result/components/info.result.session";
import {Flex} from "antd";
import ButtonCreatePlayersSessionInfo from "./components/buttonCreatePlayers.info.session.home";
import AppSpinner from "../../../../../shared/components/spinner/AppSpinner";
import {SessionInfoContext} from "./context/context.info.session";
import {useParams} from "react-router-dom";
import {SessionInfoProvider} from "./context/provider.info.session";
import {TableInfoSessionHome} from "./components/table.info.session.home";


export default function InfoSessionHome() {
    const {id} = useParams();

    return (
        <SessionInfoProvider sessionId={id}>
            <InfoSessionHomeUnwrapped/>
        </SessionInfoProvider>
    )
}

function InfoSessionHomeUnwrapped() {
    const sessionInfo = useSessionInfo();

    if (sessionInfo.context.loading) {
        return <AppSpinner/>;
    }

    return (
        <Flex className={"w-full p-8"} vertical>
            <InfoResultSession session={sessionInfo.context.session}/>
            {sessionInfo.context.currentUser?.isAdmin() && <Flex className={"w-full mt-2"} justify={"end"}>
                <ButtonCreatePlayersSessionInfo/>
            </Flex>}
            <TableInfoSessionHome/>
        </Flex>
    )
}