import {Button, Flex, Modal} from "antd";
import React, {useState} from "react";
import {CameraOutlined} from "@ant-design/icons";
import t from "../../../configs/i18n";


interface CameraPopupProps {
    visibleByDefault?: boolean;
}

export default function CameraPopup({visibleByDefault = true}: CameraPopupProps) {
    const [visible, setVisible] = useState(visibleByDefault);

    return (
        <Modal open={visible} onCancel={() => setVisible(false)} footer={false} closeIcon={false} maskClosable={false}>
            <Flex className={"text-center"} align={"center"} justify={"center"} gap={"5px"} vertical>
                <CameraOutlined className={"text-6xl text-red-500 mt-5"} />
                <h2 className={"text-red-500"}>{t.sessionGame.cameraPopup.title}</h2>
                <p>{t.sessionGame.cameraPopup.message}</p>
                <h4>{t.sessionGame.cameraPopup.message2}</h4>
                <Button onClick={() => setVisible(false)}  danger type={"primary"} size={"large"}>{t.sessionGame.cameraPopup.button}</Button>
            </Flex>
        </Modal>
    )
}