import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {MenuProps} from "antd";
import {
    CalendarOutlined,
    CheckOutlined,
    ContactsOutlined,
    PlayCircleOutlined,
    ProfileOutlined
} from "@ant-design/icons";
import {supabase} from "../../../configs/supabase";
import t from "../../../configs/i18n";
import {useHomeContext} from "../context/context.home";
import { Role } from "../../../shared/models/UserData/Role";


export default function useHome() {
    const [currentRoute, setCurrentRoute] = useState<string[]>();
    const location = useLocation();
    const navigate = useNavigate();
    const context = useHomeContext();

    const [items, setItems] = useState<MenuProps['items']>([
        {
            label: t.home.dashboard,
            key: '',
            icon: <ProfileOutlined />,
         },
        {
            label: t.home.session.inProgress,
            key: 'session-in-progress',
            icon: <PlayCircleOutlined />,
        },
        {
            label: t.home.session.future,
            key: 'session-future',
            icon: <CalendarOutlined />,
        },
        {
            label: t.home.session.passed,
            key: 'session-old',
            icon: <CheckOutlined />,
        }
    ]);

    useEffect(() => {
        if (context.user?.isAdmin()) {
            items?.push({
                label: t.common.companies,
                key: 'company',
                icon: <ContactsOutlined />,
            })
            setItems(items);
        }
    }, [context.user]);

    useEffect(() => {
        const path = location.pathname.split("/")
        if (path.length === 2) {
            setCurrentRoute([""]);
        } else {
            setCurrentRoute(path.slice(1));
        }
    }, [location.pathname]);

    const onMenuClick = (e: any) => {
        navigate(e.key);
    }

    const disconnect = async () => {
        await supabase.auth.signOut();
        navigate("/");
    }

    return {
        currentRoute,
        items,
        onMenuClick,
        disconnect,
        context,
    }
}