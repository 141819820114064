import {Button, Flex, Modal} from "antd";
import t from "../../../configs/i18n";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useMemo } from "react";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    onOk: () => void;
    onExitOrNo?: () => void;
    content: string | React.ReactNode;
    zIndex?: number;
}

export default function PopupConfirmation(props: Props) {
    const content = useMemo(() => {
        if (!props.content) return "";
        if (typeof props.content === "string") {
            return (
                <h2>
                    {props.content}
                </h2>
            )
        }
        return props.content;
    }, [props.content])
    return (
        <Modal open={props.open} onCancel={props.onExitOrNo} footer={false} zIndex={props.zIndex}>
            <Flex align={"center"} justify={"center"} className={"w-full h-full text-center"} vertical>
                <QuestionCircleOutlined className={"text-6xl text-green-light mt-6"} />
                {content}
                <Flex className={"mt-8"} gap={"10px"}>
                    <Button onClick={props.onOk} className={"w-[80px]"} size={"large"}>{t.common.yes}</Button>
                    <Button onClick={props.onExitOrNo} className={"w-[80px]"} size={"large"}>{t.common.no}</Button>
                </Flex>
            </Flex>
        </Modal>
    )
}