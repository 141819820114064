import ItStep1 from "./step1/it.step1";
import ItStep2 from "./step2/it.step2";
import StepVideoQuizz from "../shared/step_video_quizz/StepVideoQuizz";
import ItStep4 from "./step4/it.step4";
import ItStep6 from "./step6/it.step6";
import ItStep8 from "./step8/it.step8";
import ItStep10 from "./step10/it.step10";
import ItStep11 from "./step11/it.step11";
import ItStep12 from "./step12/ItStep12";
import {CalculatorFilled} from "@ant-design/icons";

const sustainableItFrSolo = {
    title: 'Numérique responsable - solo',
    description: 'Découvrez les bonnes pratiques pour un numérique plus responsable',
    longDescription: 'Apprenez en plus sur le numérique responsable, en résolvant des énigmes en équipe. Serez-vous l\'équipe la plus rapide et forte !?',
    lang: "fr",
    rules: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/rules.webm",
    tablet: false,
    solo: true,
    steps: [
        {
            component: <ItStep1/>,
            baseHints: [],
            description: {
                backgroundLight: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/solo/fr/step1/background.png",
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step1/video.webm",
                lock: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step1/lock.png",
                answer: "60",
                question: "Quel pourcentage d’augmentation des émissions de gaz à effet de serre liées au secteur numérique prévoit-on d’ici 2040 ?",
                hints: [
                    "Pour sortir de cette pièce, cliquez sur le cadenas",
                    "La réponse se trouve dans la vidéo, écoutez la attentivement",
                ],
                solution: {
                    "penalty": 120
                },
                timeBeforeGreen: 180
            },
        },
        {
            component: <ItStep2/>,
            baseHints: [],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/solo/fr/step2/background.png",
                sketch: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step2/sketch_",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step2/hint_",
                description: [
                    <p>L’accès à Internet nous permet d’appeler, de <strong>discuter</strong>, d’échanger et d’apprendre à distance
                        via les réseaux sociaux, les webinaires, les visioconférences, les forums, les tutos, les Moocs …</p>,
                    <p>Aujourd’hui, avec nos ordinateurs, tablettes, téléphones etc, il est facile de checker ses mails, rédiger un
                        article, effectuer des analyses financières. Ainsi, chacun peut (télé)<strong>travailler</strong> aisément.</p>,
                    <p>Le numérique permet également de <strong>se distraire</strong> en ligne (lire, écouter de la musique, regarder une série,
                        jouer à la console ou encore scroller sur les réseaux).</p>,
                    <p>Aujourd'hui, plus que jamais, les organisations s'appuient sur les technologies numériques
                        pour <strong>automatiser</strong> leurs processus. Ce qui permet d’améliorer leur efficacité, d’augmenter la
                        satisfaction clients, renforcer leur sécurité et de réduire leurs coûts (néo-banques, Uber, Airbnb, …)</p>
                ],
                answers: [
                    "discuter",
                    "travailler",
                    "se distraire",
                    "automatiser",
                ],
                title: "À quoi sert le numérique ?",
                hints: [
                    "Cliquez sur chaque portrait grisé pour découvrir ce que chaque célébrité a permis de faire avec le numérique.",
                    "Prêtez attention au mot en gras.",
                    "Pour valider le code, faites correspondre chaque croquis et chaque photo afin d’avoir la bonne combinaison."
                ],
                solution: {
                    "penalty": 120
                },
                timeBeforeGreen: 180
            }
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step3/video.webm",
                questions: [
                    {
                        name: "Qu'est ce que l'effet rebond ?",
                        answers: [
                            "A - L'effet rebond est un phénomène économique se produisant lorsqu'une récession est suivie d'une période de forte croissance.",
                            "B - L'effet rebond est un effet physique qui se produit lorsqu'un objet rebondit sur une surface après avoir été lancé.",
                            "C - L'effet rebond est un terme utilisé en psychologie pour décrire le comportement de certaines personnes qui se rétablissent rapidement après un traumatisme ou une blessure.",
                            "D - L'effet rebond est lorsque nous économisons de l'énergie ou des ressources, mais que nous augmentons notre consommation globale, annulant ainsi les bénéfices environnementaux de notre démarche."
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "L'effet rebond désigne le fait d'économiser de l'énergie ou des ressources, tout en augmentant la consommation globale, annulant ainsi les bénéfices environnementaux de la démarche.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quel pourcentage du flux de données total sur Internet représentent les contenus vidéos ?",
                        answers: [
                            "A - 10%",
                            "B - 40%",
                            "C - 80%",
                            "D - 90%"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "La bonne réponse était 80%",
                        multiple: false,
                        bonus: 30
                    }
                ]
            }
        },
        {
            component: <ItStep4/>,
            baseHints: [],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/solo/fr/step4/background.png",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step4/hint_p",
                backgroundPassword: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step4/background_password.webp",
                imageCompleted: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step4/answer_p",
                answers: [
                    ["infrastructures", "infrastructure"],
                    ["fabrication"],
                    ["déchets", "déchet"],
                    ["appareils", "appareil"],
                    ["obsolescence"],
                ],
                description: [
                    "Souvent invisibles car présents dans l’océan ou dans l’espace, ils sont pourtant indispensables au fonctionnement du réseau.",
                    "Elle est de loin la principale source d'impact du numérique sur l'environnement.",
                    "En 2021, ils pesaient déjà 57 millions de tonnes.",
                    "On en compte 34 milliards dans le monde.",
                    "Elle peut être technique ou psychologique..."
                ],
                descriptionCompleted: [
                    "Pour que nos appareils disposent d’Internet, il faut des satellites, des câbles sous-marins, des antennes-relais, des data centers etc. qu’on ne voit pas forcément.",
                    "Il faut bien concevoir tous ces équipements numériques avant de pouvoir les utiliser. Cette étape est la plus grosse source d'impact du numérique.",
                    "Nos anciens équipements sont enfouis, incinérés ou sont exportés illégalement car certains récupèrent les métaux présents dans nos appareils. Ainsi, le recyclage n'est que partiel",
                    "Téléphones, ordinateurs, montres connectées, consoles vidéo ... bref tous nos objets connectés arrivent dans notre quotidien et sont prêts à l’emploi. Mais que se cache-t-il derrière nos clics ?",
                    "Elle peut être technique ou psychologique (marketing) et nous pousse à racheter de nouveaux produits pour avoir le dernier modèle... alors que nos appareils fonctionnent encore..."
                ],
                prefix: [
                    "Les",
                    "La",
                    "Des",
                    "Nos",
                    "L'",
                ],
                hints: [
                    "Fouillez minutieusement votre écran pour retrouver 5 cartes indispensables à cette frise.",
                    "Lisez attentivement ces 5 cartes pour faire correspondre les explications de ces dernières avec le texte inscrit sous les bulles de couleurs présentes sur la frise du cadenas.",
                ],
                solution: {
                    "penalty": 120
                },
                timeBeforeGreen: 180
            }
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step5/video.webm",
                questions: [
                    {
                        name: "Quelle est la principale source d’impact du numérique ?",
                        answers: [
                            "A - La fabrication des équipements numériques",
                            "B - La consommation électrique des datacenters",
                            "C - La phase d’usage de nos équipements (sport avec une montre connectée, vidéos sur nos ordinateurs, jeux sur consoles vidéos….)",
                            "D - Les réseaux (satellites, câbles sous-marins…)"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "La fabrication des équipements numériques est de loin la source la plus importante d'impact du numérique.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quel est le pourcentage de l'empreinte carbone attribué à la phase de fabrication dans le numérique ?",
                        answers: [
                            "A - 24%",
                            "B - 46%",
                            "C - 78%",
                            "D - 99%"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "La bonne réponse était 78%.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Combien comptait-on d’équipements en 2019 dans l’univers du numérique ?",
                        answers: [
                            "A - 4,1 milliards",
                            "B - 8 milliards",
                            "C - 32 milliards",
                            "D - 34 milliards"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "En 2019, il y avait plus de 34 milliards d'équipements numériques, et ce chiffre ne cesse de croitre.",
                        multiple: false,
                        bonus: 30
                    }
                ]
            }
        },
        {
            component: <ItStep6/>,
            baseHints: [],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/solo/fr/step6/background.png",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/solo/fr/step6/hint_",
                backpack: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/solo/fr/step6/backpack.png",
                answers:  [
                    "200",
                    "600",
                ],
                description1 : <>Lorsque j'emmène mon <strong>ordinateur de 2kg</strong> dans mon sac, il y a aussi :</>,
                answer1 : "d'énergies fossiles",
                answer2 : "de minéraux",
                description2: "et des milliers de litres d'eau douce.",
                description3: <>Lorsque j'emmène <strong>mon smartphone</strong> dans mon sac, il y a aussi :</>,
                answer3: "matériaux différents, dont",
                answer4: "métaux",
                description4: "4 tours du monde sont nécessaires pour le fabriquer (conception, extraction, assemblage, distribution...).",
                hints: [
                    "Les deux ressources sont disponibles en cliquant sur les tableaux \"Energies fossiles\" et \"Minéraux\" à droite sur la commode. Des informations précieuses s'afficheront, soyez attentif.",
                    "Sur chaque carte ressource, la couleur et le symbole à droite sont cruciales. Cliquez ensuite sur l'ordinateur pour trouver la correspondance.",
                    "Pour renseigner la quantité trouvée pour chaque ressource, cliquez sur le sac à dos avec le cadenas."
                ],
                solution: {
                    "penalty": 300
                },
                timeBeforeGreen: 180
            }
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step7/video.webm",
                questions: [
                    {
                        name: "Combien de kg de ressources sont nécessaire pour fabriquer un ordinateur de 2kg ?",
                        answers: [
                            "A - 800kg",
                            "B - 600kg",
                            "C - 70kg",
                            "D - 50kg"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "La bonne réponse était 800kg.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quel taux des terres rares (métaux critiques) est recyclé ?",
                        answers: [
                            "A - 1%",
                            "B - 17%",
                            "C - 37%",
                            "D - 60%"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "Seulement 1% des terres rares est actuellement recyclé.",
                        multiple: false,
                        bonus: 30
                    }
                ]
            }
        },
        {
            component: <ItStep8/>,
            baseHints: [],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/solo/fr/step8/background.png",
                definitions: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/solo/fr/step8/definition",
                prefield: [2, 5, 6, 7],
                crossword: {
                    across: {
                        4: { clue: '', answer: 'EAU', row: 3, col: 1 },
                        6: { clue: '', answer: 'SANTE', row: 5, col: 0 },
                        7: { clue: '', answer: 'DESTRUCTION', row: 8, col: 2 },
                        8: { clue: '', answer: 'PENURIE', row: 14, col: 5 },
                    },
                    down: {
                        1: { clue: '', answer: 'POLLUTION', row: 0, col: 12 },
                        2: { clue: '', answer: 'RUPTURE', row: 2, col: 3 },
                        3: { clue: '', answer: 'CONFLIT', row: 2, col: 9 },
                        5: { clue: '', answer: 'RECHAUFFEMENT', row: 3, col: 7 },
                    },
                },
                hints: [
                    "Survolez bien votre écran et cliquez sur toutes les images en rapport avec le monde.",
                    "D'autres définitions pour vous aider : <br/><br/>Mot 1 (P……..) : Contamination de l'eau, terre et air.<br/>Mot 3 (C…….) : Tensions entre pays pour les minerais rares.<br/>Mot 4 (…) : Liquide vital à boire.<br/>Mot 8 (P……) : Manque futur de ressources pour nos équipements."
                ],
                solution: {
                    "penalty": 300
                },
                timeBeforeGreen: 180
            }
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step9/video.webm",
                questions: [
                    {
                        name: "Qu’est-ce que les minerais de sang ?",
                        answers: [
                            "A - Ce sont les taux de concentration des minerais présent dans le sang des creuseurs",
                            "B - Ce sont les blessures qu’un mineur se fait pendant l’extraction des métaux rares et précieux",
                            "C - Ce sont des minerais appelés ainsi car ils sont utilisés dans le domaine médical.",
                            "D - Ce sont des minerais aussi appelés de conflits et extraits dans des zones de violations des droits et où les profits servent généralement à financer des groupes armés."
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "Les minerais de sang tiennent leur nom des conflits que leur extraction engendre. Souvent extraits dans des zones de violations des droits, et dont les profits financent des groupes armées.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Parmi ces impacts causés par le numérique, quel est l’intrus ?",
                        answers: [
                            "A - Santé mentale",
                            "B - Stress Hydrique",
                            "C - Destruction de la biodiversité",
                            "D - Pollution de l'eau, des sols et de l'air"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "La santé mentale n'est pas un impact environnemental !",
                        multiple: false,
                        bonus: 30
                    }
                ]
            }
        },
        {
            component: <ItStep10/>,
            baseHints: [],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/solo/fr/step10/background.png",
                modal: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/solo/fr/step10/modal_background.png",
                hint_links: [
                    "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/solo/fr/step10/take_action.png",
                    "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step10/hint2_p2.webp",
                    "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step10/hint2_p1.webp",
                    "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step10/hint2_p3.webp",
                    "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step10/hint3_p3.webp",
                    "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step10/hint3_p4.webp",
                    "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step10/hint3_p2.webp",
                    "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step10/hint4_p3.webp",
                    "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step10/hint4_p2.webp",
                ],
                answers: [
                    "12",
                    "19",
                    "89",

                    "2",
                    "49",

                    "66",
                    "94",

                    "33",
                ],
                descriptions: [
                    "Je privilégie la location ou le prêt d'équipements, lorsque si possible.",
                    "Je limite le suréquipement avec des objets 3 en 1.",
                    "Je questionne mes besoins avant de réaliser un achat.",
                    "Je donne une seconde vie à mes équipements lorsque je ne les utilise plus (réutilisation, réparation ou recyclage).",
                    "Je prends conscience de l'obsolescence (programmée, logicielle, psychologique) et je conserve mon matériel plus longtemps.",
                    "Je préfère la Wi-Fi à la 4G et je stocke mes documents sur un disque plutôt que sur le cloud.",
                    "J'envoie des mails et/ou pièces-jointes uniquement aux personnes concernées et je me désabonne des publicités.",
                    "J'utilise une multiprise à interrupteur.",
                ],
                category1: "Je m'engage à ne pas sur-consommer",
                category2: "J'allonge la durée de vie des équipements",
                category3: "Je raisonne mon usage des flux de données",
                category4: "Je diminue ma consommation électrique",
                hints: [
                    "Entrez le nombre associé à une action dans la bonne catégorie. Un nombre ne peut-être utilisé qu'une seule fois.",
                    "Lorsque la catégorie est bonne, elle passera en vert."
                ],
                solution: {
                    "penalty": 120
                },
                timeBetweenHints: 180
            }
        },
        {
            component: <ItStep11/>,
            baseHints: [],
            description: {
                endMessage1: "Vous pouvez être fiers de vous. Vous avez élaboré un plan d'action cohérent pour aller vers un numérique plus responsable !",
                endMessage2: <>Pour découvrir votre temps, vous devez d'abord tous réaliser le quizz final ! Attention des malus/bons sont à gagner dans ce quizz. <strong>Faites vite !</strong></>,
                questions: [
                    {
                        name: "Quel est le pourcentage des émissions de gaz à effet de serre produit par le numérique aujourd’hui ?",
                        answers: [
                            "A - 0 à 2%",
                            "B - 3 à 4%",
                            "C - 5 à 6%",
                            "D - 7 à 8%"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Le numérique représente aujourd’hui 3 à 4 % des émissions de gaz à effet de serre (GES) dans le monde. Si cette part demeure modeste comparativement à d’autres secteurs, la croissance annuelle de la consommation de numérique (volume de données, terminaux, etc.) doit nous interroger.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "En termes d'utilisation, la surconsommation numérique est ainsi principalement causée par :",
                        answers: [
                            "A - L’envoi de multiples mails",
                            "B - L’usage intensif des vidéos",
                            "C - La navigation Web sur plusieurs onglets",
                            "D - Les réseaux sociaux"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "La vidéo est le poids lourd des usages du numérique (VOD, pornographie, Tubes, réseaux, télésurveillance, télémédecine…). Il ne faut pas oublier que la vidéo en ligne n’est pas un usage dématérialisé.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quelle part des déchets est collectée et recyclée à l’échelle mondiale ?",
                        answers: [
                            "A - 17%",
                            "B - 37%",
                            "C - 57%",
                            "D - 77%"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "En 2019, seuls 17% des déchets d’équipements électriques et électroniques (DEEE) ont été collectés et recyclés à l’échelle mondiale. Même si les déchets sont correctement collectés, la majeure partie d’un objet électronique n’est pas indéfiniment recyclable voire pas recyclable du tout (quantité trop infime, alliage, plastiques).",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quelle étape a l'impact le plus néfaste pour notre planète dans le numérique ?",
                        answers: [
                            "A - La fabrication des équipements",
                            "B - La distribution des équipements",
                            "C - L’utilisation des équipements",
                            "D - La fin de vie"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "La phase de fabrication est la principale source d’impact du numérique (terminaux utilisateur, réseaux et centre de données).\nDe plus, la fabrication des équipements individuels : téléviseurs, ordinateurs, smartphones, tablette etc. plus nombreux et plus rapidement obsolète que les infrastructures - est le principal impact néfaste sur l’environnement (60 à 90% selon l’indicateur).",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "En moyenne, combien a-t-on d’équipements numériques par personne ?",
                        answers: [
                            "A - 2",
                            "B - 4",
                            "C - 6",
                            "D - 8"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "Dans le monde, le numérique représente 34 milliards d'équipements pour 4.1 milliards d'utilisateurs, soit 8 équipements par personne.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "À quelle fréquence, en moyenne, remplaçons-nous nos smartphones aujourd'hui ?",
                        answers: [
                            "A - 0 - 1 an",
                            "B - 2 - 3 ans",
                            "C - 4 - 5 ans",
                            "D - + 6 ans"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Nous remplaçons aujourd’hui nos smartphones tous les 2 à 3 ans en moyenne. Alors qu’ils fonctionnent généralement encore bien. Effet de mode, obsolescences, manque d’entretien etc. nous incitent à un renouvellement trop fréquent qui n’est pas sans conséquence sur l’environnement.\nEn utilisant votre smartphone le plus longtemps possible, vous évitez la production de nouveaux appareils et vous préservez l’environnement et les populations !",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Qu’est-ce l’obsolescence psychologique ?",
                        answers: [
                            "A - Un consommateur remplace la coque de son téléphone cassé",
                            "B - Un consommateur achète un nouvel équipement car il en a besoin",
                            "C - Un consommateur change son produit qui lui paraît trop vieux, démodé",
                            "D - Un consommateur agit “écolo” en achetant recyclé"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "L’idée est d’inciter à consommer vite et plus grâce à des stratégies commerciales bien rodées (publicité, surproduction...), rendant ainsi les anciens produits “démodés”.\nL’obsolescence psychologique, aussi appelée culturelle est le fait de renouveler ses produits plus rapidement que nécessaire pour être à la pointe de la tendance.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Combien y-a-t-il de matériaux différents dans un smartphone ?",
                        answers: [
                            "A - 50",
                            "B - 70",
                            "C - 200",
                            "D - 600"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Plus de 70 matériaux différents sont utilisés pour fabriquer un smartphone : du plastique, du verre mais aussi une cinquantaine de métaux précieux et rares (or, argent, platine, tantale…).\nCes métaux ne sont pas recyclables à l’infini et sont en stock limité.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quels écogestes peut-on mettre en place pour avoir un fort impact et réduire son empreinte environnementale ?",
                        answers: [
                            "A - Garder son téléphone quelques années de plus pour éviter de produire à nouveau",
                            "B - Acheter des appareils reconditionnés pour éviter la surproduction",
                            "C - Stocker ses données sur le cloud plutôt que sur un disque dur",
                            "D - Questionner ses besoins pour éviter la surconsommation"
                        ],
                        validAnswers: [
                            0,
                            1,
                            3
                        ],
                        description: "L’impact environnemental des équipements numérique étant principalement dû à la production des appareils, le comportement des utilisateurs pèse significativement sur la réduction des émissions de gaz à effet de serre causées par le numérique.\nEnfin, stocker ses données sur le cloud est très énergivore, il est préférable de les conserver sur un disque dur personnel.",
                        multiple: true,
                        bonus: 30
                    }
                ]
            }
        },
        {
            component: <ItStep12/>,
            baseHints: [],
            description: {
                endMessage1: "Vous pouvez être fiers de vous. Vous avez élaboré un plan d'action cohérent pour aller vers un numérique plus responsable !",
                additionalButton: <><CalculatorFilled />  Calculer votre impact numérique</>,
            }
        }
    ],
}

export default sustainableItFrSolo;