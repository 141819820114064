import {Modal} from "antd";
import ItHintStep8 from "../hint/ItHintStep8";
import React from "react";

export interface BaseHintProps {
    number: number
    open: boolean
    setOpen: (open: boolean) => void
}

export default function HintModalStep8({number, open, setOpen}: BaseHintProps) {
    return (
        <Modal open={open} footer={false} onCancel={() => setOpen(false)}>
            <ItHintStep8 number={number}/>
        </Modal>
    )
}