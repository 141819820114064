import InvisibleButton from "../../../../shared/components/button/InvisibleButton";
import {useState} from "react";
import PasswordModalStep9 from "./PasswordModalStep9";
import StepProps from "../../../types/StepProps";
import HintModalStep9 from "./HintModalStep9";

interface ButtonsStep4Props {
    showHint: boolean;
    playerNumber: number;
    mainHintPos: {x: number, y: number, h: number, w: number};
    passwordPos?: {x: number, y: number, h: number, w: number};
    stepContext: StepProps;
}

export default function ButtonsStep9({playerNumber, showHint, mainHintPos, stepContext, passwordPos = {x: 620, y: 156, h: 250, w: 350}}: ButtonsStep4Props) {
    const [hintModal, setHintModal] = useState<boolean>(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);



    return (
        <>
            <HintModalStep9 open={hintModal} setOpen={setHintModal} number={playerNumber} />
            <PasswordModalStep9 stepContext={stepContext} open={openPasswordModal} setOpen={setOpenPasswordModal} />


            {/* Main Hint Button */}
            <InvisibleButton onClick={() => setHintModal(true)} top={mainHintPos.y} left={mainHintPos.x} h={mainHintPos.h} w={mainHintPos.w} showHint={showHint}/>
            {/* Password Modal */}
            <InvisibleButton onClick={() => setOpenPasswordModal(true)} top={passwordPos?.y} left={passwordPos?.x} h={passwordPos?.h} w={passwordPos?.w} showHint={showHint}/>
        </>
    )
}