import {Flex, Image} from "antd";
import TheWokiesLogo from "./../../../shared/assets/images/logo_square.webp";

function BannerLogin() {
    return (
        <Flex className="w-full h-12 bg-beige">
            <Image className="ml-2 mt-1" src={TheWokiesLogo} preview={false} placeholder={true} width={40} />
        </Flex>
    )
}

export default BannerLogin;
