import removeAccents from 'remove-accents';


export default class Answer {
    public static normalize(str: string): string {
        if (str === undefined || str === null) {
            return "";
        }

        return removeAccents(str.toLowerCase().trim());
    }

    public static isCorrect(answer: string, expected: string): boolean {
        if (answer === undefined || expected === undefined || answer === "" || expected === "" || answer === null || expected === null) {
            return false;
        }

        return this.normalize(answer) === this.normalize(expected);
    }

    public static isCorrectArray(answer: string, expected: string[]): boolean {
        if (answer === undefined || expected === undefined || answer === "" || expected === null) {
            return false;
        }

        return expected.some(possibleAnswer => this.normalize(possibleAnswer) === this.normalize(answer));
    }

    public static isCorrectWithOrder(answer: { [key: string]: string }, expected: string[], sort: boolean = true): boolean {
        if (answer === undefined || expected === undefined || answer === null || expected === null) {
            return false;
        }

        let sortedKeys = Object.keys(answer).sort();
        if (!sort) {
            sortedKeys = Object.keys(answer);
        }
        let sortedAnswer: { [key: string]: string } = {};
        for (let key of sortedKeys) {
            sortedAnswer[key] = answer[key];
        }

        const answerArray = Object.values(sortedAnswer);
        if (answerArray.length !== expected.length) {
            return false;
        }

        return answerArray.every((value, index) => this.normalize(value) === this.normalize(expected[index]));
    }

    public static isCorrectWithOrderArray(answer: { [key: string]: string }, expected: string[][], sort: boolean = true): boolean {
        if (answer === undefined || expected === undefined || answer === null || expected === null) {
            return false;
        }

        let sortedKeys = Object.keys(answer).sort();
        if (!sort) {
            sortedKeys = Object.keys(answer);
        }
        let sortedAnswer: { [key: string]: string } = {};
        for (let key of sortedKeys) {
            sortedAnswer[key] = answer[key];
        }

        const answerArray = Object.values(sortedAnswer);
        if (answerArray.length !== expected.length) {
            return false;
        }

        return answerArray.every((value, index) => {
            const normalizedValue = this.normalize(value);
            return expected[index].some(possibleAnswer => this.normalize(possibleAnswer) === normalizedValue);
        });
    }
}