import {Button, Carousel, Empty, Flex} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useMemo, useRef, useState} from "react";
import {CarouselRef} from "antd/es/carousel";
import CardsPhotoResultSession from "./cards.photo.result.session";
import {useSessionResultContext} from "../context/context.result.session";
import t from "../../../../../../configs/i18n";


export default function PhotoResultSession() {
    const ref = useRef<CarouselRef>(null)
    const context = useSessionResultContext();
    const [carouselIndex, setCarouselIndex] = useState<number>(0);
    const teamWithPhoto = useMemo(() => context.teamRank.filter(t => t.photo), [context.teamRank]);

    const next = () => {
        if (carouselIndex === (teamWithPhoto.length - 1) / 4) {
            setCarouselIndex(0);
        } else {
            setCarouselIndex(carouselIndex => carouselIndex + 1);
        }
    }

    const prev = () => {
        if (carouselIndex === 0) {
            setCarouselIndex((teamWithPhoto.length - 1) / 4);
        } else {
            setCarouselIndex(carouselIndex => carouselIndex - 1);
        }
    }


    return (
        <Flex justify={"center"} align={"center"} className={"w-full bg-[#F3F8FF] rounded-lg p-4 box-border"} vertical>
           <h2 className={"text-green-dark"}>{t.home.session.seePhoto}</h2>
            <Flex align={"center"}>

                {teamWithPhoto.length > 0 && <>
                    <Button onClick={prev} type={"text"}><LeftOutlined/></Button>

                    <div className="grid grid-cols-1 w-full">
                        <Carousel dots={false} ref={ref} className={"pb-8"}>
                            <CardsPhotoResultSession teams={teamWithPhoto.slice(carouselIndex * 4, carouselIndex * 4 + 4)}/>
                        </Carousel>
                    </div>
                    <Button onClick={next} type={"text"}><RightOutlined/></Button>
                </>}

                {teamWithPhoto.length === 0 && <Empty description={t.home.session.noPhoto}/>}


            </Flex>
        </Flex>
    )
}