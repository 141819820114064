import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import {Button, Flex, Form} from "antd";
import ImageR from "../../../../shared/components/image/ImageR";
import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";
import t from "../../../../configs/i18n";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import Answer from "../../../../shared/services/answer/Answer";
import Notification from "../../../../shared/services/notification/Notification";
import { useRef, useState } from "react";
import MouseFloatingText from "../../../../shared/components/mouse/MouseFloatingText";

interface Props {
    onValid?: () => void;
    popup?: boolean;
}

export default function ItStep6PasswordContent(props: Props) {
    const context = useGameContext();
    const backpackImage = context.step?.description.backpack
    const [form] = Form.useForm();
    const answers = context.step?.description.answers
    const id = `${context.team?.id}-${context.team?.step}`;

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [onHover, setOnHover] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    
    const updateButton = (value: string, id: string) => {
        // Check if all fields have values
        const allFieldsFilled = Object.values(form.getFieldsValue() as string[]).every(
            (value) => value !== undefined && value !== ""
        );
        setIsButtonDisabled(!allFieldsFilled); // Disable if not all fields are filled
    };

    const validate = () => {
        const isValid = Answer.isCorrectWithOrder(form.getFieldsValue(), answers);

        if (!isValid) {
            Notification.error(t.sessionGame.wrongAnswer);
            return;
        }

        props.onValid && props.onValid();
    }



    return (
        <div ref={componentRef}>
            {(props.popup === undefined || props.popup) && <CanClosePopup/>}
            <Flex>
                <ImageR className={"mt-5 object-scale-down"} src={backpackImage} width={"100px"} height={"200px"} />
                <Flex className={"ml-8 mt-8"} vertical>

                    <p className={"m-0"}>{context.step?.description.description1}</p>
                    <Flex align={"center"}>
                        <Flex className={"w-24 mr-2"}>
                            <SyncronizedInput number={2} id={`${id}-1`} addonAfter={"kg"} placeHolder={"?"} size={"small"} player={context.player} playersTeam={context.playersTeam} form={form} onChange={updateButton}/>
                        </Flex>
                        <p>{context.step?.description.answer1}</p>
                    </Flex>

                    <Flex align={"center"}>
                        <Flex className={"w-24 mr-2"}>
                            <SyncronizedInput number={3} id={`${id}-2`} addonAfter={"kg"} placeHolder={"?"} size={"small"} player={context.player} playersTeam={context.playersTeam} form={form} onChange={updateButton}/>
                        </Flex>
                        <p>{context.step?.description.answer2}</p>
                    </Flex>
                    <p className={"m-0"}>{context.step?.description.description2}</p>

                    <p className={"m-0 mt-8"}>{context.step?.description.description3}</p>
                    <Flex align={"center"}>
                        <Flex className={"w-24 mr-2"}>
                            <SyncronizedInput number={4} id={`${id}-3`} placeHolder={"?"} size={"small"} player={context.player} playersTeam={context.playersTeam} form={form} onChange={updateButton}/>
                        </Flex>
                        <p>{context.step?.description.answer3}</p>
                    </Flex>

                    <Flex align={"center"}>
                        <Flex className={"w-24 mr-2"}>
                            <SyncronizedInput number={1} id={`${id}-4`} placeHolder={"?"} size={"small"} player={context.player} playersTeam={context.playersTeam} form={form} onChange={updateButton}/>
                        </Flex>
                        <p>{context.step?.description.answer4}</p>
                    </Flex>
                    <p className={"m-0"}>{context.step?.description.description4}</p>

                </Flex>
            </Flex>
            <Flex justify={"center"} align={"center"} className={"mt-10"}>
                <Flex onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
                    <Button onClick={validate} type={"primary"} disabled={isButtonDisabled}>{t.common.validate}</Button>
                </Flex>
            </Flex>
            <MouseFloatingText start={componentRef.current?.getBoundingClientRect()} condition={onHover && isButtonDisabled} text={t.common.allFieldsFilled}/>
        </div>
    )
}