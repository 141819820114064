import {Button, Flex} from "antd";
import {InputValue} from "../../../shared/models/Team/InputValue";
import t from "../../../configs/i18n";
import Question from "../step_quizz/type/Question";
import scenarios from "../../scenarios";

interface Props {
    question: Question
    answers: string[],
    setAnswers: (answers: string[]) => void,
}

export default function QuestionGroupTablet(props: Props) {
    const onClickAnswer = (answer: string) => {
        if (props.answers.includes(answer)) {
            props.setAnswers(props.answers.filter(a => a !== answer))
        } else if (!props.question.multiple) {
            props.setAnswers([answer])
        } else {
            props.setAnswers([...props.answers, answer])
        }
    }

    return (
        <Flex align={"center"} vertical>
            <h3 className={"mt-5 mb-0"}>{props.question.name}</h3>
            {props.question.answers.map(answer => {
                let type : "default" | "primary" = "default"
                let style : "" | "disabled:bg-green-light" = ""
                if (props.answers && props.answers.includes(answer)) {
                    style = "disabled:bg-green-light"
                    type = "primary"
                }
                return (
                    <Flex className={`w-fit h-fit mt-5`}>
                        <Button className={`max-w-[700px] h-auto whitespace-normal ${style}`} type={type} onClick={() => onClickAnswer(answer)}>{answer}</Button>
                    </Flex>
                )
            })}
        </Flex>
    )
}