import {Team} from "../../../../shared/models/Team/Team";
import {Player} from "../../../../shared/models/Player/Player";
import {Button, Flex, notification, Tooltip} from "antd";
import {
    CaretLeftOutlined,
    CaretRightOutlined,
    FieldTimeOutlined,
    InfoCircleOutlined,
    LoginOutlined
} from "@ant-design/icons";
import React from "react";
import TeamInfoPopup from "./TeamInfoPopup";
import {supabase} from "../../../../configs/supabase";
import Notification from "../../../../shared/services/notification/Notification";
import {UserData} from "../../../../shared/models/UserData/UserData";

interface TeamActionsProps {
    team: Team | undefined;
    players: Player[] | undefined;
    user: UserData| undefined;
    onlyInfo?: boolean;
}

export default function TeamActions({team, players, user, onlyInfo = false} : TeamActionsProps) {
    const [openInfoPopup, setOpenInfoPopup] = React.useState<boolean>(false);

    const nextStep = async () => {
        const newStep = (team?.step || 0) + 1;
        const response = await supabase
            .rpc("update_team", {
                team_id: team?.id,
                new_step_number: newStep,
            });

        if (response.error) {
            Notification.error("Erreur lors de la modification de l'équipe.", response.error);
            return;
        }

        notification.success({message: `L'équipe est maintenant à l'étape ${newStep}`});
    }

    const previousStep = async () => {
        const newStep = (team?.step || 0) - 1;
        const response = await supabase
            .rpc("update_team", {
                team_id: team?.id,
                new_step_number: newStep,
            });

        if (response.error) {
            Notification.error("Erreur lors de la modification de l'équipe.", response.error);
            return;
        }

        notification.success({message: `L'équipe est maintenant à l'étape ${newStep}`});
    }

    const bonusMalus = async (bonus: number) => {
        const response = await supabase
            .rpc("update_bonus", {
                team_id: team?.id,
                new_bonus: bonus
            });

        if (response.error) {
            Notification.error("Erreur lors de l'attribution du bonus.", response.error);
            return;
        }

        notification.success({message: `Le bonus/malus a été attribué.`});
    }

    return (
        <Flex align={"center"}  className={`h-full w-full`}>
            <TeamInfoPopup user={user} team={team} open={openInfoPopup} setOpen={setOpenInfoPopup} needGM={team?.getNeedGamemaster(players)} />
            {!onlyInfo && <Tooltip title="Rejoindre l'équipe">
                <Button href={`/session_game/${players?.filter(player => player.team === team?.id)?.at(0)?.playerId}`} target={"_blank"} className={"mr-2"} type={team?.getNeedGamemaster(players) ? "primary" : "dashed"} danger={team?.getNeedGamemaster(players)} size={"small"}><LoginOutlined /></Button>
            </Tooltip>}

            {!onlyInfo && <Tooltip title="30s de malus">
                <Button onClick={() => bonusMalus(-30)} className={"mr-2"} size={"small"} danger><FieldTimeOutlined /></Button>
            </Tooltip>}

            {!onlyInfo && <Tooltip title="30s de bonus">
                <Button onClick={() => bonusMalus(30)} className={"mr-2"} size={"small"}><FieldTimeOutlined /></Button>
            </Tooltip>}

            {!onlyInfo && <Tooltip title="Étape précendente">
                <Button onClick={previousStep} className={"mr-2"} type={"primary"} size={"small"}><CaretLeftOutlined /></Button>
            </Tooltip>}

            {!onlyInfo && <Tooltip title="Étape suivante">
                <Button onClick={nextStep} className={"mr-2"} type={"primary"}
                        size={"small"}><CaretRightOutlined/></Button>
            </Tooltip>}

            <Tooltip title="Modifier l'équipe">
                <Button onClick={() => setOpenInfoPopup(true)} size={"small"} type={team?.getNeedGamemaster(players) ? "primary" : "default"} danger={team?.getNeedGamemaster(players)}><InfoCircleOutlined /></Button>
            </Tooltip>
        </Flex>
    )
}