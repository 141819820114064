import {Card, Flex} from "antd";
import ReactCardFlip from "react-card-flip";
import {useState} from "react";


export default function FlipFlopCard({front, back}: {front: string, back: string}) {
    const [flipped, setFlipped] = useState(false);
    return (
        <Flex className={"w-full"} onMouseOver={() => setFlipped(true)} onMouseLeave={() => setFlipped(false)}>
            <ReactCardFlip isFlipped={flipped} flipDirection={"horizontal"}>
                <Card className={"w-[300px] text-center bg-[#D68ABA]"}>
                    <p>{front}</p>
                </Card>
                <Card className={"w-[300px]  text-center bg-beige"}>
                    <p>{back}</p>
                </Card>
            </ReactCardFlip>
        </Flex>
    )
}