import React from 'react';
import {JaaSMeeting} from "@jitsi/react-sdk";
import Session from "../../models/Session/session";
import {Team} from "../../models/Team/Team";
import AppSpinner from "../spinner/AppSpinner";
import { getCurrentLanguage } from '../../../configs/i18n';

interface JitsiVisioProps {
    className?: string;
    name: string | undefined;
    mail: string | undefined;
    session?: Session | undefined;
    team?: Team | undefined;
    size?: string;
    height?: string;
    autoJoin?: boolean;
    admin?: boolean;
    jwt?: string;
}

interface JitsiVisioState {
    roomName: string;
    key: number;
}


class JitsiVisio extends React.Component<JitsiVisioProps, JitsiVisioState> {
    state: JitsiVisioState = {
        roomName: "Waiting room",
        key: Date.now(),
    };

    componentDidMount() {
        this.updateRoomName();
    }

    componentDidUpdate(prevProps: JitsiVisioProps) {
        // Condition pour vérifier si les props 'session' ou 'team' ont changé
        if (this.props.session?.name !== prevProps.session?.name || this.props.team?.name !== prevProps.team?.name) {
            this.updateRoomName();
            // Modifier la clé pour forcer la reconstruction de JaaSMeeting
            this.setState({ key: Date.now() });
        }
    }

    updateRoomName() {
        const { session, team } = this.props;
        if (session) {
            this.setState({ roomName: session.name });
        } else if (team) {
            this.setState({ roomName: team.name });
        } else {
            this.setState({ roomName: "Waiting room" });
        }
    }

    render() {
        const { className, mail, name, size = "330px", height = '100%' } = this.props;
        const { roomName, key } = this.state;
        const roomUrl = "vpaas-magic-cookie-3269c32aa8084d4bab028fb104fe98a1";
        const lang = getCurrentLanguage();
        let toolbarButtons : string[] = [
            'camera',
            'microphone',
            'chat',
            'desktop',
            'settings',
        ]


        if (this.props.admin) {
            toolbarButtons = [
                'camera',
                'microphone',
                'fullscreen',
                'chat',
                'tileview',
                'desktop',
                'settings',
                'participants-pane'
            ]
        }

        const configOverwrite = {
            startWithAudioMuted: true,
            startWithVideoMuted: true,
            prejoinConfig: {
                enabled: !this.props?.autoJoin || false,
            },
            screenshotCapture: {
                enabled: true,
                mode: 'always',
            },
            disabledSounds: ["PARTICIPANT_JOINED_SOUND", "PARTICIPANT_LEFT_SOUND", "INCOMING_MSG_SOUND"],
            toolbarButtons: toolbarButtons,
        };


        return (
            <div id={"thewokies-viso"} className={className}>
                <JaaSMeeting
                    key={key} // Utilisation de la clé pour forcer la reconstruction
                    appId={roomUrl}
                    roomName={roomName}
                    userInfo={{ displayName: name || "", email: mail || "" }}
                    lang={lang}
                    jwt={this.props.jwt}
                    configOverwrite={configOverwrite}
                    getIFrameRef={iframeRef => {
                        iframeRef.style.height = height;
                        iframeRef.style.width = size;
                        iframeRef.style.margin = "0";
                        iframeRef.style.overflow = "hidden";
                    }}
                    spinner={AppSpinner}
                />
            </div>
        );
    }
}

export default JitsiVisio;
