import {Modal} from "antd";
import HintItStep2 from "../hint/hint.it.step2";
import EmptyModal from "./EmptyModal";

interface HintModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    playerNumber: number;
}

export default function HintModal({open, setOpen, playerNumber}: HintModalProps) {

    if (playerNumber > 4) return <EmptyModal open={open} setOpen={setOpen}/>

    return (
        <Modal open={open} footer={null} onCancel={() => setOpen(false)}>
            <HintItStep2 number={playerNumber}/>
        </Modal>
    )
}