import InvisibleButton from "../../../../shared/components/button/InvisibleButton";
import {useState} from "react";
import StepProps from "../../../types/StepProps";
import HintModalStep5 from "./HintModal.step5";
import PasswordModalStep5 from "./PasswordModal.step5";

interface Props {
    showHint: boolean;
    playerNumber: number;
    mainHintPos: {x: number, y: number, h: number, w: number};
    passwordPos: {x: number, y: number, h: number, w: number};
    stepContext: StepProps;
}

export default function ButtonsStep5({playerNumber, showHint, mainHintPos, stepContext, passwordPos}: Props) {
    const [hintModal, setHintModal] = useState<boolean>(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);



    return (
        <>
            <HintModalStep5 open={hintModal} setOpen={setHintModal} number={playerNumber}/>
            <PasswordModalStep5 stepContext={stepContext} open={openPasswordModal} setOpen={setOpenPasswordModal} />


            {/* Main Hint Button */}
            <InvisibleButton onClick={() => setHintModal(true)} top={mainHintPos.y} left={mainHintPos.x} h={mainHintPos.h} w={mainHintPos.w} showHint={showHint}/>
            {/* Password Modal */}
            <InvisibleButton onClick={() => setOpenPasswordModal(true)} top={passwordPos.y} left={passwordPos.x} h={passwordPos.h} w={passwordPos.w} showHint={showHint}/>
        </>
    )
}