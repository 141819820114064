import {Modal} from "antd";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import ItStep2PasswordContent from "./it.step2.password.content";
import { useEffect } from "react";

interface PasswordModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function PasswordModal({open, setOpen}: PasswordModalProps) {
    const context = useGameContext();

    useEffect(() => {
        if (context.solution?.solutionOn) {
            setOpen(true);
        }
    }, [context.solution?.solutionOn]);

    return (
        <Modal footer={null} width={"fit-content"} open={open} destroyOnClose={false} onCancel={() => !context.solution?.solutionOn && setOpen(false)} closable={!context.solution?.solutionOn}>
            <ItStep2PasswordContent/>
        </Modal>
    )
}