import {Modal} from "antd";
import ItHint1Step6 from "../hint/ItHint1Step6";
import React from "react";

export interface BaseHintProps {
    number: number
    open: boolean
    setOpen: (open: boolean) => void
}

export default function HintModalStep6({number, open, setOpen}: BaseHintProps) {
    const modalStyles = {
        content: {
            backgroundColor: "#243763"
        }
    }

    return (
        <Modal styles={modalStyles} open={open} footer={false} onCancel={() => setOpen(false)}>
            <ItHint1Step6 number={number}/>
        </Modal>
    )
}