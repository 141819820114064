import {
    CluesInput,
    CrosswordGrid,
    CrosswordProvider,
    CrosswordProviderImperative
} from "@jaredreisinger/react-crossword";
import React, {useRef, useState} from "react";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import {Button, Flex, notification} from "antd";
import t from "../../../configs/i18n";
import PopupGoodAnswer from "../../../shared/components/popup/PopupGoodAnswer";

export default function ItTabletStep8() {
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);
    const context = useGameContext();
    const ref = useRef<CrosswordProviderImperative>(null);
    const data : CluesInput = context.step?.description.crossword;

    const validate = () => {
        const correct = ref.current?.isCrosswordCorrect() || false;

        if (!correct) {
            notification.error({message: t.sessionGame.crosswordIncorrect});
            return;
        }

        setOpenSuccess(true);
    }


    return (
        <Flex gap={"20px"} className={"w-full h-full mt-8"} align={"center"} justify={"center"} vertical>
            <PopupGoodAnswer open={openSuccess} setOpen={setOpenSuccess} team={context.team} player={context.player} playersTeam={context.playersTeam} currentUser={context.user}/>
            <Flex className={"w-[480px] ml-[-80px]"} align={"center"} justify={"center"}>
                <CrosswordProvider theme={{
                    allowNonSquare: true,
                    gridBackground: "transparent",
                    columnBreakpoint: '9999px',
                    numberColor: '#14665F',
                }} storageKey={`${context.session?.id}`} ref={ref} data={data}>
                    <CrosswordGrid/>
                </CrosswordProvider>
            </Flex>
            <Button className={"mt-4"} onClick={validate} type={"primary"} size={"large"}>{t.common.validate}</Button>
        </Flex>
    )
}