import {BaseHintProps} from "../../../types/BaseHintProps";
import {Flex} from "antd";
import ImageR from "../../../../shared/components/image/ImageR";
import {useGameContext} from "../../../../pages/session_game/context/context.game";

export default function ItHint1Step6({number}: BaseHintProps) {
    const context = useGameContext();
    let ext = "png";
    if (number === 5) {
        ext = "webp";
    }
    const image = `${context.step?.description.hint}${number}.${ext}`;

    return (
        <Flex align={"center"} justify={"center"} vertical>
            <ImageR src={image} width={"500px"} height={"300px"} className={"object-scale-down mt-5 mb-5"} />
        </Flex>
    )
}