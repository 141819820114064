import {Modal} from "antd";
import HintItStep2 from "../hint/hint.it.step2";

interface HintModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    playerNumber: number;
}

export default function HintModal({open, setOpen, playerNumber}: HintModalProps) {



    return (
        <Modal open={open} footer={null} onCancel={() => setOpen(false)}>
            <HintItStep2 number={playerNumber}/>
        </Modal>
    )
}