import {Flex} from "antd";
import P1Buttons from "./components/P1Buttons";
import InvisibleButton from "../../../shared/components/button/InvisibleButton";
import P2Buttons from "./components/P2Buttons";
import P3Buttons from "./components/P3Buttons";
import {useState} from "react";
import P4Buttons from "./components/P4Buttons";
import PasswordModal from "./components/PasswordModal";
import ImageR from "../../../shared/components/image/ImageR";
import {useGameContext} from "../../../pages/session_game/context/context.game";


export default function ItStep2() {
    const context = useGameContext();
    const [open, setOpen] = useState(false);
    const backgroundUrl = `${context.step?.description.background}${context.player?.number}.webp`;

    return (
        <Flex align={"flex-start"} className={"w-full h-full relative"}>
            <ImageR width={"1000px"} height={"580px"} src={backgroundUrl} className={"object-scale-down"}/>
            {context.player?.number === 1 && <P1Buttons showHint={context.showHint}/>}
            {context.player?.number === 2 && <P2Buttons showHint={context.showHint}/>}
            {context.player?.number === 3 && <P3Buttons showHint={context.showHint}/>}
            {context.player?.number === 4 && <P4Buttons showHint={context.showHint}/>}
            <PasswordModal open={open} setOpen={setOpen}/>
            <InvisibleButton onClick={() => setOpen(true)} showHint={context.showHint} top={299} left={471} h={55} w={55} />
        </Flex>
    )
}