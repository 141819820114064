import {Button, Card, Flex, Modal} from "antd";
import ImageR from "../../../shared/components/image/ImageR";
import TotalTeamTimer from "../../../shared/components/time/TotalTeamTimer";
import {useEffect, useState} from "react";
import {supabase} from "../../../configs/supabase";
import AppSpinner from "../../../shared/components/spinner/AppSpinner";
import TimeVisualizer from "../../../shared/components/time/TimeVisualizer";
import Notification from "../../../shared/services/notification/Notification";
import t from "../../../configs/i18n";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import ImpactCO2 from "../../../shared/components/utils/ImpactCO2";
import Const from "../../../shared/services/const/Const";


export default function ItStep12() {
    const context = useGameContext();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const backgroundUrl = "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step11/background.webp"
    const [state, setState] = useState(0)

    const fetchData = async () => {
        const response = await supabase.rpc("end_game", {
            team_id: context.team?.id
        })

        if (response.error) {
            Notification.error(t.error.common, response.error);
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <AppSpinner/>
    }

    return (
        <Flex align={"flex-center"} justify={"center"} className={"relative"}>
            <Modal zIndex={Const.NOTIFICATION_POPUP} width={800} open={open} onCancel={() => setOpen(false)} footer={false}>
                <ImpactCO2/>
            </Modal>
            {state === 0 &&
                <Card
                    className={"text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-1/2"}>
                    <h2>{`${t.common.congratulations} ${context.team?.name}`}</h2>
                    <p>{context.step?.description.endMessage1}</p>
                    <p>{`${context.team?.name} ${t.sessionGame.youCompleted}`}</p>
                    <TotalTeamTimer team={context.team} session={context.session}/>
                    <Button onClick={() => setState(1)} className={"mt-4"} size={"large"} type={"primary"}>{t.common.validate}</Button>
                </Card>
            }
            {state === 1 &&
                <Card className={"text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-1/2"}>
                    <h2>{t.sessionGame.waitingForTeam}</h2>
                    <p>{context.step?.description.endMessage2 || t.sessionGame.debrief}</p>
                    <TimeVisualizer showSeconds={false} timestamp={new Date(context?.session?.endDate || 0).getTime()}/>
                    {context.step?.description.additionalButton && <Button onClick={() => setOpen(true)} className={"mt-4"} type={"primary"}>{context.step?.description.additionalButton}</Button>}
                </Card>
            }
            <ImageR src={backgroundUrl} width={"1000px"} height={"600px"} className={"object-scale-down"}/>
        </Flex>
    )

}