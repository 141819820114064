import {Team} from "../../../shared/models/Team/Team";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Flex} from "antd";
import Question from "../../../scenarios/shared/step_quizz/type/Question";
import Session from "../../../shared/models/Session/session";
import scenarios from "../../../scenarios/scenarios";
import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";
import t from "../../../configs/i18n";
import SessionQuizzRanking from "./SessionQuizzRanking";

interface Props {
    teams: Team[] | undefined;
    session: Session | undefined;
}

export default function SessionQuizz(props: Props) {
    const [showRanking, setShowRanking] = useState<boolean>(false);

    const currentStep = useMemo(() => props.teams?.at(0)?.step || 1, [props.teams]);
    const currentQuestion= useMemo(() => props.teams?.at(0)?.subStep || 0, [props.teams]);
    const scenario = useMemo(() => scenarios[props.session?.scenario || ""], [props.session]);
    const questions : Question[] = useMemo(() => scenario.steps[(currentStep) - 1].description.questions, [props.teams]);
    const [waitingForteams, setWaitingForTeams] = useState<string[]>([]);

    useEffect(() => {
        const subscribe = supabase
            .channel("finishedQuizz")
            .on("broadcast", { event: "*" }, payload => {
                console.log("BROADCAST", payload)
                setWaitingForTeams(waitingForteams => waitingForteams.filter(team => team !== payload.payload.team));
            })
            .subscribe();
        props.teams && setWaitingForTeams(props.teams.map(team => team.name));

        return () => {
            subscribe.unsubscribe();
        }
    }, []);

    const nextQuestion = () => {
        props.teams?.forEach(async team => {
            const response = await supabase
                .from("team")
                .update({
                    sub_step: (team.subStep || 0) + 1
                })
                .eq("id", team.id);

            if (response.error) {
                Notification.error(t.error.team, response.error.message);
            }
        })

        props.teams && setWaitingForTeams(props.teams.map(team => team.name));
        setShowRanking(false);
    }

    const onClickRanking = () => {
        supabase
            .channel("quizz")
            .send({
                type: "broadcast",
                event: "showRanking",
                payload: {status: "ok"},
            })

        setShowRanking(true);
    }
    return (
        <Flex className={"w-full min-h-[1000px] pl-16 pr-16"} align={"center"} justify={"center"} vertical>
            {!showRanking && currentQuestion < scenario.steps[currentStep - 1].description.questions.length && <>
                <h1 className={"text-green-dark"}>Question {currentQuestion + 1}</h1>
                <h2>{questions?.at(currentQuestion || 0)?.name}</h2>
                <Button onClick={onClickRanking}>Montrer les résultats</Button>
                {waitingForteams.length !== 0 && <h3>En attente de : {waitingForteams.join(", ")}</h3>}
            </>}
            {!showRanking && currentQuestion >= scenario.steps[currentStep - 1].description.questions.length && <>
                <h1 className={"text-green-dark"}>Fin du quizz</h1>
            </>}
            {showRanking && <>
                <h1 className={"text-green-dark"}>Question {currentQuestion + 1}</h1>
                <h2 className={"text-green-light"}>La réponse était</h2>
                <h3 className={"mb-8"}>{questions[currentQuestion].description}</h3>
                <SessionQuizzRanking teams={props.teams}/>
                <Button className={"mt-8"} onClick={nextQuestion}>Question suivante</Button>
            </>}
        </Flex>
    )
}