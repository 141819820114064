import {createContext, useContext} from "react";
import Session from "../../../../../../shared/models/Session/session";
import {Team} from "../../../../../../shared/models/Team/Team";
import {Player} from "../../../../../../shared/models/Player/Player";

interface SessionResultContextType {
    session: Session | undefined;
    loading: boolean;
    teamRank: Team[];
    players: Player[];
}

export const SessionResultContext = createContext<SessionResultContextType>({
    session: undefined,
    loading: true,
    teamRank: [],
    players: []
});

export const useSessionResultContext = () => useContext(SessionResultContext);