import {Button, TableProps} from "antd";
import {Company} from "../../../../../shared/models/Company/company";
import React from "react";
import {useHomeContext} from "../../../context/context.home";
import t from "../../../../../configs/i18n";

export default function useManageCompanies() {
    const context = useHomeContext();

    const columns: TableProps<Company>['columns'] = [
        {
            title: '',
            dataIndex: 'logo',
            key: 'logo',
            render: (value : string) => <img className="w-12 h-12" src={value} alt=""/>,
        },
        {
            title: t.common.name,
            dataIndex: 'name',
            key: 'name',
            render: (text) => text,
        },
        {
            title: '',
            key: 'edit',
            render: () => <Button>{t.common.update}</Button>,
        },
    ];

    return {
        context,
        columns
    }
}