import {Flex} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import t from "../../../configs/i18n";
import Question from "../step_quizz/type/Question";
import {useTabletQuizzContext} from "./context/context.tablet.quizz";
import SessionQuizzRanking from "../../../pages/session_gm/components/SessionQuizzRanking";

interface QuestionDescriptionProps {
    question: Question;
    validated: boolean | undefined;
    answers: string[];
}

export default function QuestionDescriptionTablet({validated, question, answers}: QuestionDescriptionProps) {
    const context = useTabletQuizzContext();

    let bgColor = "bg-black"
    if (validated === true) {
        bgColor = "bg-green-500"
    } else if (validated === false) {
        bgColor = "bg-red-500"
    }

    return (
        <Flex align={"center"} className={"w-3/4 text-center"} vertical>
            {validated === true && <>
                <CheckOutlined className={"text-6xl text-green-light"}/>
                <p className={"text-green-dark font-bold"}>{t.common.goodAnswer}</p>
            </>}
            {validated === false && <>
                <CloseOutlined className={"text-6xl text-red-500"}/>
                <p className={"text-red-500 font-bold"}>{t.common.wrongAnswer}</p>
            </>}
            <p className={"font-bold"}>{t.sessionGame.youAnswered} : {answers.length > 0 ? answers.join(", ") : t.common.noAnswer}</p>
            <p>{question.name}</p>
            <Flex className={`p-4 ${bgColor} bg-opacity-10`}>
                <p className={"font-bold"}>{question.description}</p>
            </Flex>

            <h3 className={"text-green-dark"}>Classement</h3>
            <SessionQuizzRanking teams={context.teams} />

        </Flex>
    )
}