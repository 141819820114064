import StepProps from "../../types/StepProps";
import StepQuizz from "../../shared/step_quizz/StepQuizz";
import EndScreen from "./EndScreen";
import {useGameContext} from "../../../pages/session_game/context/context.game";

export default function ItStep11() {
    const context = useGameContext();

    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }

    return (
        <StepQuizz preComponent={<EndScreen stepContext={stepContext}/>}/>
    )
}