import {SurveyType} from "../../../shared/models/Survey/SurveyType";
import {Button, Flex, Input, Rate} from "antd";
import React, {ChangeEvent} from "react";
import t from "../../../configs/i18n";

const { TextArea } = Input;


interface SurveyComponentProps {
    survey: {[question: string]: {type: SurveyType, answer: string | number}}
    setSurvey: React.Dispatch<React.SetStateAction<{[p: string]: {type: SurveyType, answer: string | number}}>>
    question: string | undefined
    type: SurveyType
    answers?: string[]
    className?: string
    optional?: boolean
}

export default function SurveyQuestion({survey, setSurvey, question, type, answers = [], className, optional = false}: SurveyComponentProps) {
    const onRatingChange = (value: number) => {
        setSurvey(prevSurvey => {
            const newSurvey = {...prevSurvey};
            newSurvey[question || ""] = {
                type: type,
                answer: value
            }
            return newSurvey;
        })
    }

    const onOptionChange = (value: string) => {
        setSurvey(prevSurvey => {
            const newSurvey = {...prevSurvey};
            newSurvey[question || ""] = {
                type: type,
                answer: value
            }
            return newSurvey;
        })
    }

    const onInputChange = (value: ChangeEvent<HTMLTextAreaElement>) => {
        setSurvey(prevSurvey => {
            const newSurvey = {...prevSurvey};
            newSurvey[question || ""] = {
                type: type,
                answer: value.currentTarget.value
            }
            return newSurvey;
        })
    }

    return (
        <Flex vertical>
            <h4 className={"text-center"}>{`${question} ${optional ? `(${t.common.optional})` : ""}`}</h4>
            {(type === SurveyType.RATING_GLOBAL || type === SurveyType.RATING_GAME) &&
                <Flex justify={"center"}>
                    <Rate allowHalf onChange={onRatingChange} />
                </Flex>
            }
            {(type === SurveyType.OPTION || type === SurveyType.RECOMMENDATION) &&
                <Flex justify={"center"}>
                    {answers?.map(answer => {
                        return <Button className={"mr-4"} onClick={() => onOptionChange(answer)} type={survey[question || ""]?.answer === answer ? "primary" : "default"}>{answer}</Button>
                    })}
                </Flex>}
            {type === SurveyType.INPUT &&
                <Flex>
                    <TextArea rows={4} onChange={onInputChange} />
                </Flex>
            }
        </Flex>
    )
}