import {Flex, Modal} from "antd";
import {WifiOutlined} from "@ant-design/icons";
import AppSpinner from "../spinner/AppSpinner";
import {useEffect, useState} from "react";
import SyncService from "../../services/sync/SyncService";
import Const from "../../services/const/Const";
import t from "../../../configs/i18n";

export default function SyncPopup() {
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        SyncService.setOnDesync(() => setOpen(true));
        SyncService.setOnSync(() => setOpen(false));
        window.addEventListener("offline", () => SyncService.desync());
        window.addEventListener("online", () => SyncService.sync());
    }, [setOpen]);

    return (
        <>
            {/* Out of sync modal */}
            <Modal destroyOnClose={true} zIndex={Const.SYNC_Z_INDEX} open={open} footer={null} closeIcon={false}>
                <Flex align={"center"} justify={"center"} vertical>
                    <WifiOutlined className={"mt-6 mb-4 text-5xl text-red-500"}/>
                    <h3 className={"mb-8"}>{t.syncPopup.title}</h3>
                    <AppSpinner size={"medium"} color={"rgb(239 68 68)"} />
                    <p className={"mt-8"}>{t.syncPopup.message}</p>
                </Flex>
            </Modal>
        </>
    )
}