export class Company {
    name: string;
    logo: string;
    id: string;

    constructor(obj: any) {
        this.name = obj.name;
        this.logo = obj.logo;
        this.id = obj.id;
    }

    public static newCompany(obj: any) : Company | undefined {
        if (!obj) {
            return undefined;
        }
        return new Company(obj);
    }

    public static newCompanies(obj: any[]) : Company[] {
        return obj.map((data) => new Company(data));
    }
}
