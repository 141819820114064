
export default abstract class LocaleText {
    abstract login: {
        title: string;
        cardTitle: string;
        success: string;
        imaplayer: string;
    };

    abstract home: {
        dashboard: string;
        session: {
            create: string
            result: string;
            showResult: string;
            seePhoto: string;
            exportSurvey: string;
            exportPlayerStatus: string;
            deleteAlert: string;
            passed: string;
            future: string;
            inProgress: string;
            stats: string;
            podium: string;
            createSuccess: string;
            noPhoto: string;
        },
        company: {
            create: string;
            name: string;
            logo: string;
        }
    };

    abstract common: {
        answer: string;
        congratulations: string;
        connect: string;
        mail: string;
        password: string;
        company: string;
        companies: string;
        name: string;
        firstName: string;
        lastName: string;
        date: string;
        update: string;
        yourTeam: string;
        noTeam: string;
        team: string;
        step: string;
        player: string;
        goBack: string;
        validate: string;
        cancel: string;
        warning: string;
        finished: string;
        continue: string;
        only: string
        secondes: string;
        yourAnswer: string;
        optional: string;
        yes: string;
        no: string;
        wrongAnswer: string
        goodAnswer: string
        noAnswer: string;
        allFieldsFilled: string;
        noted: string;
    };

    abstract error: {
        common: string;
        wrongMail: string;
        wrongPassword: string;
        wait60: string;
        disconnect: string;
        session: string;
        company: string;
        score: string;
        players: string;
        player: string;
        team: string;
        teams: string;
        notConnected: string;
        chrono: string;
        subscription: string;
        survey: string;
        input: string;
        dataMap: string;
        linkExpired: string;
        gamemaster: string;
        user: string;
        validateStep: string;
        noSlash: string;
        emptySolution: string;
        onlyLettersAndNumbers: string;
    };

    abstract test: {
        api: string;
        websocket: string;
        jass: string;
        jitsi: string;
        supabase: string;
        success: string;
        error: string;
    };

    abstract sessionLogin: {
        alreadyStarted: string;
        cannotJoin: string;
        enterMail: string;
        enterTeam: string;
        joinSession: string;
        closedState: string;
        comeBack: string;
        toConnect: string;
        problemDetected: string;
        accessLimited: string;
        helpUrl: string;
        organisatorHasSolved: string;
    };

    abstract sessionJoin: {
        playerInfo: string;
        teamInfo: string;
        imPresent: string;
        team: string;
    };

    abstract sessionGame: {
        noStep: string;
        noTeam: string;
        suggestCalling: string;
        canClosePopup: string;
        wrongAnswer: string;
        crosswordIncorrect: string;
        wrongAnswers: string;
        goToQuizz: string;
        confirmationGoToQuizzL1: string;
        confirmationGoToQuizzL2: string;
        youCompleted: string;
        waitingForTeam: string;
        debrief: string;
        thisQuestionNotExists: string;
        canAnswer: string,
        winBonus: string;
        goodAnswerQuiz: string;
        wrongAnswerQuiz: string;
        youAnswered: string;
        visio: {
            accessVisio: string;
            noVisioLink: string;
            mainVisio: string;
            teamVisio: string;
        }
        gamemasterPopup: {
            title: string;
            calledBy: string;
            messageChrono: string;
        };
        sosPopup: {
            title: string;
            message: string;
            buttonHint: string;
            buttonGamemaster: string;
            buttonHelp: string;
        };
        hintPopup: {
            title: string;
            noPlayer: string;
        };
        game: {
            inputOtherPlayer: string;
            toRemember: string;
        };
        cameraPopup: {
            title: string;
            message: string;
            message2: string;
            button: string;
        };
        goodAnswerPopup: {
            title: string;
            button: string;
        };
        timerPausedPopup: {
            title: string;
            message: string;
            button: string;
        };
        waitingForPlayers: {
            error: string;
            title: string;
        };
        hints: {
            hints: string;
            yourhints: string;
            penalty: string;
            hint: string;
            click: string;
            unlock: string;
            cantUnlock: string;
            order: string;
            answer: string;
            answerCondition: string;
            confirmationQuestion: string;
            confirmationSolution: string;
            confirmationInfo: string;
            confirmationInfo1: string;
            confirmationInfo2: string;
            solutionComplete: string;
        },
        actionType: {
            user: string;
        }
    };


    abstract spinner: {
        message: string;
        tryAgain: string;
    };

    abstract syncPopup: {
        title: string;
        message: string;
    };

    abstract sessionEnd: {
        seeRanking: string;
        sessionEnded: string;
        survey: {
            success: string;
            error: string;
            emptyFields: string;
            survey: string;
            thanks: string;
            globalRating: string;
            tryAgain: string;
            game: string;
            recommendation: string;
            ratingGame: string;
            input: string;
            email: string;
        }
    };

    abstract fileNames: {
        survey: string;
        attendance: string;
        template: string;
    }

    abstract bdd: {
        teamName: string;
    }

}