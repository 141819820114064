import {Player} from "../../../../shared/models/Player/Player";
import {Team} from "../../../../shared/models/Team/Team";
import {createContext, useContext} from "react";
import Question from "../../step_quizz/type/Question";

interface Type {
    teams: Team[]
    players: Player[]
    loading: boolean;
    questions: Question[];
}

export const TabletQuizzContext = createContext<Type>({
    teams: [],
    players: [],
    loading: false,
    questions: []
});

export const useTabletQuizzContext = () => useContext(TabletQuizzContext);