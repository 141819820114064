import {Button, Flex} from "antd";
import {useMemo, useState} from "react";
import {QuestionCircleOutlined} from "@ant-design/icons";
import Session from "../../../../shared/models/Session/session";
import HintPopup from "./HintPopup";
import t from "../../../../configs/i18n";

interface HintButtonProps {
    session: Session | undefined;
}

export default function HintButton({session}: HintButtonProps) {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Flex>
            <HintPopup open={open} setOpen={setOpen}/>
            <Button onClick={() => setOpen(true)} size={"small"} type={"primary"}><QuestionCircleOutlined/> {t.sessionGame.hints.hints}</Button>
        </Flex>

    )
}