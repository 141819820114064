import {Modal} from "antd";
import React from "react";
import EcoHintStep9 from "../hint/EcoHintStep9";

export interface BaseHintProps {
    number: number
    open: boolean
    setOpen: (open: boolean) => void
}


const modalStyles = {
    content: {
        backgroundColor: "#698988"
    }
};

export default function HintModalStep9({number, open, setOpen}: BaseHintProps) {
    return (
        <Modal styles={modalStyles} open={open} footer={false} onCancel={() => setOpen(false)}>
            <EcoHintStep9 number={number}/>
        </Modal>
    )
}