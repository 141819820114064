import TeamTable from "../../../../../session_gm/components/TeamTable/TeamTable";
import useSessionInfo from "../callbacks/useSessionInfo";
import {useMemo} from "react";
import scenarios from "../../../../../../scenarios/scenarios";
import {Flex} from "antd";
import PlayerBox from "../../../../../../shared/components/player/PlayerBox";
import Normalize from "../../../../../../shared/services/formatter/normalize";

export function TableInfoSessionHome() {
    const sessionInfo = useSessionInfo();
    const scenario = useMemo(() => scenarios[sessionInfo.context.session?.scenario as string], [sessionInfo.context.session?.scenario])


    return (
        <>
            {!scenario.tablet && <TeamTable
                teams={sessionInfo.context.teams}
                session={sessionInfo.context.sessionRealtime}
                players={sessionInfo.context.players}
                onlinePlayers={new Set<string>()}
                user={sessionInfo.context.currentUser}/>}

            {scenario.tablet && <Flex className={"w-full"}>
                {sessionInfo.context.sessionRealtime?.additionnals?.players?.map(player =>
                    <PlayerBox name={Normalize.firstName(player.firstName) + " " + Normalize.lastName(player.lastName)} popup={false}/>
                )}
            </Flex>}
        </>
    )
}