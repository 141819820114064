interface InterruptorProps {
    onClick: () => void;
    showHint: boolean;
}

export default function Interruptor({onClick, showHint}: InterruptorProps) {


    return (
        <div onClick={onClick} style={{
            border: showHint ? "2px solid lightgreen" : "none"
        }} className={"absolute top-[70px] left-[30px] h-[15px] w-[15px] rounded-full bg-none cursor-pointer hover:bg-green-300 z-50"}>
        </div>
    )
}