import {Modal} from "antd";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import ItStep2PasswordContent from "./it.step2.password.content";

interface PasswordModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function PasswordModal({open, setOpen}: PasswordModalProps) {
    return (
        <Modal footer={null} width={"fit-content"} open={open} destroyOnClose={false} onCancel={() => setOpen(false)}>
            <ItStep2PasswordContent/>
        </Modal>
    )
}