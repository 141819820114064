import Session from "../Session/session";
import {Team} from "../Team/Team";
import {supabase} from "../../../configs/supabase";
import {UserData} from "../UserData/UserData";
import {ErrorCode} from "../../services/const/ErrorCode";
import React from "react";
import {RealtimeChannel} from "@supabase/supabase-js";
import Notification from "../../services/notification/Notification";
import t from "../../../configs/i18n";
import Normalize from "../../services/formatter/normalize";

export class Player {
    playerId: string | undefined;
    session: Session | string | undefined;
    team: Team | string | undefined;
    old_team: string;
    userdata: UserData | string;
    number: number | undefined = undefined;
    stepValidated: boolean;
    need_gamemaster: boolean;
    additionals: {
        players: string[];
    };

    constructor(obj: any) {
        this.playerId = obj.id;
        this.team = typeof obj.team === "object" ? Team.newTeam(obj.team) : obj.team;
        this.old_team = obj.old_team;
        this.number = obj.number;
        this.stepValidated = obj.step_validated;
        this.need_gamemaster = obj.need_gamemaster;
        this.additionals = obj.additionals;
    }

    public getTeamId(): string | undefined {
        return this.team instanceof Team ? this.team.id : this.team as string;
    }

    public getId() : string {
        return this.userdata instanceof UserData ? this.userdata.uid : this.userdata as string;
    }

    public getName() : string {
        return this.userdata instanceof UserData ? this.userdata.name : "";
    }

    public getEmail() : string {
        return (this.userdata as UserData)?.mail || "";
    }

    public getTeam() : Team | undefined {
        return this.team as Team;
    }

    public getSession() : Session | undefined {
        return this.session as Session;
    }

    public static async newPlayer(obj: any) : Promise<Player | undefined> {
        if (!obj) {
            return undefined;
        }

        const player = new Player(obj);
        player.userdata = typeof obj.userdata === "object" ? await UserData.newUserData(obj.userdata) : obj.userdata;
        player.session = typeof obj.session === "object" ? await Session.newSession(obj.session) : obj.session;
        return player;
    }

    public async fetchUserData() : Promise<void> {
        if (this.userdata instanceof UserData) {
            return;
        }

        this.userdata = await UserData.getUser(this.userdata as string) || this.userdata;
    }

    public static async newPlayers(obj: any[]) : Promise<Player[]> {
        const players = [];
        for (const player of obj) {
            players.push(await Player.newPlayer(player))
        }
        return players.filter((player) => player !== undefined) as Player[];
    }

    public static getTeamId(player: Player | undefined) : string | undefined {
        const team = (player?.team as Team);
        if (team?.id) {
            return team.id
        }

        return player?.team as string;
    }

    public static async bySession(session: Session | undefined, columns: string = "*, userdata:userdata_with_email!userdata(*)") : Promise<Player[]> {
        if (!session || !session.id) {
            Notification.error(t.error.session, ErrorCode.EMPTY_VALUE);
            return [];
        }

        const playerQuery = await supabase
            .from("player")
            .select(columns)
            .eq("session", session.id);

        if (playerQuery.error) {
            Notification.error(t.error.session, playerQuery.error);
            return [];
        }

        return Player.newPlayers(playerQuery.data);
    }

    public static listenBySession(session: Session | undefined, setPlayers:  (value: React.SetStateAction<Player[]>) => void) {
        if (!session || !session.id) {
            Notification.error(t.error.session, ErrorCode.EMPTY_VALUE);
            return;
        }
        
        supabase.channel(`player-session-name-${session.id}`)
            .on("postgres_changes",
            {
                schema: 'public',
                table: "userdata",
                event: 'UPDATE',
            }, (payload) => {
                setPlayers((currentPlayers) => {
                    return currentPlayers.map(player => {
                        if (player.getId() === payload.new.uid) {(async () => {
                            player.userdata = await UserData.newUserData(payload.new)?? player.userdata;
                        })();}
                        return player;
                    });
                });
            }).subscribe();
        
        supabase.channel(`player-session-team-${session.id}`)
            .on("postgres_changes",
            {
                schema: 'public',
                table: "team",
                event: 'UPDATE',
            }, (payload) => {
                (async () => {
                    const updatedPlayer = await Player.bySession(session)
                    if (!updatedPlayer) {
                        return
                    }
                    setPlayers(updatedPlayer);
                })();
            }).subscribe();

        return supabase.channel(`player-session-${session.id}`)
            .on("postgres_changes",
            {
                schema: 'public',
                table: "player",
                filter: `session=eq.${session.id}`,
                event: '*',
            }, (payload) => {
                if (payload.eventType === "INSERT") {(
                    async () => {
                        const newPlayer = await Player.newPlayer(payload.new)
                        if (!newPlayer) {
                            return
                        }
                        await newPlayer.fetchUserData();
                        setPlayers(currentPlayers => [...currentPlayers, newPlayer]);
                    })();
                }
                else if (payload.eventType === "UPDATE") {
                    (async () => {
                        const updatedPlayer = await Player.newPlayer(payload.new)
                        if (!updatedPlayer) {
                            return
                        }
                        await updatedPlayer.fetchUserData();
                        setPlayers(currentPlayers => currentPlayers.map(player => player.playerId === updatedPlayer.playerId ? updatedPlayer : player))
                    })();
                }
                else if (payload.eventType === "DELETE") {
                    (async () => {
                        const deletedPlayer = await Player.newPlayer(payload.old)
                        if (!deletedPlayer) {
                            return
                        }
                        setPlayers(currentPlayers => currentPlayers.filter(player => player.playerId !== deletedPlayer.playerId))
                    })();
                }
            }).subscribe();
    }
    
    public static async byId(id: string | undefined, columns: string, showError: boolean = true) : Promise<Player | undefined> {
        if (!id) {
            showError && Notification.error(t.error.player, ErrorCode.EMPTY_VALUE);
            return undefined;
        }

        const playerQuery = await supabase
            .from("player")
            .select(columns)
            .eq("id", id);

        if (playerQuery.error) {
            showError && Notification.error(t.error.player, playerQuery.error);
            return undefined;
        }

        return await Player.newPlayer(playerQuery.data?.at(0));
    }

    public static listenById(id: string | undefined, callback: (player: Player | undefined) => void) : void {
        if (!id) {
            Notification.error(t.error.player, ErrorCode.EMPTY_VALUE);
            return;
        }
        
        supabase
            .channel(`player-${id}`)
            .on("postgres_changes",
                {
                    event: 'UPDATE',
                    schema: 'public',
                    table: 'player',
                    filter: `id=eq.${id}`,
                }, (payload) => {
                    setTimeout(async () => {
                        callback(await Player.newPlayer(payload.new))
                    }, 0)

                }).subscribe();
    }

    public static async getByTeam(teamId: string | undefined, columns: string = "*, userdata!userdata(*)") : Promise<Player[]> {
        if (!teamId) {
            return [];
        }

        const playerQuery = await supabase
            .from("player")
            .select(columns)
            .eq("team", teamId);

        if (playerQuery.error) {
            Notification.error(t.error.player, playerQuery.error);
            return [];
        }

        return Player.newPlayers(playerQuery.data);
    }

    public static listenByTeam(teamId: string | undefined, setPlayers: (value: React.SetStateAction<Player[]>) => void) : RealtimeChannel | undefined{

        if (!teamId) {
            Notification.error(t.error.players, ErrorCode.EMPTY_VALUE);
            return undefined;
        }


        return supabase.channel(`player-team-${teamId}`)
            .on("postgres_changes",
                {
                    schema: 'public',
                    table: "player",
                    filter: `team=eq.${teamId}`,
                    event: '*',
                }, (payload) => {
                    if (payload.eventType === "INSERT") {
                        (async () => {
                            const newPlayer = await Player.newPlayer(payload.new)
                            if (!newPlayer) {
                                return
                            }
                            await newPlayer.fetchUserData();
                            setPlayers(currentPlayers => [...currentPlayers, newPlayer]);
                        })();
                    }
                    else if (payload.eventType === "UPDATE") {
                        (async () => {
                            const updatedPlayer = await Player.newPlayer(payload.new)
                            if (!updatedPlayer) {
                                return
                            }
                            await updatedPlayer.fetchUserData();
                            setPlayers(currentPlayers => {
                                const playerIndex = currentPlayers.findIndex(player => player.playerId === updatedPlayer.playerId);
                                if (playerIndex !== -1) {
                                    return currentPlayers.map(player => player.playerId === updatedPlayer.playerId ? updatedPlayer : player);
                                } else {
                                    return [...currentPlayers, updatedPlayer];
                                }
                            });
                        })();
                    } else if (payload.eventType === "DELETE") {
                        (async () => {
                            const deletedPlayer = await Player.newPlayer(payload.old)
                            if (!deletedPlayer) {
                                return
                            }
                            setPlayers(currentPlayers => currentPlayers.filter(player => player.playerId !== deletedPlayer.playerId))
                        })();
                    }
                })
            .on("postgres_changes",
                {
                    schema: 'public',
                    table: "player",
                    filter: `old_team=eq.${teamId}`,
                    event: 'UPDATE',
                }, (payload) => {
                    (async () => {
                        const updatedPlayer = await Player.newPlayer(payload.new)
                        if (!updatedPlayer) {
                            return
                        }
    
                        const newTeamId = updatedPlayer.getTeamId();
                        const oldTeamId = updatedPlayer.old_team;
    
                        if (newTeamId !== oldTeamId) {
                            setPlayers(currentPlayers => currentPlayers.filter(player => player.playerId !== updatedPlayer.playerId))
                        }
                    })();
                })
            .subscribe();
    }

    public static listenTeam(player: Player | undefined, callbackTeam: (value: Team | undefined) => void, setPlayers: (value: React.SetStateAction<Player[]>) => void, callbackCurrentPlayer?: (value: Player) => void){
        if (!player) {
            Notification.error(t.error.player, ErrorCode.EMPTY_VALUE);
            return;
        }
        let currentPlayer = player;
        let teamChannel : RealtimeChannel | undefined;
        let playerChannel : RealtimeChannel | undefined;

        if (player.getTeamId()) {
            teamChannel = Team.listenById(player.getTeamId(), callbackTeam);
            playerChannel = Player.listenByTeam(player.getTeamId(), setPlayers);
        }

        Player.listenById(player.playerId, async (updatedPlayer) => {
            if (!updatedPlayer) {
                Notification.error(t.error.player, ErrorCode.EMPTY_VALUE);
                return;
            }
            await updatedPlayer.fetchUserData();

            const newTeamId = updatedPlayer.getTeamId();
            const oldTeamId = currentPlayer.getTeamId();

            if (callbackCurrentPlayer) {
                callbackCurrentPlayer(updatedPlayer);
            }

            if (newTeamId !== oldTeamId) {
                teamChannel?.unsubscribe();
                playerChannel?.unsubscribe();
                currentPlayer = updatedPlayer;

                if (!newTeamId) {
                    callbackTeam(undefined);
                    setPlayers([]);
                    return;
                }

                callbackTeam(await Team.byId(newTeamId, "*"));
                teamChannel = Team.listenById(newTeamId, callbackTeam);

                setPlayers(await Player.getByTeam(newTeamId));
                playerChannel = Player.listenByTeam(newTeamId, setPlayers);
            }
        });
    }

    public static async create(userId: string, sessionId: string, teamId?: string) : Promise<{exists: boolean, withName: boolean, id: string} | undefined> {
        const { error } = await supabase
            .from("player")
            .insert({
                session: sessionId,
                userdata: userId,
                team: teamId ?? null,
                number: teamId ? 1 : null,
            });

        if (error) {
            Notification.error(t.error.player, error);
            return;
        }

        return (await Player.doesExist(userId, sessionId))
    }

    
    public static async doesExist(id: string | undefined, session: string): Promise<{exists: boolean, withName: boolean, id: string}> {
        if (!id) {
            return {exists: false, withName: false, id: ""};
        }
        const playerExistReponse = await supabase
            .from("player")
            .select("*, userdata!userdata(firstname, lastname)")
            .eq("userdata", id)
            .eq("session", session)
            .maybeSingle()
        
        if (playerExistReponse.error) {
            Notification.error(t.error.players, playerExistReponse.error);
            return {exists: false, withName: false, id: ""};
        }

        if (!playerExistReponse.data) {
            return {exists: false, withName: false, id: ""};
        }

        return {exists: true, withName: playerExistReponse.data.userdata?.firstname && playerExistReponse.data.userdata?.lastname, id: playerExistReponse.data.id};
    }
    
    public static async listenToNames(teamId: string, setPlayers: (value: React.SetStateAction<Player[]>) => void) {
        return supabase.channel(`player-team-name-${teamId}`)
            .on("postgres_changes",
                {
                    schema: 'public',
                    table: "userdata",
                    event: 'UPDATE',
                }, (payload) => {
                    setPlayers((currentPlayers) => {
                        return currentPlayers.map(player => {
                            if (player.getId() === payload.new.uid && player.userdata instanceof UserData) {
                                player.userdata.name = Normalize.firstName(payload.new.firstname) + " " + Normalize.lastName(payload.new.lastname);
                            }
                            return player;
                        });
                    });
                }).subscribe();
    }
}
