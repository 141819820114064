import Notification from "../../services/notification/Notification";
import {ErrorCode} from "../../services/const/ErrorCode";
import {supabase} from "../../../configs/supabase";
import Session from "./session";
import t from "../../../configs/i18n";
import {Player} from "../Player/Player";
import {Team} from "../Team/Team";

export default class SessionService {
    public static async id(id: string | undefined, columns: string) : Promise<Session | undefined> {
        if (!id) {
            Notification.error(t.error.session, ErrorCode.EMPTY_VALUE);
            return undefined;
        }

        const query = await supabase
            .from("session")
            .select(columns)
            .eq("id", id);

        if (query.error) {
            Notification.error(t.error.session, query.error);
            return undefined;
        }

        return Session.newSession(query.data?.at(0));
    }

    public static async all(columns: string) : Promise<Session[]> {
        const query = await supabase
            .from("session")
            .select(columns)
            .order("start_date", {ascending: false})

        if (query.error) {
            Notification.error(t.error.session, query.error);
            return [];
        }

        return Session.newSessions(query.data);
    }

    public static async user(id: string) : Promise<Session[]> {
        const query = await supabase
            .from("player")
            .select("session(*, company(logo))")
            .eq("userdata", id);

        if (query.error) {
            Notification.error(t.error.session, query.error);
            return [];
        }

        const players = await Player.newPlayers(query.data);
        return players
            .map(p => p.getSession() as Session)
            .sort((a, b) => (b?.startDate?.getTime() || 0) - (a?.startDate?.getTime() || 0));
    }

    public static async gamemaster(id: string) : Promise<Session[]> {
        const query = await supabase
            .from("role")
            .select("session(*, company(logo))")
            .eq("userdata", id)
            .not("session", "is", null);

        if (query.error) {
            Notification.error(t.error.session, query.error);
            return [];
        }

        return Session.newSessions(query.data.map((r: any) => r.session)).sort((a, b) => (b.startDate?.getTime() || 0) - (a.startDate?.getTime() || 0));
    }

    public static async rank(sessionId: string | undefined) : Promise<Team[]> {
        const queryRanking = await supabase
            .rpc('ranking', {
                session_id: sessionId,
            });

        if (queryRanking.error) {
            Notification.error(t.error.score, queryRanking.error.message);
            return [];
        }

        return Team.newTeams(queryRanking.data);
    }

    public static async players(sessionId: string | undefined) : Promise<Player[]> {
        const queryPlayers = await supabase
            .from("player")
            .select("team, userdata!userdata(*)")
            .eq("session", sessionId);

        if (queryPlayers.error) {
            Notification.error(t.error.players, queryPlayers.error.message);
            return [];
        }

        return Player.newPlayers(queryPlayers.data);
    }

    public static async importCSVTablet(players: any, sessionId: string | undefined) : Promise<boolean> {
        const playersNames : {firstName: string, lastName: string, mail: string}[] = []
        for (const newPlayer of players) {
            if (!newPlayer["First name"] || !newPlayer["Last name"] || !newPlayer["Email"]) {
                Notification.error("Erreur lors de la lecture du fichier", ErrorCode.EMPTY_VALUE);
                return false;
            }

            playersNames.push({firstName: newPlayer["First name"], lastName: newPlayer["Last name"], mail: newPlayer["Email"]})
        }

        const response = await supabase.from("session")
            .update({
                additionnals: {
                    players: playersNames
                }
            })
            .eq("id", sessionId);

        if (response.error) {
            Notification.error("Erreur lors de la création des joueurs", response.error);
            return false;
        }

        return true;
    }
}