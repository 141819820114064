import {BaseHintProps} from "../../../types/BaseHintProps";
import {Flex} from "antd";
import ImageR from "../../../../shared/components/image/ImageR";
import {useGameContext} from "../../../../pages/session_game/context/context.game";

export default function HintItStep4({number}: BaseHintProps) {
    const context = useGameContext();
    const image = `${context.step?.description.hint}${number}.webp`;

    return (
        <Flex align={"center"} justify={"center"} className="h-full w-full" vertical>
            <ImageR src={image} width={"100%"} height={"100%"} className={"object-scale mt-5 mb-5"} />
        </Flex>
    )
}