import {ConfigProvider} from "antd";
import locale from "antd/locale/fr_FR";
import React from "react";
import Const from "../shared/services/const/Const";

interface Props {
    children?: React.ReactNode
}
export default function AntConfig(props: Props) {
    return (
        <ConfigProvider locale={locale} theme={{
            token: {
                colorPrimary: '#00A88A',
                fontFamily: 'Poppins, sans-serif',
            },
            components: {
                Typography: {
                    colorSuccess: '#00A88A',
                },
                Notification: {
                    zIndexPopup: Const.NOTIFICATION_POPUP
                }
            }
        }}>
            {props.children}
        </ConfigProvider>
    )
}