import {UserData} from "../../../shared/models/UserData/UserData";
import Session from "../../../shared/models/Session/session";
import {Company} from "../../../shared/models/Company/company";
import {createContext, useContext} from "react";

interface HomeContextType {
    user: UserData | undefined;
    sessions: Session[];
    companies : Company[];
    loading: boolean;
    fetch: () => Promise<void>;
}

export const HomeContext = createContext<HomeContextType>({
    user: undefined,
    sessions: [],
    companies: [],
    loading: true,
    fetch: async () => {}
});

export const useHomeContext = () => useContext(HomeContext);