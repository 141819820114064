import {supabase} from "../../../configs/supabase";
import Notification from "../../services/notification/Notification";
import {Company} from "../Company/company";
import {ErrorCode} from "../../services/const/ErrorCode";
import Session from "../Session/session";
import { UserData } from "../UserData/UserData";
import { Player } from "./Player";
import t, { forceLanguage } from "../../../configs/i18n";
import scenarios from "../../../scenarios/scenarios";

export async function importCsv(players: any, session: Session | undefined, callBackProgress?: () => void) : Promise<boolean> {
    forceLanguage(scenarios[session?.scenario || ""].lang);
    await supabase.from("player").delete().eq("session", session?.id);
    await supabase.from("team").delete().eq("session", session?.id);

    for (const player of players) {
        if (!player["Email"] || !player["First name"] || !player["Last name"] || session === undefined) {
            Notification.error("Erreur lors de la lecture du fichier", ErrorCode.EMPTY_VALUE);
            console.error("Error while reading the file", player["Email"], player["First name"], player["Last name"], session);
            return false;
        }

        callBackProgress && callBackProgress();
        const companyQuery = await supabase.from("company").select("*").eq("name", player["Company name"]).maybeSingle()
        const company = companyQuery.data ? Company.newCompany(companyQuery.data) : session?.company;

        await newPlayer(session, company?.id, player["Email"], player["First name"], player["Last name"], player["Team number"]);
    }

    return true;
}

export async function newPlayer(session: Session, companyId: string | undefined, email: string, firstName: string, lastName: string, teamNumber?: string) {
    
    const {id, existingUser} = await UserData.newUser({
        mail: email,
        firstName: firstName,
        lastName: lastName,
        company: companyId,
        indirect: true,
    });

    const player = await Player.doesExist(id, session.id);
    let player_id = player.id;
    if (!player.exists && id && session?.id) {
        player_id = (await Player.create(id, session?.id))?.id ?? player_id;
    }

    const {error} = await supabase.rpc("assign_player_to_team", {
        session_id: session?.id,
        player_id: player_id,
        team_name: `${t.sessionJoin.team} ${teamNumber ?? ""}`,
        specific_team: teamNumber ? true : false
    })

    if (error) {
        Notification.error("Erreur lors de l'assignation du joueur à l'équipe", error);
        return false;
    }
}

export async function newPlayersTablet(session: Session, players: {mail: string, firstName: string, lastName: string}[]) {
    const oldPlayers = session.additionnals?.players ?? [];
    const response = await supabase.from("session")
        .update({
            additionnals: {
                players: [...oldPlayers, ...players]
            }
        })
        .eq("id", session.id);

    if (response.error) {
        Notification.error("Erreur lors de la création des joueurs", response.error);
        return false;
    }

    return true;
}
