import StepProps from "../../types/StepProps";
import {Flex} from "antd";
import ButtonsStep10 from "./components/buttonsStep10";
import ImageR from "../../../shared/components/image/ImageR";
import {useGameContext} from "../../../pages/session_game/context/context.game";

export default function ItStep10() {
    const context = useGameContext();
    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }


    const backgroundUrl = `${context.step?.description.background}${stepContext.player?.number}.webp`;

    return (
        <Flex align={"flex-start"} className={"w-[1000px] h-[640px] relative"}>
            <ImageR src={backgroundUrl} width={"1000px"} height={"600px"} className={"object-scale-down"}/>
            {stepContext.player?.number === 1 && <ButtonsStep10 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={1} mainHintPos={{x: 340, y: 355, h: 25, w: 90}} />}
            {stepContext.player?.number === 2 && <ButtonsStep10 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={2} mainHintPos={{x: 250, y: 266, h: 70, w: 50}} />}
            {stepContext.player?.number === 3 && <ButtonsStep10 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={3} mainHintPos={{x: 535, y: 325, h: 50, w: 80}} />}
            {stepContext.player?.number === 4 && <ButtonsStep10 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={4} mainHintPos={{x: 357, y: 136, h: 144, w: 250}}/>}
        </Flex>
    )
}