import {Modal} from "antd";
import ImageR from "../../../../shared/components/image/ImageR";
import { useGameContext } from "../../../../pages/session_game/context/context.game";
import { useMemo } from "react";

export interface BaseHintProps {
    number: number
    open: boolean
    setOpen: (open: boolean) => void
}

export default function HintModalStep6({number, open, setOpen}: BaseHintProps) {
    const context = useGameContext();
    const color = useMemo(() => {
        switch(number){
            case 1:
                return "#FF7828"
            case 2:
                return "#D369E5"
            case 3:
                return "#243762"
        }
    }, [number])

    return (
        <Modal styles={{content: {backgroundColor: color}}} open={open} footer={false} onCancel={() => setOpen(false)} width={600}>
            <div className="flex justify-center">
                <ImageR src={`${context.step?.description.hint}${number}.png`} width={"500px"} height={"300px"} className={"object-scale-down mt-5 mb-5"} />
            </div>
        </Modal>
    )
}