import {Flex} from "antd";
import React from "react";
import {Route, Routes} from "react-router-dom";
import AppSpinner from "../../shared/components/spinner/AppSpinner";
import ListSessionHome from "./subPages/session/list/list.session.home";
import useHome from "./callbacks/useHome";
import MenuHome from "./components/menu.home";
import {HomeProvider} from "./context/provider.home";
import SessionCreateHome from "./subPages/session/create/create.session.home";
import ResultSession from "./subPages/session/result/result.session";
import ManageComapany from "./subPages/company/manage.comapany";
import CreateCompanies from "./subPages/company/create.companies";
import InfoSessionHome from "./subPages/session/info/info.session.home";

export default function HomePage() {
    return (
        <HomeProvider>
            <HomePageUnwrapped/>
        </HomeProvider>
    )
}

function HomePageUnwrapped() {
    const home = useHome();

    if (home.context.loading) {
        return (<AppSpinner tryAgain={true} size={"large"}/>);
    }

    return (
        <Flex className="h-full w-full" vertical>
            <MenuHome onMenuClick={home.onMenuClick} currentRoute={home.currentRoute} homeItems={home.items} />
            <Flex className="w-full h-full max-h-screen overflow-y-auto">
                <Routes>
                    <Route path="session/create" element={<SessionCreateHome/>}/>
                    <Route path="session/result/:id" element={<ResultSession/>}/>
                    <Route path="session/info/:id" element={<InfoSessionHome/>}/>
                    <Route path="session-old" element={<ListSessionHome type={"past"}/>}/>
                    <Route path="session-future" element={<ListSessionHome type={"future"}/>}/>
                    <Route path="session-in-progress" element={<ListSessionHome type={"current"}/>}/>
                    <Route path="company" element={<ManageComapany/>}/>
                    <Route path="company/create" element={<CreateCompanies/>}/>
                    <Route path="" element={<ListSessionHome type={"all"}/>}/>
                </Routes>
            </Flex>
        </Flex>
    );
}
