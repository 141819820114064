import Quizz from "../../../scenarios/shared/step_quizz/Quizz";

export enum SessionState {
    CLOSED = "CLOSED",
    OPEN = "OPEN",
    VIDEO = "VIDEO",
    RULES = "RULES",
    IN_GAME = "IN_GAME",
    QUIZZ = "QUIZZ",
    SURVEY = "SURVEY",
    FINAL = "FINAL",
    RANK_1 = "RANK_1",
    RANK_2 = "RANK_2",
    RANK_3 = "RANK_3",
    ENDED = "ENDED",
}