import {Button, Flex, Form, Input, Modal} from "antd";
import React, {useEffect, useRef, useState} from "react";
import './styles/mask.css';
import PopupGoodAnswer from "../../../shared/components/popup/PopupGoodAnswer";
import Interruptor from "./components/Interruptor";
import ImageR from "../../../shared/components/image/ImageR";
import Answer from "../../../shared/services/answer/Answer";
import Notification from "../../../shared/services/notification/Notification";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import t from "../../../configs/i18n";
import MouseFloatingText from "../../../shared/components/mouse/MouseFloatingText";

interface ItStep1Props {
    preStatelightOn?: boolean;
}

export default function ItStep1({preStatelightOn = false}: ItStep1Props) {
    const containerRefModal = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [lightOn, setLightOn] = useState<boolean>(preStatelightOn);
    const [answerModal, setAnswerModal] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [userAnswer, setUserAnswer] = useState<string>("");
    const [onHoverValidate, setOnHover] = useState<boolean>(false);
    const flashLightClass = !lightOn ? "mask-container" : "";

    const context = useGameContext();

    useEffect(() => {
        containerRef?.current?.style.setProperty('--cursorX', `0px`);
        containerRef?.current?.style.setProperty('--cursorY', `0px`);
        const update = (e: any) => {
            if (containerRef.current) {
                const containerRect = containerRef.current.getBoundingClientRect();
                const x = e.clientX - containerRect.left;
                const y = e.clientY - containerRect.top;

                containerRef.current.style.setProperty('--cursorX', `${x}px`);
                containerRef.current.style.setProperty('--cursorY', `${y}px`);
            }
        };

        document.addEventListener('mousemove', update);
        document.addEventListener('touchmove', update);

        return () => {
            document.removeEventListener('mousemove', update);
            document.removeEventListener('touchmove', update);
        };
    }, []);


    const onClickLock = () => {
        setAnswerModal(true)
    }


    const validateAnswer = () => {
        if (!Answer.isCorrect(userAnswer, context.step?.description.answer)) {
            Notification.error(t.sessionGame.wrongAnswer)
            return;
        }

        setSuccessModal(true);
    }

    const updateAnswer = (answer: string) => {
        if (answer.length <= 4 && (answer.match(/^\d+$/) || answer === "")) {
            setUserAnswer(answer)
        }
    }

    return (
        <Flex className={"w-full h-full relative"}>
            <PopupGoodAnswer currentUser={context.user} playersTeam={context.playersTeam} player={context.player} team={context.team} open={successModal} setOpen={setSuccessModal}/>

            <Modal open={answerModal} onCancel={() => setAnswerModal(false)} footer={null}>
                <div ref={containerRefModal}></div>
                <p className={"mt-10"}>{context.step?.description.question}</p>
                <Flex className="text-center justify-center  text-[1.2em]">
                    <Input
                        onChange={(e) => updateAnswer(e.target.value)}
                        value={userAnswer}
                        className={"text-center w-[5em] mr-2"}
                    />
                    <div className="mt-[3px]">%</div>
                </Flex>
                <Flex justify={"center"} className={"mt-10"} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
                    <Button onClick={validateAnswer} className="w-full" type="primary" htmlType="submit" disabled={!userAnswer}>
                        {t.common.validate}
                    </Button>
                </Flex>
                <MouseFloatingText text={t.common.allFieldsFilled} condition={!userAnswer && onHoverValidate} start={containerRefModal.current?.getBoundingClientRect()}/>
            </Modal>


            {!preStatelightOn && <Interruptor showHint={context.showHint} onClick={() => setLightOn(!lightOn)}/>}
            {lightOn &&
                <div>
                    <video controls className={"absolute top-[40px] left-[330px] w-[340px]"} src={context.step?.description.video}/>
                    <img style={{border: context.showHint ? "4px solid lightgreen" : "none"}} onClick={onClickLock} className={"absolute top-[170px] left-[935px] w-[40px] cursor-pointer"}
                         src={context.step?.description.lock} alt="lock"/>
                </div>
            }

            <Flex className={`${flashLightClass}`} align="flex-start" ref={containerRef}>
                {!lightOn && <ImageR width={"1000px"} height={"600px"} className="object-scale-down" src={context.step?.description.backgroundNoLight}/>}
                {lightOn && <ImageR width={"1000px"} height={"600px"} className="object-scale-down" src={context.step?.description.backgroundLight}/>}
            </Flex>
        </Flex>
    );
}
