import {useEffect, useMemo, useState} from "react";
import SurveyResponse from "../../../../../../shared/models/Survey/SurveyResponse";
import Survey from "../../../../../../shared/models/Survey/Survey";
import {useSessionResultContext} from "../context/context.result.session";
import {SurveyType} from "../../../../../../shared/models/Survey/SurveyType";
import {supabase} from "../../../../../../configs/supabase";
import scenarios from "../../../../../../scenarios/scenarios";
import { getCustomLanguage } from "../../../../../../configs/i18n";

export default function useSessionSurvey() {
    const [survey, setSurvey] = useState<SurveyResponse[]>([])
    const [loading, setLoading] = useState(true)
    const context = useSessionResultContext();

    const globalRating = useMemo(() => Survey.getAverage(survey, SurveyType.RATING_GLOBAL), [survey])
    const gameRating = useMemo(() => Survey.getAverage(survey, SurveyType.RATING_GAME), [survey])
    const recommandation = useMemo(() => Survey.percentage(survey, SurveyType.RECOMMENDATION), [survey])
    const inputsAnswers = useMemo(() => survey.filter(survey => survey.type === SurveyType.INPUT).map(survey => survey.answer), [survey])
    const t = useMemo(() => getCustomLanguage(scenarios[context.session?.scenario || "sustainable.fr.it"].lang), [context.session?.scenario])

    const fetchData = async () => {
        const survey = await Survey.getAllSurvey(context.session?.id)
        setSurvey(survey);
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [context.session?.id, t])

    function generateSurveyCsv(): void {
        const users: { [key: string]: { [key: string]: string } } = {};
        const questions = new Set<string>();
        const summary: { [key: string]: { total: number, count: number, type: string } } = {};

        // Collecter toutes les questions et organiser les réponses par utilisateur
        survey.forEach((response) => {
            const { mail, question, answer, type } = response;
            if (!users[mail]) users[mail] = { 'user': mail }; // Inclure le mail de l'utilisateur
            users[mail][question] = answer;
            questions.add(question.replace(/"/g, '""')); // Échapper les guillemets

            // Préparer le résumé pour le calcul des moyennes et des pourcentages
            if (!summary[question]) summary[question] = { total: 0, count: 0, type: type };
            
            if (type === 'RATING_GLOBAL' || type === 'RATING_GAME') {
                summary[question].total += parseFloat(answer);
                summary[question].count += 1;
            } else if (type === 'RECOMMENDATION' || type === 'OPTION') {
                summary[question].total += answer.toLowerCase() === t.common.yes.toLowerCase() ? 1 : 0;
                summary[question].count += 1;
            }
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        const headers = [
            t.sessionEnd.survey.email,
            t.sessionEnd.survey.globalRating,
            t.sessionEnd.survey.ratingGame,
            t.sessionEnd.survey.game,
            t.sessionEnd.survey.recommendation,
            t.sessionEnd.survey.tryAgain,
            t.sessionEnd.survey.input
        ];
        csvContent += headers.map(field => `"${field}"`).join(",") + "\n"; // En-têtes échappées

        // Données des utilisateurs
        Object.entries(users).sort(
            ([_, userA], [__, userB]) => parseFloat(userA[t.sessionEnd.survey.ratingGame] ?? -1) < parseFloat(userB[t.sessionEnd.survey.ratingGame] ?? -1) ? 1 : -1
        ).forEach(([_, answers]) => {
            const rowContent = headers.map(field => {
                const value = field !== t.sessionEnd.survey.email ? answers[field.replace(/"/g, '')] || "" : answers['user'];
                return `"${value.replace(/"/g, '""')}"`; // Échapper les valeurs
            }).join(",");
            csvContent += rowContent + "\n";
        });

        // Ligne de résumé
        const summaryRow = headers.map(question => {
            const { total, count, type } = summary[question.replace(/"/g, '')] || { total: 0, count: 0, type: '' };
            if (type === 'RATING_GLOBAL' || type === 'RATING_GAME') {
                return `"${(total / count).toFixed(2)}"`;
            } else if (type === 'RECOMMENDATION' || type === 'OPTION') {
                return `"${((total / count) * 100).toFixed(2)}%"`;
            }
            return '""';
        }).join(",");
        csvContent += summaryRow;

        const encodedUri = encodeURI(csvContent);

        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getFileName(t.fileNames.survey));
        document.body.appendChild(link);

        // Déclenchement du téléchargement
        link.click();
        document.body.removeChild(link); // Nettoyage
    }

    const generatePlayerStatusCSV = async () => {
        const status = await supabase.rpc('get_player_status', {
            session_id: context.session?.id
        }).csv();

        const csv = status.data || "";

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", getFileName(t.fileNames.attendance));
        document.body.appendChild(link);

        // Déclenchement du téléchargement
        link.click();
        document.body.removeChild(link); //
    }

    const getFileName = (template: string) => {
        return `${template}${context.session?.company.name.replace(" ", "_")}_${new Date(survey[0].date).toLocaleDateString()}_${new Date(survey[0].date).toLocaleTimeString()}.csv`
    }


    return {
        globalRating,
        gameRating,
        recommandation,
        inputsAnswers,
        loading,
        generateSurveyCsv,
        generatePlayerStatusCSV
    }
}