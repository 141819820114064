import {Button, Flex} from "antd";
import Question from "./type/Question";
import {InputValue} from "../../../shared/models/Team/InputValue";
import t from "../../../configs/i18n";
import {useGameContext} from "../../../pages/session_game/context/context.game";

interface QuizzProps {
    question: Question
    id: string
    answers: string[],
    disabled?: boolean
}

export default function QuestionGroup({question, answers, id, disabled = false}: QuizzProps) {
    const onClickAnswer = async (answer: string) => {
        if (answers && answers.includes(answer)) {
            const newValue = answers.filter(a => a !== answer)
            await InputValue.updateOrCreate(id, JSON.stringify(newValue))
            return;
        }

        if (question.multiple) {
            await InputValue.updateOrCreate(id, JSON.stringify([...answers, answer]))
            return;
        }

        await InputValue.updateOrCreate(id, JSON.stringify([answer]))
    }


    return (
        <Flex align={"center"} vertical>
            <h3 className={"mt-5 mb-0"}>{question.name}</h3>
            <p className={"text-red-500 mt-1 mb-4 text-xs"}>{t.sessionGame.winBonus}</p>
            {question.answers.map(answer => {
                let type : "default" | "primary" = "default"
                let style : "" | "disabled:bg-green-light" = ""
                if (answers && answers.includes(answer)) {
                    style = "disabled:bg-green-light"
                    type = "primary"
                }
                return (
                    <Flex className={`w-fit h-fit mt-5`}>
                        <Button disabled={disabled} className={`max-w-[700px] h-auto whitespace-normal ${style}`} type={type} onClick={() => onClickAnswer(answer)}>{answer}</Button>
                    </Flex>
                )
            })}
        </Flex>
    )
}