import {Flex} from "antd";
import {BaseHintProps} from "../../../types/BaseHintProps";
import ImageR from "../../../../shared/components/image/ImageR";
import {useMemo} from "react";
import {useGameContext} from "../../../../pages/session_game/context/context.game";


export default function HintItStep2({number}: BaseHintProps) {
    const context = useGameContext();

    const image = useMemo(() => `${context.step?.description.hint}${number}.webp`, [number])
    const description = context.step?.description.description;

    return (
        <Flex align={"center"} justify={"center"} vertical>
            <ImageR src={image} width={"200px"} height={"200px"} className={"object-scale-down"} />
            <Flex className={"text-center"}>
                {description[number - 1]}
            </Flex>
        </Flex>
    )

}