import {Button, Flex, Form, FormInstance} from "antd";
import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import {SketchAnswer} from "./SketchAnswer";
import t from "../../../../configs/i18n";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import {ReactElement, useEffect, useRef, useState} from "react";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import Answer from "../../../../shared/services/answer/Answer";
import Notification from "../../../../shared/services/notification/Notification";
import MouseFloatingText from "../../../../shared/components/mouse/MouseFloatingText";

interface Props {
    popup?: boolean;
}


export default function ItStep2PasswordContent(props: Props) {
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);
    const [form] = Form.useForm();
    const context = useGameContext();
    const description : ReactElement[] = context.step?.description.description;
    const answers : string[] = context.step?.description.answers;
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [onHover, setOnHover] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    
    const updateButton = (value: string, id: string) => {
        // Check if all fields have values
        const allFieldsFilled = Object.values(form.getFieldsValue() as string[]).every(
            (value) => value !== undefined && value !== ""
        );
        setIsButtonDisabled(!allFieldsFilled); // Disable if not all fields are filled
    };

    const validate = async () => {
        const inputs = form.getFieldsValue();
        if (!Answer.isCorrectWithOrder(inputs, answers)) {
            Notification.error(t.sessionGame.wrongAnswer);
            return;
        }
        setOpenSuccess(true);
    }

    useEffect(() => {
        if (context.solution?.solutionOn) {
            setIsButtonDisabled(false);
        }
    }, [context.solution?.solutionOn]);

    return (
        <div ref={componentRef}>
            <PopupGoodAnswer currentUser={context.user} open={openSuccess} setOpen={setOpenSuccess} team={context.team} player={context.player} playersTeam={context.playersTeam}/>
            <Flex className={"h-[500px] w-[850px]"} align={"center"} justify={"center"} vertical>
                {(props.popup || props.popup === undefined) && <CanClosePopup/>}
                <h2 className={"text-green-dark"}>{context.step?.description.title}</h2>
                <Flex gap={"large"} justify={"center"} className={"w-full h-full text-center"}>
                    <SketchAnswer playerToAnswer={2} number={1} answer={answers[0]} description={description[0]} form={form} onValueChange={updateButton}/>
                    <SketchAnswer playerToAnswer={4} number={2} answer={answers[1]} description={description[1]} form={form} onValueChange={updateButton}/>
                    <SketchAnswer playerToAnswer={1} number={3} answer={answers[2]} description={description[2]} form={form} onValueChange={updateButton}/>
                    <SketchAnswer playerToAnswer={3} number={4} answer={answers[3]} description={description[3]} form={form} onValueChange={updateButton}/>
                </Flex>
                <Flex onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
                    <Button onClick={validate} type={"primary"} className={"mt-4"} disabled={isButtonDisabled}>
                        {t.common.validate}
                    </Button>
                </Flex>
            </Flex>
            <MouseFloatingText text={t.common.allFieldsFilled} condition={isButtonDisabled && onHover} start={componentRef.current?.getBoundingClientRect()}/>
        </div>
    );
}