import {Button, Flex, notification, Popover, Tooltip} from "antd";
import {CaretLeftOutlined, CaretRightOutlined, FieldTimeOutlined, ToolOutlined, UserOutlined} from "@ant-design/icons";
import React, {useMemo} from "react";
import {UserData} from "../../../shared/models/UserData/UserData";
import {Team} from "../../../shared/models/Team/Team";
import {Player} from "../../../shared/models/Player/Player";
import {Link} from "react-router-dom";
import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";
import { useGameContext } from "../context/context.game";
import { Role } from "../../../shared/models/UserData/Role";

interface GmButtonProps {
    team: Team | undefined
    playersTeam: Player[]
    currentPlayer: Player | undefined
}

function GmTools({team, playersTeam, currentPlayer}: GmButtonProps) {
    const player1 = useMemo(() => playersTeam.find(player => player.number === 1), [playersTeam]);
    const player2 = useMemo(() => playersTeam.find(player => player.number === 2), [playersTeam]);
    const player3 = useMemo(() => playersTeam.find(player => player.number === 3), [playersTeam]);
    const player4 = useMemo(() => playersTeam.filter(player => player.number === 4).at(0), [playersTeam]);
    const player4bis = useMemo(() => playersTeam.filter(player => player.number === 4).at(1), [playersTeam]);


    const nextStep = async () => {
        const newStep = (team?.step || 0) + 1;
        const response = await supabase
            .rpc("update_team", {
                team_id: team?.id,
                new_step_number: newStep,
            });

        if (response.error) {
            Notification.error("Erreur lors de la modification de l'équipe.", response.error);
            return;
        }

        notification.success({message: `L'équipe est maintenant à l'étape ${newStep}`});
    }

    const previousStep = async () => {
        const newStep = (team?.step || 0) - 1;
        const response = await supabase
            .rpc("update_team", {
                team_id: team?.id,
                new_step_number: newStep,
            });

        if (response.error) {
            Notification.error("Erreur lors de la modification de l'équipe.", response.error);
            return;
        }

        notification.success({message: `L'équipe est maintenant à l'étape ${newStep}`});
    }

    const setSubStep = async (n: number) => {
        let newsubStep : number | null = 0;

        if (team?.subStep === undefined || team?.subStep === null) {
            newsubStep = 0;
        }
        else if (team.subStep + n < 0) {
            newsubStep = null;
        } else {
            newsubStep = team.subStep + n;
        }


        const response = await supabase
            .rpc("update_sub_step", {
                new_sub_step: newsubStep,
                team_id: team?.id,
            });

        if (response.error) {
            Notification.error("Erreur lors de la modification de l'équipe.", response.error);
            return;
        }

        notification.success({message: `L'équipe est maintenant à l'étape ${newsubStep !== null ? newsubStep + 1 : "null"}`});
    }

    const unlockGamemaster = async () => {
        const response = await supabase
            .rpc("fix_gamemaster", {
                team_id: team?.id
            });

        if (response.error) {
            Notification.error("Erreur lors de la résolution du problème.", response.error);
            return;
        }

        notification.success({message: `L'équipe est maintenant débloqué.`});
    }

    const bonusMalus = async (bonus: number) => {
        const response = await supabase
            .rpc("update_bonus", {
                team_id: team?.id,
                new_bonus: bonus
            });

        if (response.error) {
            Notification.error("Erreur lors de l'attribution du bonus.", response.error);
            return;
        }

        notification.success({message: `Le bonus/malus a été attribué.`});
    }


    return (
        <Flex gap={"5px"} className={"w-full h-full"} align={"center"} justify={"center"} vertical>
            <p className={"m-0 mt-2"}>Voir un joueur</p>
            {player1 &&
                <Link to={`/session_game/${player1.playerId}`} reloadDocument={true}>
                    <Button className={"h-auto"} type={currentPlayer?.getId() === player1.getId() ? "primary" : "default"}>
                        <Flex align={"center"} vertical>
                            <Flex>
                                <UserOutlined/> Joueur 1
                            </Flex>
                            {player1?.getName()}
                        </Flex>
                    </Button>
                </Link>
            }
            {player2 &&
                <Link to={`/session_game/${player2.playerId}`} reloadDocument={true}>
                    <Button className={"h-auto"} type={currentPlayer?.getId() === player2.getId() ? "primary" : "default"}>
                        <Flex align={"center"} vertical>
                            <Flex>
                                <UserOutlined/> Joueur 2
                            </Flex>
                            {player2?.getName()}
                        </Flex>
                    </Button>
                </Link>
            }
            {player3 &&
                <Link to={`/session_game/${player3.playerId}`} reloadDocument={true}>
                    <Button className={"h-auto"} type={currentPlayer?.getId() === player3.getId() ? "primary" : "default"}>
                        <Flex align={"center"} vertical>
                            <Flex>
                                <UserOutlined/> Joueur 3
                            </Flex>
                            {player3?.getName()}
                        </Flex>
                    </Button>
                </Link>
            }
            {player4 &&
                <Link to={`/session_game/${player4.playerId}`} reloadDocument={true}>
                    <Button className={"h-auto"} type={currentPlayer?.getId() === player4.getId() ? "primary" : "default"}>
                        <Flex align={"center"} vertical>
                            <Flex>
                                <UserOutlined/> Joueur 4
                            </Flex>
                            {player4?.getName()}
                        </Flex>
                    </Button>
                </Link>
            }
            {player4bis &&
                <Link to={`/session_game/${player4bis.playerId}`} reloadDocument={true}>
                    <Button className={"h-auto"} type={currentPlayer?.getId() === player4bis.getId() ? "primary" : "default"}>
                        <Flex align={"center"} vertical>
                            <Flex>
                                <UserOutlined/> Joueur 4bis
                            </Flex>
                            {player4bis?.getName()}
                        </Flex>
                    </Button>
                </Link>
            }
            <p className={"m-0 mt-2"}>Etape</p>
            <Flex gap={"5px"} align={"center"}>
                <Tooltip title="Étape précendente">
                    <Button onClick={previousStep} type={"primary"} size={"small"} danger><CaretLeftOutlined/></Button>
                </Tooltip>
                <p>{team?.step}</p>
                <Tooltip title="Étape suivante">
                    <Button onClick={nextStep} type={"primary"} size={"small"}><CaretRightOutlined/></Button>
                </Tooltip>
            </Flex>

            <p className={"m-0 mt-2"}>Question</p>
            <Flex gap={"5px"} align={"center"}>
                <Tooltip title="Question précendente">
                    <Button onClick={() => setSubStep(-1)} type={"primary"} size={"small"} danger><CaretLeftOutlined/></Button>
                </Tooltip>
                <p>{team?.subStep !== null && team?.subStep !== undefined ? team.subStep + 1 : ""}</p>
                <Tooltip title="Question suivante">
                    <Button onClick={() => setSubStep(1)} type={"primary"} size={"small"}><CaretRightOutlined/></Button>
                </Tooltip>
            </Flex>

            <p className={"m-0 mt-2"}>Bonus/Malus</p>
            <Flex gap={"5px"} align={"center"}>
                <Tooltip title="30s de malus">
                    <Button onClick={() => bonusMalus(-30)} size={"small"} type={"primary"} danger><FieldTimeOutlined/></Button>
                </Tooltip>
                <p>{team?.bonus && team?.bonus > 0 ? "-" : "+"}{Math.abs(team?.bonus || 0)}s</p>
                <Tooltip title="30s de bonus">
                    <Button onClick={() => bonusMalus(30)} size={"small"} type={"primary"}><FieldTimeOutlined/></Button>
                </Tooltip>
            </Flex>
            {team?.getNeedGamemaster(playersTeam) === true &&
                <Button className={"mt-4"} onClick={unlockGamemaster} type={"primary"} danger>Débloquer</Button>}
        </Flex>
    )
}

export default function GmButton({team, playersTeam, currentPlayer}: GmButtonProps) {
    const context = useGameContext();
    return (
        <Popover content={() => <GmTools currentPlayer={currentPlayer} team={team} playersTeam={playersTeam}/>}>
            {context.user?.hasRights(Role.GAMEMASTER, context.session?.id) === true && <Button size={"small"} type={"primary"}><ToolOutlined/></Button>}
        </Popover>
    )
}