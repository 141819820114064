import {Button, Flex, Form, Modal} from "antd";
import StepProps from "../../../types/StepProps";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import React, {useRef, useState} from "react";
import SyncronizedCrossword from "../../../../shared/components/input/SyncronizedCrossword";
import {CluesInput} from "@jaredreisinger/react-crossword";
import Answer from "../../../../shared/services/answer/Answer";
import Notification from "../../../../shared/services/notification/Notification";
import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import t from "../../../../configs/i18n";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import MouseFloatingText from "../../../../shared/components/mouse/MouseFloatingText";

interface PasswordModalStep4Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    stepContext: StepProps;
}


export default function PasswordModalStep8({open, setOpen, stepContext}: PasswordModalStep4Props) {
    const context = useGameContext();
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);
    const [form] = Form.useForm();

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [onHover, setOnHover] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    
    const updateButton = () => {
        // Check if all fields have values
        const allFieldsFilled = Object.values(form.getFieldsValue() as string[]).every(
            (value) => value !== undefined && value !== ""
        );
        setIsButtonDisabled(!allFieldsFilled); // Disable if not all fields are filled
    };

    const data : CluesInput = context.step?.description.crossword;

    const validate = () => {
        const values = form.getFieldsValue();
        for (const key in values) {
            const number = parseInt(key.split("-")[0]);
            const wordInfo = data.across[number] ?? data.down[number];
            const valueNormalized = Answer.normalize(values[key])
            const wordNormalized = Answer.normalize(wordInfo.answer);

            if (valueNormalized !== wordNormalized) {
                Notification.error(t.sessionGame.crosswordIncorrect);
                return;
            }
        }


        setOpen(false);
        setOpenSuccess(true);
    }


    return (
        <div>
            <PopupGoodAnswer currentUser={stepContext.currentUser} open={openSuccess} setOpen={setOpenSuccess} team={stepContext.team} player={stepContext.player} playersTeam={stepContext.playersTeam} />
            <Modal width={800} open={open} footer={null} onCancel={() => setOpen(false)}>
                <div ref={componentRef}/>
                <CanClosePopup/>
                <Flex className={"w-full mt-4"}>
                    <SyncronizedCrossword form={form} data={data} stepContext={stepContext} onUpdate={updateButton}/>
                </Flex>
                <Flex justify={"center"} className={"mt-10"} >
                    <div onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
                        <Button disabled={isButtonDisabled} onClick={validate} type={"primary"}>{t.common.validate}</Button>
                    </div>
                </Flex>
                <MouseFloatingText start={componentRef.current?.getBoundingClientRect()} text={t.common.allFieldsFilled} condition={onHover && isButtonDisabled}/>
            </Modal>
        </div>
    )
}