import {BaseHintProps} from "../../../types/BaseHintProps";
import {Card, Flex} from "antd";
import ImageR from "../../../../shared/components/image/ImageR";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import InvisibleButtonWithLetter from "../components/InvisibleButtonWithLetter";
import ReactCardFlip from "react-card-flip";
import {useState} from "react";
import FlipFlopCard from "../components/flipflopcard";

export default function HintEcoStep5({number}: BaseHintProps) {
    const context = useGameContext();
    const image = `${context.step?.description.hint}${number}.png`;
    const [flipped, setFlipped] = useState(false);

    return (
        <Flex align={"center"} justify={"center"} vertical>
            {(number === 1 || number === 2) && <ImageR src={image} width={"425px"} height={"425px"} className={"object-scale-down mt-5 mb-5"}/>}
            {number == 3 &&
                <Flex gap={10} vertical>
                    <h2 className={"text-center"}>Focus on impacts</h2>
                    {context.step?.description.cardP3.map((card : any) => <FlipFlopCard front={card.front} back={card.back}/>)}
                </Flex>
            }
            {number == 4 &&
                <Flex gap={10} vertical>
                    <h2 className={"text-center"}>Focus on impacts</h2>
                    {context.step?.description.cardP4.map((card : any) => <FlipFlopCard front={card.front} back={card.back}/>)}
                </Flex>
            }



            {/*{number === 3 && <>*/}
            {/*    <InvisibleButtonWithLetter letter={context.step?.description.lettersP3[0]} position={{x: 130, y: 150, h: 70, w: 270}} showHint={false}/>*/}
            {/*    <InvisibleButtonWithLetter letter={context.step?.description.lettersP3[1]} position={{x: 130, y: 230, h: 70, w: 270}} showHint={false}/>*/}
            {/*    <InvisibleButtonWithLetter letter={context.step?.description.lettersP3[2]} position={{x: 130, y: 310, h: 70, w: 270}} showHint={false}/>*/}
            {/*    <InvisibleButtonWithLetter letter={context.step?.description.lettersP3[3]} position={{x: 130, y: 390, h: 70, w: 270}} showHint={false}/>*/}
            {/*</>}*/}
            {/*{number === 4 && <>*/}
            {/*    <InvisibleButtonWithLetter letter={context.step?.description.lettersP4[0]} position={{x: 130, y: 150, h: 70, w: 270}} showHint={false}/>*/}
            {/*    <InvisibleButtonWithLetter letter={context.step?.description.lettersP4[1]} position={{x: 130, y: 230, h: 70, w: 270}} showHint={false}/>*/}
            {/*    <InvisibleButtonWithLetter letter={context.step?.description.lettersP4[2]} position={{x: 130, y: 310, h: 70, w: 270}} showHint={false}/>*/}
            {/*    <InvisibleButtonWithLetter letter={context.step?.description.lettersP4[3]} position={{x: 130, y: 390, h: 70, w: 270}} showHint={false}/>*/}
            {/*</>}*/}
        </Flex>
    )
}