import {Button, Card, Form, Input} from "antd";
import {UserOutlined} from "@ant-design/icons";
import React, {useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import Session from "../../../shared/models/Session/session";
import {UserData} from "../../../shared/models/UserData/UserData";
import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";
import {Player} from "../../../shared/models/Player/Player";
import t from "../../../configs/i18n";
import scenarios from "../../../scenarios/scenarios";

interface SessionLoginCardProps {
    session: Session | undefined;
}

export default function SessionLoginCard({session}: SessionLoginCardProps) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const scenario = useMemo(() => scenarios[session?.scenario as string], [session?.scenario])

    const signIn = async (mail: string) => {
        const signInResponse = await supabase.auth.signInWithPassword({
            email: mail,
            password: mail
        })

        if (signInResponse.error) {
            Notification.error(t.error.common, signInResponse.error);
            setLoading(false);
            return;
        }
    }

    const joinGame = async (playerId: string, name: boolean) => {
        if (!session) {
            Notification.error(t.error.session);
            return;
        }
        if (!name) {
            navigate(`/session_join/${playerId}`);
        } else if (session.isCommonRoomState()) {
            navigate(`/session_common_room/${playerId}`);
        } else {
            navigate(`/session_game/${playerId}`);
        }
    }

    const connectToSession = async () => {

        if (!session) {
            Notification.error(t.error.session);
            return;
        }

        setLoading(true);
        const formValues = form.getFieldsValue();
        let mail = (formValues.mail as string).trim().toLowerCase();
        if (scenario.tablet) {
            mail = `${mail}@${session?.company.name}-${session?.name}-thewokies.fr`.replaceAll(" ", "-").trim().toLowerCase();
        }
        
        
        const {id, existingUser} = await UserData.newUser({
            mail: mail,
            company: session.company.id || session?.company as unknown as string
        });

        const player = await Player.doesExist(id, session.id);
        if (player.exists) {
            await signIn(mail);
            joinGame(player.id, player.withName);
            setLoading(false);
            return;
        } else if (!session.canJoinExclusively() && id) {
            await signIn(mail);
            const player = await Player.create(id, session.id);
            player?.id && joinGame(player.id, player.withName);
        } else {
            Notification.error(t.sessionLogin.cannotJoin);
            setLoading(false);
        }


    }

    useEffect(() => {
        UserData.getCurrentSupabaseUser().then((user) => {
            if (user) {
                if (scenario.tablet) {
                    return;
                }
                form.setFieldValue("mail", user.email);
            }
        })
    }, []);

    return (
        <Card title={t.sessionLogin.joinSession} headStyle={{color: '#14665F', textAlign: "center"}} className="h-fit w-96">
            <Form form={form} name="normal_login" onFinish={connectToSession}>
                <p className={"text-center"}>{scenario.tablet ? t.sessionLogin.enterTeam : t.sessionLogin.enterMail}</p>
                <Form.Item name="mail" rules={[{ required: true, message: "" }]}>
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={scenario.tablet ? t.common.team : t.common.mail} />
                </Form.Item>

                <Button className="w-full" type="primary" htmlType="submit" loading={loading}>
                    {t.sessionLogin.joinSession}
                </Button>
            </Form>
        </Card>
    )
}