import {useEffect, useState} from "react";
import {SessionResultContext} from "./context.result.session";
import Session from "../../../../../../shared/models/Session/session";
import {Team} from "../../../../../../shared/models/Team/Team";
import SessionService from "../../../../../../shared/models/Session/sessionService";
import {Player} from "../../../../../../shared/models/Player/Player";
import { supabase } from "../../../../../../configs/supabase";

interface Props {
    children: React.ReactNode;
    sessionId: string | undefined;
}

export const SessionResultProvider = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [session, setSession] = useState<Session>();
    const [teamRank, setTeamRank] = useState<Team[]>([])
    const [players, setPlayers] = useState<Player[]>([]);

    const updateTeam = (newTeam: Team | undefined) => {
        if (!newTeam) {
            return;
        }
        
        setTeamRank(prevTeamRank => {
            return prevTeamRank.map(team => {
                if (team.id === newTeam.id) {
                    return newTeam;
                }
                return team;
            });
        });
    }

    const fetchData = async () => {
        setSession(await Session.byId(props.sessionId, "*, company(*)"));
        setTeamRank(await SessionService.rank(props.sessionId));
        setPlayers(await SessionService.players(props.sessionId));

        supabase
            .channel(`SessionResultProvider`)
            .on("postgres_changes",
                {
                    schema: 'public',
                    table: 'team',
                    event: 'UPDATE',
                }, (payload) => {
                    updateTeam(Team.newTeam(payload.new));
                }).subscribe();

        setLoading(false)
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <SessionResultContext.Provider value={{loading, session, teamRank, players}}>
            {props.children}
        </SessionResultContext.Provider>
    );
}