import sustainableItFrScenario from "./sustainable-it/sustainable-it.fr.scenario";
import React, {ReactElement} from "react";
import {BaseHintProps} from "./types/BaseHintProps";
import sustainableItEnScenario from "./sustainable-it/sustainable-it.en.scenario";
import {ecoMobilityEnScenario} from "./ecomobility/ecomobility.en.scenario";
import {sustainableItTabletFr} from "./sustainable-it-tablet/sustainable-it-tablet.fr";
import {ecoMobilityFrScenario} from "./ecomobility/ecomobility.fr.scenario";
import {ecoMobilityDassaultFrScenario} from "./ecomobility/ecomobility.fr.dassault.scenario";
import sustainableItFrDecathlonScenario from "./sustainable-it/sustainable-it.fr.decathlon.scenario";
import sustainableItEnDecathlonScenario from "./sustainable-it/sustainable-it.en.decathlon.scenario";
import sustainableItFrSolo from "./sustainable-it-solo/sustainable-it.solo.fr";
import sustainableItFrEdfScenario from "./sustainable-it/sustainable-it.fr.edf.scenario";

type Scenario = {
    title: string;
    description: string;
    longDescription: string;
    rules: string | string[];
    lang: string;
    tablet: boolean;
    solo?: boolean | undefined;
    finalTeamQuizz?: boolean;
    finalTeamQuizzStep?: number;
    steps: {
        description: any
        component: ReactElement;
        baseHints: ((props : BaseHintProps) => ReactElement)[]
    }[];
};

type Scenarios = {
    [key: string]: Scenario;
};

const scenarios : Scenarios = {
    "sustainable.fr.it": sustainableItFrScenario,
    "sustainable.en.it": sustainableItEnScenario,
    "sustainable.fr.decathlon.it": sustainableItFrDecathlonScenario,
    "sustainable.en.decathlon.it": sustainableItEnDecathlonScenario,
    "sustainable.fr.edf.it": sustainableItFrEdfScenario,
    "ecomobility.en.it": ecoMobilityEnScenario,
    "ecomobility.fr.it": ecoMobilityFrScenario,
    "ecomobility.fr.dassault.it": ecoMobilityDassaultFrScenario,
    "sustainable.tablet.fr.it": sustainableItTabletFr,
    "sustainable.solo.fr.it": sustainableItFrSolo
}

export default scenarios;
