import {Button, Flex, Image} from "antd";
import Session from "../../../shared/models/Session/session";
import React from "react";
import {UserOutlined} from "@ant-design/icons";

interface SessionGmHeaderProps {
    Session: Session | undefined;
    SessionRealtime: Session | undefined;
    playersOnline: number;
    playersTotal: number;
}

export default function SessionGmHeader({Session, SessionRealtime, playersTotal, playersOnline}: SessionGmHeaderProps) {
    return (
        <Flex justify={"space-between"} align={"center"} className="w-full h-14 bg-beige">
            <Flex className={"h-full"} justify={"center"} align={"center"}>
                <Image className="ml-6 max-h-[40px] max-w-[60px] object-scale-down" src={Session?.company.logo} preview={false} placeholder={false} width={60}/>
                <Flex justify={"center"} className="ml-12 h-full text-green-light" vertical>
                    <p className="m-0 text-xs font-bold">{Session?.company.name}</p>
                    <p className="m-0 text-xs">{SessionRealtime?.name}</p>
                </Flex>
            </Flex>
            <Button ghost className={"pointer-events-none mr-6 font-bold"} type={"primary"}><UserOutlined /> {playersOnline} / {playersTotal}</Button>
        </Flex>
    )
}
