import {Button, Flex, Form, Modal} from "antd";
import StepProps from "../../../types/StepProps";
import ImageR from "../../../../shared/components/image/ImageR";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import {useState} from "react";
import Answer from "../../../../shared/services/answer/Answer";
import Notification from "../../../../shared/services/notification/Notification";
import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import t from "../../../../configs/i18n";
import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";

interface PasswordModalStep4Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    stepContext: StepProps;
}


export default function PasswordModalStep1({open, setOpen, stepContext}: PasswordModalStep4Props) {
    const [form] = Form.useForm();
    const context = useGameContext();
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);

    const image : string = context.step?.description.password;
    const answers : string[] = context.step?.description.answers;

    const modalStyles = {
        content: {
            backgroundColor: "#FDFCF5"
        }
    };

    const validate = () => {
        const isValid = Answer.isCorrectWithOrder(form.getFieldsValue(), answers);

        if (!isValid) {
            Notification.error(t.sessionGame.wrongAnswer);
            return;
        }

        setOpenSuccess(true)
        setOpen(false);
    }


    return (
        <>
            <PopupGoodAnswer currentUser={stepContext.currentUser} open={openSuccess} setOpen={setOpenSuccess} team={stepContext.team} player={stepContext.player} playersTeam={stepContext.playersTeam} />
            <Modal styles={modalStyles} width={500} open={open} footer={null} onCancel={() => setOpen(false)}>
                <CanClosePopup/>
                <Flex>
                    <ImageR src={image} width={"450px"} height={"300px"} className={"object-scale-down mt-8"}/>
                </Flex>
                <Flex className={"absolute top-[345px] left-[40px]"} vertical>
                    <Flex gap={"5px"} align={"center"} className={"text-sm"}>
                        <p>{context.step?.description.text1}</p>
                        <SyncronizedInput number={4} className={"w-[70px] text-white bg-blue-500 hover:bg-blue-500 focus:bg-blue-500"} size={"small"}
                                          id={`1-${stepContext.team?.id}-1`}
                                          player={stepContext.player} playersTeam={stepContext.playersTeam} form={form}/>
                        <p>{context.step?.description.text2}</p>
                        <SyncronizedInput number={1} className={"w-[100px] text-white bg-red-500 hover:bg-red-500 focus:bg-red-500"} size={"small"}
                                          id={`1-${stepContext.team?.id}-2`}
                                          player={stepContext.player} playersTeam={stepContext.playersTeam} form={form}/>
                        <p>{context.step?.description.text3}</p>
                        <SyncronizedInput number={3} className={"w-[50px] text-white bg-yellow-500 hover:bg-yellow-500 focus:bg-yellow-500"} size={"small"}
                                          id={`1-${stepContext.team?.id}-3`}
                                          player={stepContext.player} playersTeam={stepContext.playersTeam} form={form}/>
                    </Flex>
                    <Flex gap={"5px"} align={"center"}>
                        <p>{context.step?.description.text4}</p>
                        <SyncronizedInput number={2} className={"w-[70px] text-white bg-purple-500 hover:bg-purple-500 focus:bg-purple-500"} size={"small"}
                                          id={`1-${stepContext.team?.id}-4`}
                                          player={stepContext.player} playersTeam={stepContext.playersTeam} form={form}/>
                    </Flex>
                    <p>{context.step?.description.text5}</p>
                </Flex>
                <Flex justify={"center"}>
                    <Button onClick={validate} className={"mt-10"} type={"primary"}>{t.common.validate}</Button>
                </Flex>
            </Modal>
        </>
    )
}