import {Button, Form, Input, InputNumber, Modal, notification, Upload} from "antd";
import {Team} from "../../../../shared/models/Team/Team";
import {supabase} from "../../../../configs/supabase";
import Notification from "../../../../shared/services/notification/Notification";
import {DeleteOutlined, UploadOutlined} from "@ant-design/icons";
import ImageR from "../../../../shared/components/image/ImageR";
import Popup from "../../../../shared/components/popup/Popup";
import {UserData} from "../../../../shared/models/UserData/UserData";
import { uploadTeamImage } from "../../../../shared/services/images/upload";


interface TeamInfoPopupProps {
    team: Team | undefined;
    open: boolean;
    setOpen: (value: boolean) => void;
    user: UserData | undefined;
    needGM: boolean | undefined;
}

export default function TeamInfoPopup({open, setOpen, team, user, needGM} : TeamInfoPopupProps) {
    const [form] = Form.useForm();

    const onOk = async () => {
        const values = form.getFieldsValue();
        const response = await supabase
            .rpc("update_team", {
                team_id: team?.id,
                new_name: values.name,
                new_step_number: values.step
            });

        if (response.error) {
            Notification.error("Erreur lors de la modification de l'équipe.", response.error);
            return;
        }

        notification.success({message: "Equipe modifiée avec succès"});
        setOpen(false);
    }

    const fixGamemaster = async () => {
        const response = await supabase
            .rpc("fix_gamemaster", {
                team_id: team?.id
            });

        if (response.error) {
            Notification.error("Erreur lors de la résolution du problème.", response.error);
            return;
        }

        notification.success({message: "Problème résolu avec succès"});
        setOpen(false);
    }

    const deleteTeam = async () => {
        const response = await supabase
            .from("team")
            .delete()
            .eq("id", team?.id);

        if (response.error) {
            Notification.error("Erreur lors de la suppression de l'équipe.", response.error);
            return;
        }

        notification.success({message: "Équipe supprimée avec succès"});
        setOpen(false);
    }

    return (
        <Modal title={"Informations équipes"} open={open && team !== undefined}  onOk={onOk} destroyOnClose={true} onCancel={() => setOpen(false)}>
            {team !== undefined && <Form preserve={false} form={form}>
                <Form.Item className={"mt-10"} label={"Nom de l'équipe"} name={"name"} initialValue={team?.name}>
                    <Input placeholder={"Nom de l'équipe"}/>
                </Form.Item>

                <Form.Item label={"Etape"} name={"step"} initialValue={team?.step}>
                    <InputNumber />
                </Form.Item>

                <Form.Item>
                    {needGM && <Button type={"primary"} onClick={fixGamemaster} danger>Résoudre le problème</Button>}
                </Form.Item>

                <Form.Item>
                    {team?.photo && <ImageR src={team?.photo} width={"200px"} height={"200px"}/>}
                </Form.Item>

                <Form.Item>
                    <Upload name="logo" listType="picture" accept="image/*" customRequest={(o) => uploadTeamImage(o, team)}>
                        <Button icon={<UploadOutlined/>}>Photo d'équipe</Button>
                    </Upload>
                </Form.Item>

                {user?.isAdmin() && <Form.Item>
                    <Button type={"primary"} onClick={() => Popup.warning("Voulez vous supprimer l'équipe ?", deleteTeam)} danger><DeleteOutlined/></Button>
                </Form.Item>}

            </Form>}
        </Modal>
    )
}