import {Flex} from "antd";
import Session from "../../../shared/models/Session/session";
import t from "../../../configs/i18n";

interface SessionClosedProps {
    session: Session | undefined;
}

export default function SessionClosed({session}: SessionClosedProps) {
    return (
        <Flex justify={"center"} vertical>
            <h2 className={"text-green-dark"}>{t.sessionLogin.closedState}</h2>
            <p className={"text-green-dark text-center"}>{`${t.sessionLogin.comeBack} ${new Date(session?.startDate!).toLocaleString()} ${t.sessionLogin.toConnect}`}</p>
        </Flex>
    )
}