import {Form, FormInstance, Input} from "antd";
import React, {useEffect, useMemo, useRef} from "react";
import {InputValue} from "../../models/Team/InputValue";
import {Player} from "../../models/Player/Player";
import SyncService from "../../services/sync/SyncService";
import t from "../../../configs/i18n";
import { useGameContext } from "../../../pages/session_game/context/context.game";
import scenarios from "../../../scenarios/scenarios";

interface SyncronizedInputProps {
    id : string;
    player: Player | undefined;
    playersTeam: Player[];
    form?: FormInstance;
    number?: number;
    className?: string;
    addonAfter?: string | null;
    placeHolder?: string;
    forcePlacholder?: boolean;
    getValue?: (value: string) => void;
    size?: "large" | "middle" | "small";
    onChange?: (value: string, id: string) => void;
    isDisabled?: boolean;
    color?: string;
    noPadding?: boolean;
    maxLength?: number;
    fetchCallback?: () => void;
}



export default function SyncronizedInput({id, player, playersTeam, number, form, className = "", addonAfter = null, placeHolder = "", size = "middle", onChange = () => {}, isDisabled = false, forcePlacholder = false, color, noPadding, maxLength, fetchCallback} : SyncronizedInputProps) {
    const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const context = useGameContext();
    const classNameMemo = useMemo(() => {
        if (context.session?.scenario && scenarios[context.session.scenario].solo) {
            return `${className} ${context.solution?.solutionOn ? "disabled:bg-green-500 hover:cursor-not-allowed disabled:text-white" : ""}`
        }
        return className;
    }, [context.solution?.solutionOn, className])

    const loadValues = async () => {
        await InputValue.byId(id, false).then(input => {
            if (!input || context.solution?.solutionOn) {
                return;
            }
            form?.setFieldValue(id, input?.value);
            onChange(input?.value || "", id);
        });
        fetchCallback && fetchCallback();
    }

    const fetchData = () => {
        loadValues();
        return InputValue.listen(id, player?.getId(), (value) => {
            form?.setFieldValue(id, value);
            onChange(value || "", id);
        });
    }

    useEffect(() => {
        if (context?.session?.scenario && scenarios[context.session.scenario].solo) return;
        const channel = fetchData()
        SyncService.register(id, fetchData);

        return () => {
            channel?.unsubscribe();
        }
    }, [form, id, player?.getId()]);

    const onChangeInput = (e : any) => {
        if (!e.target) {
            return;
        }

        
        if (!(context?.session?.scenario && scenarios[context.session.scenario].solo)) {
            const value = e.target.value;
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
    
            debounceTimeoutRef.current = setTimeout(async () => {
                if (e.target) {
                    await InputValue.updateOrCreate(id, e.target.value, player?.getId())
                }
            }, 1000);
            onChange(value, id);
        } else {
            if (!context.solution?.solutionOn) {
                const value = e.target.value;
                onChange(value, id);
            }
        }
    }


    const playerToAnswer = number ? playersTeam.find(p => p.number === number) : null;
    const playerDisabled = !!(player?.number !== number && playerToAnswer);
    if (!forcePlacholder) {
        placeHolder = playerDisabled ? t.sessionGame.game.inputOtherPlayer : placeHolder;
    }

    return (
            <Form form={form} preserve={false}>
                <Form.Item className={"m-0"} name={id}>
                    <Input size={size} disabled={playerDisabled || isDisabled || (context?.session?.scenario !== undefined && scenarios[context.session.scenario].solo && context.solution?.solutionOn)} onChange={onChangeInput} className={classNameMemo} addonAfter={addonAfter} placeholder={placeHolder}/>
                </Form.Item>
            </Form>
    )

}