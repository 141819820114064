import { Flex, Modal } from "antd";
import ImageRatioBackground from "../../../../shared/components/image/ImageRatioBackground";
import ImageR from "../../../../shared/components/image/ImageR";
import { useGameContext } from "../../../../pages/session_game/context/context.game";

interface HintModalProps {
    number: number,
    open: boolean,
    setOpen: (open: boolean) => void
}

export default function HintModal({number, open, setOpen}: HintModalProps) {
    const context = useGameContext();
    const image = `${context.step?.description.definitions}_${number}.png`;

    return (
        <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
            <Flex align={"center"} justify={"center"} vertical>
                <ImageR src={image} width={"500px"} height={"300px"} className={"object-scale-down mt-5 mb-5"} />
            </Flex>
        </Modal>
    )
}
