import StepProps from "../../types/StepProps";
import {Flex} from "antd";
import ButtonsStep8 from "./components/buttons.step8";
import ImageR from "../../../shared/components/image/ImageR";
import {useGameContext} from "../../../pages/session_game/context/context.game";

export default function ItStep8() {
    const context = useGameContext();
    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }

    const backgroundUrl = `${context.step?.description.background}${stepContext.player?.number}.webp`;

    return (
        <Flex align={"flex-start"} className={"w-[1000px] h-[640px] relative"}>
            <ImageR src={backgroundUrl} width={"1000px"} height={"600px"} className={"object-scale-down"}/>
            {stepContext.player?.number === 1 && <ButtonsStep8 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={1} mainHintPos={{x: 300, y: 350, h: 40, w: 60}} />}
            {stepContext.player?.number === 2 && <ButtonsStep8 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={2} mainHintPos={{x: 420, y: 15, h: 215, w: 215}} />}
            {stepContext.player?.number === 3 && <ButtonsStep8 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={3} mainHintPos={{x: 745, y: 345, h: 40, w: 70}} passwordPos={{x: 840, y: 330, h: 65, w: 100}} />}
            {stepContext.player?.number === 4 && <ButtonsStep8 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={4} mainHintPos={{x: 510, y: 325, h: 75, w: 65}}/>}
        </Flex>
    )
}