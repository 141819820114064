import React from 'react';
import ReactDOM from 'react-dom/client';
import './shared/assets/styles/index.css';
import reportWebVitals from './reportWebVitals';
import "./configs/i18n";

import {App} from "antd";
import AppRouter from "./pages/router";
import AntConfig from "./configs/ant";
import "./configs/sentry";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <App className={"w-full h-full"}>
        <AntConfig>
            <AppRouter/>
        </AntConfig>
    </App>

);

reportWebVitals();
