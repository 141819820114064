import {Flex, Modal} from "antd";
import ImageR from "../../../../shared/components/image/ImageR";

interface EmptyModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function EmptyModal({open, setOpen}: EmptyModalProps) {

    const emptyImage = "https://firebasestorage.googleapis.com/v0/b/the-wokies.appspot.com/o/scenario%2Fsustainable-it%2F1.0%2Ffr%2Fstep2%2Fempty.webp?alt=media";

    return (
        <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
            <Flex align={"center"} justify={"center"}>
                <ImageR src={emptyImage} width={"300px"} height={"300px"}/>
            </Flex>
        </Modal>
    )
}