import {useHomeContext} from "../../../../context/context.home";
import React, {useEffect, useState} from "react";
import {HeartOutlined, TrophyOutlined} from "@ant-design/icons";
import {MenuProps} from "antd";
import {useSessionResultContext} from "../context/context.result.session";
import t from "../../../../../../configs/i18n";

export default function useSessionResult() {
    const homeContext = useHomeContext();
    const context = useSessionResultContext();
    const [currentTab, setCurrentTab] = useState<("rank" | "stat")[]>(["rank"]);
    const [items, setItems] = useState<MenuProps['items']>([
        {
            label: t.home.session.podium,
            key: 'rank',
            icon: <TrophyOutlined />,
        }
    ]);

    useEffect(() => {
        if (homeContext.user?.isAdmin()) {
            setItems(items => [...items || [], {
                label: t.home.session.stats,
                key: 'stat',
                icon: <HeartOutlined/>,
            }])
        }
    }, [homeContext.user]);

    const changeTab = (e: any) => {
        setCurrentTab([e.key as any])
    }

    return {
        currentTab,
        items,
        changeTab,
        context,
    }
}