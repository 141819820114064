import {Flex, Modal} from "antd";
import HintItStep4 from "../hint/eco.step.3";

export interface BaseHintProps {
    number: number
    open: boolean
    setOpen: (open: boolean) => void
}

export default function HintModalStep3({number, open, setOpen}: BaseHintProps) {
    const modalStyles = {
        content: {
            backgroundColor: "#FFFFFF"
        }
    }

    return (
        <Modal mask={false} styles={modalStyles} open={open} footer={false} onCancel={() => setOpen(false)}>
            <Flex className={"w-full h-full"} justify={"center"}>
                <HintItStep4 number={number} />
            </Flex>
        </Modal>
    )
}