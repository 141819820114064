import {Button, Flex, Modal, notification, Progress} from "antd";
import React, {useEffect, useMemo} from "react";
import {FileExcelOutlined, PlusOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import {UploadRequestOption} from "rc-upload/lib/interface";
import Papa from 'papaparse';
import Notification from "../../../../../../../shared/services/notification/Notification";
import {importCsv} from "../../../../../../../shared/models/Player/PlayerService";
import {useSessionInfoContext} from "../../context/context.info.session";
import {ErrorCode} from "../../../../../../../shared/services/const/ErrorCode";
import scenarios from "../../../../../../../scenarios/scenarios";
import SessionService from "../../../../../../../shared/models/Session/sessionService";
import { Role } from "../../../../../../../shared/models/UserData/Role";
import t from "../../../../../../../configs/i18n";


interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function ExcelPopupInfoSessionHome(props: Props) {
    const context = useSessionInfoContext();
    const [uploading, setUploading] = React.useState<number>(0);
    const [nbUpload, setNbUpload] = React.useState<number>(0);
    const scenario = useMemo(() => scenarios[context.session?.scenario as string], [context.session?.scenario])


    useEffect(() => {
        setUploading(0);
    }, [props.open]);

    const downloadTemplate = () => {
        const csvContent = "Email,First name,Last name,Company name,Team number\n";

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${t.fileNames.template}${context.session?.company?.name}_${context.session?.startDate?.toLocaleDateString()}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const parseCSV = async (options : UploadRequestOption) => {
        const {onSuccess, onError, file, onProgress} = options;
        Papa.parse(file as any, {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {
                if (!results.data) {
                    Notification.error("Erreur lors de la lecture du fichier", ErrorCode.EMPTY_VALUE);
                    return;
                }

                setNbUpload(results.data.length);
                let result = false;
                if (scenario.tablet) {
                    result = await SessionService.importCSVTablet(results.data as any, context.session?.id);
                } else if (context.session && context.currentUser?.hasRights(Role.GAMEMASTER, context.session.id)) {
                    result = await importCsv(results.data as any, context.session, () => setUploading((prev) => prev + 1));

                }
                if (!result) {
                    setUploading(0);
                    return;
                }

                notification.success({message: "Importation réussie"});
                props.setOpen(false);
            },
            error: (error) => {
                Notification.error("Erreur lors de l'importation du fichier", error.message);
            }
        })
    }



    return (
        <Modal destroyOnClose={true} className={"min-w-[800px]"} open={props.open} onCancel={() => props.setOpen(false)} footer={false} >
            <Flex gap={"20px"} className={"w-full pb-8"} vertical={true}>
                <h2>Ajouter un participant via un fichier</h2>
                <Flex gap={"10px"} align={"center"} className={"w-full"}>
                    <p>Télécharger le fichier modèle ainsi que les instructions d’utilisation </p>
                    <Button onClick={downloadTemplate} type={"text"}><FileExcelOutlined /> Template inscription </Button>
                </Flex>
                {uploading === 0 && <Dragger accept={".csv"} customRequest={parseCSV} showUploadList={false}>
                    <PlusOutlined className={"text-3xl"} />
                    <p>Ajouter un tableau</p>
                    <p>(Fichier format CSV)</p>
                </Dragger>}
                {uploading > 0 &&
                    <Flex className={"w-full"} justify={"center"}>
                        <Progress type="circle" percent={Math.round(uploading / nbUpload * 100)} />
                    </Flex>
                }
            </Flex>
        </Modal>

    )
}