import {SurveyType} from "./SurveyType";

export default class SurveyResponse {
    answer: string;
    type: SurveyType;
    mail: string;
    question: string;
    date: string;

    public constructor(obj: any) {
        this.answer = obj.answer;
        this.type = obj.type;
        this.mail = obj.mail;
        this.question = obj.question;
        this.date = obj.date;
    }

    public static newSurveyResponse(obj: any) : SurveyResponse | undefined {
        if (!obj) {
            return undefined;
        }
        return new SurveyResponse(obj);
    }

    public static newSurveyResponses(obj: any[]) : SurveyResponse[] {
        return obj.map((data) => new SurveyResponse(data));
    }
}