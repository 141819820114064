import {Flex, Form, FormInstance} from "antd";
import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";
import ImageR from "../../../../shared/components/image/ImageR";
import Answer from "../../../../shared/services/answer/Answer";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import t from "../../../../configs/i18n";

interface PasswordModalProps {
    number: number;
    form: FormInstance;
    answer: string,
    description: JSX.Element,
    playerToAnswer: number,
    onValueChange?: (value: string, id: string) => void;
}

export function SketchAnswer({number, answer, description, form, playerToAnswer, onValueChange}: PasswordModalProps) {
    const context = useGameContext();

    const getId = () => {
        return `${context.team?.id}-${context.team?.step}-${number}`
    }

    const input = Form.useWatch(getId(), form)


    const getSketch = () => {
        return `${context.step?.description.sketch}${number}.webp`;
    }

    const getImage = () => {
        return `${context.step?.description.hint}${number}.webp`;
    }



    return (
        <Flex className={"max-w-[200px]"} align={"center"} vertical>
            {Answer.isCorrect(input, answer) && <ImageR height={"150px"} src={getImage()} />}
            {!Answer.isCorrect(input, answer) && <ImageR height={"150px"} src={getSketch()} />}
            <SyncronizedInput form={form} number={playerToAnswer} placeHolder={t.common.yourAnswer}  id={getId()} player={context.player} playersTeam={context.playersTeam} className={"mt-4 disabled:bg-gray-300 disabled:placeholder-gray-200 disabled:text-white"} onChange={onValueChange} />
            {Answer.isCorrect(input, answer) && <span className={"text-xs"}>{description}</span>}
        </Flex>
    )
}