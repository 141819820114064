import {Button, Form, Input, notification, Space} from "antd";
import {Team} from "../../../../shared/models/Team/Team";
import {CheckOutlined} from "@ant-design/icons";
import React, { useEffect } from "react";
import {supabase} from "../../../../configs/supabase";
import Notification from "../../../../shared/services/notification/Notification";

interface TeamInputNameProps {
    team: Team;
}

export default function TeamInputName({team} : TeamInputNameProps) {
    const [name, setName] = React.useState<string>();

    useEffect(() => {
        setName(team.name);
    }, [team]);

    const valid = async () => {
        const response = await supabase.from("team").update({name: name}).eq("id", team.id)
        if (response.error) {
            Notification.error("Erreur lors de la modification de l'équipe.", response.error);
            return;
        }
        notification.success({message: "Equipe modifiée avec succès"});
    }

    return (
        <Space.Compact className={"min-w-[150px] items-center"}>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
            <Button onClick={valid} type="primary"><CheckOutlined/></Button>
        </Space.Compact>
    );
}