import {Divider, Flex} from "antd";
import React from "react";
import {Team} from "../../../shared/models/Team/Team";

interface Props {
    teams: Team[] | undefined;
}

export default function SessionQuizzRanking(props: Props) {
    return (
        <Flex className={"w-[400px]"} vertical>
            {props.teams?.sort((a, b) => (b.points || 0) - (a.points || 0)).map((team, index) => (
                <>
                    <Flex gap={"5px"}>
                        <h3>{index + 1}.</h3>
                        <h3>{team.name} -</h3>
                        <h3>{team.points} points</h3>
                    </Flex>
                    <Divider />
                </>
            ))}
        </Flex>
    )
}