import {useEffect, useState} from "react";
import {Flex} from "antd";
import AppSpinner from "../../shared/components/spinner/AppSpinner";
import TestService from "../../shared/services/test/TestService";
import t from "../../configs/i18n";

export default function Test() {
    const [loading, setLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<string[]>([]);

    const fetchData = async () => {
        const results = await TestService.start();
        const errorMessages = TestService.getErrorMessages(results);
        setErrors(errorMessages);
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <AppSpinner/>
    }

    return (
        <Flex className={"w-full h-full"} align={"center"} justify={"center"} vertical>
            {errors.length === 0 && <h1 className={"text-green-dark"}>{t.test.success}</h1>}
            {errors.length > 0 && <h1 className={"text-red-500"}>{t.test.error}</h1>}
            {errors.map(error => <p>{error}</p>)}
        </Flex>
    )


}