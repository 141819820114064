import {Button, Card, Flex} from "antd";
import ImageR from "../../../shared/components/image/ImageR";
import StepProps from "../../types/StepProps";
import WaitingForPlayers from "../../../shared/components/popup/WaitingForPlayers";
import {useEffect, useMemo, useState} from "react";
import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";
import t from "../../../configs/i18n";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import { Role } from "../../../shared/models/UserData/Role";
import ImageRatioBackground from "../../../shared/components/image/ImageRatioBackground";

interface EndScreenProps {
    stepContext: StepProps;
}

export default function EndScreen({stepContext} : EndScreenProps) {
    const context = useGameContext();
    const [waitingForPlayers, setWaitingForPlayers] = useState<boolean>(false);
    const backgroundUrl = "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step11/background.webp"

    const validate = async () => {
        const response = await supabase
            .from("team")
            .update({
                sub_step: 0
            })
            .eq("id", stepContext.team?.id)

        if (response.error) {
            Notification.error(t.error.validateStep, response.error);
            return;
        }

        const responseRestartTimer = await supabase
            .rpc("restart_timer", {
                team_id: stepContext.team?.id
            })
        
        if (responseRestartTimer.error) {
            Notification.error(t.error.validateStep, responseRestartTimer.error);
            return;
        }
    }

    return (
        <ImageRatioBackground bgImage={backgroundUrl}>
            <WaitingForPlayers setOpen={setWaitingForPlayers} currentUser={stepContext.currentUser} team={stepContext.team} playersTeam={stepContext.playersTeam} open={waitingForPlayers}/>
            <Card className={"text-center top-[40vh] left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-[50vw]"}>
                <h2>{`${t.common.congratulations} ${stepContext.player?.getName()}`}</h2>
                <p>{context.step?.description.endMessage1}</p>
                <p>{context.step?.description.endMessage2}</p>
                <Button onClick={validate} className={"mt-4"} size={"large"} type={"primary"}>{t.sessionGame.goToQuizz}</Button>
            </Card>
        </ImageRatioBackground>
    )

}