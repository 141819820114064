import EcoStep1 from "./step1/eco.step1";
import HintEcoStep1 from "./step1/hint/eco.step.1";
import EcoStep3 from "./step3/eco.step3";
import HintEcoStep3 from "./step3/hint/eco.step.3";
import EcoStep5 from "./step5/eco.step5";
import HintEcoStep5 from "./step5/hint/eco.step.5";
import EcoStep7 from "./step7/eco.step7";
import HintEcoStep7 from "./step7/hint/eco.step.7";
import Hint2EcoStep7 from "./step7/hint/eco.hint2.step.7";
import EcoStep9 from "./step9/ecoStep9";
import EcoHintStep9 from "./step9/hint/EcoHintStep9";
import StepVideoQuizz from "../shared/step_video_quizz/StepVideoQuizz";
import ItStep11 from "../sustainable-it/step11/it.step11";
import ItStep12 from "../sustainable-it/step12/ItStep12";

export const ecoMobilityFrScenario = {
    title: 'Ecomobilité',
    description: 'Découvrez les meilleures pratiques pour un monde numérique plus responsable.',
    longDescription: 'Découvrez les pratiques numériques responsables en résolvant des énigmes en équipe. Serez-vous l\'équipe la plus rapide et la plus forte ?',
    lang: "fr",
    rules: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/rules.mp4",
    tablet: false,
    steps: [
        {
            component: <EcoStep1/>,
            baseHints: [HintEcoStep1],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step1/background_p",
                password: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step1/password.webp",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step1/hint_p",
                answers: [
                    "2019",
                    "transports",
                    "24",
                    "ges"
                ],
                text1: "En",
                text2: ", le secteur des",
                text3: "émettait",
                text4: "% des émissions mondiales de",
                text5: "due à la combustion d'énergie",
            },
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step2/video.mp4",
                questions: [
                    {
                        name: "En France, quelle part des émissions de GES est attribuée aux transports ?",
                        answers: [
                            "A - 4%",
                            "B - 24%",
                            "C - 31%",
                            "D - 94%"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "En France, le transport est la principale source de pollution avec 31 % des émissions GES, soit près d’un tiers",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quelle est la principale source d'émissions de CO2 dans le secteur du transport ?",
                        answers: [
                            "A - Le transport ferroviaire",
                            "B - Le transport aerien",
                            "C - La transport maritime",
                            "D - Le transport routier"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "En France, les transports routiers contribuent à la quasi-totalité avec 94 % des émissions du secteur des transports.",
                        multiple: false,
                        bonus: 30
                    },
                ]
            }
        },
        {
            component: <EcoStep3/>,
            baseHints: [HintEcoStep3],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step3/background_p",
                password: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step3/password.png",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step3/hint_p",
                answersCategory1: [
                    "12",
                    "7",
                    "20",
                ],
                answersCategory2: [
                    "5",
                    "19",
                    "14",
                ],
                answersCategory3: [
                    "2",
                    "9",
                    "15",
                ],
                answersCategory4: [
                    "16",
                    "3",
                    "10",
                ],
                descriptions: {
                    5: "Aller au cinéma ou au bowling",
                    2: "Faire le plein de légumes au marché",
                    16: "Rendez-vous chez le dentiste",
                    12: "Déposer les enfants à l'école",
                    9: "Acheter un cadeau d'anniversaire",
                    3: "Déposer un colis à la poste",
                    7: "Me rendre dans un coworking",
                    19: "Visiter un musée",
                    10: "Aller chez le coiffeur",
                    20: "Aller à ma formation professionnelle",
                    14: "Réaliser une séance de yoga au parc",
                    15: "Réaliser une session shopping",
                }
            },
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step4/video.mp4",
                questions: [
                    {
                        name: "En France, quelle est la part des trajets domicile travail effectuée en voiture ?",
                        answers: [
                            "A - 1/2",
                            "B - 3/4 ",
                            "C - 2/3",
                            "D - 1/4"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "En France, le travail est la principale raison de déplacements et les 3/4 des trajets domicile travail s’effectuent en voiture, le plus souvent seul.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "En France, quelle est la part des actifs qui effectuent leurs trajets inférieurs à 5 km en voiture ?",
                        answers: [
                            "A - 80%",
                            "B - 60%",
                            "C - 40%",
                            "D - 20%"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "60% des actifs effectuent leurs déplacements pour des trajets inférieurs à 5 km en voiture.",
                        multiple: false,
                        bonus: 30
                    },
                ]
            }
        },
        {
            component: <EcoStep5/>,
            baseHints: [HintEcoStep5],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step5/background_p",
                password: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step5/password.png",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step5/hint_p",
                title: "Que pouvons-nous faire pour faire face à tous ces défis ?",
                cardP3: [
                    {
                        front: "Impact sur le bien-être",
                        back: "E",
                    },
                    {
                        front: "Épuisement des ressources",
                        back: "T",
                    },
                    {
                        front: "Pollution de l'air",
                        back: "N",
                    },
                    {
                        front: "Impact économique",
                        back: "M",
                    },
                ],
                cardP4: [
                    {
                        front: "Réchauffement climatique",
                        back: "A",
                    },
                    {
                        front: "Destruction de la biodiversité",
                        back: "I",
                    },
                    {
                        front: "Destruction des récifs coralliens",
                        back: "G",
                    },
                    {
                        front: "Nuisances sonores",
                        back: "R",
                    }
                ],
                phrase1: [
                    {
                        letter: "A",
                        number: 3,
                        placeholder: "3"
                    },
                    {
                        letter: "G",
                        number: 1,
                        placeholder: "6"
                    },
                    {
                        letter: "I",
                        number: 2,
                        placeholder: "8"
                    },
                    {
                        letter: "R",
                        number: 3,
                        placeholder: "2"
                    }
                ],
                phrase2: [
                    {
                        letter: "M",
                        number: 1,
                        placeholder: "7"
                    },
                    {
                        letter: "A",
                        number: 3,
                        placeholder: "3",
                    },
                    {
                        letter: "I",
                        number: 2,
                        placeholder: "8"
                    },
                    {
                        letter: "N",
                        number: 2,
                        placeholder: "1",
                    },
                    {
                        letter: "T",
                        number: 4,
                        placeholder: "5"
                    },
                    {
                        letter: "E",
                        number: 4,
                        placeholder: "4"
                    },
                    {
                        letter: "N",
                        number: 2,
                        placeholder: "1"
                    },
                    {
                        letter: "A",
                        number: 3,
                        placeholder: "3"
                    },
                    {
                        letter: "N",
                        number: 2,
                        placeholder: "1"
                    },
                    {
                        letter: "T",
                        number: 4,
                        placeholder: "5"
                    },
                ],
            },
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step6/video.mp4",
                questions: [
                    {
                        name: "Quels sont les impacts des transports traditionnels évoqués précédemment ?",
                        answers: [
                            "A - Impacts économiques",
                            "B - Impacts sur la santé / qualité de vie",
                            "C - Impacts écologiques",
                            "D - Les 3"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "Repenser nos modes de déplacements est urgent, avec à la clé des bénéfices pour l’économie, pour la protection de l’environnement, pour la santé et notre qualité de vie.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quand on parle de coût social du bruit en France, de quoi s’agit-il ?",
                        answers: [
                            "A - Le coût social du bruit se réfère à la quantité de décibels générés par le trafic dans les grandes villes.",
                            "B - Le coût social du bruit est la valeur monétaire liée à l’ensemble des impacts générés par les nuisances sonores supportées par la société.",
                            "C - Le coût social du bruit est le budget que l'État français alloue annuellement à la recherche sur les matériaux insonorisants",
                            "D - Le coût social du bruit représente le financement des concerts et festivals pour promouvoir la culture musicale dans les zones urbaines."
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Effectivement, les nuisances sonores ont un coût en raison des maladies provoqués, de la perte de productivité et de la dégradation de la qualité de vie.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quel budget annuel moyen un ménage français consacre-t-il à la voiture ?",
                        answers: [
                            "A - Moins de 1000€",
                            "B - Moins de 3000€",
                            "C - Plus de 5 000€",
                            "D - Plus de 7 000€"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "La voiture reste dominante dans la dépense de transports avec plus de 5000€/an consacré en moyenne chez les Français.",
                        multiple: false,
                        bonus: 30
                    },
                ]
            }
        },
        {
            component: <EcoStep7/>,
            baseHints: [HintEcoStep7, Hint2EcoStep7],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step7/background_p",
                password: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step7/password.png",
                hint1: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step7/hint1_p",
                hint2: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step7/hint2_p",
                descriptions: [
                    "- 560kg de CO2 / 3 mois",
                    "Une meilleure santé",
                    "De nouvelles rencontres",
                    "Des économies"
                ],
                answers: [
                    "Vélo + Transports en commun",
                    "Marche à pied",
                    "SUV électrique + Covoiturage",
                    "Trottinette électrique"
                ],
                options : [
                    {
                        label: "Marche à pied + Bus",
                        value: "Marche à pied + Bus"
                    },
                    {
                        label: "Vélo + Transports en commun",
                        value: "Vélo + Transports en commun"
                    },

                    {
                        label: "Autopartage voiture",
                        value: "Autopartage voiture"
                    },
                    {
                        label: "Voiture + Train",
                        value: "Voiture + Train"
                    },
                    {
                        label: "Vélo électrique",
                        value: "Vélo électrique"
                    },
                    {
                        label: "Trottinette électrique",
                        value: "Trottinette électrique"
                    },
                    {
                        label: "SUV électrique + Covoiturage",
                        value: "SUV électrique + Covoiturage"
                    },
                    {
                        label: "Voiture thermique",
                        value: "Voiture thermique"
                    },
                    {
                        label: "Moto",
                        value: "Moto"
                    },
                    {
                        label: "Avion",
                        value: "Avion"
                    },
                    {
                        label: "Marche à pied",
                        value: "Marche à pied"
                    },
                    {
                        label: "Marche à pied + Bus",
                        value: "Marche à pied + Bus"
                    },
                ]
            },
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step8/video.mp4",
                questions: [
                    {
                        name: "Sur une distance de 10km, combien de fois le bus émet-il moins de CO2 que la voiture ?",
                        answers: [
                            "A - Environ 76 fois moins",
                            "B - Environ 40 fois moins",
                            "C - Environ 7 fois moins",
                            "D - Environ 2 fois moins"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "Les transports en commun sont moins polluants que la voiture, mais aussi plus économiques. Si le coût annuel moyen d’un trajet domicile travail de 10km est de 1 000€ en voiture, il n’est que de 300€ en bus !",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "En l'absence de transports en commun disponibles, quelles alternatives représentent des solutions de mobilités douces pour les longs trajets ?",
                        answers: [
                            "A - La voiture électrique",
                            "B - La moto",
                            "C - Le covoiturage",
                            "D - La voiture thermique"
                        ],
                        validAnswers: [
                            0,
                            2
                        ],
                        description: "Voiture électrique : Sur l’ensemble de la durée de vie, les voitures électriques sont moins polluantes que les voitures thermiques. Covoiturage : Le covoiturage permet de diviser les émissions de CO2 par le nombre de passagers",
                        multiple: true,
                        bonus: 30
                    },
                ]
            }
        },
        {
            component: <EcoStep9/>,
            baseHints: [EcoHintStep9],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step9/background_p",
                hintAll: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step9/hint_all.png",
                hint1: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step9/hint1_p",
                hint2: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step9/hint2_p",
                hint3: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step9/hint3_p",
                hint4: "https://api.thewokies.fr/storage/v1/object/public/scenario/ecomobility/v1/fr/step9/hint4_p",
                answers: [
                    "12",
                    "60",
                    "89",
                    "19",
                    "38",
                    "2",
                    "46",
                    "49",
                    "94",
                    "28",
                    "35",
                    "66",
                    "33",
                    "67",
                    "54",
                    "27",
                ],
                descriptions: [
                    "Pour les déplacements de moins de 2 km, je marche à pied plutôt que la voiture pour une meilleure santé.",
                    "J’opte pour le vélo pour les trajets de moins de 5 km ou pour des déplacements en ville (je serais même plus rapide que les voitures).",
                    "Je préfère le bus, le tramway ou le métro pour mes trajets quotidiens plutôt que la voiture individuelle. Un vrai gain d’économie !",
                    "Je combine le vélo puis les transports en communs sur les trajets d’environ une heure. Mon trajet sera constant et je fais des économies.",
                    "Je planifie mes trajets pour regrouper plusieurs tâches en un seul déplacement.",
                    "Je me gare dans des parkings à l'entrée des villes et j’utilise les transports en commun pour finir votre trajet.",
                    "Je profite des initiatives locales comme les journées sans voiture pour explorer des alternatives de transport.",
                    "Je veille à avoir une conduite souple et respectueuse des limitations de vitesse pour réduire la consommation de carburant.",
                    "Je loue une voiture pour une courte durée grâce aux services d'autopartage plutôt que d'en posséder une.",
                    "J’utilise les vélos en libre-service disponibles dans de nombreuses villes.",
                    "Pour les trajets un peu plus longs, j’utilise les scooters électriques partagés.",
                    "Je participe à un programme de covoiturage organisé par l'entreprise pour réduire le nombre de voiture",
                    "Si je dois utiliser une voiture, je préfére un modèle électrique ou hybride pour réduire les émissions de CO2.",
                    "Je réduis mes déplacements en travaillant à domicile lorsque cela est possible pour pour éviter la pollution liée au déplacement.",
                    "Je favorise les voyages d'affaires et personnels en train plutôt qu'en avion lorsque c'est possible pour les longs trajet.",
                    "Je vérifie que ma voiture est bien entretenue pour optimiser sa consommation de carburant et réduire les émissions polluantes.",

                ],
                category1: "Je m'engage à utiliser des mobilités douces",
                category2: "Je change mon rapport à la voiture",
                category3: "J’adopte des solutions de mobilité partagée",
                category4: "Je réduis mon impact environnemental"
            }
        },
        {
            component: <ItStep11/>,
            baseHints: [],
            description: {
                endMessage1: "Vous pouvez être fiers de vous. Vous avez développé un plan d'action cohérent pour avancer vers une mobilité plus durable !",
                endMessage2: <>Pour découvrir votre score, vous devez d'abord terminer le quiz final ! Attention, des pénalités/bonus peuvent être gagnés dans ce quiz. <strong>Faites-le rapidement !</strong></>,
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step3/video.webm",
                questions: [
                    {
                        name: "Quel pourcentage des émissions mondiales de CO2 est dû aux transports ?",
                        answers: [
                            "A - 3%",
                            "B - 24%",
                            "C - 51%",
                            "D - 64%"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Le secteur des transports est la source d'émissions qui connaît la croissance la plus rapide et représente actuellement 24% des émissions mondiales de gaz à effet de serre liées à l'énergie.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "En France, quelle part des émissions de GES est attribuée spécifiquement au trafic routier ? ",
                        answers: [
                            "A - 50%",
                            "B - 65%",
                            "C - 94%",
                            "D - 80%"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "Les transports routiers sont responsables de la quasi-totalité des émissions du secteur des transports, à hauteur de 94%, dont plus de la moitié est due aux véhicules particuliers.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quel est le coût social du bruit en France ?",
                        answers: [
                            "A - 50 milliards d'euros",
                            "B - 95 milliards d'euros",
                            "C - 147.1 milliards d'euros ",
                            "D - 200 milliards d'euros"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "Les analyses socio-économiques montrent que les problématiques de pollutions sonores ont un impact majeur notamment sur notre santé (fatigue, stress qui engendre des maladies…",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Sur l’ensemble du coût social du bruit en France, quelle part est due aux transports ?",
                        answers: [
                            "A - 1/2",
                            "B - 2/3",
                            "C - 3/4",
                            "D - 4/5"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Deux tiers du coût social du bruit (66,5%), sont attribués aux transports, parmi lesquels le bruit routier constitue plus de la moitié (54,8%)",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quel est le budget annuel moyen consacré par un français à la voiture ?",
                        answers: [
                            "A - Environ 500€",
                            "B - Environ 1500€",
                            "C - Environ 3000€",
                            "D - Environ 5000€ "
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "Avec une dépense moyenne annuelle de plus de 5000€, la voiture représente une part significative dans le budget des Français, incluant l'entretien, l'assurance, le stationnement et des coûts de carburant croissants.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Quel est l’ordre de grandeur de morts prématurées en France dû à la pollution de l’air ?",
                        answers: [
                            "A - 8000 décès",
                            "B - 48 000 décès",
                            "C - 158 000 décès",
                            "D - 198 000 décès"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Ce chiffre varie selon les études. Si l'on retient les 48 000 décès fréquemment évoqués, cela représente 8% de la mortalité en France, un chiffre non négligeable.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Ce chiffre varie selon les études. Si l'on retient les 48 000 décès fréquemment évoqués, cela représente 8% de la mortalité en France, un chiffre non négligeable.",
                        answers: [
                            "A - Réduction des émissions de CO2 et de la pollution atmosphérique.",
                            "B - Diminution des embouteillages et du temps passé dans le trafic.",
                            "C - Économies sur les frais de déplacement, tels que l'essence, l'entretien et le stationnement.",
                            "D - Opportunité de lire, se reposer, ou travailler pendant le trajet, améliorant ainsi la qualité du temps de déplacement."
                        ],
                        validAnswers: [
                            0,
                            1,
                            2,
                            3
                        ],
                        description: "Opter pour le transport en commun permet de réduire notre impact environnemental, réaliser des économies et mieux utiliser notre temps, améliorant ainsi notre qualité de vie.",
                        multiple: true,
                        bonus: 30
                    },
                    {
                        name: "Quelles sont les économies annuelles réalisées grâce au covoiturage avec un collègue sur un trajet domicile travail de 30 km ?",
                        answers: [
                            "A - 200€",
                            "B - 800€",
                            "C - 1 000€",
                            "D - 2 000€"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "En plus de ces économies substantielles, le covoiturage favorise le partage des émissions de CO2, contribuant ainsi à une réduction significative de notre impact environnemental et renforçant la solidarité entre collègues.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "La Loi d'Orientation des Mobilités (LOM) et le forfait mobilité durable en France visent à :",
                        answers: [
                            "A - Permettre à tous de voyager gratuitement",
                            "B - Promouvoir des pratiques de mobilité respectueuses de l'environnement",
                            "C - Contribuer à des déplacements plus lointains et rapides",
                            "D - Encourager l'achat de deux roues motorisés"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "La Loi d'Orientation des Mobilités (LOM) a été publiée au Journal officiel le 26 décembre 2019. Cette loi transforme en profondeur la politique des mobilités, avec un objectif simple : des transports du quotidien à la fois plus faciles, moins coûteux et plus propres.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Parmi les éco-gestes suivants, lequel ne contribue pas à une mobilité douce ?",
                        answers: [
                            "A - Utiliser un vélo électrique pour les trajets courts",
                            "B - Opter pour le covoiturage",
                            "C - Faire des courts trajets (< 5km) en voiture personnelle",
                            "D - Prendre le train pour les longues distances"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "En France, 75% des trajets en voiture sont de moins de 5 km. Opter pour la marche ou le vélo pour ces courtes distances diminue notre impact écologique et contribue à rendre nos villes plus vertes et agréables.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Les augmentations des émissions de gaz à effet de serre (GES- dans le secteur du transport en France depuis 1800 s'expliquent principalement par :",
                        answers: [
                            "A - Plus de trajets effectués quotidiennement par personne",
                            "B - Un temps de transport quotidien accru par habitant",
                            "C - L'allongement de la distance parcourue quotidiennement par habitant",
                            "D - Les trois facteurs"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "Si le nombre de déplacements par jour (2 à 3 en moyenne) et le temps passé dans les transports (environ 1 heure par jour) restent plutôt stables depuis 1800, c'est l'extension de la distance parcourue qui marque la véritable évolution. Nous sommes passés d’une mobilité dominée par la marche à une mobilité dominée par des modes de transports bien plus rapide et notamment dominée par la voiture. Cependant, nous n'avons pas cherché à réduire notre temps de transport mais plutôt à augmenter notre rayon d'action, exploitant la vitesse pour aller plus loin.",
                        multiple: false,
                        bonus: 30
                    },
                ]
            }
        },
        {
            component: <ItStep12/>,
            baseHints: [],
            description: {
                endMessage1: "Vous pouvez être fiers de vous. Vous avez développé un plan d'action cohérent pour avancer vers un monde numérique plus responsable !"
            }
        }
    ]
}
