import {Flex} from "antd";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import t from "../../../configs/i18n";

export default function Ended() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/");
    }, []);

    return (
        <Flex className={"h-full min-w-[1000px] w-full max-h-screen overflow-y-auto"}>
            <Flex className={"w-full h-full"} align={"center"} justify={"center"}>
                <h1 className={"text-green-dark"}>{t.sessionEnd?.sessionEnded}</h1>
            </Flex>
        </Flex>
    )
}