import InvisibleButton from "../../../../shared/components/button/InvisibleButton";
import {useState} from "react";
import PasswordModalStep6 from "./PasswordModal.step6";
import StepProps from "../../../types/StepProps";
import HintModalStep6 from "./HintModal.step6";

interface ButtonsStep4Props {
    showHint: boolean;
    playerNumber: number;
    mainHintPos: {x: number, y: number, h: number, w: number};
    passwordPos?: {x: number, y: number, h: number, w: number};
    stepContext: StepProps;
}

export default function ButtonsStep6({playerNumber, showHint, mainHintPos, stepContext, passwordPos = {x: 240, y: 330, h: 100, w: 120}}: ButtonsStep4Props) {
    const [hint1Modal, setHint1Modal] = useState<boolean>(false);
    const [allHintModal, setAllHintModal] = useState<boolean>(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);



    return (
        <>
            <HintModalStep6 open={hint1Modal} setOpen={setHint1Modal} number={playerNumber} />
            <HintModalStep6 open={allHintModal} setOpen={setAllHintModal} number={5} />

            <PasswordModalStep6 stepContext={stepContext} open={openPasswordModal} setOpen={setOpenPasswordModal} />


            {/* Main Hint Button */}
            <InvisibleButton onClick={() => setHint1Modal(true)} top={mainHintPos.y} left={mainHintPos.x} h={mainHintPos.h} w={mainHintPos.w} showHint={showHint}/>
            {/* Password Modal */}
            <InvisibleButton onClick={() => setOpenPasswordModal(true)} top={passwordPos?.y} left={passwordPos?.x} h={passwordPos?.h} w={passwordPos?.w} showHint={showHint}/>
            {/* All Hint Button */}
            <InvisibleButton onClick={() => setAllHintModal(true)} top={265} left={430} h={120} w={125} showHint={showHint}/>
        </>
    )
}