import Notification from "../../shared/services/notification/Notification";
import t from "../../configs/i18n";
import {ErrorCode} from "../../shared/services/const/ErrorCode";
import {supabase} from "../../configs/supabase";
import {useNavigate} from "react-router-dom";
import {Button, Flex} from "antd";

export default function MagicLinkPage() {
    const navigate = useNavigate();

    const logIn = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');

        if (!token) {
            Notification.error(t.error.user, ErrorCode.EMPTY_USER);
            navigate("/")
            return;
        }

        const response = await supabase.auth.verifyOtp({
            token_hash: token,
            type: 'email',
        })

        if (response.error) {
            Notification.error(t.error.linkExpired);
            navigate("/")
            return;
        }

        navigate("/home");
    }

  return (
    <Flex gap={"15px"} className={"w-full h-full"} justify={"center"} align={"center"} vertical>
        <h2>{t.login.title}</h2>
        <Button onClick={logIn} size={"large"} type={"primary"}>{t.login.cardTitle}</Button>
    </Flex>
  );
}