import {SurveyType} from "./SurveyType";
import {supabase} from "../../../configs/supabase";
import Notification from "../../services/notification/Notification";
import SurveyResponse from "./SurveyResponse";
import t from "../../../configs/i18n";

export default class Survey {

    public static async getAllSurvey(sessionId: string | undefined): Promise<SurveyResponse[]> {
        let hasMore = true;
        const batchSize = 500; // Number of rows to fetch per batch
        let allData: any[] = []; // Array to store the accumulated data
        let start = 0; // Starting index for the range
        let end = batchSize - 1; // Ending index for the range

        while (hasMore) {
            const {data, error} = await supabase.rpc("get_survey_info", {
                session_id: sessionId,
            }).range(start, end);

            if (error) {
                Notification.error(t.error.survey, error)
                return [];
            }
        
            // If data is returned, add it to the allData array
            if (data && data.length > 0) {
                allData = allData.concat(data);
                // Update the start and end for the next batch
                start += batchSize;
                end += batchSize;
            } else {
                // If no data is returned, set hasMore to false to exit the loop
                hasMore = false;
            }
        }

        return SurveyResponse.newSurveyResponses(allData);
    }

    public static getAverage(reponses: SurveyResponse[], type: SurveyType): number {
        const ratings : string[] = reponses.filter(survey => survey.type === type).map((rating : any) => rating.answer)
        const globalRating = ratings?.reduce((acc, rating) => acc + parseFloat(rating), 0) / ratings?.length;
        return parseFloat(globalRating.toFixed(2));
    }

    public static percentage(reponses: SurveyResponse[], type: SurveyType): number {
        const answers : string[] = reponses.filter(survey => survey.type === type).map(survey => survey.answer)
        const globalRating = answers.filter(answer => answer === "Oui" || answer === "Yes").length / answers.length * 100;
        return parseFloat(globalRating.toFixed(2));
    }
}