import {notification} from "antd";

import * as Sentry from "@sentry/browser";


export default class Notification {
    public static error(message: string, reason?: any) {
        notification.error({message: message});

        if (reason) {
            console.error(message, reason);
            Sentry.captureMessage(`${message} : ${JSON.stringify(reason)}`)
        }
    }
}