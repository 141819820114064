import {Player} from "../../models/Player/Player";
import Session from "../../models/Session/session";
import {supabase} from "../../../configs/supabase";
import { RealtimeChannel } from "@supabase/supabase-js";

export default class OnlineService {
    public static start(player: Player | undefined, session: Session | undefined) {
        OnlineService.startId(player?.getId() as string)
    }

    public static startId(playerId: string) {
        const roomOne = supabase.channel("presence")
        roomOne.subscribe(this.subscribtion(playerId, roomOne))
    }

    public static listen(callback: (players: Set<string>) => void) {
        const roomOne = supabase.channel("presence")

        roomOne
            .on('presence', { event: 'sync' }, this.detecting(roomOne, callback))
            .subscribe()
    }

    public static async startAndListen(playerId: string, callback: (players: Set<string>) => void) {
        const roomOne = supabase.channel("presence")

        roomOne
            .on('presence', { event: 'sync' }, this.detecting(roomOne, callback))
            .subscribe(this.subscribtion(playerId, roomOne))
    }

    private static subscribtion(playerId: string, room: RealtimeChannel) {
        return async (status: string) => {
            const userStatus = {
                id: playerId,
            }
            if (status !== 'SUBSCRIBED') { return }

            const presenceTrackStatus = await room.track(userStatus)
        }
    }

    private static detecting(room: RealtimeChannel, callback: (players: Set<string>) => void) {
        return async () => {
            const newState = room.presenceState()
            const players = new Set<string>()

            for (const id in newState) {
                for (const state of newState[id]) {
                    const info = state as unknown as { id: string }
                    players.add(info.id)
                }
            }
            callback(players)
        }
    }
}