import {Flex} from "antd";
import QuizzTablet from "./QuizzTablet";
import {useMemo, useState} from "react";
import WaitingForPlayers from "../../../shared/components/popup/WaitingForPlayers";
import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";
import t from "../../../configs/i18n";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import Question from "../step_quizz/type/Question";
import ProviderTabletQuizz from "./context/provider.tablet.quizz";
import {useTabletQuizzContext} from "./context/context.tablet.quizz";
import SessionQuizzRanking from "../../../pages/session_gm/components/SessionQuizzRanking";

interface Props {
    preComponent?: JSX.Element;
}

export default function StepTabletQuizz(props : Props) {
    const context = useGameContext();

    return (
        <ProviderTabletQuizz session={context.session} step={context.step}>
            <StepTabletQuizzUnwrapped preComponent={props.preComponent} />
        </ProviderTabletQuizz>
    )
}

function StepTabletQuizzUnwrapped({preComponent} : Props) {
    const stepContext = useGameContext();
    const context = useTabletQuizzContext();
    const points : number = useMemo(() => stepContext.step?.description.questionPoints || 0, [stepContext.step?.description.questionPoints]);
    const nbQuestions : number = useMemo(() => context.questions.length, [context.questions]);

    const validatedSubStep = async (valid: boolean, actualQuestion : Question) => {
        supabase
            .channel("finishedQuizz")
            .send({
                type: "broadcast",
                event: "*",
                payload: {team: stepContext.team?.name},
            })

        if (valid) {
            const result = await supabase.from("team").update({
                points: (stepContext.team?.points || 0) + points,
            }).eq("id", stepContext.team?.id);

            if (result.error) {
                Notification.error(t.error.team, result.error);
            }
        }
    }

    return (
        <Flex justify={"center"} className={"min-w-[1000px] w-full h-[640px]"} vertical>
            {stepContext.team?.subStep === null && preComponent}
            {stepContext.team?.subStep !== null && stepContext.team?.subStep !== undefined && <>
                {stepContext.team?.subStep < context.questions.length &&
                    <QuizzTablet callbackValidate={validatedSubStep} />
                }

                {stepContext.team?.subStep >= context.questions.length &&
                    <Flex justify={"center"} align={"center"} className={"min-w-[1000px] h-full"} vertical>
                        <h1 className={"text-green-dark"}>Quizz terminé !</h1>
                        <h2>Vous avez eu {stepContext.team.points / points} bonne réponses sur {nbQuestions}</h2>
                        <h3 className={"text-green-dark"}>Classement</h3>
                        <SessionQuizzRanking teams={context.teams} />
                    </Flex>
                }
            </>}
        </Flex>
    )
}