import {createContext, useContext} from "react";
import Session from "../../../../../../shared/models/Session/session";
import {Team} from "../../../../../../shared/models/Team/Team";
import {Player} from "../../../../../../shared/models/Player/Player";
import {UserData} from "../../../../../../shared/models/UserData/UserData";

interface ContextType {
    session: Session | undefined;
    loading: boolean;
    sessionRealtime: Session | undefined;
    teams: Team[];
    players: Player[];
    currentUser: UserData | undefined;
}

export const SessionInfoContext = createContext<ContextType>({
    session: undefined,
    loading: true,
    sessionRealtime: undefined,
    teams: [],
    players: [],
    currentUser: undefined
});

export const useSessionInfoContext = () => useContext(SessionInfoContext);