import {Button, Flex, Menu, MenuProps} from "antd";
import {LogoutOutlined} from "@ant-design/icons";
import React from "react";
import useHomeMenu from "../callbacks/useHomeMenu";

interface Props {
    onMenuClick: (e: any) => void;
    currentRoute: string[] | undefined;
    homeItems: MenuProps['items'];
}
export default function MenuHome(props: Props) {
    const menuData = useHomeMenu();

    return (
        <Flex align={"center"} justify={"space-between"} className={"w-full shadow pl-8 pr-8 box-border"}>
            <Flex className={"h-[60px] w-full"}>
                <Menu className={"flex w-full text-[13px] mt-[6px] border-none"} onClick={props.onMenuClick} selectedKeys={props.currentRoute} mode="horizontal" items={props.homeItems}>
                </Menu>
            </Flex>
            <Flex>
                <Button onClick={menuData.disconnect} size={"small"} type={"text"}><LogoutOutlined/></Button>
                {/*<Button size={"small"} type={"text"}><SettingOutlined /></Button>*/}
            </Flex>
        </Flex>
    )
}