import React, {useEffect, useMemo, useState} from 'react';
import {Flex} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Player} from "../../shared/models/Player/Player";
import AppSpinner from "../../shared/components/spinner/AppSpinner";
import Session from "../../shared/models/Session/session";
import {Company} from "../../shared/models/Company/company";
import {Team} from "../../shared/models/Team/Team";
import {SessionState} from "../../shared/models/Session/sessionState";
import Banner from "./components/Banner";
import Sidebar from "./components/Sidebar";
import Video from "./components/Video";
import JitsiVisio from "../../shared/components/jitsi/JitsiVisio";
import CameraPopup from "../../shared/components/popup/CameraPopup";
import SyncPopup from "../../shared/components/popup/SyncPopup";
import SyncService from "../../shared/services/sync/SyncService";
import OnlineService from "../../shared/services/sync/OnlineService";
import {UserData} from "../../shared/models/UserData/UserData";
import scenarios from "../../scenarios/scenarios";
import WaitingTablet from "./components/WaitingTablet";
import { Role } from '../../shared/models/UserData/Role';
import Notification from '../../shared/services/notification/Notification';
import t, { forceLanguage } from '../../configs/i18n';


function SessionCommonRoom() {
    const { id } = useParams();
    const [players, setPlayers] = useState<Player[]>([]);
    const [session, setSession] = useState<Session>();
    const [company, setCompany] = useState<Company | null>()
    const [team, setTeam] = useState<Team>()
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPlayer, setCurrentPlayer] = useState<Player | undefined>()
    const videoUrl = useMemo(() => session?.getRuleVideo(), [session?.subState])
    const navigate = useNavigate();

    const fetchData = async () => {
        const player = await Player.byId(id, "*, userdata!userdata(*), session(*, company(*)), team!team(*)")
        if (!player) {
            Notification.error(t.error.player)
            return;
        }

        const currentUser = await UserData.getCurrentUser()
        if (currentUser?.uid !== player.getId() as unknown as string && !currentUser?.hasRights(Role.GAMEMASTER, session?.id)) {
            navigate(`/session_login/${player?.getSession()?.id}`)
            return;
        } else if (player?.getSession() && !player?.getSession()?.isCommonRoomState()) {
            navigate(`/session_game/${id}`)
            return;
        }

        const teamPlayers = await Player.getByTeam(player.getTeamId())

        setCurrentPlayer(player);
        setTeam(player?.team as Team)
        setSession(player?.getSession())
        setCompany(player?.getSession()?.company)
        setPlayers(teamPlayers)

        player.getSession()?.listen(setSession, () => SyncService.desync(), () => SyncService.sync())
        Player.listenTeam(player, setTeam, setPlayers, setCurrentPlayer)
        Player.listenToNames(player.getId(), setPlayers)

        forceLanguage(scenarios[player?.getSession()?.scenario as string].lang)

        setLoading(false)
    }

    useEffect(() => {
        if (Session.inGame(session?.state)) {
            navigate(`/session_game/${currentPlayer?.playerId}`)
        }

        if (Session.isFinalState(session?.state)) {
            navigate(`/session_end/${session?.id}`)
        }
    }, [session?.state]);

    useEffect(() => {
        fetchData();
        SyncService.register("fetchData", fetchData)
    }, [id]);

    const scenario = useMemo(() => scenarios[session?.scenario as string], [session?.scenario])

    if (loading) {
        return <AppSpinner tryAgain={true}/>
    }

    return (
        <Flex className={"w-full h-full"}>
            <CameraPopup visibleByDefault={!scenario.tablet}/>
            <SyncPopup/>
            <Flex className={"w-full h-full"} vertical>
                <Banner company={company} session={session}/>
                <Flex className={"w-full h-full"}>
                    {session?.state === SessionState.VIDEO && !scenario.tablet && session && <Video url={videoUrl ?? ""} key={videoUrl}/>}
                    {!scenario.tablet && <JitsiVisio className={`${session?.state === SessionState.VIDEO ? "w-1/3" : "w-full"} h-full`} name={currentPlayer?.getName()} mail={currentPlayer?.getEmail()} session={session} size={"100%"} height={"100%"}/>}
                    {scenario.tablet && <WaitingTablet/>}
                </Flex>
            </Flex>
            {session?.state !== SessionState.VIDEO && session?.state !== SessionState.RULES && !scenario.tablet &&
                <Sidebar team={team} playersTeam={players} player={currentPlayer}/>}
        </Flex>
    );
}

export default SessionCommonRoom;
