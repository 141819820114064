import {BaseHintProps} from "../../../types/BaseHintProps";
import {Button, Carousel, Flex} from "antd";
import ImageR from "../../../../shared/components/image/ImageR";
import {LeftCircleFilled, RightCircleFilled} from "@ant-design/icons";
import {useRef} from "react";
import {CarouselRef} from "antd/es/carousel";
import {useGameContext} from "../../../../pages/session_game/context/context.game";

export default function ItHintStep10({number}: BaseHintProps) {
    const context = useGameContext();
    const ref = useRef<CarouselRef>(null)

    const hints = [
        context.step?.description.hintAll,
        `${context.step?.description.hint1}${number}.webp`,
        `${context.step?.description.hint2}${number}.webp`,
        `${context.step?.description.hint3}${number}.webp`,
        `${context.step?.description.hint4}${number}.webp`
    ]

    const prev = () => {
        ref.current?.prev()
    }

    const next = () => {
        ref.current?.next()
    }


    return (
        <Flex gap={"10px"} justify={"center"} align={"center"} className="mr-[10px]">
            <Button onClick={prev} type={"primary"} className="translate-x-[43px] ml-[-43px]"><LeftCircleFilled/></Button>
            <div className="block w-[380px] h-full translate-x-[43px]">
                <Carousel ref={ref}>
                    {hints.map((hint, index) => {
                        return (
                            <Flex>
                                <ImageR src={hint} width={"380px"} height={"380px"} className={"object-scale-down"}/>
                            </Flex>
                        )
                    })}
                </Carousel>
            </div>
            <Button onClick={next} type={"primary"}><RightCircleFilled/></Button>
        </Flex>
    )
}