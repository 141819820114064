import {Modal} from "antd";
import StepProps from "../../../types/StepProps";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import {useState} from "react";
import ItStep4PasswordContent from "./it.step4.password.content";
import {useGameContext} from "../../../../pages/session_game/context/context.game";

interface PasswordModalStep4Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}


export default function PasswordModalStep4({open, setOpen}: PasswordModalStep4Props) {
    const context = useGameContext();
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);

    const modalStyles = {
        content: {
            backgroundColor: "#FEF9F3"
        }
    };

    return (
        <>
            <PopupGoodAnswer currentUser={context.user} open={openSuccess} setOpen={setOpenSuccess} team={context.team} player={context.player} playersTeam={context.playersTeam} />
            <Modal styles={modalStyles} width={950} open={open} footer={null} onCancel={() => setOpen(false)}>
                <ItStep4PasswordContent onValid={() => {
                    setOpenSuccess(true);
                    setOpen(false);
                }}/>
            </Modal>
        </>
    )
}