import {Button, Card, DatePicker, Flex, Form, Input, InputNumber, Select} from "antd";
import React from "react";
import AppSpinner from "../../../../../shared/components/spinner/AppSpinner";
import useSessionCreate from "./callbacks/useSessionCreate";
import Normalize from "../../../../../shared/services/formatter/normalize";
import SearchableSelect from "../../../../../shared/components/input/SearchableSelect";

const { RangePicker } = DatePicker;

export default function SessionCreateHome() {
    const sessionCreate = useSessionCreate();

    if (sessionCreate.loading) {
        return (<AppSpinner size={"large"}/>);
    }

    return (
        <Flex align={"center"} className="w-full h-full mt-20" vertical>
            <h2 className="text-green-dark">Créer une session</h2>
            <Card className="w-1/3 shadow-md">
                <Form form={sessionCreate.form} onFinish={sessionCreate.validateForm} className="mt-4" name="normal_login">

                    <Form.Item name="name" rules={[{required: true, message: ""}]}>
                        <Input placeholder="Nom de la session"/>
                    </Form.Item>

                    <Form.Item name="company" rules={[{required: true, message: ""}]}>
                        <Select options={sessionCreate.companies} placeholder={"Choisir une entreprise"}/>
                    </Form.Item>

                    <p>Joueurs attendus</p>
                    <Form.Item name="nbPlayers" rules={[{required: true, message: ""}]}>
                        <InputNumber placeholder="XX"/>
                    </Form.Item>

                    <Form.Item name="mainGamemaster" rules={[{required: true, message: ""}]}>
                        <SearchableSelect options={sessionCreate.gamemasters} placeholder={"Choisir un gamemaster principal"}/>
                    </Form.Item>


                    <Form.Item name="gamemasters" rules={[{required: false, message: ""}]}>
                        <SearchableSelect mode={"multiple"} options={sessionCreate.gamemasters} placeholder={"Choisir des gamemasters"}/>
                    </Form.Item>

                    <p>Date de la session</p>
                    <Form.Item name="dates" rules={[{required: true, message: ""}]}>
                        <RangePicker showTime={{ format: 'HH:mm', minuteStep: 5 }} />
                    </Form.Item>

                    <Form.Item name="scenario" rules={[{required: true, message: ""}]}>
                        <Select options={sessionCreate.availableScenarios} placeholder={"Choisir un scénario"}/>
                    </Form.Item>

                    <Form.Item>
                        <Button className="w-full" type="primary" htmlType="submit">
                            Créer la session
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Flex>
    )
}
