import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import Session from "../../shared/models/Session/session";
import {Flex, Image} from "antd";
import AppSpinner from "../../shared/components/spinner/AppSpinner";
import TheWokiesLogo from "../../shared/assets/images/logo_square.webp";
import SessionInfoCard from "../../shared/components/utils/SessionInfoCard";
import {SessionState} from "../../shared/models/Session/sessionState";
import SessionLoginCard from "./components/SessionLoginCard";
import SessionClosed from "./components/SessionClosed";
import {supabase} from "../../configs/supabase";
import Notification from "../../shared/services/notification/Notification";
import {WarningOutlined} from "@ant-design/icons";
import SyncPopup from "../../shared/components/popup/SyncPopup";
import SyncService from "../../shared/services/sync/SyncService";
import SessionFinished from "./components/SessionFinished";
import RessourceTestPopup from "./components/RessourceTestPopup";
import {TestResult} from "../../shared/services/test/TestResult";
import TestService from "../../shared/services/test/TestService";
import t, {forceLanguage} from "../../configs/i18n";
import scenarios from "../../scenarios/scenarios";

function SessionLogin() {
    const { id } = useParams();
    const [session, setSession] = useState<Session>();
    const [sessionRealTime, setSessionRealTime] = useState<Session>();
    const [testResult, setTestResult] = useState<TestResult>();
    const [loading, setLoading] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const fetchData = async () => {
        const results = await TestService.start();
        setTestResult(results);
        setOpen(results.hasError);

        const query = await supabase
                .from("session")
                .select('*, company(*)')
                .eq('id', id);

        if (query.error) {
            Notification.error("Erreur lors de la récupération des données.", query.error)
            return;
        }

        if (!query.data || query.data.length === 0) {
            Notification.error("La session n'existe pas.", query.error)
            navigate("/")
            return;
        }

        const session = await Session.newSession(query.data[0]);
        setSession(session)
        setSessionRealTime(session)
        session?.listen(setSessionRealTime, () => SyncService.desync(), () => SyncService.sync())
        const scenario = scenarios[session?.scenario || ""];
        forceLanguage(scenario.lang)

        setLoading(false);
    }


    useEffect(() => {
        fetchData();
        SyncService.register("fetchData", fetchData);
    }, [id]);

    const scenario = useMemo(() => scenarios[session?.scenario as string], [session?.scenario])

    if (loading) {
        return <AppSpinner />
    }

    return (
        <Flex className="w-full h-full" vertical>
            <SyncPopup/>
            <RessourceTestPopup open={open} setOpen={setOpen} session={sessionRealTime} testResult={testResult}/>
            <SessionInfoCard company={session?.company} session={sessionRealTime} />
            <Flex align={"center"} className="w-full h-full mt-[-50px]" vertical>
                <Image preview={false} width={"150px"} src={TheWokiesLogo}/>
                <h2 className={"m-0"}>{scenario.title}</h2>
                <p className={"mt-4 mb-8"}>{scenario.longDescription}</p>

                {sessionRealTime?.canJoinExclusively() && <span className={"text-4xl text-red-500"}><WarningOutlined/> </span>}
                {sessionRealTime?.canJoinExclusively() &&
                    <h3 className={"text-red-500"}>{t.sessionLogin.alreadyStarted}</h3>}

                {sessionRealTime?.canJoin() && <SessionLoginCard session={sessionRealTime}/>}

                {sessionRealTime?.state === SessionState.ENDED && <SessionFinished/>}
                {sessionRealTime?.state === SessionState.CLOSED && <SessionClosed session={sessionRealTime}/>}
            </Flex>
        </Flex>
    );
}

export default SessionLogin;