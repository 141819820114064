import {Button, Flex, Statistic} from "antd";
import React from "react";
import AppSpinner from "../../../../../../shared/components/spinner/AppSpinner";
import useSessionSurvey from "../callbacks/useSessionSurvey";
import t from "../../../../../../configs/i18n";

export default function SessionsStats() {
    const sessionSurvey = useSessionSurvey();

    if (sessionSurvey.loading) {
        return <AppSpinner/>
    }

    return (
        <Flex vertical>
            <h2>Statistiques</h2>
            <Flex gap={"10px"} className={"w-full"} justify={"center"} align={"center"}>
                <Statistic className={"text-center pl-8 pr-8 pt-4 pb-4 bg-[#F3F8FF] rounded-lg"} title="Note globale" value={`${sessionSurvey.globalRating} / 5`}/>
                <Statistic className={"text-center pl-8 pr-8 pt-4 pb-4 bg-[#F3F8FF] rounded-lg"} title="Note d'apprentissage" value={`${sessionSurvey.gameRating} / 5`}/>
                <Statistic className={"text-center pl-8 pr-8 pt-4 pb-4 bg-[#F3F8FF] rounded-lg"} title="Recommendation" value={`${sessionSurvey.recommandation} %`}/>
            </Flex>
            <Flex gap={"20px"} justify={"center"} className={"mt-4 mb-4"}>
                <Button onClick={sessionSurvey.generateSurveyCsv} type={"primary"}>{t.home.session.exportSurvey}</Button>
                <Button onClick={sessionSurvey.generatePlayerStatusCSV} type={"primary"}>{t.home.session.exportPlayerStatus}</Button>
            </Flex>
        </Flex>
    )
}