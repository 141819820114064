import React, {useEffect, useMemo, useState} from 'react';
import {Flex} from 'antd';
import {Team} from "../../models/Team/Team";
import {PauseCircleOutlined} from "@ant-design/icons";
import TimeVisualizer from "./TimeVisualizer";


interface TimerProps {
    initialDate: Date | undefined;
    team: Team | undefined;
    showBonus?: boolean;
}

export default function Timer({initialDate, team, showBonus = true}: TimerProps) {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    let timeDifference = useMemo(() => {
        if (team?.currentTimerStop) {
            const currentTimerStop = new Date(team?.currentTimerStop);
            return currentTimerStop.getTime() - initialDate!.getTime() - (team?.totalTimerStop || 0);
        }

        return currentTime.getTime() - initialDate!.getTime() - (team?.totalTimerStop || 0);
    }, [currentTime, initialDate, team?.currentTimerStop, team?.totalTimerStop])


    const bgColor = useMemo(() => team?.currentTimerStop ? "bg-gray-500" : "bg-white", [team?.currentTimerStop])
    const textColor = useMemo(() => team?.currentTimerStop ? "text-white" : "text-green-dark", [team?.currentTimerStop])

    return (
        <Flex align={"center"} justify={"center"}>
            {team?.currentTimerStop && <PauseCircleOutlined className={"mr-2 text-gray-500 text-xl"} />}
            <TimeVisualizer timestampDifference={timeDifference} bgColor={bgColor} textColor={textColor} />
            {showBonus && team?.bonus !== undefined && team?.bonus! > 0 && (<p className={"ml-2 text-[10px] text-green-light font-bold"}>- {Math.abs(team.bonus)} s</p>)}
            {showBonus && team?.bonus !== undefined && team?.bonus! < 0 && (<p className={"ml-2 text-[10px] text-red-500 font-bold"}>+ {Math.abs(team.bonus)} s</p>)}
        </Flex>
    );
}
