import {useEffect, useState} from "react";
import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";
import t from "../../../configs/i18n";
import {Form, notification} from "antd";
import {useNavigate} from "react-router-dom";

export default function useLogin(passwordLogin: boolean, tablet?: boolean) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const redirection = async () => {
        if ((await supabase.auth.getSession()).data.session) {
            navigate('/home');
        }
    }

    const loginWithPassword = async () => {
        setLoading(true);
        const response = await supabase.auth.signInWithPassword({
            email: tablet ? "tablet@thewokies.fr" : form.getFieldValue('mail'),
            password: form.getFieldValue('password')
        });

        if (response.error) {
            if (response.error.name === "AuthApiError") {
                Notification.error(t.error.wrongPassword, response.error);
            } else {
                Notification.error(t.error.common, response.error);

            }
            setLoading(false);
            return;
        }

        navigate('/home');
        return;
    }

    const loginWithOtp = async () => {
        if (form.getFieldValue('mail') === "tablet@thewokies.fr") {
            navigate('/tablet');
            return;
        }
        setLoading(true);
        const response = await supabase.auth.signInWithOtp({
            email: form.getFieldValue('mail'),
            options: {
                shouldCreateUser: true,
                emailRedirectTo: window.location.origin + '/magic_link',
            }
        });

        if (response.error) {
            Notification.error(t.error.wait60, response.error);
            setLoading(false);
            return;
        }

        notification.success({message: t.login.success});
        setLoading(false);
    }

    const login = passwordLogin ? loginWithPassword : loginWithOtp;

    useEffect(() => {
        redirection();
    }, []);

    return {
        login,
        loading,
        form
    }
}