import {Col, Flex, FormInstance, Row} from "antd";
import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import {useMemo, useState} from "react";

interface Props {
    number: number
    form: FormInstance
    answers: string[]
    descriptions: string[]
}

export default function InputDescriptionStep3(props: Props) {
    const context = useGameContext();
    const [userAnswers, setUserAnswers] = useState<Map<string, string>>(new Map());
    const onChangeInput = (value: string, id: string) => {
        setUserAnswers(oldMap => new Map(oldMap.set(id, value)))
    }

    const valid = useMemo(() => {
        const userAnswersSet = new Set(Array.from(userAnswers.values()))

        for (const validAnswer of props.answers) {
            if (!userAnswersSet.has(validAnswer)) {
                return false;
            }
        }
        return true;
    }, [userAnswers, props.answers])

    const bgColor = useMemo(() => valid ? "bg-green-light focus:bg-green-light hover:bg-green-light disabled:bg-green-light" : "", [valid])

    const answer1Id = useMemo(() => `3-1-${props.number}-${context.team?.id}`, [props.number, context.team?.id]);
    const answer2Id = useMemo(() => `3-2-${props.number}-${context.team?.id}`, [props.number, context.team?.id]);
    const answer3Id = useMemo(() => `3-3-${props.number}-${context.team?.id}`, [props.number, context.team?.id]);


    return (
        <Flex align={"middle"} gap={"3px"} className={"absolute top-[320px] left-[40px] h-[180px] w-[195px] overflow-hidden"} vertical>
            <Flex gap={"5px"} align={"center"} className={"pl-1 pr-4 h-[55px] w-[195px] overflow-hidden"}>
                <SyncronizedInput onChange={onChangeInput} className={`w-[40px] ${bgColor}`} id={answer1Id}
                                  number={props.number} player={context.player} playersTeam={context.playersTeam}
                                  form={props.form}/>
                {Object.keys(props.descriptions).includes(userAnswers.get(answer1Id) || "") &&
                    <p>{props.descriptions[userAnswers.get(answer1Id) as any]}</p>}
            </Flex>
            <Flex gap={"5px"}  align={"center"} className={"pl-1 pr-4 h-[55px] w-[195px] overflow-hidden"}>
                <SyncronizedInput onChange={onChangeInput} className={`w-[40px] ${bgColor}`}  id={answer2Id} number={props.number} player={context.player} playersTeam={context.playersTeam} form={props.form}/>
                {Object.keys(props.descriptions).includes(userAnswers.get(answer2Id) || "") && <p>{props.descriptions[userAnswers.get(answer2Id) as any]}</p>}
            </Flex>
            <Flex gap={"5px"}   align={"center"} className={"pl-1 pr-4 h-[55px] w-[195px] overflow-hidden"}>
                <SyncronizedInput onChange={onChangeInput} className={`w-[40px] ${bgColor}`}   id={answer3Id} number={props.number} player={context.player} playersTeam={context.playersTeam} form={props.form}/>
                {Object.keys(props.descriptions).includes(userAnswers.get(answer3Id) || "") && <p>{props.descriptions[userAnswers.get(answer3Id) as any]}</p>}
            </Flex>
        </Flex>
    )
}