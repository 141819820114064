import {Component} from "react";
import {DraggableCore, DraggableData, DraggableEvent} from "react-draggable";
import "./styles.css";

interface ColumnProps {
    id: number;
    checkMatch: (number: number | null, id: number) => void;
    isOpen: boolean;
    height: number;
    mainClass: string;
}

interface ColumnState {
    list: number[];
    marginTop: number;
    onStep: boolean;
    number: number;
    prevOffset: number;
    canSetNumber: boolean;
}

export class ColumnLock extends Component<ColumnProps, ColumnState> {
    state: ColumnState = {
        list: [6, 7, 8, 9, 0, 1, 2, 3, 4, 5],
        marginTop: 0,
        onStep: false,
        number: 0,
        prevOffset: 0,
        canSetNumber: true,
    }

    dragStart = (): void | false => {
        if (this.props.isOpen) return false;
    }

    dragStop = (): void => {
        this.setNumber();
    }

    setNumber = (): void => {
        const { checkMatch, id } = this.props;
        const { canSetNumber } = this.state;
        checkMatch(canSetNumber ? this.getNumber() : null, id);
    }

    listUpdate = (isUp: boolean): void => {
        isUp ? this.updateUp() : this.updateDown();
        this.setInitialPos();
    }

    updateState = (list: number[]): void => {
        this.setState({
            list,
            onStep: false,
            canSetNumber: true,
        });
    }

    updateUp = (): void => {
        const { list } = this.state;
        list.unshift(list.pop()!);
        this.decNumber();
        this.updateState(list);
    }

    updateDown = (): void => {
        const { list } = this.state;
        list.push(list.shift()!);
        this.incNumber();
        this.updateState(list);
    }

    getNumber = (): number => {
        return this.state.number;
    }

    incNumber = (): void => {
        this.setState(state => ({ number: state.number === 9 ? 0 : state.number + 1 }));
    }

    decNumber = (): void => {
        this.setState(state => ({ number: state.number === 0 ? 9 : state.number - 1 }));
    }

    moveBack = (): void => {
        this.setState({ onStep: false, canSetNumber: true });
        this.setInitialPos();
    }

    dragAction = (e: DraggableEvent, data: DraggableData): void | false => {
        const { height, isOpen } = this.props;
        if (isOpen) return false;
        const { onStep, prevOffset } = this.state;
        const step = height / 4;
        const offset = data.deltaY;
        const isContinue = prevOffset ? offset === prevOffset : true;
        const isUp = offset > 0;

        if (!(offset % step)) {
            this.setState(state => {
                const newMargin = isUp ? state.marginTop + step : state.marginTop - step;
                return {
                    marginTop: newMargin,
                    onStep: true,
                    canSetNumber: false,
                    prevOffset: offset
                };
            });
        }

        if (isContinue) {
            onStep && this.listUpdate(isUp);
        } else {
            this.moveBack();
        }
    }

    setInitialPos = (): void => {
        this.setState({ marginTop: -this.props.height * 1.75 });
    }

    componentDidMount() {
        this.setInitialPos();
        this.setNumber();
    }

    render() {
        const { height, mainClass } = this.props;
        const { list, marginTop } = this.state;
        const cellSize = height / 2;
        const itemStyle = {
            fontSize: `${cellSize * 0.8}px`,
            height: `${cellSize}px`,
            display: 'block',
        };
        const dragStyle = {
            height: `${height}px`,
            display: 'inline-block',
            overflow: 'hidden',
        };
        const listStyle = {
            marginTop: `${marginTop}px`,
            listStyle: 'none',
        };

        return (
            <div className={`${mainClass}-drag`} style={dragStyle}>
                <DraggableCore
                    grid={[cellSize, cellSize / 2]}
                    onStart={this.dragStart}
                    onDrag={this.dragAction}
                    onStop={this.dragStop}
                >
                    <ul style={listStyle}>
                        {list.map((number, index) =>
                            <li key={index} style={itemStyle}>{number}</li>
                        )}
                    </ul>
                </DraggableCore>
            </div>
        );
    }
}
