import StepProps from "../../types/StepProps";
import {Flex} from "antd";
import ButtonsStep4 from "./components/buttons.step4";
import ImageR from "../../../shared/components/image/ImageR";
import {useGameContext} from "../../../pages/session_game/context/context.game";

export default function ItStep4() {
    const context = useGameContext();
    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }
    const backgroundUrl = `${context.step?.description.background}${stepContext.player?.number}.webp`;

    return (
        <Flex align={"flex-start"} className={"w-[1000px] h-[640px] relative"}>
            <ImageR src={backgroundUrl} width={"1000px"} height={"600px"} className={"object-scale-down"}/>
            {stepContext.player?.number === 1 && <ButtonsStep4 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={1} mainHintPos={{x: 530, y: 238, h: 201, w: 237}} secondHintPos={{x: 280, y: 100, h: 150, w: 150}}/>}
            {stepContext.player?.number === 2 && <ButtonsStep4 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={2} mainHintPos={{x: 620, y: 0, h: 245, w: 259}} secondHintPos={{x: 120, y: 384, h: 150, w: 150}}/>}
            {stepContext.player?.number === 3 && <ButtonsStep4 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={3} mainHintPos={{x: 60, y: 250, h: 310, w: 218}} secondHintPos={{x: 780, y: 73, h: 150, w: 150}}/>}
            {stepContext.player?.number === 4 && <ButtonsStep4 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={4} mainHintPos={{x: 290, y: 150, h: 181, w: 181}} secondHintPos={{x: 180, y: 290, h: 150, w: 150}}/>}
        </Flex>
    )
}