import {Button, Flex, List} from "antd";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import Top3ResultSession from "./top3.result.session";
import {Team} from "../../../../../../shared/models/Team/Team";
import PhotoResultSession from "./photo.result.session";
import {useSessionResultContext} from "../context/context.result.session";
import SessionResultTeamRow from "./teamRow.result.session";

export default function RankingResultSession() {
    const [showAll, setShowAll] = useState<boolean>(false);
    const context = useSessionResultContext();

    return (
        <Flex gap={"20px"} className={"w-full pb-6"} vertical>
            <Flex className={"w-full min-h-[400px] bg-[#F3F8FF] pt-4 rounded-lg overflow-hidden"}>
                <Top3ResultSession effect={false} />
            </Flex>

            <Flex className={"bg-[#F3F8FF] rounded-lg p-4"} vertical>
                <List
                    className={"w-full"}
                    itemLayout="horizontal"
                    dataSource={showAll ? context.teamRank : context.teamRank.slice(0, 3)}
                    renderItem={(team: Team, index) => <SessionResultTeamRow index={index} team={team} />}
                />
                <Button onClick={() => setShowAll(!showAll)} type={"text"}>{showAll ? <UpOutlined/> : <DownOutlined/>}</Button>
            </Flex>
            <PhotoResultSession/>
        </Flex>
  );
}