import StepProps from "../../types/StepProps";
import {Flex} from "antd";

import ImageR from "../../../shared/components/image/ImageR";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import ButtonsStep3 from "./components/buttonsStep3";

export default function EcoStep3() {
    const context = useGameContext();
    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }
    const backgroundUrl = `${context.step?.description.background}${stepContext.player?.number}.png`;

    return (
        <Flex align={"flex-start"} className={"w-[1000px] h-[640px] relative"}>
            <ImageR src={backgroundUrl} width={"1000px"} height={"600px"} className={"object-scale-down"}/>
            {stepContext.player?.number === 1 && <ButtonsStep3 passwordModalPos={{x: 730, y: 330, h: 95, w: 95}} stepContext={stepContext} showHint={stepContext.showHint} playerNumber={1}/>}
            {stepContext.player?.number === 2 && <ButtonsStep3 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={2}/>}
            {stepContext.player?.number === 3 && <ButtonsStep3 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={3}/>}
            {stepContext.player?.number === 4 && <ButtonsStep3 passwordModalPos={{x: 730, y: 330, h: 95, w: 95}} stepContext={stepContext} showHint={stepContext.showHint} playerNumber={4}/>}
        </Flex>
    )
}