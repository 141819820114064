import removeAccents from "remove-accents";

export default class Normalize {
    public static text(text: string): string {
        return text.trim().toLowerCase();
    }

    public static firstName(firstName: string): string {
        if (firstName === undefined || firstName === null) {
            return "";
        }
        const name = firstName.trim().toLowerCase();
        return name.charAt(0).toUpperCase() + name.slice(1);
    }
    public static lastName(lastName: string): string {
        if (lastName === undefined || lastName === null) {
            return "";
        }
        return lastName.trim().toUpperCase();
    }

    public static noAccent(str: string): string {
        if (str === undefined || str === null) {
            return "";
        }

        return removeAccents(str.toLowerCase().trim());
    }
}