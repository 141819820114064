import InvisibleButton from "../../../../shared/components/button/InvisibleButton";
import {useState} from "react";
import PasswordModalStep4 from "./PasswordModal.step3";
import StepProps from "../../../types/StepProps";
import HintModalStep3 from "./HintModal.step3";
import PasswordModalStep3 from "./PasswordModal.step3";

interface ButtonsStep4Props {
    showHint: boolean;
    playerNumber: number;
    stepContext: StepProps;
    passwordModalPos?: {x: number, y: number, h: number, w: number};
}

export default function ButtonsStep3({playerNumber, showHint, stepContext, passwordModalPos = {x: 190, y: 285, h: 95, w: 95}}: ButtonsStep4Props) {
    const [hintModal, setHintModal] = useState<boolean>(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);



    return (
        <>
            <HintModalStep3 open={hintModal} setOpen={setHintModal} number={playerNumber}/>
            <PasswordModalStep3 stepContext={stepContext} open={openPasswordModal} setOpen={setOpenPasswordModal} />


            {/* Main Hint Button */}
            <InvisibleButton onClick={() => setHintModal(true)} top={36} left={365} h={200} w={200} showHint={showHint}/>
            {/* Password Modal */}
            <InvisibleButton onClick={() => setOpenPasswordModal(true)} top={passwordModalPos.y} left={passwordModalPos.x} h={passwordModalPos.h} w={passwordModalPos.w} showHint={showHint}/>
        </>
    )
}