import {Team} from "../../../../../shared/models/Team/Team";
import Session from "../../../../../shared/models/Session/session";
import {Player} from "../../../../../shared/models/Player/Player";
import {UserData} from "../../../../../shared/models/UserData/UserData";
import {Flex, notification, Select, Table, TableProps} from "antd";
import PlayerBox from "../../../../../shared/components/player/PlayerBox";
import {supabase} from "../../../../../configs/supabase";
import Notification from "../../../../../shared/services/notification/Notification";
import React from "react";

interface TeamTableProps {
    teams: Team[] | undefined;
    players: Player[] | undefined;
    user: UserData | undefined;
    session: Session | undefined;
}


export default function TeamTableTablet({teams, players, user, session} : TeamTableProps) {
    const columns: TableProps<Team>['columns'] = [
        {
            title: 'Equipe',
            dataIndex: '',
            key: 'name',
            render: (team : Team) => <p>{team.name}</p>
    },
    {
        title: 'Joueurs',
        dataIndex: '',
        key: 'players',
        render: (team : Team | undefined) => {
            return (
                <Flex className={"w-full"}>
                    {team?.otherPlayers.map(player => <PlayerBox name={player} online={true} popup={false}/>)}
                </Flex>
            )
        }
    },
    {
        title: 'Gamemaster',
            dataIndex: '',
        key: 'gamemaster',
        render: (team : Team ) => {
        const onChooseGm = async (gmId: string) => {
            const response = await supabase.from("team").update({gamemaster: gmId}).eq("id", team.id);
            if (response.error) {
                Notification.error("Erreur lors de la mise à jour du gamemaster", response.error);
                return;
            }

            notification.success({message: "Gamemaster mis à jour"});
        }

            return (
                <Select<string> disabled={!user?.isAdmin()} onChange={onChooseGm} size="small" className="w-36" options={session?.gamemasters?.map(gm => ({label: gm.name, value: gm.uid}))}
            value={team.gamemaster} placeholder={"Choisir un gamemaster"}/>
            )
        }
    }
];

    return (
        <Table pagination={false} size={"small"} columns={columns} dataSource={teams} className="w-full mt-4 pl-12 pr-12"/>
    )
}