import {LoadingOutlined} from "@ant-design/icons";
import {Button, Flex, Spin} from "antd";
import {useEffect, useState} from "react";
import t from "../../../configs/i18n";

interface AppSpinnerProps {
    size?: "small" | "medium" | "large";
    centered?: boolean;
    color?: string;
    tryAgain?: boolean;
}

export default function AppSpinner({ size = "large", centered = true, color = "#00A88A", tryAgain = false}: AppSpinnerProps) {
    const [showTryAgain, setShowTryAgain] = useState<boolean>(false);

    let sizeNumber : number = 42;

    if (size === "small") {
        sizeNumber = 24;
    } else if (size === "medium") {
        sizeNumber = 42;
    } else if (size === "large") {
        sizeNumber = 100;
    }

    useEffect(() => {
        if (tryAgain) {
            setTimeout(() => {
                setShowTryAgain(true)
            }, 4000)
        }

    }, [tryAgain]);

    if (centered) {
        return (
            <Flex gap={"20px"} align={"center"} justify={"center"} className="w-full h-full" vertical>
                <Spin indicator={<LoadingOutlined style={{ fontSize: sizeNumber, color: color}} spin />}/>
                {showTryAgain && <>
                    <h2>{t.spinner.message}</h2>
                    <Button size={"large"} onClick={() => window.location.reload()}>{t.spinner.tryAgain}</Button>
                </>}
            </Flex>
        )
    }

    return (
        <div>
            <Spin indicator={<LoadingOutlined style={{ fontSize: sizeNumber, color: color }} spin />}/>
        </div>
    );
}
