import {Flex, Form, FormInstance} from "antd";
import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";
import StepProps from "../../../types/StepProps";
import ImageR from "../../../../shared/components/image/ImageR";
import Answer from "../../../../shared/services/answer/Answer";
import {useGameContext} from "../../../../pages/session_game/context/context.game";

interface InputWithDescriptionProps {
    pos: {x: number, y: number, w: number};
    number: number;
    form: FormInstance;
    answers: string[][];
    imgPos?: {x: number, y: number, w: number};
    onChange?: (value: string, id: string) => void;
}


export default function InputWithDescription({pos, number, form, answers, imgPos = {x: 0, y: 0, w: 120}, onChange}: InputWithDescriptionProps) {
    const context = useGameContext();
    const id = `${context.team?.id}-${context.team?.step}-${number}`
    const value = Form.useWatch(id, form)

    const imageCompleted = `${context.step?.description.imageCompleted}${number}.webp`
    const description : string[] = context.step?.description.description
    const descriptionCompleted : string[] = context.step?.description.descriptionCompleted
    const prefix : string[] = context.step?.description.prefix
    const isAnswerValid = Answer.isCorrectArray(value, answers[number - 1])

    return (
        <Flex className={"relative w-0 h-0"}>
            <Flex style={{left: pos.x - 24 + "px", top: pos.y - 35 + "px", maxWidth: pos.w + "px", minWidth: pos.w + "px",  position: "absolute"}} vertical>
                <Flex style={{left: imgPos.x + 13 + "px", top: imgPos.y + -120 + "px", position: "absolute"}}>
                    {isAnswerValid && <ImageR src={imageCompleted} className={"object-scale-down"} width={`${imgPos.w}px`} height={"84px"} />}
                </Flex>
                <Flex align={"center"}>
                    <p className={"font-bold m-0 mr-2"}>{prefix[number - 1]}</p>
                    <SyncronizedInput form={form} size={"small"} number={number === 5 ? undefined : number} id={id} player={context.player} playersTeam={context.playersTeam} onChange={onChange} />
                </Flex>
                <Flex className={"text-[10px] text-justify"}>
                    {!isAnswerValid && (context.player?.number === number || number === 5 || !context.playersTeam.find(p => p.number === number)) && <p className={"m-0 font-bold"}>{description[number - 1]}</p>}
                    {isAnswerValid && <p className={"m-0 font-bold"}>{descriptionCompleted[number - 1]}</p>}
                </Flex>
            </Flex>
        </Flex>

    )
}