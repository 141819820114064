import Session from "../../../../../../shared/models/Session/session";
import Popup from "../../../../../../shared/components/popup/Popup";
import {supabase} from "../../../../../../configs/supabase";
import Notification from "../../../../../../shared/services/notification/Notification";
import {DeleteOutlined} from "@ant-design/icons";
import {Button} from "antd";
import React from "react";
import t from "../../../../../../configs/i18n";

interface Props {
    session: Session | undefined;
}

export default function DeleteButtonListSessionHome(props: Props) {

    const deleteSession = () => {
        Popup.warning(t.home.session.deleteAlert, async () => {
                const {error} = await supabase.from("session").delete().eq("id", props.session?.id);
                if (error) {
                    Notification.error(t.error.common, error.message);
                }
                return !error
            }
        )
    }

    return (
        <Button type={"primary"} danger={true} onClick={deleteSession}>
            <DeleteOutlined/>
        </Button>
    )
}