import {Flex} from "antd";
import {CalendarOutlined} from "@ant-design/icons";
import DateFormatter from "../../../../../../shared/services/formatter/dateFormatter";
import React, {useMemo} from "react";
import {useSessionResultContext} from "../context/context.result.session";
import scenarios from "../../../../../../scenarios/scenarios";
import Session from "../../../../../../shared/models/Session/session";


interface Props {
    session: Session | undefined;
}

export default function InfoResultSession(props: Props) {
    const scenario = useMemo(() => scenarios[props.session?.scenario as string], [props.session?.scenario])

    return (
        <Flex className={"bg-beige rounded-lg p-6"} align={"center"}>
            <Flex gap={"5px"} vertical>
                <h3 className={"text-green-500 m-0"}>{scenario.title}</h3>
                <h2 className={"m-0"}>{props.session?.name}</h2>
                <Flex gap={"10px"}>
                    <CalendarOutlined /><p className={"m-0"}>{DateFormatter.format(new Date(props.session?.startDate || new Date()))}</p>
                </Flex>
            </Flex>
        </Flex>
    )
}