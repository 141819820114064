import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import {Button, Flex, Form, FormInstance} from "antd";
import t from "../../../../configs/i18n";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import Notification from "../../../../shared/services/notification/Notification";
import InputCategory from "./InputCategory";
import React, { useEffect, useRef, useState } from "react";
import MouseFloatingText from "../../../../shared/components/mouse/MouseFloatingText";
import ImageR from "../../../../shared/components/image/ImageR";
import CustomCarousel from "./CustomCarousel";
import { BaseSizeProvider } from "../../../../shared/components/simpleContexts/provider.base.size";

interface Props {
    onValid?: () => void;
    popup?: boolean;
    form: FormInstance;
}

export default function ItStep10PasswordContent(props: Props) {
    const { containerRef } = useGameContext();
    return <BaseSizeProvider containerRef={containerRef}>
        <ItStep10PasswordContentUnwrapped {...props} />
    </BaseSizeProvider>
}


function ItStep10PasswordContentUnwrapped(props: Props) {
    const context = useGameContext();

    const answers = context.step?.description.answers;

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [onHover, setOnHover] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    
    const updateButton = (value: string, id: string) => {
        // Check if all fields have values
        const allFieldsFilled = Object.values(props.form.getFieldsValue() as string[]).every(
            (value) => value !== undefined && value !== ""
        );
        setIsButtonDisabled(!allFieldsFilled); // Disable if not all fields are filled
    };

    const validate = () => {
        const values = Object.values(props.form.getFieldsValue());

        for (const answer of answers) {
            if (!values.includes(answer)) {
                Notification.error(t.sessionGame.wrongAnswers);
                return;
            }
        }

        props.onValid && props.onValid();
    }

    useEffect(() => {
        if (context.solution?.solutionOn) {
            setIsButtonDisabled(false);
        }
    }, [context.solution?.solutionOn]);

    return (
        <Flex className="w-full h-full" vertical>
            <div ref={componentRef}></div>
            {(props.popup === undefined || props.popup) && <CanClosePopup/>}
            <Flex className="w-full h-[90%]">
                <CustomCarousel/>
                <div className="w-[50%] h-full pl-[1%]">
                    <InputCategory title={context.step?.description.category1} min={1} group={1} form={props.form} onChange={updateButton} size={3}/>
                    <InputCategory title={context.step?.description.category2} min={4} group={2} form={props.form} onChange={updateButton} size={2}/>
                    <InputCategory title={context.step?.description.category3} min={6} group={3} form={props.form} onChange={updateButton} size={2}/>
                    <InputCategory title={context.step?.description.category4} min={8} group={4} form={props.form} onChange={updateButton} size={1}/>
                </div>
            </Flex>
            <Button onClick={validate} disabled={isButtonDisabled} className="absolute left-1/2 -translate-x-1/2 bottom-[3%]" type="primary">Valider</Button>
            <MouseFloatingText text={t.common.allFieldsFilled} start={componentRef.current?.getBoundingClientRect()} condition={onHover && isButtonDisabled} />
        </Flex>
    )
}