import {useGameContext} from "../../../pages/session_game/context/context.game";
import ImageRatioBackground from "../../../shared/components/image/ImageRatioBackground";
import InvisibleButton from "../../../shared/components/button/InvisibleButton";
import PasswordModalStep4 from "./components/PasswordModal.step4";
import HintModalStep4 from "./components/HintModal.step4";
import { useEffect, useState } from "react";

export default function ItStep4() {
    const context = useGameContext();
    const [hintModals, setHintModals] = useState<boolean[]>([false, false, false, false, false]);
    const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);
    const hintButtons = [
        {x: 38, y: 59, h: 7, w: 4},
        {x: 65, y: 0, h: 33, w: 23},
        {x: 70, y: 65, h: 35, w: 29},
        {x: 46.5, y: 40, h: 27, w: 11},
        {x: 15.5, y: 77, h: 18, w: 8},
    ];

    const changeModalState = (i: number, newState: boolean) => {
        setHintModals(prevState => prevState?.map((state, j) => i === j ? newState : state));
    }

    return (
        <ImageRatioBackground bgImage={context.step?.description.background}>
            {hintButtons.map((pos, i) => {
                return <>
                    <HintModalStep4 open={hintModals[i]} setOpen={state => changeModalState(i, state)} number={i + 1} />
                    <InvisibleButton onClick={() => changeModalState(i, true)} top={pos.y} left={pos.x} h={pos.h} w={pos.w} showHint={context.showHint} posInPercent/>
                </>
            })}
            <PasswordModalStep4 open={openPasswordModal} setOpen={setOpenPasswordModal} />
            <InvisibleButton onClick={() => setOpenPasswordModal(true)} top={83} left={59.4} h={14} w={10.5} showHint={context.showHint} posInPercent/>

        </ImageRatioBackground>
    )
}