import {Button, Flex} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import QuestionGroupTablet from "./QuestionGroupTablet";
import t from "../../../configs/i18n";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import Question from "../step_quizz/type/Question";
import {useTabletQuizzContext} from "./context/context.tablet.quizz";
import QuestionDescriptionTablet from "./QuestionDescriptionTablet";
import {supabase} from "../../../configs/supabase";
import {subscribe} from "node:diagnostics_channel";

interface Props {
    callbackValidate: (valid: boolean, question: Question) => void;
}

enum State {
    QUESTION,
    WAITING,
    RESULT
}

export default function QuizzTablet(props: Props) {
    const [userAnswers, setUserAnswers] = useState<string[]>([])
    const [state, setState] = useState<State>(State.QUESTION)
    const stepContext = useGameContext();
    const context = useTabletQuizzContext();

    const question = useMemo(() => context.questions[stepContext.team?.subStep || 0], [context.questions, stepContext.team?.subStep]);
    const isValid = () => {
        return question.validAnswers.every(answer => userAnswers.includes(question.answers[answer])) && userAnswers.length === question.validAnswers.length;
    }

    const validate = async () => {
        setState(State.WAITING)
        props.callbackValidate(isValid(), question)
    }

    useEffect(() => {
        setUserAnswers([]);
        setState(State.QUESTION)
    }, [stepContext.team?.subStep])

    useEffect(() => {
        const subscribe = supabase
            .channel("quizz")
            .on("broadcast", { event: "*" }, payload => {
                console.log("BROADCAST", payload)
                setState(State.RESULT)
            })
            .subscribe();

        return () => {
            subscribe.unsubscribe();
        }
    }, []);

    return (
        <Flex align={"center"} className={"text-center"} vertical>
            {state === State.QUESTION && <>
                <h2 className={"text-green-dark"}>Question {(stepContext.team?.subStep || 0) + 1}</h2>
                <QuestionGroupTablet question={question} answers={userAnswers} setAnswers={setUserAnswers}/>
                <Button className={"mt-10"} onClick={validate} size={"large"} type={"primary"}>{t.common.validate}</Button>
            </>}
            {state === State.WAITING && <>
                <h1 className={"text-green-dark"}>En attente des prochaines questions ...</h1>
            </>}
            {state === State.RESULT && <>
                <QuestionDescriptionTablet question={question} validated={isValid()} answers={userAnswers} />
            </>}
        </Flex>
    )
}