import {Button, Flex, Form, notification} from "antd";
import ImageR from "../../../../shared/components/image/ImageR";
import InputWithDescription from "./InputWithDescription";
import Answer from "../../../../shared/services/answer/Answer";
import Notification from "../../../../shared/services/notification/Notification";
import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import t from "../../../../configs/i18n";
import MouseFloatingText from "../../../../shared/components/mouse/MouseFloatingText";
import { useEffect, useRef, useState } from "react";

interface Props {
    onValid?: () => void;
    popup?: boolean;
}


export default function ItStep4PasswordContent(props: Props) {
    const [form] = Form.useForm();
    const context = useGameContext();

    const image : string = context.step?.description.backgroundPassword;
    const answers : string[][] = context.step?.description.answers;

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [onHover, setOnHover] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    
    const updateButton = (value: string, id: string) => {
        // Check if all fields have values
        const allFieldsFilled = Object.values(form.getFieldsValue() as string[]).every(
            (value) => value !== undefined && value !== ""
        );
        setIsButtonDisabled(!allFieldsFilled); // Disable if not all fields are filled
    };

    const validate = () => {
        const isValid = Answer.isCorrectWithOrderArray(form.getFieldsValue(), answers);

        if (!isValid) {
            Notification.error(t.sessionGame.wrongAnswer);
            return;
        }

        props.onValid && props.onValid();
    }

    useEffect(() => {
        if (context.solution?.solutionOn) {
            const correctAnswers = answers.map(answerArray => answerArray[0]);
            const sortedKeys = Object.keys(form.getFieldsValue()).sort();
            const sortedValues = sortedKeys.map((_, i) => correctAnswers[i]);
            const formValues = Object.fromEntries(sortedKeys.map((key, index) => [key, sortedValues[index]]));
            form.setFieldsValue(formValues);
                
            // Update button state
            setIsButtonDisabled(false);
            notification.success({message: t.sessionGame.hints.solutionComplete})
        }
    }, [context.solution?.solutionOn])

    return (
        <div ref={componentRef}>
            {(props.popup === undefined || props.popup) && <CanClosePopup/>}
            <Flex>
                <InputWithDescription pos={{x: 35, y: 235, w: 130}}  imgPos={{x: 10, w: 100, y: -5}}   number={4}  form={form} answers={answers} onChange={updateButton} />
                <InputWithDescription pos={{x: 405, y: 235, w: 160}} imgPos={{x: 15, w: 120, y: 0}}  number={2} form={form} answers={answers} onChange={updateButton} />
                <InputWithDescription pos={{x: 760, y: 235, w: 130}} imgPos={{x: 10, y: -15, w: 90}}  number={3} form={form} answers={answers} onChange={updateButton} />
                <InputWithDescription pos={{x: 190, y: 410, w: 200}} imgPos={{w: 120, x: 35, y:0 }} number={1} form={form} answers={answers} onChange={updateButton} />
                <InputWithDescription pos={{x: 555, y: 410, w: 180}} imgPos={{w: 105, x: 30, y: -10 }} number={5} form={form} answers={answers} onChange={updateButton} />
                <ImageR src={image} width={"900px"} height={"400px"} className={"object-scale-down"}/>
            </Flex>
            <Flex justify={"center"} className="mt-10" align="center">
                <Flex onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
                    <Button onClick={validate} className={"mt-10"} type={"primary"} disabled={isButtonDisabled}>
                        {t.common.validate}
                    </Button>
                </Flex>
            </Flex>
            <MouseFloatingText start={componentRef.current?.getBoundingClientRect()} text={t.common.allFieldsFilled} condition={onHover && isButtonDisabled}/>
        </div>
    )
}