import {Button, Flex, notification} from "antd";
import {CombinationLock} from "../../../shared/components/input/lock/CombinationLock";
import {useState} from "react";
import PopupGoodAnswer from "../../../shared/components/popup/PopupGoodAnswer";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import t from "../../../configs/i18n";
import ItStep1 from "../../sustainable-it/step1/it.step1";

export default function ItTabletStep1() {
    const [codeValid, setCodeValid] = useState(false);
    const [valid, setValid] = useState(false);
    const context = useGameContext();


    const onValid = () => {
        if (codeValid) setValid(true);
        if (!codeValid) notification.error({message: t.sessionGame.wrongAnswer, style: {zIndex: 9999}})
    }

    return (
        <Flex gap={"40px"} className={"w-full h-full"} align={"center"} justify={"center"} vertical>
            {valid && <div className="h-[600px] w-[1000px]">
                <ItStep1 preStatelightOn={true}/>
            </div>}
            {!valid && <>
                    <Flex className={"h-[150px] scale-[1.5]"}>
                        <CombinationLock onUnmatch={() => setCodeValid(false)} onMatch={() => setCodeValid(true)} code={context.step?.description.code} height={80}/>
                    </Flex>
                    <Button className={"mt-8"} onClick={onValid} size={"large"} type={"primary"}>Valider</Button>
                </>
            }
        </Flex>
    )
}