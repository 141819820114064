import EmptyModal from "./EmptyModal";
import InvisibleButton from "../../../../shared/components/button/InvisibleButton";
import {useState} from "react";
import HintModal from "./HintModal";

interface P1ButtonsProps {
    showHint: boolean;
}

export default function P1Buttons({showHint}: P1ButtonsProps) {
    const [emptyModal, setEmptyModal] = useState<boolean>(false);
    const [hintModal, setHintModal] = useState<boolean>(false);



    return (
        <>
            <EmptyModal open={emptyModal} setOpen={setEmptyModal}/>
            <HintModal playerNumber={1} open={hintModal} setOpen={setHintModal} />
            <InvisibleButton showHint={showHint} onClick={() => setHintModal(true)} top={50} left={73} h={193} w={193} />
            <InvisibleButton showHint={showHint} onClick={() => setEmptyModal(true)} top={50} left={706} h={143} w={142} />
            <InvisibleButton showHint={showHint} onClick={() => setEmptyModal(true)} top={216} left={868} h={100} w={100} />
        </>
    )
}