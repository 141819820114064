import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";
import t from "../../../configs/i18n";
import {useNavigate} from "react-router-dom";

export default function useHomeMenu() {
    const navigate = useNavigate();

    const disconnect = async () => {
        const response = await supabase.auth.signOut();

        if (response.error) {
            Notification.error(t.error.disconnect, response.error.message);
            return;
        }

        navigate("/");
    }

    return {
        disconnect
    }
}