import {Flex, Form, Modal} from "antd";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import {useEffect, useState} from "react";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import ItStep10PasswordContent from "./it.step10.pasword.content";
import ImageRatioBackground from "../../../../shared/components/image/ImageRatioBackground";
import { InputValue } from "../../../../shared/models/Team/InputValue";

interface PasswordModalStep4Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}


export default function PasswordModalStep10({open, setOpen}: PasswordModalStep4Props) {
    const context = useGameContext();
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);
    const [form] = Form.useForm();
    const answers = context.step?.description.answers;

    const validate = () => {
        setOpen(false);
        setOpenSuccess(true);
    }

    useEffect(() => {
        if (context.solution?.solutionOn && open) {
            for (const [index, input] of Object.keys(form.getFieldsValue()).entries()) {
                form.setFieldValue(input, answers[index]);
            }
        } else if (context.solution?.solutionOn) {
            setOpen(true);
        }
    }, [context.solution?.solutionOn, open]);

    return (
        <>
            <PopupGoodAnswer currentUser={context.user} open={openSuccess} setOpen={setOpenSuccess} team={context.team} player={context.player} playersTeam={context.playersTeam} />
            <Modal width={"125vh"} open={open} footer={null} onCancel={() => !context.solution?.solutionOn && setOpen(false)} closable={!context.solution?.solutionOn}>
                <Flex className={"h-[70vh] my-5"} vertical>
                    <ItStep10PasswordContent onValid={validate} popup={false} form={form}/>
                </Flex>
            </Modal>
        </>
    )
}