import {Modal} from "antd";
import React from "react";
import ItHintStep10 from "../hint/ItHintStep10";

export interface BaseHintProps {
    number: number
    open: boolean
    setOpen: (open: boolean) => void
}

export default function HintModalStep10({number, open, setOpen}: BaseHintProps) {
    return (
        <Modal open={open} footer={false} onCancel={() => setOpen(false)}>
            <ItHintStep10 number={number}/>
        </Modal>
    )
}