import {Button, Flex, Modal} from "antd";
import {WarningOutlined} from "@ant-design/icons";
import Session from "../../../shared/models/Session/session";
import {TestResult} from "../../../shared/services/test/TestResult";
import TestService from "../../../shared/services/test/TestService";
import Popup from "../../../shared/components/popup/Popup";
import t from "../../../configs/i18n";

interface RessourceTestPopupProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    session: Session | undefined;
    testResult: TestResult | undefined
}

export default function RessourceTestPopup({open, setOpen, session, testResult}: RessourceTestPopupProps) {
    return (
        <Modal destroyOnClose={true} keyboard={false} open={open} maskClosable={false} footer={null} closeIcon={false}>
            <Flex className={"text-center"} align={"center"} justify={"center"} vertical>
                <WarningOutlined className={"mt-6 mb-4 text-5xl text-red-500"}/>
                <h3 className={"text-red-500"}>{t.sessionLogin.problemDetected}</h3>

                {TestService.getErrorMessages(testResult).map((message) => <p>{message}</p>)}
                <h4>{t.sessionLogin.accessLimited}</h4>
                {session?.helpUrl && <Button className={"mb-4"} href={session.helpUrl} target={"_blank"} type={"primary"} danger>{t.sessionLogin.helpUrl}</Button>}
                <Button  size={"small"}  className={"mt-4"} onClick={() => Popup.warning(t.sessionLogin.organisatorHasSolved, () => setOpen(false))}>{t.sessionLogin.organisatorHasSolved}</Button>
            </Flex>
        </Modal>
    )
}