import {Button, Flex} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import AppSpinner from "../../shared/components/spinner/AppSpinner";
import {Navigate, useParams} from "react-router-dom";
import HeaderInGame from "./components/HeaderInGame";
import JitsiVisio from "../../shared/components/jitsi/JitsiVisio";
import WaitingGamemasterPopup from "./components/WaitingGamemasterPopup";
import SyncPopup from "../../shared/components/popup/SyncPopup";
import CameraPopup from "../../shared/components/popup/CameraPopup";
import {WarningOutlined} from "@ant-design/icons";
import t from "../../configs/i18n";
import GameProvider from "./context/provider.game";
import {useGameContext} from "./context/context.game";
import scenarios from "../../scenarios/scenarios";
import { Player } from "../../shared/models/Player/Player";
import CallGamemaster from "../../shared/components/button/CallGamemaster";
import { Role } from "../../shared/models/UserData/Role";
import Notification from "../../shared/services/notification/Notification";
export default function SessionGame() {
    const { id } = useParams();
    
    return (
        <GameProvider playerId={id}>
            <SessionGameUnwrapped />
        </GameProvider>
    )
}

function SessionGameUnwrapped() {
    const [waitingGameMasterPopup, setWaitingGameMasterPopup] = useState<boolean>(false);
    const [GMcaller, setGMcaller] = useState<string>("");
    const context = useGameContext();
    const scenario = useMemo(() => scenarios[context.session?.scenario as string], [context.session?.scenario])

    const accessVisio = () => {
        if (!context.team) {
            window.open(context.session?.helpUrl, '_blank');
            return;
        }
        if (context.team?.visioLink) {
            window.open(context.team.visioLink, '_blank');
        } else {
            Notification.error(t.sessionGame.visio.noVisioLink, t.sessionGame.visio.noVisioLink);
        }
    }

    useEffect(() => {
        if (!context.team) {
            if (context.player?.need_gamemaster) {
                setGMcaller(context.player.getName());
                setWaitingGameMasterPopup(true);
            } else {
                setWaitingGameMasterPopup(false);
            }
            return;
        }
        const caller = context.playersTeam.find((player: Player) => player.need_gamemaster)
        if (caller) {
            setGMcaller(caller.getName());
            setWaitingGameMasterPopup(true);
        } else {
            setWaitingGameMasterPopup(false);
        }
    }, [context.playersTeam, context.team, context.player?.need_gamemaster]);


    if (context.loading) {
        return <AppSpinner tryAgain={true}/>
    }

    return (
        <Flex className={"w-full h-full"} ref={context.containerRef}>
            <CameraPopup visibleByDefault={context.user?.hasRights(Role.GAMEMASTER, context.session?.id) !== true && !scenario.tablet && !scenario.solo && !context.session?.noJitsi} />
            <SyncPopup/>
            <WaitingGamemasterPopup setOpen={setWaitingGameMasterPopup} currentUser={context.user} caller={GMcaller} open={waitingGameMasterPopup}/>
            <Flex className={`w-full ${!scenario.solo ?'h-[640px]':''}`}>
                <Flex className={`w-full ${scenario.solo ? 'h-full bg-[#137866]' : ''}`} vertical>
                    <HeaderInGame currentUser={context.user} currentPlayer={context.player} team={context.team} company={context.company} playersTeam={context.playersTeam} session={context.session} solo={scenario.solo ? true : false} />
                    {context.step && context.step.component}
                    {!context.step &&
                        <Flex align={"center"} justify={"center"} className={`${scenario.solo ? 'h-full' : 'min-w-[1000px] h-[640px]'}`} vertical>
                            <WarningOutlined className={"text-4xl text-red-500"}/>
                            {
                                !context.player?.team && <>
                                    <h1 className="text-center">{t.sessionGame.noTeam}<br/>{t.sessionGame.suggestCalling}</h1><CallGamemaster setOpen={setWaitingGameMasterPopup}/>
                                </>
                            }
                            {context.player?.team && <h1>{t.sessionGame.noStep}</h1>}
                        </Flex>
                    }
                </Flex>
                {!scenario.tablet && !scenario.solo && !context.session?.noJitsi && <JitsiVisio jwt={context.user?.jitsijwt} autoJoin={true} className={"h-full w-full"} name={context.user?.name} mail={context.user?.mail as unknown as string} team={context.team} size={"100%"} height={"640px"} />}
                {context.session?.noJitsi && <Flex className={"h-full w-full bg-beige items-center justify-center"}><Button type="primary" onClick={accessVisio}>{context.team ? t.sessionGame.visio.teamVisio : t.sessionGame.visio.mainVisio }</Button></Flex>}
            </Flex>
        </Flex>
    )
}