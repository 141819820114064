import {supabase} from "../../../configs/supabase";
import delay from "../time/delay";

export default class SyncService {
    private static _syncFunctions: Map<string, () => void> = new Map<string, () => void>();
    private static _onDesync: () => void;
    private static _onSync: () => void;
    private static _stopTryingToSync: boolean = false;
    private static _isSyncing: boolean = false;

    public static setOnDesync(callback: () => void) {
        this._onDesync = callback;
    }

    public static setOnSync(callback: () => void) {
        this._onSync = callback;
    }

    public static register(id: string, syncFunction: () => void) {
        this._syncFunctions.set(id, syncFunction);
    }

    public static async desync() {
        if (this._isSyncing || this._stopTryingToSync) {
            return;
        }
        this._stopTryingToSync = true;
        this._isSyncing = true;

        if (this._onDesync) {
            this._onDesync();
        }

        while (true) {
            await supabase.removeAllChannels();
            for (const syncFunction of this._syncFunctions.values()) {
                syncFunction();
            }

            if (!this._isSyncing) {
                break;
            }

            await delay(5000);
        }

        if (this._onSync) {
            this._onSync();
        }
        this._stopTryingToSync = false;
    }

    public static sync() {
        this._isSyncing = false;
    }
}