import {Flex, Modal} from "antd";
import HintEcoStep5 from "../hint/eco.step.5";

export interface BaseHintProps {
    number: number
    open: boolean
    setOpen: (open: boolean) => void
}

export default function HintModalStep5({number, open, setOpen}: BaseHintProps) {
    const modalStyles = {
        content: {
            backgroundColor: "#C5D4FE"
        }
    }

    return (
        <Modal mask={false} styles={modalStyles} open={open} footer={false} onCancel={() => setOpen(false)}>
            <Flex className={"w-full h-full"} justify={"center"}>
                <HintEcoStep5 number={number} />
            </Flex>
        </Modal>
    )
}