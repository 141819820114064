import React, { useEffect } from "react";
import {Flex} from "antd";
import {Team} from "../../../shared/models/Team/Team";
import {Player} from "../../../shared/models/Player/Player";
import PlayerBox from "../../../shared/components/player/PlayerBox";
import t from "../../../configs/i18n";
import OnlineService from "../../../shared/services/sync/OnlineService";

interface SidebarProps {
    playersTeam: Player[]
    team: Team | undefined,
    player: Player | undefined
}

function Sidebar({playersTeam, team, player} : SidebarProps) {
    const [onlinePlayers, setOnlinePlayers] = React.useState<Set<string>>(new Set<string>())

    useEffect(() => {
        OnlineService.startAndListen(player?.getId() ?? "", setOnlinePlayers)
    }, [])

    return (
        <Flex className={"w-64 bg-beige items-center"} vertical>
            <Flex className={"mt-10 items-center"} vertical>
                <h1 className="text-center text-green-light font-bold text-lg">{t.common.yourTeam}</h1>
                <p className="font-bold text-sm mt-[5px]">{team?.name ? team?.name : t.common.noTeam}</p>
                <Flex justify={"center"} align={"center"} className="h-full" vertical>
                    {playersTeam.map((player) => <PlayerBox popup={false} player={player} online={onlinePlayers.has(player.getId())} key={player.getId()}/>)}
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Sidebar;
