import React from "react";
import Normalize from "../../services/formatter/normalize";
import { Select } from "antd";

interface SearchableSelectProps<T> {
    options: {label: string, value: string}[];
    placeholder: string;
    mode?: "multiple" | "tags" | undefined;
    size?: "large" | "middle" | "small" | undefined;
    value?: T;
    onChange?: (value: T) => Promise<void> | void;
    disabled?: boolean;
}

export default function SearchableSelect<T=string>({options, placeholder, mode, size, value, onChange, disabled}: SearchableSelectProps<T>) {
    const onValidate = (value: T) => {
        if (onChange) {
            onChange(value);
        }
    }

    // Custom filterOption function to search by label
    const filterOption = (input: string, option: any) => {
        const normalizedLabel = Normalize.noAccent(option?.label?.toString() || "");
        return normalizedLabel.toLowerCase().includes(Normalize.noAccent(input).toLowerCase());
    };
    return (
        <Select<T>
            showSearch
            virtual={false}
            options={options}
            placeholder={placeholder}
            filterOption={filterOption}
            mode={mode}
            size={size}
            value={value}
            onChange={onValidate}
            disabled={disabled}
        />
    )
}