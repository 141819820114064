import {Flex} from "antd";
import {JaaSMeeting} from "@jitsi/react-sdk";
import React from "react";

export default function TestJitsi() {
    const configOverwrite = {
        startWithAudioMuted: true,
        startWithVideoMuted: true,
        prejoinConfig: {
            enabled: true
        },
        screenshotCapture: {
            enabled: true,
            mode: 'always',
        },
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
        toolbarButtons: [
            'camera',
            'microphone',
            'chat',
            'tileview',
            'desktop',
            'select-background',
            'settings'
        ]
    };

    return (
        <Flex className={"w-full h-full"}>
            <div id={"thewokies-viso"}>
                <JaaSMeeting
                    appId={"vpaas-magic-cookie-3269c32aa8084d4bab028fb104fe98a1"}
                    roomName={"Test visio"}
                    lang={"fr"}
                    configOverwrite={configOverwrite}
                    getIFrameRef={iframeRef => {
                        iframeRef.style.height = "100vh";
                        iframeRef.style.width = "100vw";
                        iframeRef.style.margin = "0";
                        iframeRef.style.overflow = "hidden";
                    }}
                />
            </div>
        </Flex>
    )
}