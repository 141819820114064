import {supabase} from "../../../configs/supabase";
import {TestResult} from "./TestResult";
import t from "../../../configs/i18n";


export default class TestService {
    public static async start(): Promise<TestResult> {
        const results : TestResult = {
            imageLoaded: false,
            websocketConnected: false,
            jassOk: false,
            cdnJitsiOk: false,
            supabaseOk: false,
            hasError: false
        };

        // Test de l'image
        results.imageLoaded = await new Promise<boolean>((resolve) => {
            const img = new Image();
            img.src = 'https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step1/lock.png';
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
        });

        // Test du WebSocket
        results.websocketConnected = await new Promise<boolean>((resolve) => {
            const ws = new WebSocket('wss://api.thewokies.fr/realtime/v1/websocket?apikey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhsZHlnenZuem5zbGxpbGlkZXJ3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDg2MTEyNjksImV4cCI6MjAyNDE4NzI2OX0.cb6LKhiZDe8S-Qgi7i0RzHj9ImyfwHPNORdUZo-vDvM&vsn=1.0.0');
            ws.onopen = () => {
                resolve(true);
                ws.close();
            };
            ws.onerror = () => resolve(false);
        });

        // Test de l'accès à des ressources sur un autre domaine
        results.jassOk = await fetch('https://8x8.vc/')
            .then(() => true)
            .catch(() => false);

        results.cdnJitsiOk = await fetch('https://web-cdn.jitsi.net/')
            .then(() => true)
            .catch(() => false);

        // Test de Supabase
        const response = await supabase.from("test").select("*").eq("id", 1);
        results.supabaseOk = !(response.error || response.data.length === 0 || response.data.at(0).value !== "working");

        results.hasError = !results.imageLoaded || !results.websocketConnected || !results.jassOk || !results.cdnJitsiOk || !results.supabaseOk;

        return results;
    }

    public static getErrorMessages(results: TestResult | undefined): string[] {
        const messages: string[] = [];
        if (!results?.imageLoaded) {
            messages.push(t.test.api);
        }
        if (!results?.websocketConnected) {
            messages.push(t.test.websocket);
        }
        if (!results?.jassOk) {
            messages.push(t.test.jass);
        }
        if (!results?.cdnJitsiOk) {
            messages.push(t.test.jitsi);
        }
        if (!results?.supabaseOk) {
            messages.push(t.test.supabase);
        }
        return messages;
    }
}