import { Button, Flex } from "antd";
import { Modal } from "antd";
import { useGameContext } from "../../../../pages/session_game/context/context.game";
import t from "../../../../configs/i18n";

export default function DetailsModal(props: {
    open: boolean,
    setOpen: (open: boolean) => void,
    number: number
}) {
    const context = useGameContext();
    if (!context.step?.description.categoryDescriptions) return <></>;
    return <Modal open={props.open} footer={null} closable={false}>
        <Flex justify="center" align="center" vertical>
            <h2 className="text-green-dark w-full text-center text-3xl">{t.sessionGame.game.toRemember}</h2>
            <p>{context.step?.description.categoryDescriptions[props.number - 1]}</p>
            <Button type="primary" className="text-center mt-4" onClick={() => props.setOpen(false)}>{t.common.noted}</Button>
        </Flex>
    </Modal>
}