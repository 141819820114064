import InvisibleButton from "../../../../shared/components/button/InvisibleButton";
import {useState} from "react";
import PasswordModalStep7 from "./PasswordModal.step7";
import StepProps from "../../../types/StepProps";
import HintModalStep7 from "./HintModal.step7";
import Hint2ModalStep7 from "./HintModal2.step7";

interface Props {
    showHint: boolean;
    playerNumber: number;
    stepContext: StepProps;
    password: {x: number, y: number, h: number, w: number};
    hint1: {x: number, y: number, h: number, w: number};
    hint2: {x: number, y: number, h: number, w: number};
}

export default function ButtonsStep7({playerNumber, showHint, stepContext, password, hint1, hint2}: Props) {
    const [hintModal, setHintModal] = useState<boolean>(false);
    const [hint2Modal, setHint2Modal] = useState<boolean>(false);

    const [openPasswordModal, setOpenPasswordModal] = useState(false);



    return (
        <>
            <HintModalStep7 open={hintModal} setOpen={setHintModal} number={playerNumber}/>
            <Hint2ModalStep7 open={hint2Modal} setOpen={setHint2Modal} number={playerNumber}/>

            <PasswordModalStep7 stepContext={stepContext} open={openPasswordModal} setOpen={setOpenPasswordModal} />


            {/* Main Hint Button */}
            <InvisibleButton onClick={() => setHintModal(true)} top={hint1.y} left={hint1.x} h={hint1.h} w={hint1.w} showHint={showHint}/>
            {/* Secondary Hint Button */}
            <InvisibleButton onClick={() => setHint2Modal(true)} top={hint2.y} left={hint2.x} h={hint2.h} w={hint2.w} showHint={showHint}/>

            {/* Password Modal */}
            <InvisibleButton onClick={() => setOpenPasswordModal(true)} top={password.y} left={password.x} h={password.h} w={password.w} showHint={showHint}/>
        </>
    )
}