import StepProps from "../../types/StepProps";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import ImageRatioBackground from "../../../shared/components/image/ImageRatioBackground";
import PasswordModalStep8 from "./components/PasswordModal.step8";
import {useState} from "react";
import InvisibleButton from "../../../shared/components/button/InvisibleButton";
import HintModal from "./components/HintModal";
export default function ItStep8() {
    const context = useGameContext();
    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }
    const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
    const [definitionsModalOpen, setDefinitionsModalOpen] = useState<boolean[]>([false, false, false, false]);
    const defPositions = [
        {top: 4.7, left: 43.55, h: 31.7, w: 18.7},
        {top: 48.7, left: 45.25, h: 14, w: 8.4},
        {top: 58, left: 75, h: 7, w: 6},
        {top: 42, left: 27, h: 21, w: 9}
    ]

    const openDefinitionsModal = (index: number, open: boolean) => {
        setDefinitionsModalOpen(prev => prev.map((_, i) => i === index ? open : prev[i]));
    }

    return (
        <ImageRatioBackground bgImage={context.step?.description.background}>
            {defPositions.map((pos, index) => (
                <>
                    <InvisibleButton onClick={() => openDefinitionsModal(index, true)} top={pos.top} left={pos.left} h={pos.h} w={pos.w} posInPercent />
                    <HintModal number={index + 1} open={definitionsModalOpen[index]} setOpen={(open) => openDefinitionsModal(index, open)} />
                </>
            ))}
            <InvisibleButton onClick={() => setPasswordModalOpen(true)} top={56} left={85} h={11} w={9} posInPercent/>
            <PasswordModalStep8 open={passwordModalOpen} setOpen={setPasswordModalOpen} stepContext={stepContext}/>
        </ImageRatioBackground>
    )
}