import StepProps from "../../types/StepProps";
import {Flex} from "antd";

import ImageR from "../../../shared/components/image/ImageR";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import ButtonsStep7 from "./components/buttonsStep7";

export default function EcoStep7() {
    const context = useGameContext();
    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }
    const backgroundUrl = `${context.step?.description.background}${stepContext.player?.number}.png`;

    return (
        <Flex align={"flex-start"} className={"w-[1000px] h-[640px] relative"}>
            <ImageR src={backgroundUrl} width={"1000px"} height={"600px"} className={"object-scale-down"}/>
            {stepContext.player?.number === 1 && <ButtonsStep7 hint1={{x: 250, y: 90, h: 60, w: 230}} hint2={{x: 900, y: 120, h: 50, w: 80}} password={{x: 535, y: 485, h: 60, w: 60}} stepContext={stepContext} showHint={stepContext.showHint} playerNumber={1}/>}
            {stepContext.player?.number === 2 && <ButtonsStep7 hint1={{x: 510, y: 110, h: 60, w: 230}} hint2={{x: 20, y: 122, h: 50, w: 80}} password={{x: 350, y: 485, h: 60, w: 60}} stepContext={stepContext} showHint={stepContext.showHint} playerNumber={2}/>}
            {stepContext.player?.number === 3 && <ButtonsStep7 hint1={{x: 510, y: 110, h: 60, w: 230}} hint2={{x: 900, y: 120, h: 50, w: 80}} password={{x: 380, y: 485, h: 60, w: 60}} stepContext={stepContext} showHint={stepContext.showHint} playerNumber={3}/>}
            {stepContext.player?.number === 4 && <ButtonsStep7 hint1={{x: 270, y: 110, h: 60, w: 230}} hint2={{x: 20, y: 122, h: 50, w: 80}} password={{x: 510, y: 440, h: 60, w: 60}} stepContext={stepContext} showHint={stepContext.showHint} playerNumber={4}/>}
        </Flex>
    )
}