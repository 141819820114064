import {Flex} from "antd";
import Quizz from "./Quizz";
import Question from "./type/Question";
import {useEffect, useMemo, useState} from "react";
import WaitingForPlayers from "../../../shared/components/popup/WaitingForPlayers";
import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";
import t from "../../../configs/i18n";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import { Role } from "../../../shared/models/UserData/Role";
import scenarios from "../../scenarios";

interface StepQuizzProps {
    preComponent?: JSX.Element;
}

export default function StepQuizz({preComponent} : StepQuizzProps) {
    const [waitingSubStep, setWaitingSubStep] = useState<boolean>(false);
    const stepContext = useGameContext();
    const questions : Question[] = stepContext.step?.description.questions;
    const context = useGameContext();
    const maxSubStep = useMemo(() => context.step?.description.questions.length - 1, [context.step?.description.questions.length]);
    const isSolo = useMemo(() => scenarios[context.session?.scenario ?? ''].solo, [context.session?.scenario]);

    const playersToWait = useMemo(() => {
        return stepContext.playersTeam;
    }, [stepContext.playersTeam])


    const playerAnswering = useMemo(() => {
        if (!stepContext.team?.playerToAnswer) {
            return stepContext.playersTeam.sort((a, b) => (a.number || 0)  - (b.number || 0)).at(0);
        }

        const playerToAnswer = stepContext.playersTeam.find(player => player.playerId === stepContext.team?.playerToAnswer);
        if (!playerToAnswer) {
            return stepContext.playersTeam.sort((a, b) => (a.number || 0)  - (b.number || 0)).at(0);
        }

        return playerToAnswer
    }, [stepContext.playersTeam, stepContext.team?.playerToAnswer])

    const validatedSubStep = async () => {
        if (!context.session?.scenario || scenarios[context.session.scenario].solo || scenarios[context.session.scenario].tablet) {
            if ((stepContext.team?.subStep ?? -1) + 1 < questions.length) {
                const response = await supabase
                    .from("team")
                    .update({
                        sub_step: (stepContext.team?.subStep ?? -1) + 1
                    })
                    .eq("id", stepContext.team?.id)
                if (response.error) {
                    Notification.error(t.error.validateStep, response.error);
                    return;
                }
            } else if (stepContext.team?.step) {
                const response = await supabase
                    .rpc("update_team", {
                        team_id: stepContext.team?.id,
                        new_step_number: stepContext.team.step + 1
                    })

                if (response.error) {
                    Notification.error(t.error.validateStep, response.error);
                    return;
                }
            }
            return;
        }
        if (!stepContext.user?.hasRights(Role.GAMEMASTER, context.session?.id)) {
            const response = await supabase
                .from("player")
                .update({
                    step_validated: true
                })
                .eq("id", stepContext.player?.playerId)

            if (response.error) {
                Notification.error(t.error.validateStep, response.error);
                return;
            }
        }

        setWaitingSubStep(true);
    }

    useEffect(() => {
        setWaitingSubStep((stepContext.player?.stepValidated ?? false) && !context.user?.hasRights(Role.GAMEMASTER, context.session?.id));
    }, [stepContext.team?.subStep, stepContext.team?.step, stepContext.player?.stepValidated])

    useEffect(() => {
        if (!context.session?.scenario || scenarios[context.session.scenario].solo || scenarios[context.session.scenario].tablet) return;
        if (stepContext.team?.subStep === null || stepContext.team?.subStep === undefined) {
            (async () => {
                if (!stepContext.team?.id) {
                    Notification.error(t.error.team);
                    return;
                }
                const response = await supabase.rpc("start_sub_step", {
                    team_id: stepContext.team.id,
                    new_max_sub_step: maxSubStep ?? 0
                })

                if (response.error) {
                    Notification.error(t.sessionGame.waitingForPlayers.error, response.error);
                }
            })()
        }
    }, [stepContext.team?.subStep])

    return (
        <Flex justify={"center"} className={`w-full ${isSolo ? 'h-full bg-beige' : 'min-w-[1000px] h-[640px]'}`} vertical>
            <WaitingForPlayers currentUser={stepContext.user} team={stepContext.team} playersTeam={playersToWait} open={waitingSubStep} setOpen={setWaitingSubStep}/>
            {(stepContext.team?.subStep === null || (stepContext.team?.subStep !== undefined && stepContext.team?.subStep <= -1)) && preComponent}
            {stepContext.team?.subStep !== null && stepContext.team?.subStep !== undefined && stepContext.team?.subStep > -1 && <>
                {stepContext.team?.subStep < questions.length &&
                    <Quizz playerAnswering={playerAnswering} callbackValidate={validatedSubStep} question={questions[stepContext.team?.subStep]} />
                }
                {stepContext.team?.subStep >= questions.length &&
                    <Flex justify={"center"} align={"center"} className={"min-w-[1000px] h-full"}>
                        <h1>{t.sessionGame.thisQuestionNotExists}</h1>
                    </Flex>
                }
            </>}
        </Flex>
    )
}