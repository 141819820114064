import {Company} from "../Company/company";
import {UserData} from "../UserData/UserData";
import {supabase} from "../../../configs/supabase";
import {ErrorCode} from "../../services/const/ErrorCode";
import Notification from "../../services/notification/Notification";
import {Gamemaster} from "./gamemaster";
import t from "../../../configs/i18n";
import {SessionState} from "./sessionState";
import { Role } from "../UserData/Role";
import { notification } from "antd";
import { Dispatch, SetStateAction } from "react";
import scenarios from "../../../scenarios/scenarios";

export default class Session {
    id: string;
    name: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    startGameDate: Date | undefined;
    gamemasters: Gamemaster[];
    maingamemaster: UserData;
    company: Company;
    organiser: UserData | undefined;
    nbPlayers: number;
    state: SessionState;
    subState: number | undefined;
    helpUrl: string;
    scenario: string;
    teamAutoDistribution: boolean;
    additionnals: {
        players: {
            firstName: string;
            lastName: string;
            mail: string;
        }[];
    } | undefined;
    photo: string | undefined;
    noJitsi: boolean;

    constructor(obj: any) {
        this.id = obj.id;
        this.name = obj.name;
        this.startDate = obj.start_date? new Date(obj.start_date): undefined;
        this.endDate = obj.end_date? new Date(obj.end_date): undefined;
        this.startGameDate = obj.start_game_date? new Date(obj.start_game_date): undefined;
        this.maingamemaster = obj.main_gamemaster;
        this.company = obj.company;
        this.organiser = undefined;
        this.nbPlayers = obj.nb_players;
        this.state = obj.state;
        this.subState = obj.sub_state;
        this.helpUrl = obj.help_url;
        this.scenario = obj.scenario;
        this.teamAutoDistribution = obj.team_auto_distribution;
        this.additionnals = obj.additionnals;
        this.photo = obj.photo;
        this.noJitsi = obj.no_jitsi;
        this.gamemasters = obj.gamemasters || [];
    }

    private copy() : Session {
        let res = new Session(this);
        res.startDate = this.startDate;
        res.endDate = this.endDate;
        res.startGameDate = this.startGameDate;
        res.maingamemaster = this.maingamemaster;
        res.nbPlayers = this.nbPlayers;
        res.helpUrl = this.helpUrl;
        res.teamAutoDistribution = this.teamAutoDistribution;
        res.subState = this.subState;
        res.noJitsi = this.noJitsi;
        return res;
    }

    public getNumberGamemasters() : number {
        if (!this.gamemasters) {
            return 0;
        }

        return this.gamemasters.length;
    }

    public static async newSession(obj: any) : Promise<Session | undefined> {
        if (!obj) {
            return undefined;
        }
        const session: Session = new Session(obj);
        await session.updateGamemasters();
        await session.updateOrganiser(obj.organiser);
        return session;
    }

    public async updateGamemasters() : Promise<void> {
        this.gamemasters = (await this.getGamemasters()) || [];
    }

    public async getGamemasters() : Promise<Gamemaster[] | undefined> {
        const {data, error} = await supabase
            .from("role")
            .select("userdata(*)")
            .eq("session", this.id)
            .eq("type", Role.GAMEMASTER);

        if (error) {
            Notification.error(t.error.session, error);
            return;
        }

        const gamemasters: Gamemaster[] = [];
        for (const row of data) {
            const gm: UserData | undefined = await UserData.newUserData(row.userdata);
            if (gm) {
                gamemasters.push(gm.mapToGamemaster());
            }
        };
        return gamemasters;
    }


    public async updateOrganiser(o: any) : Promise<void> {
        if (!o) {
            return;
        }
        this.organiser = await UserData.newUserData(o, true);
    }

    public static newSessions(obj: any[]) : Session[] {
        return obj.map((session: any) => new Session(session));
    }

    public static isFinalState(state: SessionState | undefined) : boolean {
        return state === SessionState.ENDED || state === SessionState.FINAL || state === SessionState.SURVEY || state === SessionState.RANK_1 || state === SessionState.RANK_2 || state === SessionState.RANK_3;
    }

    public isCommonRoomState() : boolean {
        return this.state === SessionState.OPEN || this.state === SessionState.VIDEO || this.state === SessionState.RULES;
    }

    public static inGame(state: SessionState | undefined) : boolean {
        return state === SessionState.IN_GAME || state === SessionState.QUIZZ;
    }

    public canJoin() : boolean {
        return (Session.inGame(this.state) || this.isCommonRoomState() || Session.isFinalState(this.state)) && this.state !== SessionState.ENDED;
    }

    public canJoinExclusively() : boolean {
        return this.canJoin() && this.state !== SessionState.OPEN;
    }

    public static async byId(id: string | undefined, columns: string) : Promise<Session | undefined> {
        if (!id) {
            Notification.error(t.error.session, ErrorCode.EMPTY_VALUE);
            return undefined;
        }

        const query = await supabase
            .from("session")
            .select(columns)
            .eq("id", id);

        if (query.error) {
            Notification.error(t.error.session, query.error);
            return undefined;
        }

        return Session.newSession(query.data?.at(0));
    }

    public listen(setSession: Dispatch<SetStateAction<Session | undefined>>, callbackError? : () => void, callbackOk?: () => void) : void {
        supabase
            .channel(`session-${this.id}`)
            .on("postgres_changes",
            {
                event: 'UPDATE',
                schema: 'public',
                table: "session",
                filter: `id=eq.${this.id}`,
            }, (payload) => {
                const updateSession = async () => {
                    setSession(await Session.newSession(payload.new));
                }
                updateSession();
            }).subscribe((status, err) => {
                if (err) {
                    Notification.error(t.error.subscription, JSON.stringify(err));
                    callbackError && callbackError()
                }
                else if (status === "CHANNEL_ERROR" || status === "TIMED_OUT") {
                    callbackError && callbackError()
                } else if (status === "SUBSCRIBED"){
                    callbackOk && callbackOk()
                }
        });

        supabase
            .channel(`session-${this.id}-roles`)
            .on("postgres_changes",
            {
                event: '*',
                schema: 'public',
                table: "role",
            }, (payload) => {
                (async () => {
                    const gms = await this.getGamemasters();
                    setSession((prevSession) => {
                        console.log('DEBUG', prevSession?.maingamemaster)
                        if (!prevSession) {
                            return prevSession;
                        }
                        prevSession.gamemasters = gms || [];
                        console.log("Gamemasters updated", prevSession.gamemasters);
                        return prevSession.copy();
                    });
                })();
            }).subscribe((status, err) => {
                if (err) {
                    Notification.error(t.error.subscription, JSON.stringify(err));
                    callbackError && callbackError()
                }
                else if (status === "CHANNEL_ERROR" || status === "TIMED_OUT") {
                    callbackError && callbackError()
                } else if (status === "SUBSCRIBED"){
                    callbackOk && callbackOk()
                }
            });
    }

    public async updateGamemastersDB(gamemasterIds: string[]) : Promise<void> {
        try {
            const { data, error } = await supabase
                .from("role")
                .select("id, userdata(*)")
                .eq("session", this.id)
                .eq("type", Role.GAMEMASTER);
            
            if (error) {
                throw error;
            }

            data?.filter((role: any) => !gamemasterIds.includes(role.userdata.uid)).forEach(async (role: any) => {
                const {error} = await supabase
                    .from("role")
                    .delete()
                    .eq("id", role.id)
                    .eq("session", this.id);
                    
                if (error) {
                    throw error;
                }
            });

            const ids: string[] = data?.map((role: any) => role.userdata.uid) || [];
            gamemasterIds.filter((gmId: string) => !ids.includes(gmId)).forEach(async (gmId: string) => {
                const {error} = await supabase
                    .from("role")
                    .insert([{session: this.id, userdata: gmId, type: Role.GAMEMASTER}]);

                if (error) {
                    throw error;
                }
            });
            notification.success({
                message: "Gamemaster modifié avec succès",
                placement: "topRight",
            });
        } catch (e) {
            console.error("Erreur lors de la modification des gamemasters.", e)
            Notification.error("Erreur lors de la modification du gamemaster.", e)
        }
    }

    public getRuleVideo() : string {
        const rules = scenarios[this.scenario || ""].rules;
        
        if (typeof rules === "string") {
            return rules;
        } else if ((typeof rules[0]) === "string") {
            return this.subState !== undefined ? rules[this.subState] : "";
        }
        
        return "";
    }

    public isMaxVideo() : boolean {
        const rules = scenarios[this.scenario || ""].rules;
        
        if (typeof rules === "string") {
            return true;
        } else if ((typeof rules[0]) === "string" && this.subState !== undefined) {
            return this.subState >= rules.length - 1;
        }

        return true;
    }
}
