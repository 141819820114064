import {Button, Flex, Modal} from "antd";
import { useEffect, useMemo, useState } from "react";
import t from "../../../../configs/i18n";
import { useGameContext } from "../../context/context.game";
import { InfoCircleOutlined, LeftCircleFilled, LockOutlined, RightCircleFilled } from "@ant-design/icons";
import PopupConfirmation from "../../../../shared/components/popup/PopupConfirmation";
import { supabase } from "../../../../configs/supabase";
import Notification from "../../../../shared/services/notification/Notification";
import { BaseSizeProvider, useBaseSize } from "../../../../shared/components/simpleContexts/provider.base.size";
import Const from "../../../../shared/services/const/Const";

interface HintPopupProps {
    open: boolean;
    setOpen: (value: boolean) => void;
}

export default function HintPopup({open, setOpen}: HintPopupProps) {
    const context = useGameContext();
    return <BaseSizeProvider containerRef={context.containerRef}>
        <HintPopupUnwrapped open={open} setOpen={setOpen}/>
    </BaseSizeProvider>
}

function HintPopupUnwrapped({open, setOpen}: HintPopupProps) {
    const context = useGameContext();
    const {baseSize} = useBaseSize();
    const newHint: number = useMemo(() => context.team?.subStep ?? 0, [context.team?.subStep]);
    const colors = [
        "bg-[#FFC466] hover:bg-[#D89848] text-white",
        "bg-[#F16B62] hover:bg-[#CE3B3B] text-white",
        "bg-green-light hover:bg-green-dark text-white",
    ]

    

    return (
        <Modal open={open} onCancel={() => setOpen(false)} footer={false} width={`${baseSize * 60}px`} zIndex={Const.HINT_POPUP_ZINDEX}>
            <Flex gap={"middle"} className={"mb-4"} align={"center"} justify={"center"} vertical>
                <h2 className={"text-green-dark"} style={{marginBottom: -baseSize * 0.3, fontSize: baseSize * 3 + 1, marginTop: baseSize * 0.5}}>{t.sessionGame.hints.yourhints}</h2>
                <div className="italic font-extralight text-center" style={{fontSize: baseSize * 0.7 + 1.5, marginBottom: baseSize * 0.5, marginTop: -baseSize * 0.5}}>
                    {t.sessionGame.hints.penalty}
                    <br/>
                    {t.sessionGame.hints.order}
                </div>
                <Flex gap={"middle"} justify={"center"}>    
                    {context.step?.description.hints.map((hint: string, i: number) => 
                        <HintBox type={i === newHint ? "next" : i < newHint ? "unlocked" : "locked"} colors={colors} i={i} key={i} setOpen={setOpen}/>
                    )}
                </Flex>
                <SolutionBox condition={context.step?.description && newHint >= context.step.description.hints.length} setOpen={setOpen} />
                <div style={{fontSize: baseSize * 1.12, marginTop: baseSize * 1}}><InfoCircleOutlined/> {t.sessionGame.hints.answerCondition}</div>
            </Flex>
        </Modal>
    )
}

interface HintBoxProps {
    type: "unlocked" | "next" | "locked";
    colors: string[];
    i: number;
    setOpen: (open: boolean) => void;
}

function HintBox({type, colors, i, setOpen}: HintBoxProps) {
    const [hover, setHover] = useState<boolean>(false)
    const [openedHint, setOpenHint] = useState<boolean>(false);
    const [openedConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const context = useGameContext()
    const {baseSize} = useBaseSize();
    const greyHover = useMemo(() => hover && type === "next", [hover, type])
    const color = useMemo(() => type === "unlocked" ? colors[i] : greyHover ? "bg-gray-500 text-white" : "text-[#253570] border-2 border-[#253570] border-solid justify-center", [type, hover])

    const open = () => {
        if (!hover) return;
        if (type === "next") {
            setHover(false)
            setOpenConfirmation(true)
        } else if (type === "unlocked") {
            setHover(false)
            setOpen(false)
            setOpenHint(true)
        }
    }

    const onConfirm = async () => {
        if (!context.team) {
            Notification.error(t.error.team)
            return;
        }
        const {error} = await supabase.from("team").update({sub_step: (context.team?.subStep ?? 0) + 1, bonus: context.team?.bonus - 30}).eq("id", context.team.id);

        if (error) {
            Notification.error(t.error.common)
            return;
        }
        setOpen(false)
        setOpenConfirmation(false)
        setOpenHint(true)
    }

    const onUnconfirm = () => {
        setOpenConfirmation(false)
    }

    return <Flex
            onClick={open}
            align={"center"}
            style={{
                fontSize: `${baseSize * 0.9}px`,
                width: `${baseSize * 16}px`,
                height: `${baseSize * 12}px`,
                paddingLeft: `${baseSize * 0.6}px`,
                paddingRight: `${baseSize * 0.6}px`,
                paddingTop: `${baseSize * 0.7}px`
            }}
            className={`justify-around rounded-lg cursor-pointer text-center ${color}`}
            onMouseOver={() => !hover && setHover(!openedConfirmation && !openedHint)}
            onMouseLeave={() => setHover(false)}
            vertical
        >
            <HintModal num={i} open={openedHint} setOpen={setOpenHint} />
            <PopupConfirmation open={openedConfirmation} setOpen={setOpenConfirmation} onOk={onConfirm} onExitOrNo={onUnconfirm} zIndex={Const.HINT_POPUP_ZINDEX + 1} content={
                <>
                    <h2 className={"mt-12 text-green-light -mb-2"}>{t.sessionGame.hints.confirmationQuestion}</h2>
                    <h4 className="font-normal">{t.sessionGame.hints.confirmationInfo}</h4>
                </>
            }/>
            {type !== "unlocked" && !greyHover && <LockOutlined style={{fontSize: `${baseSize * 2.5}px`}} />}
            <div>
                <div style={{fontSize: `${baseSize * 2.5}px`}} className="font-bold">
                    {`${t.sessionGame.hints.hint} ${i + 1}`}
                </div>
                {type !== "unlocked" && !greyHover && <div style={{fontSize: `${baseSize * 1}px`}}>-30s</div>}
            </div>
            {(type === "unlocked" || greyHover) && <p style={{fontSize: `${baseSize * 1}px`}}>
                {type === "unlocked" ? t.sessionGame.hints.click : t.sessionGame.hints.unlock}
            </p>}
    </Flex>
}

function SolutionBox(
    props: {
        condition: boolean,
        setOpen: (value: boolean) => void
    }
) {
    const context = useGameContext();
    const {baseSize} = useBaseSize();
    const answerColor = useMemo(() => !props.condition ? "bg-gray-400 text-white" : "text-[#253570] border-2 border-[#253570] border-solid", [context?.step?.description, props.condition])
    const [solutionOpened, openSolution] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);

    const clickSolution = async () => {
        if (context.team?.bonus !== undefined && !context.solution?.solutionOn) {
            context.solution?.triggerSolution();
            const {error} = await supabase.from("team").update({bonus: context.team.bonus - (context.step?.description.solution.penalty ?? 0)}).eq("id", context.team.id)

            if (error) {
                Notification.error(t.error.chrono)
            }
            
            props.setOpen(false)
            openSolution(false)
        }
    }
    const open = () => {
        if (!props.condition) return;
        if (!hover) return;
        setHover(false)
        openSolution(true)
    }

    return (
        <Flex
            onClick={open}
            style={{
                fontSize: `${baseSize * 0.85}px`,
                width: `${baseSize * 30}px`,
                paddingLeft: `${baseSize * 0.6}px`,
                paddingRight: `${baseSize * 0.6}px`,
                marginTop: `${baseSize * 1.8}px`
            }}
            className={`justify-around rounded-lg cursor-pointer text-center ${answerColor}`}
            onMouseOver={() => !hover && setHover(!solutionOpened)}
            onMouseLeave={() => setHover(false)}
        >
            <PopupConfirmation open={solutionOpened} setOpen={openSolution} onOk={clickSolution} onExitOrNo={() => openSolution(false)} zIndex={Const.HINT_POPUP_ZINDEX + 1} content={
                <>
                    <h2 className={"text-green-light"} style={{marginBottom: -baseSize * 0.3, marginTop: baseSize * 1.2}}>{t.sessionGame.hints.confirmationSolution}</h2>
                    <h4 className="font-normal">{`${t.sessionGame.hints.confirmationInfo1}${context.step?.description.solution.penalty}${t.sessionGame.hints.confirmationInfo2}`}</h4>
                </>
            }/>
            <LockOutlined style={{fontSize: `${baseSize * 2.7}px`}}/>
            <Flex vertical>
                <p>
                    <div style={{fontSize: `${baseSize * 1.4}px`}} className="font-bold">
                        {t.sessionGame.hints.answer}
                    </div>
                    {`-${context.step?.description.solution.penalty}s`}
                </p>
            </Flex>
        </Flex>
    )
}

function HintModal(
    props: {
        num: number,
        open: boolean,
        setOpen: (state: boolean) => void
    }
) {
    const context = useGameContext();
    const [num, setNum] = useState<number>(props.num);
    const {baseSize} = useBaseSize();

    useEffect(() => {
        setNum(props.num)
    }, [props.num, props.open])


    return <Modal open={props.open} onCancel={() => props.setOpen(false)} footer={false} width={`${baseSize * 60}px`} className="text-center text-green-500" zIndex={Const.HINT_POPUP_ZINDEX + 1}>
        <Flex justify="center" align="center">
            {Array.from({length: context.team?.subStep ?? 0}).map((_, i) => i === num ?
                <h3 style={{fontSize: baseSize * 2.7}} className="mx-[2%] underline">{`${t.sessionGame.hints.hint} ${i + 1}`}</h3>
                :
                <h3 style={{fontSize: baseSize * 1.8}} onClick={() => setNum(i)} className="mx-[2%] font-light text-gray-500 cursor-pointer">{`${t.sessionGame.hints.hint} ${i + 1}`}</h3>
            )}
        </Flex>
        <p className="w-full text-black" style={{
            fontSize: baseSize * 1.2,
            marginBottom: baseSize * 2
        }}>{context?.step?.description.hints[num]?.split('<br/>').map((line: string, index: number) => (
            <div key={index}>
                {line}
                {index < context?.step?.description.hints[num].split('<br/>').length - 1 && <br />}
            </div>
        ))}</p>
        <Button type="primary" className="text-center" onClick={() => props.setOpen(false)} style={{fontSize: baseSize * 1, height: `${baseSize * 3}px`}}>{t.common.noted}</Button>
    </Modal>


}