import {Button, Flex, Form, Modal} from "antd";
import StepProps from "../../../types/StepProps";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import React, {useState} from "react";
import InputCategoryStep9 from "./InputCategoryStep9";
import Notification from "../../../../shared/services/notification/Notification";
import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import t from "../../../../configs/i18n";

interface PasswordModalStep4Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    stepContext: StepProps;
}


export default function PasswordModalStep9({open, setOpen, stepContext}: PasswordModalStep4Props) {
    const context = useGameContext();
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);
    const [form] = Form.useForm();

    const answers = context.step?.description.answers;
    const descriptions = context.step?.description.descriptions;


    const validate = () => {
        const values = Object.values(form.getFieldsValue());

        for (const answer of answers) {
            if (!values.includes(answer)) {
                Notification.error(t.sessionGame.wrongAnswers);
                return;
            }
        }

        setOpen(false);
        setOpenSuccess(true);
    }


    return (
        <>
            <PopupGoodAnswer currentUser={stepContext.currentUser} open={openSuccess} setOpen={setOpenSuccess} team={stepContext.team} player={stepContext.player} playersTeam={stepContext.playersTeam} />
            <Modal width={800} open={open} footer={null} onCancel={() => setOpen(false)}>
                <CanClosePopup/>
                <Flex className={"h-[400px] overflow-y-auto mt-8 border-solid border-green-dark rounded-xl p-8"} vertical>
                    <InputCategoryStep9 title={context.step?.description.category1} min={1} group={1} answers={answers} descriptions={descriptions}
                                        stepContext={stepContext} form={form}/>
                    <InputCategoryStep9 title={context.step?.description.category2} min={5} group={2} answers={answers} descriptions={descriptions}
                                        stepContext={stepContext} form={form}/>
                    <InputCategoryStep9 title={context.step?.description.category3} min={9} group={3} answers={answers} descriptions={descriptions}
                                        stepContext={stepContext} form={form}/>
                    <InputCategoryStep9 title={context.step?.description.category4} min={13} group={4} answers={answers} descriptions={descriptions}
                                        stepContext={stepContext} form={form}/>
                </Flex>
                <Flex justify={"center"}>
                    <Button onClick={validate} className={"mt-10"} type={"primary"}>{t.common.validate}</Button>
                </Flex>
            </Modal>
        </>
    )
}