import {Button, Flex, Form, Input, InputNumber, Modal, notification, Select} from "antd";
import React, {useEffect, useState} from "react";
import {Player} from "../../models/Player/Player";
import {Team} from "../../models/Team/Team";
import {supabase} from "../../../configs/supabase";
import Notification from "../../services/notification/Notification";
import Popup from "../popup/Popup";

interface PlayerInfoPopupProps {
    player : Player | undefined
    teams : Team[] | undefined
    open: boolean
    setOpen : (open : boolean) => void
}

export default function PlayerInfoPopup({player, teams, open, setOpen} : PlayerInfoPopupProps) {
    const [form] = Form.useForm()
    const [teamOptions, setTeamOptions] = useState<{label: string, value: string}[]>();

    useEffect(() => {
        if (!open) {
            return;
        }
        setTeamOptions(teams?.map(t => ({label: t.name, value: t.id})))

        form.setFieldsValue({
            name: player?.getName(),
            team: player?.team,
            number: player?.number
        })
    }, [teams, open, player, form])


    const onOk = async () => {
        let player_number = form.getFieldValue("number");
        if (player_number === 5) {
            player_number = 4;
        }

        const updatePlayer = await supabase
            .rpc("update_player", {
                player_id: player?.playerId,
                team_uuid: form.getFieldValue("team")?? null,
                player_number: player_number,
                new_name: null // TODO : first_name + last_name
            });

        if (updatePlayer.error) {
            Notification.error("Erreur lors de l'update d'un joueur", updatePlayer.error);
            return;
        }

        if (updatePlayer.data !== null) {
            Notification.error(`Conflit avec le joueur ${updatePlayer.data}`);
            return;
        }

        notification.success({message: "Joueur mis à jour"});
        setOpen(false);
    }

    const excludePlayer = async () => {
        if (player?.team && player?.need_gamemaster) {
            notification.warning({message: "Résoudre le problème du joueur avant de le déplacer"});
            return;
        }
        const reponse = await supabase
            .from("player")
            .update({
                team: null,
                number: null,
                old_team: player?.getTeamId(),
            })
            .eq("id", player?.playerId);

        if (reponse.error) {
            Notification.error("Erreur lors de l'exclusion du joueur", reponse.error);
            return;
        }

        notification.success({message: "Joueur exclu"});
    }

    const fixGamemaster = async () => {
        const response = await supabase.from("player").update({need_gamemaster: false}).eq("id", player?.playerId);

        if (response.error) {
            Notification.error("Erreur lors de la résolution du problème.", response.error);
            return;
        }

        notification.success({message: "Problème résolu avec succès"});
        setOpen(false);
    }

    const onCancel = () => {
        form.resetFields();
        setOpen(false);
    }

    const filterOption =  (input: string, option: any) : boolean => {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    }


    if (!open) {
        return null;
    }

    return (
        <Modal open={open} onOk={onOk} onCancel={onCancel} destroyOnClose={true}>
            <Form className={"mt-8"} preserve={false} form={form}>
                <p><b>Nom complet</b> : {player?.getName()}</p>

                <p><b>Email</b> : {player?.getEmail()}</p>

                <Form.Item name="team">
                    <Select<string> filterOption={filterOption} showSearch={true} placeholder={"Choisir une équipe"} options={teamOptions}/>
                </Form.Item>

                <Form.Item name="number">
                    <InputNumber min={1} max={5} placeholder={"X"} />
                </Form.Item>

                <Flex gap={"10px"} className="inline-flex" vertical>
                    {player?.team && <Button onClick={() => Popup.warning("Êtes-vous sûr d'exclure ce joueur ?", excludePlayer)} type={"primary"} danger>Exclure le joueur</Button>}
                    {player?.team === undefined && player?.need_gamemaster && <Button onClick={fixGamemaster} type={"primary"} danger>Résoudre le Problème</Button>}
                    <Button type={"primary"} href={`/session_game/${player?.playerId}`} target={"_blank"}>Rejoindre la visio</Button>
                </Flex>
            </Form>
        </Modal>
    )
}