import ImageR from "../../../../shared/components/image/ImageR";
import { useGameContext } from "../../../../pages/session_game/context/context.game";
import { useState } from "react";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { Button, Flex } from "antd";

export default function CustomCarousel() {
    const context = useGameContext();
    const [current, setCurrent] = useState<number>(0);

    return (
        <Flex
            className="relative h-full w-[50%] items-center"
            justify="space-between"
        >
            <Button
                type="primary"
                onClick={() => setCurrent((prev) => (prev - 1 + (context.step?.description.hint_links.length || 0)) % (context.step?.description.hint_links.length || 1))}
                className="w-[8%] text-center p-0"
            >
                <LeftCircleFilled/>
            </Button>
            <>
                {context.step?.description.hint_links.map((src: string, index: number) => (
                    <ImageR 
                        key={index}
                        src={src}
                        className={`absolute ${index === current ? 'block' : 'hidden'} translate-x-[14.5%] h-full`}
                    />
                ))}
            </>
            <Button
                type="primary"
                onClick={() => setCurrent((prev) => (prev + 1) % (context.step?.description.hint_links.length || 1))}
                className="w-[8%] text-center p-0"
            >
                <RightCircleFilled/>
            </Button>
        </Flex>
    )
}
