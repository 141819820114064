import {Button, Flex, Form, Modal} from "antd";
import StepProps from "../../../types/StepProps";
import ImageR from "../../../../shared/components/image/ImageR";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import {ReactElement, useState} from "react";
import Notification from "../../../../shared/services/notification/Notification";
import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import t from "../../../../configs/i18n";
import Answer from "../../../../shared/services/answer/Answer";
import SyncronizedSelect from "../../../../shared/components/input/SyncronizedSelect";
import "./style/select.css"

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    stepContext: StepProps;
}


export default function PasswordModalStep7({open, setOpen, stepContext}: Props) {
    const [form] = Form.useForm();
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);
    const context = useGameContext();


    const answer1 = Form.useWatch(`7-1-${context.team?.id}`, form);
    const answer2 = Form.useWatch(`7-2-${context.team?.id}`, form);
    const answer3 = Form.useWatch(`7-3-${context.team?.id}`, form);
    const answer4 = Form.useWatch(`7-4-${context.team?.id}`, form);
    const answers : string[] = context.step?.description.answers;

    const validate = async () => {
        const inputs = form.getFieldsValue();
        if (!Answer.isCorrectWithOrder(inputs, answers, false)) {
            Notification.error(t.sessionGame.wrongAnswer);
            return;
        }
        setOpenSuccess(true);
    }


    const modalStyles = {
        content: {
            backgroundColor: "#3F5C50"
        }
    };

    return (
        <>
            <PopupGoodAnswer currentUser={stepContext.currentUser} open={openSuccess} setOpen={setOpenSuccess} team={stepContext.team} player={stepContext.player} playersTeam={stepContext.playersTeam} />
            <Modal styles={modalStyles} width={900} open={open} footer={null} onCancel={() => setOpen(false)}>
                <CanClosePopup/>
                <Flex>
                    <ImageR src={context.step?.description.password} width={"850px"} height={"300px"} className={"object-scale-down mt-8"}/>
                </Flex>
                <Flex gap={"32px"} className={"absolute top-[405px] left-[50px] custom-ant"}>
                    <Flex vertical>
                        <SyncronizedSelect className={"disabled:bg-white"} number={1} id={`7-1-${context.team?.id}`} width={175} height={80} size={"small"} options={context.step?.description.options}  player={context.player} playersTeam={context.playersTeam} placeHolder={t.common.yourAnswer} form={form} />
                        {Answer.isCorrect(answer1, answers[0]) && <p className={"ml-0 mt-1 text-center text-white font-bold"}>{context.step?.description.descriptions[0]}</p>}
                    </Flex>
                    <Flex vertical>
                        <SyncronizedSelect number={2} id={`7-2-${context.team?.id}`} width={175} height={80} size={"small"} options={context.step?.description.options} player={context.player} playersTeam={context.playersTeam} placeHolder={t.common.yourAnswer} form={form} />
                        {Answer.isCorrect(answer2, answers[1]) && <p className={"ml-0 mt-1 text-center text-white font-bold"}>{context.step?.description.descriptions[1]}</p>}
                    </Flex>
                    <Flex vertical>
                        <SyncronizedSelect number={3} id={`7-3-${context.team?.id}`} width={175} height={80} size={"small"} options={context.step?.description.options} player={context.player} playersTeam={context.playersTeam} placeHolder={t.common.yourAnswer} form={form} />
                        {Answer.isCorrect(answer3, answers[2]) && <p className={"ml-0 mt-1 text-center text-white font-bold"}>{context.step?.description.descriptions[2]}</p>}
                    </Flex>
                    <Flex vertical>
                        <SyncronizedSelect number={4} id={`7-4-${context.team?.id}`} width={175} height={80} size={"small"} options={context.step?.description.options} player={context.player} playersTeam={context.playersTeam} placeHolder={t.common.yourAnswer} form={form} />
                        {Answer.isCorrect(answer4, answers[3]) && <p className={"ml-0 mt-1 text-center text-white font-bold"}>{context.step?.description.descriptions[3]}</p>}
                    </Flex>
                </Flex>
                <Flex justify={"center"}>
                    <Button onClick={validate} className={"mt-10"} type={"primary"}>{t.common.validate}</Button>
                </Flex>
            </Modal>
        </>
    )
}