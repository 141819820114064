import {useEffect, useMemo, useRef, useState} from "react";
import StepProps from "../../../scenarios/types/StepProps";
import {
    CluesInput,
    CrosswordGrid,
    CrosswordProvider,
    CrosswordProviderImperative
} from "@jaredreisinger/react-crossword";
import {Flex, FormInstance} from "antd";
import CrosswordInput from "./CrosswordInput";
import Answer from "../../services/answer/Answer";
import { useGameContext } from "../../../pages/session_game/context/context.game";


interface SyncronizedCrosswordProps {
    stepContext: StepProps;
    data: CluesInput;
    form: FormInstance;
    onUpdate: () => void;
    prefield?: number[];
}

interface CrosswordStorage {
    date: string,
    guesses: {[key: string]: string}
}

export default function SyncronizedCrossword({stepContext, data, form, onUpdate, prefield}: SyncronizedCrosswordProps) {
    const context = useGameContext();
    const ref = useRef<CrosswordProviderImperative>(null);
    const id = useMemo(() => `crossword-${stepContext.team?.id}`, [stepContext.team?.id])
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!prefield || !ref.current) {
            return;
        }
        ref.current?.reset();
        setLoaded(true);

        const checkCrosswordLoaded = setInterval(() => {
            try {
                for (const i of prefield) {
                    const answer = (data.across[i] ?? data.down[i])?.answer;
                    if (answer) {
                        setInCrossword(answer, i);
                    }
                }
                clearInterval(checkCrosswordLoaded);
            } catch (e) {
                console.log('debug', e)
            }
        }, 200); // Check every 200ms

        return () => clearInterval(checkCrosswordLoaded);
    }, [prefield, data]);

    const getGuess = (x: number, y: number) : string => {
        const storage = localStorage.getItem(id) || "{}";
        const jsonGuess : CrosswordStorage = JSON.parse(storage);
        const letter = jsonGuess.guesses[`${y}_${x}`];
        return letter || "";
    }

    const isCrossingWord = (x: number, y: number, isDown: boolean) : boolean => {
        const currentLetter = getGuess(x, y);
        if (currentLetter === "") {
            return false;
        }

        if (isDown) {
            const leftLetter = getGuess(x-1, y);
            return leftLetter !== ""
        }

        const upLetter = getGuess(x, y-1);
        return upLetter !== "";
    }

    
    useEffect(() => {
        if (context.solution?.solutionOn && loaded) {
            const formKeys = form.getFieldsValue();
            for (const key of Object.keys(formKeys)) {
                const number = parseInt(key.split("-")[0]);
                const answer = data.across[number]?.answer || data.down[number]?.answer;
                if (answer) {
                    form.setFieldValue(key, answer);
                    onChangeInput(answer, key);
                }
            }
        }
    }, [context.solution?.solutionOn, loaded]);

    const onChangeInput = (value: string, id: string) => {
        onUpdate();
        if (!id) {
            return;
        }

        const number = parseInt(id.split("-")[0]);
        setInCrossword(value, number);
    }
    const setInCrossword = (value: string, number: number) => {
        const isAcross = data.across[number] !== undefined;
        const x = data.across[number]?.col ?? data.down[number]?.col;
        const y = data.across[number]?.row ?? data.down[number]?.row;
        const wordInfo = data.across[number] ?? data.down[number];

        if (x === undefined || y === undefined) {
            return;
        }

        for (let i = 0; i < wordInfo.answer.length; i++) {
            const currentY = y + (isAcross ? 0 : i);
            const currentX = x + (isAcross ? i : 0);
            const isCrossing = isCrossingWord(currentX,currentY, !isAcross)

            if (isCrossing && !Answer.isCorrect(value, wordInfo.answer)) {
                continue;
            }
            ref.current?.setGuess(currentY, currentX, value[i] || "");
        }
    }

    useEffect(() => {
        if (prefield && loaded) {
            const formKeys = form.getFieldsValue();
            for (const key of Object.keys(formKeys)) {
                const number = parseInt(key.split("-")[0]);
                if (prefield.includes(number)) {
                    const answer = data.across[number]?.answer || data.down[number]?.answer;
                    if (answer) {
                        form.setFieldValue(key, answer);
                        onChangeInput(answer, key);
                    }
                }
            }
        }
    }, [loaded, prefield]);

    return (
        <Flex align={"center"} className={"w-full h-full"}>
            <Flex vertical>
                {Array.from({length: Object.keys(data.across).length + Object.keys(data.down).length}, (_, i) => i).map((i) => {
                    return <CrosswordInput stepContext={stepContext} form={form} i={i+1} onChangeInput={onChangeInput} answer={(data.across[i+1] ?? data.down[i+1]).answer} prefield={prefield?.includes(i + 1)}/>
                })}
            </Flex>
            <Flex className={"w-[45%] ml-12 pointer-events-none"}>
                <CrosswordProvider storageKey={id} theme={{
                    allowNonSquare: true,
                    gridBackground: "transparent",
                    columnBreakpoint: '9999px',
                    numberColor: '#14665F',
                }} ref={ref} data={data}>
                    <CrosswordGrid/>
                </CrosswordProvider>
            </Flex>
        </Flex>
    )

}
