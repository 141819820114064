import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";
import {useEffect, useMemo, useRef, useState} from "react";
import {Flex, FormInstance} from "antd";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import { BaseSizeProvider, useBaseSize } from "../../../../shared/components/simpleContexts/provider.base.size";

interface InputCategoryProps {
    group: number;
    form: FormInstance;
    title: string;
    min: number;
    size: number;
    onChange?: (value: string, id: string) => void;
}

export default function InputCategory({title, min, group, form, onChange, size} : InputCategoryProps) {
    const context = useGameContext();
    const [userAnswers, setUserAnswers] = useState<Map<string, string>>(new Map());
    const { baseSize } = useBaseSize();

    const answers : string[] = context.step?.description.answers;
    const descriptions : string[] = context.step?.description.descriptions;

    const validAnswers = useMemo(() => {
        return answers.slice(min - 1, min + size - 1);
    }, [answers, min, size])

    const onChangeInput = (value: string, id: string) => {
        setUserAnswers(oldMap => new Map(oldMap.set(id, value)))
        onChange && onChange(value, id);
    }


    const valid = useMemo(() => {
        const userAnswersSet = new Set(Array.from(userAnswers.values()))

        for (const validAnswer of validAnswers) {
            if (!userAnswersSet.has(validAnswer)) {
                return false;
            }
        }
        return true;
    }, [userAnswers, validAnswers])

    const bgColor = useMemo(() => valid ? "bg-green-400 focus:bg-green-400 hover:bg-green-400 disabled:bg-green-400 disabled:text-gray-700" : "", [valid])
    return (
        <div>
            <div className="text-green-dark font-bold" style={{fontSize: 1.4 * baseSize}}>{title}</div>
            {Array.from({length: size}).map((_, index) => (
                <Flex align={"center"} key={`${min + index}-${context.team?.id}-${context.team?.step}`} className="h-[5.5vh] ml-[5%]">
                    <SyncronizedInput onChange={onChangeInput} className={`w-[50px] ${bgColor}`} form={form} id={`${min + index}-${context.team?.id}-${context.team?.step}`} number={group} player={context.player} playersTeam={context.playersTeam} isDisabled={valid}/>
                    {answers.includes(userAnswers.get(`${min + index}-${context.team?.id}-${context.team?.step}`) || "") && <div className="ml-[1%]" style={{fontSize: 0.9 * baseSize, lineHeight: 0.075 * baseSize}}>{descriptions[answers.findIndex(value => value === userAnswers.get(`${min + index}-${context.team?.id}-${context.team?.step}`))]}</div>}
                </Flex>
            ))}
        </div>
    )
}