import {Button, Flex} from "antd";
import React, {useState} from "react";
import {QuestionCircleOutlined} from "@ant-design/icons";
import SOSPopup from "./SOSPopup";
import {Team} from "../../../shared/models/Team/Team";
import {Player} from "../../../shared/models/Player/Player";
import Session from "../../../shared/models/Session/session";

interface SOSButtonProps {
    team: Team | undefined;
    playersTeam: Player[];
    player: Player | undefined;
    session: Session | undefined;
}

export default function SOSButton({team, playersTeam, player, session}: SOSButtonProps) {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Flex>
            <SOSPopup session={session} player={player} playersTeam={playersTeam} team={team} open={open} setOpen={setOpen}/>
            <Button onClick={() => setOpen(true)} size={"small"} type={"primary"} danger><QuestionCircleOutlined/> SOS</Button>
        </Flex>

    )
}