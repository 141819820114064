import {Flex, Modal, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {Team} from "../../models/Team/Team";
import {Player} from "../../models/Player/Player";
import {supabase} from "../../../configs/supabase";
import Notification from "../../services/notification/Notification";
import {UserData} from "../../models/UserData/UserData";
import t from "../../../configs/i18n";
import { Role } from "../../models/UserData/Role";

interface WaitingForPlayersProps {
    team: Team | undefined;
    playersTeam: Player[];
    stepNumber?: number | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
    currentUser: UserData | undefined;
}


export default function WaitingForPlayers({team, stepNumber, playersTeam, open, setOpen, currentUser}: WaitingForPlayersProps) {
    const [waitingPlayers, setWaitingPlayers] = useState<Player[] | undefined>([]);

    const validateStep = async () => {
        if (!team || !open) {
            return
        }

        if (stepNumber !== undefined) {
            const response = await supabase.rpc("next_step", {
                    team_id: team.id,
                    new_step_number: stepNumber  + 1
                });

            if (response.error) {
                Notification.error(t.sessionGame.waitingForPlayers.error, response.error);
            }
            return;
        }
    }

    useEffect(() => {
        const waitingPlayers = playersTeam.filter(player => !player.stepValidated);
        setWaitingPlayers(waitingPlayers);

        if (waitingPlayers?.length === 0) {
            validateStep();
            setOpen(false);
        }
    }, [playersTeam, open]);

    if (!open) {
        return null;
    }


    return (
        <Modal wrapClassName={"pointer-events-none bg-black bg-opacity-40"} mask={false} onCancel={() => setOpen(false)} destroyOnClose={true} open={open} footer={null} keyboard={false} maskClosable={false} closeIcon={currentUser?.isGamemaster()}>
            <Flex align={"center"} justify={"center"} vertical>
                <h2>{t.sessionGame.waitingForPlayers.title}</h2>
                <Spin className={"mt-10 mb-10"} indicator={<LoadingOutlined style={{fontSize: 60}} spin/>}/>
                {waitingPlayers?.map(player => <p className={"m-1"}>{player.getName()}</p>)}
            </Flex>
        </Modal>
    )
}