import {Button, Flex, Popover} from "antd";
import {PlusOutlined, TableOutlined, UserAddOutlined} from "@ant-design/icons";
import ExcelPopupInfoSessionHome from "./createPlayers/excel.popup.info.session.home";
import React, {useState} from "react";
import ManualPopupInfoSessionHome from "./createPlayers/manual.popup.info.session.home";
import {useSessionInfoContext} from "../context/context.info.session";




export default function ButtonCreatePlayersSessionInfo() {
    const [open, setOpen] = useState<boolean>(false);
    const [manualOpen, setManualOpen] = useState<boolean>(false);
    const context = useSessionInfoContext();

    const hoverContent = (
        <Flex gap={"20px"} vertical={true}>
            <Button onClick={() => setManualOpen(true)} type={"text"}>
                <Flex gap={"10px"} align={"center"}>
                    <UserAddOutlined />Ajouter individuellement un participant
                </Flex>
            </Button>
            <Button onClick={() => setOpen(true)} type={"text"}>
                <Flex gap={"10px"} align={"center"}>
                    <TableOutlined />Ajouter depuis un fichier excel
                </Flex>
            </Button>
        </Flex>

    )

    return (
        <>
            <ExcelPopupInfoSessionHome open={open} setOpen={setOpen}/>
            <ManualPopupInfoSessionHome session={context.sessionRealtime} open={manualOpen} setOpen={setManualOpen}/>
            <Popover placement={"bottomLeft"} content={hoverContent}>
                <Button type={"primary"}>
                    <PlusOutlined/> Ajouter des participants
                </Button>
            </Popover>
        </>
    )
}