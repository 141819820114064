import { UploadRequestOption } from "rc-upload/lib/interface";
import { supabase } from "../../../configs/supabase";
import { notification } from "antd";
import {v4 as generateUUID} from "uuid";
import Notification from "../notification/Notification";
import { Team } from "../../models/Team/Team";

export async function uploadTeamImage (options : UploadRequestOption, team: Team) {
    const url = await uploadImage(options);

    const responsePhoto = await supabase
        .from("team")
        .update({
            photo: url
        })
        .eq("id", team?.id);

    if (responsePhoto.error) {
        Notification.error("Erreur lors de la modification de l'image", responsePhoto.error);
        return;
    }

    notification.success({message: "Image modifiée avec succès"});
    options.onSuccess && options.onSuccess("ok");
}

export async function uploadSessionImage(options : UploadRequestOption, sessionId: string) {
    const url = await uploadImage(options);

    const responsePhoto = await supabase
        .from("session")
        .update({
            photo: url
        })
        .eq("id", sessionId);

    if (responsePhoto.error) {
        Notification.error("Erreur lors de la modification de l'image", responsePhoto.error);
        return;
    }

    notification.success({message: "Image modifiée avec succès"});
    options.onSuccess && options.onSuccess("ok");
}

export async function uploadImage(options : UploadRequestOption) {
    const fileName = generateUUID();

    const respose = await supabase
        .storage
        .from('data')
        .upload(fileName, options.file)

    if (respose.error) {
        Notification.error("Erreur lors de l'upload de l'image", respose.error);
        return;
    }

    const result = supabase
        .storage
        .from('data')
        .getPublicUrl(fileName)

    return result.data?.publicUrl
    
}