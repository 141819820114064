import {Button, Card, Flex} from "antd";
import ImageR from "../../../shared/components/image/ImageR";
import StepProps from "../../types/StepProps";
import WaitingForPlayers from "../../../shared/components/popup/WaitingForPlayers";
import {useEffect, useMemo, useState} from "react";
import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";
import t from "../../../configs/i18n";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import { Role } from "../../../shared/models/UserData/Role";

interface EndScreenProps {
    stepContext: StepProps;
}

export default function EndScreen({stepContext} : EndScreenProps) {
    const context = useGameContext();
    const [waitingForPlayers, setWaitingForPlayers] = useState<boolean>(false);
    const backgroundUrl = "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step11/background.webp"
    const maxSubStep = useMemo(() => context.step?.description.questions.length - 1, [context.step?.description.questions.length]);

    const validate = async () => {
        if (!stepContext.currentUser?.hasRights(Role.GAMEMASTER, context.session?.id)) {
            const response = await supabase
                .from("player")
                .update({
                    step_validated: true
                })
                .eq("id", stepContext.player?.playerId)

            if (response.error) {
                Notification.error(t.error.common, response.error);
                return;
            }
        }

        setWaitingForPlayers(true)
    }

    return (
        <Flex align={"flex-start"} className={"relative"}>
            <WaitingForPlayers setOpen={setWaitingForPlayers} currentUser={stepContext.currentUser} team={stepContext.team} playersTeam={stepContext.playersTeam} open={waitingForPlayers}/>
            <Card className={"text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-1/2"}>
                <h2>{`${t.common.congratulations} ${stepContext.team?.name}`}</h2>
                <p>{context.step?.description.endMessage1}</p>
                <p>{context.step?.description.endMessage2}</p>
                <Button onClick={validate} className={"mt-4"} size={"large"} type={"primary"}>{t.sessionGame.goToQuizz}</Button>
            </Card>
            <ImageR src={backgroundUrl} width={"1000px"} height={"600px"} className={"object-scale-down"}/>
        </Flex>
    )

}