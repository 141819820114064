import {Button, Card, Flex, Form, Input, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import React from "react";
import useCreateCompany from "./callbacks/useCreateCompany";
import t from "../../../../configs/i18n";

export default function CreateCompanies() {
    const createCompany = useCreateCompany();

    return (
        <Flex align={"center"} className="w-full h-full mt-20" vertical>
            <h2 className="text-green-dark">{t.home.company.create}</h2>
            <Card className="w-80 shadow-md">
                <Form form={createCompany.form} className="mt-4" name="normal_login" onFinish={createCompany.finish}>
                    <Form.Item name="name" rules={[{required: true, message: ""}]}>
                        <Input placeholder={t.home.company.name}/>
                    </Form.Item>

                    <Form.Item name="upload">
                        <Upload name="logo" listType="picture" accept="image/*" customRequest={createCompany.uploadImage}>
                            <Button icon={<UploadOutlined/>}>{t.home.company.logo}</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item>
                        <Button className="w-full" type="primary" htmlType="submit">
                            {t.home.company.create}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Flex>
    )
}