import {Button, Card, Flex, Input, notification, Select, Space, TimePicker} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import React, {useCallback, useEffect, useMemo} from "react";
import {UserData} from "../../../shared/models/UserData/UserData";
import Session from "../../../shared/models/Session/session";
import {supabase} from "../../../configs/supabase";
import dayjs, {Dayjs} from "dayjs";
import Notification from "../../../shared/services/notification/Notification";
import scenarios from "../../../scenarios/scenarios";
import SearchableSelect from "../../../shared/components/input/SearchableSelect";
import { Role } from "../../../shared/models/UserData/Role";
import UserDataService from "../../../shared/models/UserData/UserDataService";


interface InfoCardProps {
    session: Session | undefined;
    user: UserData | undefined;
}


export default function SessionInfoCard({session, user}: InfoCardProps) {
    const [gamemastersOption, setGameMastersOption] = React.useState<{label: string, value: string}[]>([]);
    const scenario = useMemo(() => scenarios[session?.scenario || ""], [session?.scenario]);
    const sessionGamemasters = useMemo(() => session?.gamemasters?.map(gm => gm.uid) || [], [session?.gamemasters]);

    let currentLoginLink = `https://${window.location.hostname}/session_login/${session?.id}`;
    if (scenario.tablet) {
        currentLoginLink = `https://${window.location.hostname}/session_tablet_login/${session?.id}`;
    }

    useEffect(() => {
        (async () => {setGameMastersOption(await UserDataService.getGamemastersOptions());})();
    }, []);

    const copyLoginLink = () => {
        navigator.clipboard.writeText(currentLoginLink).then(r => {
            notification.success({
                message: "Lien de connexion copié",
                placement: "topRight",
            });
        });
    }

    const onMainGmChange = async (gmId: string) => {
        const response = await supabase
            .from("session")
            .update({main_gamemaster: gmId})
            .eq("id", session?.id)

        if (response.error) {
            console.error("Erreur lors de la modification du gamemaster principal.", response.error)
            return;
        }
    }

    const onEndTimeChange = async (value: Dayjs | null) => {
        if (!value) {
            return;
        }

        const newEndDate = new Date(value.toDate().getTime());
        newEndDate.setHours(value.hour(), value.minute(), 0, 0);
        const response = await supabase
            .from("session")
            .update({end_date: newEndDate})
            .eq("id", session?.id);

        if (response.error) {
            Notification.error("Erreur lors de la modification de la date de fin.", response.error)
            return;
        }

        notification.success({message: "Date de fin modifiée avec succès"});
    }

    const onChangeGM = async (gms: string[]) => {
        await session?.updateGamemastersDB(gms);
    }

    return (
        <Card title={"Informations"} headStyle={{color: '#14665F', textAlign: "center"}}
              className="w-[600px] mt-8 ml-8 text-xs">
            <Flex>
                <Flex className={"w-[400px]"} vertical>
                    <p>Gamemaster principal</p>
                    <SearchableSelect
                        options={gamemastersOption}
                        disabled={!user?.isAdmin()}
                        placeholder={"Choisir un gamemaster principal"}
                        value={session?.maingamemaster?.uid || session?.maingamemaster as unknown as string}
                        onChange={onMainGmChange}
                    />
                    <p>Gamemasters</p>
                    <SearchableSelect<string[]>
                        options={gamemastersOption}
                        placeholder={"Choisir des gamemasters"}
                        mode={"multiple"}
                        value={sessionGamemasters}
                        onChange={onChangeGM}
                        disabled={!user?.isAdmin()}
                        size="small"
                    />
                    <p>Scenario : {session?.scenario}</p>
                    <p>Organisateur : {session?.organiser?.name}</p>
                </Flex>
                <Flex className={"ml-6 w-full"} vertical>
                    <p>Lien de la session</p>
                    <Space.Compact className={"w-full"}>
                        <Input disabled defaultValue={currentLoginLink}/>
                        <Button onClick={copyLoginLink} type="primary"><CopyOutlined/></Button>
                    </Space.Compact>

                    <p>Fin de la session</p>
                    <TimePicker disabled={!user?.isAdmin()} onChange={onEndTimeChange} defaultValue={dayjs(new Date(session?.endDate!).getTime())} format={'HH:mm'}/>
                </Flex>
            </Flex>
        </Card>
    )
}