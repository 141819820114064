import {Flex} from "antd";
import {useEffect, useState} from "react";
import {supabase} from "../../../configs/supabase";
import Session from "../../../shared/models/Session/session";
import Notification from "../../../shared/services/notification/Notification";

interface WaitingSurveyProps {
    session: Session | undefined;

}

export default function WaitingSurvey({session}: WaitingSurveyProps) {
    const [survey, setSurvey] = useState<any[]>([]);

    const fetchData = async () => {
        const response = await supabase
            .from("survey")
            .select("id")
            .eq("session", session?.id);

        if (response.error) {
            Notification.error("Erreur lors de la récupération du questionnaire de satisfaction", response.error.message);
            return;
        }

        setSurvey(response.data);
    }

    useEffect(() => {
        fetchData();

        const subscribe = supabase
            .channel(`survey-${session?.id}`)
            .on("postgres_changes",
                {
                    event: 'INSERT',
                    schema: 'public',
                    table: "survey",
                    filter: `session=eq.${session?.id}`,
                }, (payload) => {
                    if (payload.eventType === "INSERT") {
                        setSurvey(oldSurvey => [...oldSurvey, payload.new]);
                    }
                }).subscribe();
    }, []);

    return (
        <Flex align={"center"} justify={"center"} className={"w-full mt-20 mb-20"} vertical>
            <h2>Questionnaire de satisfaction en cours...</h2>
            <h3>{survey.length} réponses</h3>
        </Flex>
    )
}