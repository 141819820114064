import {Flex} from "antd";
import PopupGoodAnswer from "../../../shared/components/popup/PopupGoodAnswer";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import {useState} from "react";
import ItStep6PasswordContent from "../../sustainable-it/step6/components/it.step6.pasword.content";

export default function ItTabletStep6() {
    const context = useGameContext();
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <PopupGoodAnswer open={open} setOpen={setOpen} team={context.team} player={context.player} playersTeam={context.playersTeam} currentUser={context.user}/>
            <Flex className={"w-full"} align={"center"} justify={"center"}>
                <Flex className={"w-[800px] h-full mt-24 scale-125"} vertical>
                    <ItStep6PasswordContent popup={false} onValid={() => {
                        setOpen(true);
                    }}/>
                </Flex>
            </Flex>
        </>
    );
}