import ItStep2PasswordContent from "../../sustainable-it/step2/components/it.step2.password.content";
import {Flex} from "antd";

export default function ItTabletStep2() {

    return (
        <Flex className={"w-full h-full"} align={"center"} justify={"center"}>
            <ItStep2PasswordContent popup={false}/>
        </Flex>
    )
}