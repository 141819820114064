import {Button, Flex, Form, Modal} from "antd";
import StepProps from "../../../types/StepProps";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import React, {useState} from "react";
import ItStep10PasswordContent from "./it.step10.pasword.content";
import DetailsModal from "./DetailsModal";

interface PasswordModalStep4Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    stepContext: StepProps;
}


export default function PasswordModalStep10({open, setOpen, stepContext}: PasswordModalStep4Props) {
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);
    const [openDetails, setOpenDetails] = useState<boolean[]>([false, false, false, false]);

    const validate = () => {
        setOpen(false);
        setOpenSuccess(true);
    }


    return (
        <>
            {Array.from({length: 4}).map((_, i) => (
                <DetailsModal open={openDetails[i]} setOpen={(openDetails: boolean) => setOpenDetails(old => old.map((value, index) => index === i ? openDetails : value))} number={i + 1} />
            ))}
            <PopupGoodAnswer currentUser={stepContext.currentUser} open={openSuccess} setOpen={setOpenSuccess} team={stepContext.team} player={stepContext.player} playersTeam={stepContext.playersTeam} />
            <Modal width={800} open={open} footer={null} onCancel={() => setOpen(false)}>
                <Flex className={"h-[600px]"} vertical>
                    <ItStep10PasswordContent onValid={validate} popup={false} setOpenDetails={setOpenDetails}/>
                </Flex>
            </Modal>
        </>
    )
}