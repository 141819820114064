import {supabase} from "../../../configs/supabase";
import Notification from "../../services/notification/Notification";
import t from "../../../configs/i18n";
import {Company} from "./company";

export default class CompanyService {
    public static async all() : Promise<Company[]> {
        const response = await supabase
            .from("company")
            .select("*");

        if (response.error) {
            Notification.error(t.error.company, response.error);
            return [];
        }

        return Company.newCompanies(response.data);
    }
}