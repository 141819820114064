import {Player} from "../../../shared/models/Player/Player";
import React, {useEffect} from "react";
import {Card, Empty, Flex, notification} from "antd";
import PlayerBox from "../../../shared/components/player/PlayerBox";
import {Team} from "../../../shared/models/Team/Team";
import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";

interface NoTeamTableProps {
    players: Player[] | undefined;
    teams: Team[] | undefined;
    onlinePlayers: Set<string>;
}


export default function NoTeamTable({players, teams, onlinePlayers} : NoTeamTableProps) {
    const [playersWithoutTeam, setPlayersWithoutTeam] = React.useState<Player[] | undefined>();
    useEffect(() => {
        setPlayersWithoutTeam(players?.filter(p => !p.team || p.number === null || p.number === undefined))
    }, [players]);

    const handleDragOver = (e: any) => {
        e.preventDefault(); // Nécessaire pour permettre le drop
    };

    const handleDrop = async (e: any) => {

        const playerId = e.dataTransfer.getData("text/plain");
        if (!playerId) {
            notification.warning({message: "Résoudre le problème du joueur avant de le déplacer"});
            return;
        }
        e.preventDefault();

        const player = await Player.byId(playerId, "*, userdata!userdata(*)");

        const response = await supabase
            .from("player")
            .update({
                team: null,
                number: null,
                old_team: player?.getTeamId(),
            })
            .eq("id", player?.playerId);

        if (response.error) {
            Notification.error("Erreur lors de l'update d'un joueur", response.error);
            return;
        }

        notification.success({message: "Joueur mis à jour"});
    };

    return (
        <Flex onDrop={handleDrop} onDragOver={handleDragOver} className={"pl-5 pr-5 mb-12"} vertical>
            <h1 className={"text-center text-green-dark"}>Joueurs sans équipes</h1>
            <Card className={"w-full h-fit min-h-12"}>
                <Flex wrap={"wrap"} className={"w-full h-full"}>
                    {playersWithoutTeam?.map(player =>
                        <PlayerBox online={onlinePlayers.has(player?.getId())} teams={teams} player={player}/>
                    )}
                    {(!playersWithoutTeam || playersWithoutTeam?.length === 0) &&
                        <Flex justify={"center"} className="w-full">
                            <Empty description={"Aucun joueur sans équipe"}/>
                        </Flex>}
                </Flex>
            </Card>
        </Flex>
    )
}