import {Flex, FormInstance} from "antd";
import SyncronizedInput from "./SyncronizedInput";
import React, {useEffect, useMemo} from "react";
import StepProps from "../../../scenarios/types/StepProps";
import Answer from "../../services/answer/Answer";

interface CrosswordInputProps {
    stepContext: StepProps;
    form: FormInstance;
    i: number;
    onChangeInput: (value: string, id: string) => void;
    answer: string;
    prefield?: boolean;
}

export default function CrosswordInput({stepContext, i, form, onChangeInput, answer, prefield}: CrosswordInputProps) {
    const [isValid, setIsValid] = React.useState<boolean>(false);

    const onChange = (value: string, id: string) => {
        setIsValid(Answer.isCorrect(value, answer));
        onChangeInput(value, id);
    }


    const bgColor = useMemo(() => isValid ? prefield ? "" : "bg-green-light focus:bg-green-light hover:bg-green-light disabled:bg-green-light" : "", [isValid, prefield])
    const inputNumber = useMemo(() => {
        if (i === 1 || i === 2) {
            return 3;
        } else if (i === 3 || i === 4) {
            return 4;
        } else if (i === 5 || i === 6) {
            return 1;
        } else if (i === 7 || i === 8) {
            return 2;
        } else {
            return 1;
        }
    }, [i])


    return (
        <Flex className={"mb-4"} align={"center"}>
            <p className={"font-bold text-green-dark m-0 mr-2"}>{i}.</p>
            <SyncronizedInput isDisabled={isValid || prefield} number={inputNumber} className={bgColor}  form={form} onChange={onChange} id={`${i}-${stepContext.team?.id}-${stepContext.team?.step}`} player={stepContext.player} playersTeam={stepContext.playersTeam}/>
        </Flex>
    )
}