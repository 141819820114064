import {Button, Flex} from "antd";
import ImageR from "../../../../../../shared/components/image/ImageR";
import {Team} from "../../../../../../shared/models/Team/Team";
import {DownloadOutlined} from "@ant-design/icons";
import {saveAs} from 'file-saver'


interface Props {
    teams: Team[]
}

export default function CardsPhotoResultSession({teams}: Props) {
    const handleDownload = (imageUrl: string, imageName: string) => {
       saveAs(imageUrl, imageName)
    };

    return (
        <div className={"!flex flex-row h-[300px] gap-4 items-center justify-center pl-4 pr-4 transition-all"}>
            {teams.map((team) => (
                <Flex className="bg-white p-4 rounded-lg w-full min-w-[150px] max-w-[calc(25%-1rem)]" vertical>
                    <Flex justify={"center"} className={"h-[200px]"}>
                        <ImageR height={"200px"} className={"max-w-full max-h-full w-auto h-auto object-contain"} src={team.photo}/>
                    </Flex>
                    <Flex className={"w-full"} justify={"space-between"} align={"center"}>
                        <h4 className="m-0 mt-4">{team.name}</h4>
                        <Button onClick={() => handleDownload(team.photo, `${team.name}.jpg`)} className={"mt-4"} size={"small"} type="primary">
                            <DownloadOutlined />
                        </Button>
                    </Flex>
                </Flex>
            ))}
        </div>
    )
}