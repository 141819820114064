import {createClient} from '@supabase/supabase-js'
import * as Sentry from "@sentry/browser";


export const supabaseConfig = {
    url: process.env.REACT_APP_SUPABASE_URL || "",
    key: process.env.REACT_APP_SUPABASE_KEY || ""
}
export const supabase = createClient(supabaseConfig.url, supabaseConfig.key)

supabase.auth.onAuthStateChange((event, session) => {
    Sentry.setUser({email: session?.user?.email})
})