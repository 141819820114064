import EmptyModal from "./EmptyModal";
import InvisibleButton from "../../../../shared/components/button/InvisibleButton";
import {useState} from "react";
import HintModal from "./HintModal";

interface P2ButtonsProps {
    showHint: boolean;
}

export default function P2Buttons({showHint}: P2ButtonsProps) {
    const [emptyModal, setEmptyModal] = useState<boolean>(false);
    const [hintModal, setHintModal] = useState<boolean>(false);

    return (
        <>
            <EmptyModal open={emptyModal} setOpen={setEmptyModal}/>
            <HintModal playerNumber={2} open={hintModal} setOpen={setHintModal} />

            <InvisibleButton showHint={showHint} onClick={() => setHintModal(true)} top={67} left={735} h={193} w={193} />
            <InvisibleButton showHint={showHint} onClick={() => setEmptyModal(true)} top={172} left={35} h={125} w={125} />
            <InvisibleButton showHint={showHint} onClick={() => setEmptyModal(true)} top={37} left={170} h={125} w={125} />
        </>
    )
}