import {useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import Session from "../../shared/models/Session/session";
import {Button, Flex} from "antd";
import Banner from "../session_common_room/components/Banner";
import {SessionState} from "../../shared/models/Session/sessionState";
import JitsiVisio from "../../shared/components/jitsi/JitsiVisio";
import {UserData} from "../../shared/models/UserData/UserData";
import EndGameSurvey from "./components/EndGameSurvey";
import AppSpinner from "../../shared/components/spinner/AppSpinner";
import Top3ResultSession from "../home/subPages/session/result/components/top3.result.session";
import Ended from "./components/Ended";
import SyncService from "../../shared/services/sync/SyncService";
import SyncPopup from "../../shared/components/popup/SyncPopup";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import RankingResultSession from "../home/subPages/session/result/components/ranking.result.session";
import {SessionResultProvider} from "../home/subPages/session/result/context/provider.result.session";
import t from "../../configs/i18n";
import scenarios from "../../scenarios/scenarios";
import OnlineService from "../../shared/services/sync/OnlineService";
import { supabase } from "../../configs/supabase";

export default function SessionEnd() {
    const { id } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [currentUser, setCurrentUser] = useState<UserData>();
    const [session, setSession] = useState<Session>();
    const [realtimeSession, setRealtimeSession] = useState<Session>();
    const [allRanking, setAllRanking] = useState<boolean>(false);
    const scenario = useMemo(() => scenarios[session?.scenario || ""], [session]);


    const fetchData = async () => {
        const session = await Session.byId(id, "*, company(*)");
        setSession(session);
        setRealtimeSession(session);
        session?.listen(setRealtimeSession, () => SyncService.desync(), () => SyncService.sync());

        const user = await UserData.getCurrentUser();
        setCurrentUser(user);

        
        const result = await supabase
            .from("player")
            .select("userdata")
            .eq("userdata", user?.uid)
            .eq("session", session?.id)
            .single();
        OnlineService.startId(result.data?.userdata as string);

        setLoading(false);
    }

    useEffect(() => {
        fetchData();
        SyncService.register("fetchData", fetchData);
    }, []);

    if (loading) {
        return <AppSpinner tryAgain={true}/>
    }

    return (
        <>
            <SyncPopup/>
            <Flex className={"w-full h-full"} vertical>
                <Banner company={session?.company} session={realtimeSession}/>
                <Flex className={"w-full h-screen"}>
                    {realtimeSession?.state === SessionState.SURVEY &&
                        <Flex className={"min-w-[1000px] w-full h-[calc(100vh-44px)] pt-12 box-border overflow-y-auto"}>
                             <EndGameSurvey currentUser={currentUser} session={session}/>
                        </Flex>
                    }

                    {(realtimeSession?.state === SessionState.RANK_3 || realtimeSession?.state === SessionState.RANK_2 || realtimeSession?.state === SessionState.RANK_1) &&
                        <Flex className={"h-[calc(100vh-44px)] min-w-[1000px] w-full overflow-y-auto pl-4 pr-4 pt-4 box-border"} vertical>
                            <SessionResultProvider sessionId={realtimeSession?.id}>
                                {realtimeSession?.state === SessionState.RANK_1 &&
                                    <Flex className={"w-full"} justify={"end"}>
                                        {!allRanking && <Button onClick={() => setAllRanking(true)}  type={"text"}><RightOutlined /> {t.sessionEnd.seeRanking}</Button>}
                                        {allRanking && <Button className={"mb-4"} onClick={() => setAllRanking(false)}  type={"text"}><LeftOutlined /> {t.common.goBack}</Button>}
                                    </Flex>
                                }
                                {!allRanking && <Top3ResultSession showTop3={true} showTop2={realtimeSession?.state === SessionState.RANK_2 || realtimeSession?.state === SessionState.RANK_1} showTop1={realtimeSession?.state === SessionState.RANK_1}/>}
                                {allRanking && <RankingResultSession/>}
                            </SessionResultProvider>
                        </Flex>
                    }

                    {realtimeSession?.state === SessionState.ENDED &&
                        <Ended/>
                    }

                    {!scenario?.tablet && <JitsiVisio autoJoin={true} className={"h-full w-full"} name={currentUser?.name} mail={currentUser?.mail} session={realtimeSession} size={"100%"} height={"100%"}/>}
                </Flex>
            </Flex>
        </>
    )
}