import {supabase} from "../../../configs/supabase";

export default class RealtimeLogger {

    private static i = 1;
    private static INTERVAL = 10000; // ms

    public static start() {
        setInterval(() => {
            const logs = {
                state: supabase.realtime.connectionState(),
                nbChannels: supabase.getChannels().length,
                channelName: supabase.getChannels().map(channel => channel.topic),
            }

            console.log(`REALTIME LOGS ${this.i} : `, JSON.stringify(logs))
            this.i++;
        }, this.INTERVAL)
    }
}