import {Button, Flex, Form, Modal} from "antd";
import StepProps from "../../../types/StepProps";
import ImageR from "../../../../shared/components/image/ImageR";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import {useState} from "react";
import Answer from "../../../../shared/services/answer/Answer";
import Notification from "../../../../shared/services/notification/Notification";
import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import t from "../../../../configs/i18n";
import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";
import InputDescriptionStep3 from "./InputDescription.step3";

interface PasswordModalStep4Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    stepContext: StepProps;
}


export default function PasswordModalStep3({open, setOpen, stepContext}: PasswordModalStep4Props) {
    const [form] = Form.useForm();
    const context = useGameContext();
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);

    const image : string = context.step?.description.password;
    const descriptions : string[] = context.step?.description.descriptions;

    const modalStyles = {
        content: {
            backgroundColor: "#3A3E79"
        }
    };

    const validate = () => {

        const values = Object.values(form.getFieldsValue());

        for (const answer of Object.keys(context.step?.description.descriptions)) {
            if (!values.includes(answer)) {
                Notification.error(t.sessionGame.wrongAnswers);
                return;
            }
        }

        setOpenSuccess(true)
        setOpen(false);
    }


    return (
        <>
            <PopupGoodAnswer currentUser={stepContext.currentUser} open={openSuccess} setOpen={setOpenSuccess} team={stepContext.team} player={stepContext.player} playersTeam={stepContext.playersTeam} />
            <Modal styles={modalStyles} width={900} open={open} footer={null} onCancel={() => setOpen(false)}>
                <CanClosePopup/>
                <Flex>
                    <ImageR src={image} width={"850px"} height={"500px"} className={"object-scale-down mt-8"}/>
                </Flex>
                <Flex className={"absolute top-[-105px] left-[10px]"}>
                    <InputDescriptionStep3 number={1} form={form} answers={context.step?.description.answersCategory1} descriptions={descriptions}/>
                </Flex>
                <Flex className={"absolute top-[-105px] left-[210px]"}>
                    <InputDescriptionStep3 number={2} form={form} answers={context.step?.description.answersCategory2} descriptions={descriptions}/>
                </Flex>
                <Flex className={"absolute top-[-105px] left-[410px]"}>
                    <InputDescriptionStep3 number={3} form={form} answers={context.step?.description.answersCategory3} descriptions={descriptions}/>
                </Flex>
                <Flex className={"absolute top-[-105px] left-[610px]"}>
                    <InputDescriptionStep3 number={4} form={form} answers={context.step?.description.answersCategory4} descriptions={descriptions}/>
                </Flex>
                <Flex justify={"center"}>
                    <Button onClick={validate} className={"mt-10"} type={"primary"}>{t.common.validate}</Button>
                </Flex>
            </Modal>
        </>
    )
}