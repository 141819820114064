import InvisibleButton from "../../../../shared/components/button/InvisibleButton";
import {useState} from "react";
import PasswordModalStep10 from "./PasswordModal.step10";
import StepProps from "../../../types/StepProps";
import HintModalStep10 from "./HintModal.step10";

interface ButtonsStep4Props {
    showHint: boolean;
    playerNumber: number;
    mainHintPos: {x: number, y: number, h: number, w: number};
    passwordPos?: {x: number, y: number, h: number, w: number};
    stepContext: StepProps;
}

export default function ButtonsStep10({playerNumber, showHint, mainHintPos, stepContext, passwordPos = {x: 35, y: 335, h: 40, w: 65}}: ButtonsStep4Props) {
    const [hintModal, setHintModal] = useState<boolean>(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);



    return (
        <>
            <HintModalStep10 open={hintModal} setOpen={setHintModal} number={playerNumber} />
            <PasswordModalStep10 stepContext={stepContext} open={openPasswordModal} setOpen={setOpenPasswordModal} />


            {/* Main Hint Button */}
            <InvisibleButton onClick={() => setHintModal(true)} top={mainHintPos.y} left={mainHintPos.x} h={mainHintPos.h} w={mainHintPos.w} showHint={showHint}/>
            {/* Password Modal */}
            <InvisibleButton onClick={() => setOpenPasswordModal(true)} top={passwordPos?.y} left={passwordPos?.x} h={passwordPos?.h} w={passwordPos?.w} showHint={showHint}/>
        </>
    )
}