import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import {Button, Flex, Form} from "antd";
import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";
import t from "../../../../configs/i18n";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import Answer from "../../../../shared/services/answer/Answer";
import Notification from "../../../../shared/services/notification/Notification";
import { useEffect, useRef, useState } from "react";
import MouseFloatingText from "../../../../shared/components/mouse/MouseFloatingText";
import ImageRatioBackground from "../../../../shared/components/image/ImageRatioBackground";

interface Props {
    onValid?: () => void;
    popup?: boolean;
}

export default function ItStep6PasswordContent(props: Props) {
    const context = useGameContext();
    const backpackImage = context.step?.description.backpack
    const [form] = Form.useForm();
    const answers = context.step?.description.answers
    const id = `${context.team?.id}-${context.team?.step}`;

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [onHover, setOnHover] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    
    const updateButton = (value: string, id: string) => {
        // Check if all fields have values
        const allFieldsFilled = Object.values(form.getFieldsValue() as string[]).every(
            (value) => value !== undefined && value !== ""
        );
        setIsButtonDisabled(!allFieldsFilled); // Disable if not all fields are filled
    };

    const validate = () => {
        const isValid = Answer.isCorrectWithOrder(form.getFieldsValue(), answers);

        if (!isValid) {
            Notification.error(t.sessionGame.wrongAnswer);
            return;
        }

        props.onValid && props.onValid();
    }

    useEffect(() => {
        if (context.solution?.solutionOn) {
            form.setFieldValue(`${id}-1`, answers[0]);
            form.setFieldValue(`${id}-2`, answers[1]);
            setIsButtonDisabled(false);
        }
    }, [context.solution?.solutionOn]);


    return (
        <div ref={componentRef} className="w-full h-[58vh]">
            {(props.popup === undefined || props.popup) && <CanClosePopup/>}
            <Flex justify={"center"} align={"center"} className={"h-[50vh] w-full my-[1.5vh]"}>
                <ImageRatioBackground bgImage={backpackImage} modal={true}>
                    <div className="absolute top-[41%] left-[35%] w-[10%] translate-y-[-50%]">
                        <SyncronizedInput number={1} id={`${id}-1`} size={"large"} player={context.player} playersTeam={context.playersTeam} form={form} onChange={updateButton} className="border-[#FF7828] hover:border-[#FF7828] focus:border-[#FF7828] border-[3px] rounded-[15px]"/>
                    </div>
                    <div className="absolute top-[59%] left-[35%] w-[10%] translate-y-[-50%]">
                        <SyncronizedInput number={2} id={`${id}-2`} size={"large"} player={context.player} playersTeam={context.playersTeam} form={form} onChange={updateButton} className="border-[#D369E5] hover:border-[#D369E5] focus:border-[#D369E5] border-[3px] rounded-[15px]"/>
                    </div>
                </ImageRatioBackground>
            </Flex>
            <Flex justify={"center"} align={"center"}>
                <Flex onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
                    <Button onClick={validate} type={"primary"} disabled={isButtonDisabled}>{t.common.validate}</Button>
                </Flex>
            </Flex>
            <MouseFloatingText start={componentRef.current?.getBoundingClientRect()} condition={onHover && isButtonDisabled} text={t.common.allFieldsFilled}/>
        </div>
    )
}