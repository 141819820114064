import {Flex, Image} from "antd";
import React from "react";
import {Company} from "../../models/Company/company";
import Session from "../../models/Session/session";

interface SessionInfoCardProps {
    company: Company | undefined | null
    session: Session | undefined | null
}

export default function SessionInfoCard({company, session}: SessionInfoCardProps) {
    return (
        <Flex align={"center"} className="w-fit bg-beige h-18 p-2 ml-4 mt-4 pr-8 text-xs">
            <Image width={"40px"} src={company?.logo} />
            <Flex className={"ml-4 text-green-dark"} vertical>
                <p className={"m-0 font-bold"}>{company?.name}</p>
                <p className={"m-0"}>{session?.name}</p>
            </Flex>
        </Flex>
    );
}