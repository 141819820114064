import {Component} from "react";
import {ColumnLock} from "./ColumnLock";
import "./styles.css";

interface CombinationLockProps {
    code: string;
    height: number;
    onMatch: () => void;
    onUnmatch?: () => void;
    openText: string;
    mainClass: string;
}

interface CombinationLockState {
    checker: (number | null)[];
    opened: boolean;
}

export class CombinationLock extends Component<CombinationLockProps, CombinationLockState> {
    state: CombinationLockState = {
        checker: [],
        opened: false,
    }

    static defaultProps = {
        code: '01234',
        height: 80,
        onMatch: () => {},
        openText: '',
        mainClass: 'combination-lock',
    }

    checkCode = (number: number | null, id: number): void => {
        const { code, onMatch, onUnmatch } = this.props;
        const codeNumbers = [...code].map(Number);
        this.setState(state => {
            state.checker[id] = number;
            return { checker: state.checker };
        }, () => {
            if (codeNumbers.length === this.state.checker.length &&
                codeNumbers.every((el, i) => el === this.state.checker[i])) {
                onMatch && onMatch();
            }
            else {
                onUnmatch && onUnmatch();
            }
        });
    }

    render() {
        const { code, onMatch, openText, height, mainClass, ...props } = this.props;
        const { opened } = this.state;

        return (
            <div className={mainClass}>
                {!!openText && (
                    <div
                        className={`${mainClass}-open ${opened ? `${mainClass}-open--show` : ''}`}
                        style={{ position: 'absolute' }}
                    >
                        {openText}
                    </div>
                )}
                <div className={`${mainClass}-container`} style={{ overflow: 'hidden', height }}>
                    {[...code].map((v, i) => (
                        <ColumnLock
                            key={i}
                            id={i}
                            checkMatch={this.checkCode}
                            isOpen={opened}
                            height={height}
                            mainClass={mainClass}
                            {...props}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
