import {Flex} from "antd";
import ImageR from "../../../shared/components/image/ImageR";
import TotalTeamTimer from "../../../shared/components/time/TotalTeamTimer";
import {Team} from "../../../shared/models/Team/Team";
import Session from "../../../shared/models/Session/session";


interface RankingBarProps {
    team: Team | undefined;
    session: Session | undefined;
    number: number
}

export default function RankingBar({team, number, session}: RankingBarProps) {
    const bgColor = ["bg-green-light", "bg-[#FFC466]", "bg-[#f16b62]"];
    const height = ["h-2/3", "h-1/3", "h-1/4"];


    return (
        <Flex gap={"10px"} className={"h-full w-full"} vertical>

            <Flex className={"h-full"} align={"center"} justify={"flex-end"} vertical>
                {team?.photo && <ImageR src={team?.photo || ""} width={"230px"} height={"150px"} className={"border-solid border-white border-4 shadow max-h-[150px] object-scale-down"} />}
                <h2 className={"text-green-dark"}>{team?.name}</h2>
                {team?.endTime !== undefined && team?.endTime !== null && <TotalTeamTimer team={team} session={session}/>}
            </Flex>


            <Flex className={`w-full ${height[number - 1]} ${bgColor[number - 1]}`} justify={"center"} vertical>
                <Flex className={"w-full h-full"} align={"center"} justify={"center"}>
                    <h1 className={"text-white text-4xl m-0"}>{number}</h1>
                </Flex>
            </Flex>
        </Flex>

    )
}