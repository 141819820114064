import {Flex, Modal} from "antd";
import HintEcoStep7 from "../hint/eco.step.7";

export interface BaseHintProps {
    number: number
    open: boolean
    setOpen: (open: boolean) => void
}

export default function HintModalStep7({number, open, setOpen}: BaseHintProps) {
    const modalStyles = {
        content: {
            backgroundColor: "#023A64"
        }
    }

    return (
        <Modal mask={false} styles={modalStyles} open={open} footer={false} onCancel={() => setOpen(false)}>
            <Flex className={"w-full h-full"} justify={"center"}>
                <HintEcoStep7 number={number} />
            </Flex>
        </Modal>
    )
}