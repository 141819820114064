import {Button, Flex, Table} from "antd";
import React from "react";
import {PlusOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import useListSession from "./callbacks/useListSession";
import t from "../../../../../configs/i18n";

interface Props {
    type: "past" | "future" | "current" | "all"
}

export default function ListSessionHome(props: Props) {
    const listSession = useListSession(props.type);

    return (
        <Flex align={"center"} className="w-full h-full pl-12 pr-12" vertical>
            <Flex justify={"flex-end"} className={"w-full mt-8"}>
                {listSession.context.user?.isAdmin() && (props.type === "all" || props.type === "future") &&
                    <Link to={"/home/session/create"}>
                        <Button type={"primary"}><PlusOutlined />{t.home.session.create}</Button>
                    </Link>
                }
            </Flex>
            <Table columns={listSession.columns} dataSource={listSession.sessions} className={`w-full ${props.type === "all" ? "mt-8" : "mt-24"}`}/>
        </Flex>
    )
}
