import {Button, Card, Flex, Form, Image, Input} from "antd";
import BannerLogin from "./components/banner.login";
import LoginLogo from "./assets/logo.login.svg";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import t from "../../configs/i18n";
import useLogin from "./callbacks/useLogin";

interface LoginPageProps {
    passwordLogin?: boolean;
    tablet?: boolean;
}

function LoginPage({passwordLogin, tablet}: LoginPageProps) {
    const {form, loading, login} = useLogin(passwordLogin??false);


    return (
        <Flex align="center" vertical>
            <BannerLogin/>
            <h1 className={"text-green-500"} >{t.login.title}</h1>
            <Flex className="w-full" justify={"space-evenly"} align={"center"}>
                <Image width={600} className={"max-w-[550px] min-w-[400px] max-h-[550px] min-h-[400px]"} preview={false} src={LoginLogo}/>

                <Card title={t.login.cardTitle} headStyle={{color: '#14665F', textAlign: "center"}} className="h-80 w-80">
                    <Form form={form}>

                        {!tablet && <Form.Item name="mail" rules={[{ required: true, message: "" }]}>
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t.common.mail} />
                        </Form.Item>}

                        {passwordLogin && <Form.Item name="password" rules={[{ required: true, message: "" }]}>
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder={t.common.password} />
                        </Form.Item>}

                        <Form.Item>
                            <Button onClick={login} loading={loading} className="w-full" type="primary">
                                {t.common.connect}
                            </Button>
                        </Form.Item>
                    </Form>
                    {
                        passwordLogin && 
                        <Flex>
                            <a className="text-gray-400 text-xs text-center w-full -my-4 underline" href="/">{t.login.imaplayer}</a>
                        </Flex>
                    }
                </Card>
            </Flex>
        </Flex>
    );
}

export default LoginPage;
