import {useGameContext} from "../../../pages/session_game/context/context.game";
import ImageRatioBackground from "../../../shared/components/image/ImageRatioBackground";
import { useState } from "react";
import HintModalStep6 from "./components/HintModal.step6";
import PasswordModalStep6 from "./components/PasswordModal.step6";
import InvisibleButton from "../../../shared/components/button/InvisibleButton";

export default function ItStep6() {
    const context = useGameContext();
    const [hint1Modal, setHint1Modal] = useState(false);
    const [hint2Modal, setHint2Modal] = useState(false);
    const [computerModal, setComputerModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);

    return (
        <ImageRatioBackground bgImage={context.step?.description.background}>
            <InvisibleButton onClick={() => setHint1Modal(true)} top={45} left={74} h={13} w={6} posInPercent/>
            <InvisibleButton onClick={() => setHint2Modal(true)} top={44.6} left={92.4} h={13} w={6} posInPercent/>
            <InvisibleButton onClick={() => setComputerModal(true)} top={44.5} left={43} h={15} w={12.5} posInPercent/>
            <InvisibleButton onClick={() => setPasswordModal(true)} top={77.5} left={33} h={21} w={10.5} posInPercent/>

            <HintModalStep6 number={1} open={hint1Modal} setOpen={setHint1Modal}/>
            <HintModalStep6 number={2} open={hint2Modal} setOpen={setHint2Modal}/>
            <HintModalStep6 number={3} open={computerModal} setOpen={setComputerModal}/>
            <PasswordModalStep6 open={passwordModal} setOpen={setPasswordModal}/>
        </ImageRatioBackground>
    )
}