import FR from "../locales/fr";
import EN from "../locales/en";
import LocaleText from "../locales/localeText";

const availableLanguages = ['fr', 'en']
let currentLanguage : string = localStorage.getItem("tw-lang") || navigator.language.split('-')[0].toLowerCase();

if (!availableLanguages.includes(currentLanguage)) {
    currentLanguage = 'en';
}

let t : LocaleText = new FR();
if (currentLanguage === "en") {
    t = new EN();
}

export const forceLanguage = (lang: string) => {
    let newLang;
    if (lang === "fr") {
        newLang = new FR();
    } else {
        newLang = new EN();
    }
    localStorage.setItem("tw-lang", lang);

    if (t.common.congratulations !== newLang.common.congratulations) {
        t = newLang;
        window.location.reload();
    }
}

export const getCustomLanguage = (lang: string) => {
    if (lang === "fr") {
        return new FR();
    } else {
        return new EN();
    }
}
export const getCurrentLanguage = () => currentLanguage;
export default t;