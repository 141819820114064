import {Button, Flex} from "antd";
import {UserOutlined} from "@ant-design/icons";
import PlayerHintPopup from "./PlayerHintPopup";
import {useState} from "react";
import {Team} from "../../../../shared/models/Team/Team";
import t from "../../../../configs/i18n";

interface PlayerButtonProps {
    number: number;
    team: Team | undefined;
}

export default function PlayerHintButton({number, team}: PlayerButtonProps) {
    const [open, setOpen] = useState(false);



    return(
        <Flex>
            <PlayerHintPopup number={number} open={open} setOpen={setOpen}/>
            <Button onClick={() => setOpen(true)} className={"h-20"}>
                <Flex align={"center"} justify={"center"} vertical>
                    <UserOutlined className={"text-2xl m-0"} />
                    <p className={"m-0 mt-2"}>{t.common.player} {number}</p>
                </Flex>
            </Button>
        </Flex>
    )
}