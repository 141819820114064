import {Modal} from "antd";
import HintItStep4 from "../hint/it.step.4";

export interface BaseHintProps {
    number: number
    open: boolean
    setOpen: (open: boolean) => void
}

export default function HintModalStep4({number, open, setOpen}: BaseHintProps) {
    const modalStyles = {
        content: {
            backgroundColor: "#DBC6AD"
        }
    }

    return (
        <Modal styles={modalStyles} open={open} footer={false} onCancel={() => setOpen(false)}>
            <HintItStep4 number={number} />
        </Modal>
    )
}