import StepProps from "../../types/StepProps";
import {Flex} from "antd";
import ButtonsStep5 from "./components/buttonsStep5";
import ImageR from "../../../shared/components/image/ImageR";
import {useGameContext} from "../../../pages/session_game/context/context.game";

export default function EcoStep5() {
    const context = useGameContext();
    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }
    const backgroundUrl = `${context.step?.description.background}${stepContext.player?.number}.png`;

    return (
        <Flex align={"flex-start"} className={"w-[1000px] h-[640px] relative"}>
            <ImageR src={backgroundUrl} width={"1000px"} height={"600px"} className={"object-scale-down"}/>
            {stepContext.player?.number === 1 && <ButtonsStep5 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={1} mainHintPos={{x: 50, y: 40, h: 230, w: 300}} passwordPos={{x: 30, y: 295, h: 70, w: 100}} />}
            {stepContext.player?.number === 2 && <ButtonsStep5 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={2} mainHintPos={{x: 80, y: 80, h: 120, w: 250}} passwordPos={{x: 30, y: 295, h: 70, w: 100}} />}
            {stepContext.player?.number === 3 && <ButtonsStep5 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={3} mainHintPos={{x: 400, y: 180, h: 230, w: 170}} passwordPos={{x: 320, y: 280, h: 50, w: 50}} />}
            {stepContext.player?.number === 4 && <ButtonsStep5 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={4} mainHintPos={{x: 400, y: 180, h: 230, w: 170}} passwordPos={{x: 320, y: 280, h: 50, w: 50}} />}
        </Flex>
    )
}