import {Flex, notification, Select, Table, TableProps} from "antd";
import React, {useMemo} from "react";
import {Team} from "../../../../../shared/models/Team/Team";
import {Player} from "../../../../../shared/models/Player/Player";
import Session from "../../../../../shared/models/Session/session";
import {UserData} from "../../../../../shared/models/UserData/UserData";
import scenarios from "../../../../../scenarios/scenarios";
import {supabase} from "../../../../../configs/supabase";
import Notification from "../../../../../shared/services/notification/Notification";
import TotalTeamTimer from "../../../../../shared/components/time/TotalTeamTimer";
import PlayerBox from "../../../../../shared/components/player/PlayerBox";
import TeamActions from "../TeamActions";


interface TeamTableGameProps {
    teams: Team[] | undefined;
    players: Player[] | undefined;
    onlinePlayers: Set<string>;
    session: Session | undefined;
    user: UserData | undefined;
    minStepTeam: number | undefined;
}


export default function TeamTableGameTablet({teams, players, onlinePlayers, session, user, minStepTeam} : TeamTableGameProps) {
    const scenario = useMemo(() => scenarios[session?.scenario || ""], [session?.scenario]);

    const columns: TableProps<Team>['columns'] = [
        {
            title: 'Infos',
            dataIndex: '',
            key: 'name',
            render: (team : Team) => {
                const onChooseGm = async (gmId: string) => {
                    const response = await supabase.from("team").update({gamemaster: gmId}).eq("id", team.id);
                    if (response.error) {
                        Notification.error("Erreur lors de la mise à jour du gamemaster", response.error);
                        return;
                    }

                    notification.success({message: "Gamemaster mis à jour"});
                }

                const color = team.getNeedGamemaster(players) ? "text-red-500" : "";
                return (
                    <Flex className={"w-full h-full"}>
                        {minStepTeam === team.step && team.step !== 1 && <div className={"w-[6px] bg-orange-400 rounded-r mr-2"}><p></p></div>}
                        <Flex align={"center"} justify={"center"} vertical>
                            <h4 className={color}>{team.step}/{scenario.steps.length} - {team.name}</h4>
                            <Select<string> disabled={!user?.isAdmin()} onChange={onChooseGm} size="small" className="w-36" options={session?.gamemasters?.map(gm => ({label: gm.name, value: gm.uid}))}
                                            value={team.gamemaster} placeholder={"Choisir un gamemaster"}/>

                            {team.endTime && <Flex className={"mt-4"}><TotalTeamTimer team={team} session={session} /></Flex>}
                        </Flex>
                    </Flex>
                )
            }
        },
        {
            title: 'Joueurs',
            dataIndex: '',
            key: 'players',
            render: (team : Team | undefined) => {
                return (
                    <Flex className={"w-full"}>
                        {team?.otherPlayers.map(player => <PlayerBox name={player} online={true} popup={false}/>)}
                    </Flex>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: '',
            key: 'actions',
            render: (team : Team) => {
                return <TeamActions user={user} team={team} players={players}/>
            }
        }
    ];

    return (
        <Table pagination={false} size={"small"} columns={columns} dataSource={teams} className="w-full mt-4 pl-12 pr-12 box-border"/>
    )
}