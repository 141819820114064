import {Flex, Modal} from "antd";
import {LockOutlined} from "@ant-design/icons";
import {UserData} from "../../../shared/models/UserData/UserData";
import Const from "../../../shared/services/const/Const";
import t from "../../../configs/i18n";

interface WaitingGamemasterPopupProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    currentUser: UserData | undefined;
    caller: string;
}

export default function WaitingGamemasterPopup({open, setOpen, caller, currentUser}: WaitingGamemasterPopupProps) {
    return (
        <Modal wrapClassName={"pointer-events-none bg-black bg-opacity-40"} mask={false} zIndex={Const.WAITING_GM_Z_INDEX} onCancel={() => setOpen(false)} destroyOnClose={false} keyboard={false} open={open} footer={null} closeIcon={currentUser?.isGamemaster()}>
        <Flex className={"text-center"} align={"center"} justify={"center"} vertical>
            <LockOutlined  className={"mt-6 mb-4 text-5xl text-red-500"}/>
            <h3>{t.sessionGame.gamemasterPopup.title}</h3>
            <p>{t.sessionGame.gamemasterPopup.calledBy} <strong>{caller}</strong>.</p>
            <p>{t.sessionGame.gamemasterPopup.messageChrono}</p>
        </Flex>
    </Modal>
    )
}