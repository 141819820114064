import {Carousel, Modal} from "antd";
import {useGameContext} from "../../context/context.game";

interface PlayerHintPopupProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    number: number;
}

export default function PlayerHintPopup({open, setOpen, number}: PlayerHintPopupProps) {
    const context = useGameContext();

    return (
        <Modal open={open} onCancel={() => setOpen(false)} footer={false}>
            <Carousel>
                {context.step?.baseHints.map(HintComponent => {
                    return (
                        <HintComponent number={number}/>
                    )
                })}
            </Carousel>
        </Modal>
    )
}