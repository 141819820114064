import {useNavigate} from "react-router-dom";
import {Form} from "antd";
import React from "react";
import {supabase} from "../../../../../configs/supabase";
import {UploadRequestOption} from "rc-upload/lib/interface";
import {v4 as generateUUID} from "uuid";

export default function useCreateCompany() {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [image, setImage] = React.useState<string | undefined>("");

    const finish = async () => {
        await supabase
            .from("company")
            .insert({
                name: form.getFieldValue("name"),
                logo: image
            })

        navigate(-1);
    }

    const uploadImage = async (options : UploadRequestOption) => {
        const {onSuccess, onError, file, onProgress} = options;
        const fileName = generateUUID();

        await supabase
            .storage
            .from('data')
            .upload(fileName, file)

        const result = supabase
            .storage
            .from('data')
            .getPublicUrl(fileName)

        setImage(result.data?.publicUrl);
        onSuccess!("ok");
    }

    return {
        form,
        finish,
        uploadImage
    }
}