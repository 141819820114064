import {Button, Flex} from "antd";
import ImageR from "../../shared/components/image/ImageR";
import logo from "../../shared/assets/images/logo_square.webp";
import {Link} from "react-router-dom";

export default function UnknownPage() {
    return (
        <Flex className={"h-full relative top-[-50px]"} align={"center"} justify={"center"} vertical>
            <ImageR src={logo} width={"200px"} height={"200px"}/>
            <h2 className={"text-green-dark"}>Il semble que cette page n'existe pas ...</h2>
            <Link to={"/"}>
                <Button size={"large"} type={"primary"}>Revenir à l'accueil</Button>
            </Link>
        </Flex>
    )
}