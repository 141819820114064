import { Button } from "antd";
import { supabase } from "../../../configs/supabase";
import { useGameContext } from "../../../pages/session_game/context/context.game";
import Notification from "../../services/notification/Notification";
import { WarningOutlined } from "@ant-design/icons";
import t from "../../../configs/i18n";

interface CallGamemasterProps {
    setOpen: (value: boolean) => void;
}

export default function CallGamemaster({setOpen}: CallGamemasterProps) {
    const { player } = useGameContext();

    const callGamemaster = async () => {
        const repsonse = await supabase
            .rpc("call_gamemaster", {
                player_id: player?.playerId,
            });

        if (repsonse.error) {
            Notification.error(t.error.gamemaster, repsonse.error);
        }

        setOpen(false)
    }

    return (
        <Button onClick={callGamemaster} className={"mb-6 mt-4"} type={"primary"} danger><WarningOutlined/> {t.sessionGame.sosPopup.buttonGamemaster}</Button>
    )
}