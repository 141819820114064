import StepProps from "../../../types/StepProps";
import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";
import React, {useMemo, useState} from "react";
import {Flex, FormInstance} from "antd";

interface InputCategoryProps {
    stepContext: StepProps;
    group: number;answers: string[];
    descriptions: string[];
    form: FormInstance
    title: string;
    min: number;
}

export default function InputCategoryStep9({title, min, stepContext, group, answers, descriptions, form}: InputCategoryProps) {
    const [userAnswers, setUserAnswers] = useState<Map<string, string>>(new Map());

    const validAnswers = useMemo(() => {
        const start = (group - 1) * 4;
        return answers.slice(start, start + 4);
    }, [answers, group])

    const onChangeInput = (value: string, id: string) => {
        setUserAnswers(oldMap => new Map(oldMap.set(id, value)))
    }


    const valid = useMemo(() => {
        const userAnswersSet = new Set(Array.from(userAnswers.values()))

        for (const validAnswer of validAnswers) {
            if (!userAnswersSet.has(validAnswer)) {
                return false;
            }
        }
        return true;
    }, [userAnswers, validAnswers])

    const bgColor = useMemo(() => valid ? "bg-green-light focus:bg-green-light hover:bg-green-light disabled:bg-green-light" : "", [valid])

    return (
        <>
            <h2 className={"text-green-dark w-full text-center"}>{title}</h2>
            <Flex align={"center"}>
                <SyncronizedInput onChange={onChangeInput} className={`w-[50px] m-2 ${bgColor}`} form={form} id={`${min}-${stepContext.team?.id}-${stepContext.team?.step}`} number={group} player={stepContext.player} playersTeam={stepContext.playersTeam}/>
                {answers.includes(userAnswers.get(`${min}-${stepContext.team?.id}-${stepContext.team?.step}`) || "") && <p>{descriptions[answers.findIndex(value => value === userAnswers.get(`${min}-${stepContext.team?.id}-${stepContext.team?.step}`))]}</p>}
            </Flex>
            <Flex align={"center"}>
                <SyncronizedInput onChange={onChangeInput} className={`w-[50px] m-2 ${bgColor}`} form={form} id={`${min + 1}-${stepContext.team?.id}-${stepContext.team?.step}`} number={group} player={stepContext.player} playersTeam={stepContext.playersTeam}/>
                {answers.includes(userAnswers.get(`${min + 1}-${stepContext.team?.id}-${stepContext.team?.step}`) || "") && <p>{descriptions[answers.findIndex(value => value === userAnswers.get(`${min + 1}-${stepContext.team?.id}-${stepContext.team?.step}`))]}</p>}
            </Flex>
            <Flex align={"center"}>
                <SyncronizedInput onChange={onChangeInput} className={`w-[50px] m-2 ${bgColor}`} form={form} id={`${min + 2}-${stepContext.team?.id}-${stepContext.team?.step}`} number={group} player={stepContext.player} playersTeam={stepContext.playersTeam}/>
                {answers.includes(userAnswers.get(`${min + 2}-${stepContext.team?.id}-${stepContext.team?.step}`) || "") && <p>{descriptions[answers.findIndex(value => value === userAnswers.get(`${min + 2}-${stepContext.team?.id}-${stepContext.team?.step}`))]}</p>}
            </Flex>
            <Flex align={"center"}>
                <SyncronizedInput onChange={onChangeInput} className={`w-[50px] m-2 ${bgColor}`} form={form} id={`${min + 3}-${stepContext.team?.id}-${stepContext.team?.step}`} number={group} player={stepContext.player} playersTeam={stepContext.playersTeam}/>
                {answers.includes(userAnswers.get(`${min + 3}-${stepContext.team?.id}-${stepContext.team?.step}`) || "") && <p>{descriptions[answers.findIndex(value => value === userAnswers.get(`${min + 3}-${stepContext.team?.id}-${stepContext.team?.step}`))]}</p>}
            </Flex>
     </>
    )
}