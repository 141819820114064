import {Flex} from "antd";
import {useEffect, useRef} from "react";

interface Props {
    url: string;
}

export default function Video(props: Props) {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        videoRef.current?.play();
    }, []);

    return (
        <Flex className={"w-full h-full"}>
            <video ref={videoRef} controls className={"w-full h-full"}>
                <source src={props.url} />
            </video>
        </Flex>
    )
}