import {Form, FormInstance, Select} from "antd";
import React, {useEffect, useRef} from "react";
import {InputValue} from "../../models/Team/InputValue";
import {Player} from "../../models/Player/Player";
import SyncService from "../../services/sync/SyncService";
import t from "../../../configs/i18n";

interface SyncronizedInputProps {
    id : string;
    player: Player | undefined;
    playersTeam: Player[];
    options: {label: string, value: string}[];
    form?: FormInstance;
    number?: number;
    className?: string;
    placeHolder?: string;
    getValue?: (value: string) => void;
    size?: "large" | "middle" | "small";
    onChange?: (value: string, id: string) => void;
    isDisabled?: boolean;
    width?: number;
    height?: number | string;
}



export default function SyncronizedSelect({id, player, options, playersTeam, number, form, className = "", placeHolder = "", size = "middle", onChange = () => {}, isDisabled = false, width = 200, height = "auto"} : SyncronizedInputProps) {
    const fetchData = () => {
        InputValue.byId(id, false).then(input => {
            if (!input) {
                return;
            }
            onChange(input?.value || "", id);
            form?.setFieldValue(id, input?.value);
        });

        return InputValue.listen(id, player?.getId(), (value) => {
            onChange(value || "", id);
            form?.setFieldValue(id, value);
        });
    }

    useEffect(() => {
        const channel = fetchData()
        SyncService.register(id, fetchData);

        return () => {
            channel?.unsubscribe();
        }
    }, [form, id, player?.getId()]);

    const onChangeInput = async (value : any) => {
        if (!value) {
            return;
        }

        onChange(value, id);
        await InputValue.updateOrCreate(id, value, player?.getId())
    }


    const playerToAnswer = number ? playersTeam.find(p => p.number === number) : null;
    const playerDisabled = !!(player?.number !== number && playerToAnswer);
    placeHolder = playerDisabled ? t.sessionGame.game.inputOtherPlayer : placeHolder;

    return (
            <Form form={form} preserve={false}>
                <Form.Item className={"m-0"} name={id}>
                    <Select style={{width: width, height: height}} options={options} size={size} disabled={playerDisabled || isDisabled} onChange={onChangeInput} className={className} placeholder={placeHolder}/>
                </Form.Item>
            </Form>
    )

}