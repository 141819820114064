import {useEffect, useState} from "react";
import Session from "../../../../../../shared/models/Session/session";
import {Team} from "../../../../../../shared/models/Team/Team";
import SessionService from "../../../../../../shared/models/Session/sessionService";
import {Player} from "../../../../../../shared/models/Player/Player";
import {SessionInfoContext} from "./context.info.session";
import SyncService from "../../../../../../shared/services/sync/SyncService";
import {UserData} from "../../../../../../shared/models/UserData/UserData";

interface Props {
    children: React.ReactNode;
    sessionId: string | undefined;
}

export const SessionInfoProvider = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [session, setSession] = useState<Session>();
    const [sessionRealtime, setSessionRealtime] = useState<Session>();
    const [teams, setTeams] = useState<Team[]>([]);
    const [players, setPlayers] = useState<Player[]>([]);
    const [currentUser, setCurrentUser] = useState<UserData>();


    const fetchData = async () => {
        const session = await Session.byId(props.sessionId, "*, company(*)")
        setSession(session)
        setSessionRealtime(session)
        session?.listen(setSessionRealtime, () => SyncService.desync(), () => SyncService.sync())

        const teams = await Team.bySession(session, "*")
        setTeams(teams)
        Team.listenInSession(session, setTeams)

        const players = await Player.bySession(session)
        setPlayers(players);
        Player.listenBySession(session, setPlayers);

        const currentUser = await UserData.getCurrentUser();
        setCurrentUser(currentUser);

        setLoading(false)
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <SessionInfoContext.Provider value={{loading, session, sessionRealtime, teams, players, currentUser}}>
            {props.children}
        </SessionInfoContext.Provider>
    );
}