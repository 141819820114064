import {Team} from "../../../../shared/models/Team/Team";
import {Flex, notification, Select, Table, TableProps} from "antd";
import React, {useMemo} from "react";
import {Player} from "../../../../shared/models/Player/Player";
import PlayerBox from "../../../../shared/components/player/PlayerBox";
import TeamInputName from "./TeamInputName";
import Session from "../../../../shared/models/Session/session";
import {supabase} from "../../../../configs/supabase";
import Notification from "../../../../shared/services/notification/Notification";
import {UserData} from "../../../../shared/models/UserData/UserData";
import scenarios from "../../../../scenarios/scenarios";
import TeamTableTablet from "./Tablet/TeamTableTablet";
import TeamActions from "./TeamActions";
import TotalTeamTimer from "../../../../shared/components/time/TotalTeamTimer";

interface TeamTableProps {
    teams: Team[] | undefined;
    session: Session | undefined;
    players: Player[] | undefined;
    onlinePlayers: Set<string>;
    user: UserData | undefined;
}


export default function TeamTable({teams, session, players, onlinePlayers, user} : TeamTableProps) {
    const scenario = useMemo(() => scenarios[session?.scenario as string], [session?.scenario])


    const columns: TableProps<Team>['columns'] = [
        {
            title: 'Equipe',
            dataIndex: '',
            key: 'name',
            render: (team : Team) => <Flex align={"center"} vertical>
                <TeamInputName team={team} />
                {team.endTime != 0 && <TotalTeamTimer team={team} session={session} />}
            </Flex>
        },
        {
            title: 'Joueur 1',
            dataIndex: '',
            key: 'player1',
            render: (team : Team | undefined) => {
                const player = players?.find(player => player.team === team?.id && player.number === 1);
                return <PlayerBox team={team} number={1} online={onlinePlayers.has(player?.getId() || "")} player={player} teams={teams} />;
            }
        },
        {
            title: 'Joueur 2',
            dataIndex: '',
            key: 'player2',
            render: (team : Team | undefined) => {
                const player = players?.find(player => player.team === team?.id && player.number === 2);
                return <PlayerBox team={team} number={2} online={onlinePlayers.has(player?.getId() || "")} player={player} teams={teams} />;
            }
        },
        {
            title: 'Joueur 3',
            dataIndex: '',
            key: 'player3',
            render: (team : Team | undefined) => {
                const player = players?.find(player => player.team === team?.id && player.number === 3);
                return <PlayerBox team={team} number={3} online={onlinePlayers.has(player?.getId() || "")} player={player} teams={teams} />;
            }
        },
        {
            title: 'Joueur 4',
            dataIndex: '',
            render: (team : Team | undefined) => {
                const player = players?.filter(player => player.team === team?.id && player.number === 4).at(0);
                return <PlayerBox team={team} number={4} online={onlinePlayers.has(player?.getId() || "")} player={player} teams={teams} />;
            }
        },
        {
            title: 'Joueur 4 bis',
            dataIndex: '',
            render: (team : Team | undefined) => {
                const player = players?.filter(player => player.team === team?.id && player.number === 4).at(1);
                return <PlayerBox team={team} number={4} online={onlinePlayers.has(player?.getId() || "")} player={player} teams={teams} />;
            }
        },
        {
            title: 'Gamemaster',
            dataIndex: '',
            key: 'gamemaster',
            render: (team : Team ) => {
                const onChooseGm = async (gmId: string) => {
                    const response = await supabase.from("team").update({gamemaster: gmId}).eq("id", team.id);
                    if (response.error) {
                        Notification.error("Erreur lors de la mise à jour du gamemaster", response.error);
                        return;
                    }

                    notification.success({message: "Gamemaster mis à jour"});
                }

                return (
                    <Select<string> disabled={!user?.isAdmin()} onChange={onChooseGm} size="small" className="w-36" options={session?.gamemasters?.map(gm => ({label: gm.name, value: gm.uid}))}
                                    value={team.gamemaster} placeholder={"Choisir un gamemaster"}/>
                )
            }
        },
        {
            title: '',
            dataIndex: '',
            key: 'actions',
            render: (team : Team) => {
                return <TeamActions onlyInfo={true} user={user} team={team} players={players}/>
            }
        }
    ];

    return (
        <>
            {!scenario.tablet && <Table pagination={false} size={"small"} columns={columns} className="w-full mt-4 pl-5 pr-5" dataSource={teams?.sort(
                (a, b) => {
                    const t1: number | undefined = a?.getTotalTime(session);
                    const t2: number | undefined = b?.getTotalTime(session);

                    return t1 ? t2 ? t1 - t2 : -1 : 1;
                }
            )}/>}
            {scenario.tablet && <TeamTableTablet session={session} teams={teams} players={players} user={user}/>}
        </>
    )
}