import {Button, Flex, Modal} from "antd";
import t from "../../../configs/i18n";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    onOk: () => void;
}

export default function PopupConfirmation(props: Props) {
    return (
        <Modal open={props.open} onCancel={() => props.setOpen(false)} footer={false}>
            <Flex align={"center"} justify={"center"} className={"w-full h-full text-center"} vertical>
                <QuestionCircleOutlined className={"text-6xl text-green-light mt-6"} />
                <h2 className={"mt-12 text-green-light -mb-2"}>{t.sessionGame.confirmationGoToQuizzL1}</h2>
                <h4 className="font-normal">{t.sessionGame.confirmationGoToQuizzL2}</h4>
                <Flex className={"mt-8"} gap={"10px"}>
                    <Button onClick={props.onOk} className={"w-[80px]"} size={"large"}>{t.common.yes}</Button>
                    <Button onClick={() => props.setOpen(false)} className={"w-[80px]"} size={"large"}>{t.common.no}</Button>
                </Flex>
            </Flex>
        </Modal>
    )
}