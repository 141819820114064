import Question from "../step_quizz/type/Question";
import StepProps from "../../types/StepProps";
import SubStepVideo from "./SubStepVideo";
import StepQuizz from "../step_quizz/StepQuizz";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import {Flex} from "antd";
import scenarios from "../../scenarios";
import { useMemo } from "react";


export default function StepVideoQuizz() {
    const context = useGameContext();
    const video : string = context.step?.description.video;
    const questions : Question[] = context.step?.description.questions;
    const isSolo = useMemo(() => scenarios[context.session?.scenario ?? ''].solo, [context.session?.scenario]);

    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }


    return (
        <Flex justify="center" className={isSolo ? 'w-full h-full bg-beige' : ''}>
            <StepQuizz preComponent={<SubStepVideo video={video} stepContext={stepContext} />} />
        </Flex>
    )
}