import {Team} from "../../models/Team/Team";
import TimeVisualizer from "./TimeVisualizer";
import {useMemo} from "react";
import Session from "../../models/Session/session";

interface TotalTeamTimerProps {
    team: Team | undefined;
    session: Session | undefined;
}

export default function TotalTeamTimer({team, session}: TotalTeamTimerProps) {

    const timestamp = useMemo(() => team?.getTotalTime(session), [session, team]);

    return (
        <TimeVisualizer timestampDifference={timestamp}/>
    )
}