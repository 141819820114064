import StepProps from "../../types/StepProps";
import {Flex} from "antd";
import ButtonsStep9 from "./components/buttonsStep9";
import ImageR from "../../../shared/components/image/ImageR";
import {useGameContext} from "../../../pages/session_game/context/context.game";

export default function EcoStep9() {
    const context = useGameContext();
    const stepContext : StepProps = {
        team: context.team,
        player: context.player,
        playersTeam: context.playersTeam,
        showHint: context.showHint,
        session: context.session,
        currentUser: context.user
    }


    const backgroundUrl = `${context.step?.description.background}${stepContext.player?.number}.png`;

    return (
        <Flex align={"flex-start"} className={"w-[1000px] h-[640px] relative"}>
            <ImageR src={backgroundUrl} width={"1000px"} height={"600px"} className={"object-scale-down"}/>
            {stepContext.player?.number === 1 && <ButtonsStep9 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={1} mainHintPos={{x: 230, y: 300, h: 100, w: 90}} />}
            {stepContext.player?.number === 2 && <ButtonsStep9 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={2} mainHintPos={{x: 230, y: 300, h: 100, w: 90}} />}
            {stepContext.player?.number === 3 && <ButtonsStep9 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={3} mainHintPos={{x: 230, y: 300, h: 100, w: 90}} />}
            {stepContext.player?.number === 4 && <ButtonsStep9 stepContext={stepContext} showHint={stepContext.showHint} playerNumber={4} mainHintPos={{x: 230, y: 300, h: 100, w: 90}}/>}
        </Flex>
    )
}