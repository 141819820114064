import {Button, Flex, Input, Modal, notification} from "antd";
import {useEffect, useRef, useState} from "react";
import PopupGoodAnswer from "../../../shared/components/popup/PopupGoodAnswer";
import Answer from "../../../shared/services/answer/Answer";
import Notification from "../../../shared/services/notification/Notification";
import {useGameContext} from "../../../pages/session_game/context/context.game";
import t from "../../../configs/i18n";
import MouseFloatingText from "../../../shared/components/mouse/MouseFloatingText";
import ImageRatioBackground from "../../../shared/components/image/ImageRatioBackground";

export default function ItStep1() {
    const containerRefModal = useRef<HTMLDivElement>(null);
    const [answerModal, setAnswerModal] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [userAnswer, setUserAnswer] = useState<string>("");
    const [onHoverValidate, setOnHover] = useState<boolean>(false);

    const context = useGameContext();
    const onClickLock = () => {
        setAnswerModal(true)
    }

    const updateAnswer = (answer: string) => {
        if (answer.length <= 4 && (answer.match(/^\d+$/) || answer === "")) {
            setUserAnswer(answer)
        }
    }

    useEffect(() => {
        if (context.solution?.solutionOn) {
            setUserAnswer(context.step?.description.answer)
            setAnswerModal(true)
            notification.success({message: t.sessionGame.hints.solutionComplete})
        }
    }, [context.solution?.solutionOn])


    const validateAnswer = () => {
        if (!Answer.isCorrect(userAnswer, context.step?.description.answer)) {
            Notification.error(t.sessionGame.wrongAnswer)
            return;
        }

        setSuccessModal(true);
    }


    return (
        <Flex className={"w-full h-full relative"}>
            <PopupGoodAnswer currentUser={context.user} playersTeam={context.playersTeam} player={context.player} team={context.team} open={successModal} setOpen={setSuccessModal}/>

            <Modal open={answerModal} onCancel={() => !context.solution?.solutionOn && setAnswerModal(false)} footer={null} closable={!context.solution?.solutionOn}>
                <div ref={containerRefModal}></div>
                <p className={"mt-10"}>{context.step?.description.question}</p>
                <Flex className="text-center justify-center  text-[1.2em]">
                    <Input
                        onChange={(e) => updateAnswer(e.target.value)}
                        value={userAnswer}
                        className={`text-center w-[4em] mr-2 ${context.solution?.solutionOn && "bg-green-500 hover:bg-green-500 hover:cursor-not-allowed text-white"}`}
                    />
                    <div className="mt-[3px]">%</div>
                </Flex>
                <Flex justify={"center"} className={"mt-10"} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
                    <Button onClick={validateAnswer} className="w-full" type="primary" htmlType="submit" disabled={!userAnswer}>
                        {t.common.validate}
                    </Button>
                </Flex>
                <MouseFloatingText text={t.common.allFieldsFilled} condition={!userAnswer && onHoverValidate} start={containerRefModal.current?.getBoundingClientRect()}/>
            </Modal>



            <ImageRatioBackground bgImage={context.step?.description.backgroundLight}>
                <div className={"absolute"} style={{left: `33%`, top: `5%`, width: `34%`, height: `42%`}}>
                    <video controls width={"100%"} src={context.step?.description.video}/>
                </div>
                <div className={`absolute cursor-pointer bg-transparent`} style={{left: `94%`, top: `31.5%`, width: `3%`, height: `7%`}} onClick={onClickLock} />
            </ImageRatioBackground>
        </Flex>
    );
}
