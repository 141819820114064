import {useEffect, useState} from "react";
import {HomeContext} from "./context.home";
import UserDataService from "../../../shared/models/UserData/UserDataService";
import SessionService from "../../../shared/models/Session/sessionService";
import CompanyService from "../../../shared/models/Company/companyService";
import {UserData} from "../../../shared/models/UserData/UserData";
import Session from "../../../shared/models/Session/session";
import {Company} from "../../../shared/models/Company/company";
import {useNavigate} from "react-router-dom";
import Notification from "../../../shared/services/notification/Notification";
import {ErrorCode} from "../../../shared/services/const/ErrorCode";
import t from "../../../configs/i18n";

interface Props {
    children: React.ReactNode;
}

export const HomeProvider = (props: Props) => {
    const [user, setUser] = useState<UserData>();
    const [sessions, setSessions] = useState<Session[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();


    const fetchData = async () => {
        const user = await UserData.getCurrentUser(false);
        setUser(user);

        const queryParams = new URLSearchParams(window.location.search);
        const apiValue = queryParams.get('api');

        if (!user) {
            if (apiValue === "true") {
                Notification.error(t.error.linkExpired, ErrorCode.EMPTY_USER);
            } else {
                Notification.error(t.error.notConnected, ErrorCode.EMPTY_USER);
            }
            navigate("/");
            return;
        }

        if (user?.isAdmin()) {
            setSessions(await SessionService.all("*, company(logo)"));
            setCompanies(await CompanyService.all())
        } else if (user?.isGamemaster()) {
            setSessions(await SessionService.gamemaster(user?.uid || ""));
        } else {
            setSessions(await SessionService.user(user?.uid || ""));
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <HomeContext.Provider value={{user, sessions, companies, loading, fetch: fetchData}}>
            {props.children}
        </HomeContext.Provider>
    );
}