import InvisibleButton from "../../../../shared/components/button/InvisibleButton";
import {useState} from "react";
import PasswordModalStep4 from "./PasswordModal.step4";
import StepProps from "../../../types/StepProps";
import HintModalStep4 from "./HintModal.step4";

interface ButtonsStep4Props {
    showHint: boolean;
    playerNumber: number;
    mainHintPos: {x: number, y: number, h: number, w: number};
    secondHintPos: {x: number, y: number, h: number, w: number};
    stepContext: StepProps;
}

export default function ButtonsStep4({playerNumber, showHint, mainHintPos, secondHintPos, stepContext}: ButtonsStep4Props) {
    const [hintModal, setHintModal] = useState<boolean>(false);
    const [allHintModal, setAllHintModal] = useState<boolean>(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);



    return (
        <>
            <HintModalStep4 open={hintModal} setOpen={setHintModal} number={playerNumber}/>
            <HintModalStep4 open={allHintModal} setOpen={setAllHintModal} number={5}/>
            <PasswordModalStep4 open={openPasswordModal} setOpen={setOpenPasswordModal} />


            {/* Main Hint Button */}
            <InvisibleButton onClick={() => setHintModal(true)} top={mainHintPos.y} left={mainHintPos.x} h={mainHintPos.h} w={mainHintPos.w} showHint={showHint}/>
            {/* All Hint Button */}
            <InvisibleButton onClick={() => setAllHintModal(true)} top={secondHintPos.y} left={secondHintPos.x} h={secondHintPos.h} w={secondHintPos.w} showHint={showHint}/>
            {/* Password Modal */}
            <InvisibleButton onClick={() => setOpenPasswordModal(true)} top={315} left={762} h={141} w={157} showHint={showHint}/>
        </>
    )
}