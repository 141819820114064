import {Flex} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import Question from "./type/Question";
import t from "../../../configs/i18n";

interface QuestionDescriptionProps {
    question: Question;
    validated: boolean | undefined;
}

export default function QuestionDescription({validated, question}: QuestionDescriptionProps) {
    let bgColor = "bg-black"
    if (validated === true) {
        bgColor = "bg-green-500"
    } else if (validated === false) {
        bgColor = "bg-red-500"
    }

    return (
        <Flex align={"center"} className={"w-3/4 text-center"} vertical>
            {validated === true && <>
                <CheckOutlined className={"text-6xl text-green-light"}/>
                <p className={"text-green-dark font-bold"}>{t.sessionGame.goodAnswerQuiz} {question.bonus} {t.common.secondes}.</p>
            </>}
            {validated === false && <>
                <CloseOutlined className={"text-6xl text-red-500"}/>
                <p className={"text-red-500 font-bold"}>{t.sessionGame.wrongAnswerQuiz} {question.bonus} {t.common.secondes}.</p>
            </>}
            <p>{question.name}</p>
            <Flex className={`p-4 ${bgColor} bg-opacity-10`}>
                <p className={"font-bold"}>{question.description}</p>
            </Flex>
        </Flex>
    )
}