import {Button, Flex, Form, Modal} from "antd";
import StepProps from "../../../types/StepProps";
import ImageR from "../../../../shared/components/image/ImageR";
import PopupGoodAnswer from "../../../../shared/components/popup/PopupGoodAnswer";
import {useState} from "react";
import Answer from "../../../../shared/services/answer/Answer";
import Notification from "../../../../shared/services/notification/Notification";
import CanClosePopup from "../../../../shared/components/text/CanClosePopup";
import {useGameContext} from "../../../../pages/session_game/context/context.game";
import t from "../../../../configs/i18n";
import SyncronizedInput from "../../../../shared/components/input/SyncronizedInput";
import {LockOutlined} from "@ant-design/icons";

interface PasswordModalStep4Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    stepContext: StepProps;
}


export default function PasswordModalStep5({open, setOpen, stepContext}: PasswordModalStep4Props) {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const context = useGameContext();
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);


    const modalStyles = {
        content: {
            backgroundColor: "#FDFCF5"
        }
    };

    const validate = () => {
        const answers1 = context.step?.description.phrase1.map((info : {letter: string, number: number}) => info.letter);
        const answers2 = context.step?.description.phrase2.map((info : {letter: string, number: number}) => info.letter);
        const isValid = Answer.isCorrectWithOrder(form1.getFieldsValue(), answers1, false);
        const isValid2 = Answer.isCorrectWithOrder(form2.getFieldsValue(), answers2, false);


        if (!isValid || !isValid2) {
            Notification.error(t.sessionGame.wrongAnswer);
            return;
        }

        setOpenSuccess(true)
        setOpen(false);
    }


    return (
        <>
            <PopupGoodAnswer currentUser={stepContext.currentUser} open={openSuccess} setOpen={setOpenSuccess} team={stepContext.team} player={stepContext.player} playersTeam={stepContext.playersTeam} />
            <Modal styles={modalStyles} width={800} open={open} footer={null} onCancel={() => setOpen(false)}>
                <CanClosePopup/>
                <Flex className={"w-full mt-4"} justify={"center"}>
                    <h2>{context.step?.description.title}</h2>
                </Flex>
                <Flex gap={"10px"} justify={"center"} className={"w-full mt-4"}>
                    {context.step?.description.phrase1.map((info : {letter: string, number: number, placeholder: string}, index : number) => (
                        <Flex className={"text-center"} align={"center"} justify={"center"} vertical>
                            <p>{info.placeholder}</p>
                            <SyncronizedInput forcePlacholder={true} placeHolder={""} className={"w-[50px]"}
                                              number={info.number}
                                              id={`5-${info.number}-${index}-${stepContext.team?.id}`}
                                              player={stepContext.player} playersTeam={stepContext.playersTeam}
                                              form={form1}/>
                        </Flex>
                    ))}
                </Flex>
                <Flex gap={"10px"} justify={"center"} className={"w-full mt-8"}>
                    {context.step?.description.phrase2.map((info : {letter: string, number: number, placeholder: string}, index : number) => (
                        <Flex className={"text-center"} align={"center"} justify={"center"} vertical>
                            <p>{info.placeholder}</p>
                            <SyncronizedInput forcePlacholder={true} placeHolder={""} className={"text-xs"} number={info.number} id={`5-2-${info.number}-${index}-${stepContext.team?.id}`} player={stepContext.player} playersTeam={stepContext.playersTeam} form={form2}/>
                        </Flex>
                    ))}
                </Flex>
                <Flex justify={"center"}>
                    <Button onClick={validate} className={"mt-10"} type={"primary"}>{t.common.validate}</Button>
                </Flex>
            </Modal>
        </>
    )
}